




import { BaseModel } from './BaseModel';

import { LossParticipantModel } from './LossParticipantModel';

export class AssignmentModel extends BaseModel {
    
    public LossParticipant: LossParticipantModel = null;
    public AssignmentType: string = null;
    public VehicleId: number = 0;
}