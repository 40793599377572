import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { ConstantsService, BreadcrumbService } from '@Common/services';
import { FNOLRoutingService, FNOLAutoService } from '../../services';

import { BaseFormComponent } from '@Common/base/baseForm.component';

import * as moment from 'moment';

@Component({
    selector: 'recoveryInformation',
    templateUrl: '../../views/loss/recoveryInformation.component.html',
    providers: [FNOLRoutingService]
})

export class RecoveryInformationComponent extends BaseFormComponent {
    constructor(private fnolRouting: FNOLRoutingService, private fnolAutoService: FNOLAutoService, private constantsService: ConstantsService, private breadcrumbService: BreadcrumbService,
        private spinner: Ng4LoadingSpinnerService) {
        super();
    }
    public hasRecoveredVehicle: boolean | null;
    public recoveredDate: string;
    public recoveredDateRange: any = {};
    public recoveredDateOptions: any = {
        locale: {
            format: 'MM/DD/YYYY'
        },
        singleDatePicker: true,
        alwaysShowCalendar: true,
        timePicker: false,
        maxDate: new Date
    }
    public hasError: boolean = false;

    public ngOnInit() {
        this.spinner.show();
       
        let autoServiceHasRecoveredVehicle = this.fnolAutoService.getHasRecoveredVehicleFromTheft();
        this.hasRecoveredVehicle = this.recoveryInfoStringToBoolean(autoServiceHasRecoveredVehicle);

        this.recoveredDate = this.fnolAutoService.getDateOfTheftRecovery();

        this.spinner.hide();
    }


    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public submit(form: NgForm) {
        if (this.validate(form)) {
            if (this.recoveredDate != null) {
                this.fnolAutoService.setDateOfTheftRecovery(this.recoveredDate);
            }
            if (this.hasRecoveredVehicle == null) {
                this.hasError = true;
            }
            else {
                if (this.hasRecoveredVehicle) {
                    this.fnolAutoService.setHasRecoveredVehicleFromTheft(this.constantsService.TrueStringValue);
                }
                else if (!this.hasRecoveredVehicle) {
                    this.fnolAutoService.setDateOfTheftRecovery(this.constantsService.EmptyString);
                    this.fnolAutoService.setHasRecoveredVehicleFromTheft(this.constantsService.FalseStringValue);
                }
                this.fnolRouting.next();
            }
        }
    }

    public recoveryInfoStringToBoolean(recoveryInfoString: string): boolean | null {
        if (recoveryInfoString != null && recoveryInfoString != undefined) {
            if (recoveryInfoString == this.constantsService.TrueStringValue) {
                return true;
            }

            if (recoveryInfoString == this.constantsService.FalseStringValue) {
                return false;
            }
        }

        return null;
    }

    public setHasRecoveredVehicle(value: boolean): void {
        this.hasRecoveredVehicle = value;
    }

    public selectedDate(value: any, datepicker?: any) {
        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;
        this.recoveredDate = moment(value.end).format('MM/DD/YYYY');
        // or manupulate your own internal property
        this.recoveredDateRange.start = value.start;
        this.recoveredDateRange.end = value.end;
        this.recoveredDateRange.label = value.label;
    }
}