




import { BaseModel } from './BaseModel';

import { AddressModel } from './AddressModel';

export class AddressValidationModel extends BaseModel {
    
    public IsVerified: boolean = false;
    public IsSuggestedAddress: boolean = false;
    public AddressToValidate: AddressModel = null;
}