import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({ selector: '[dashesPhoneFormat]' })

export class PhoneDirective implements OnChanges {

    @Input() dashesPhoneFormat: string;

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes['dashesPhoneFormat']) {
            this.formatPhoneNumber(this.dashesPhoneFormat);
        }
    }

    formatPhoneNumber(phoneNumber: string) {
        if (!phoneNumber) return;

        let cleaned = phoneNumber.replace(/\D/g, '');

        let formatted = '';
        if (cleaned.length <= 3) {
            formatted = cleaned;
        } else if (cleaned.length <= 6) {
            formatted = `${cleaned.substring(0, 3)}-${cleaned.substring(3)}`;
        } else {
            formatted = `${cleaned.substring(0, 3)}-${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`;
        }
        this.el.nativeElement.innerText = formatted;
    }
    
}