




import { BaseModel } from './BaseModel';
import { BaseLossParticipantModel } from './BaseLossParticipantModel'

export class HomeLossParticipantModel extends BaseLossParticipantModel {
    
    public PropertyId: number = 0;
}