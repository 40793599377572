<div class="reviewSubmissionPopUp">
    <ng-container *ngIf="!isGlassClaim">
        <div class="text-center">
            <h6>Leave site?</h6>
        </div>
        <hr />
        <div class="text-center">
            <p>Changes you made may not be saved.</p>
        </div>

        <div class="d-flex justify-content-center flex-wrap">
            <div class="button-wrapper">
                <button class="btn btn-primary" (click)="cancel()" id="origBtn">Cancel</button>
            </div>
            <div class="button-wrapper">
                <button class="btn btn-primary" (click)="leaveSite()" id="newBtn">Leave</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isGlassClaim">
        <div class="text-center">
            <h6>You are leaving this site and navigating to the <em class="emphasis-bold">Safelite Online Portal</em>.</h6>
        </div>

        <div class="d-flex justify-content-center flex-wrap">
            <div class="button-wrapper">
                <button class="btn btn-primary" (click)="cancel()" id="origBtn">Cancel</button>
            </div>
            <div class="button-wrapper">
                <button class="btn btn-primary" (click)="leaveSite()" id="newBtn">Continue</button>
            </div>
        </div>
    </ng-container>
</div>