<div ngForm #isDamagesOtherThanWindshieldForm="ngForm" class="main-inner-wrapper">
    <p class="header">Glass Damages other than Windshield?</p>
    <span *ngIf="hasError" class="red">Please select one of the following options to continue.</span>

    <div class="input-wrapper">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="setIsDamagesOtherThanWindshield(true);" [ngClass]="{'selected': IsDamagesOtherThanWindshield != null && IsDamagesOtherThanWindshield}">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="setIsDamagesOtherThanWindshield(false); resetGlassDamageAreaDescription();" [ngClass]="{'selected': IsDamagesOtherThanWindshield != null && !IsDamagesOtherThanWindshield}">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
        <div *ngIf="IsDamagesOtherThanWindshield">
            <div class="row">
                <div class="fieldLabel-wrapper blueHeader">
                    <label>Area and Description of Glass Damages<b style="color:darkred">*</b></label>
                </div>
                <textarea Validate required DisallowSpecialCharacters allowedCharacters="." name="GlassDamageAreaDescription" cols="30" rows="10" [(ngModel)]="vm.GlassDamageAreaDescription" #VehicleClaimInfoModel="ngModel" maxlength="255"></textarea>
            </div>

        </div>
    </div>



    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(isDamagesOtherThanWindshieldForm)">Continue</button>
    </div>
</div>