




import { BaseModel } from './BaseModel';
import { BaseClaimTypeModel } from './BaseClaimTypeModel'

export class HomeClaimTypeModel extends BaseClaimTypeModel {
    
    public IsWildfireRelated: boolean = null;
}