




import { BaseModel } from './BaseModel';


export class SMSConsentModel extends BaseModel {
    
    public LossParticipantId: number = 0;
    public CellPhoneNumber: string = null;
    public IsEligibleForMCP: boolean = false;
    public SMSConsent: boolean = null;
}