




import { BaseModel } from './BaseModel';


export class ReporterDetailsModel extends BaseModel {
    
    public BaseUrl: string = null;
    public HomeForm: string = null;
    public AutoForm: string = null;
    public ThirdPartyAutoForm: string = null;
    public Name: string = null;
    public Email: string = null;
    public Phone: string = null;
    public ReportedBy: string = null;
} 