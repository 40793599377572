import { Injectable } from '@angular/core';
import { DeviceTypeEnum } from "@ClaimsModels/index";

@Injectable()
export class DeviceDetectionService {

    public DeviceType: DeviceTypeEnum = DeviceTypeEnum.Unknown;

    constructor() {
        this.determineDeviceType();
    }

    private determineDeviceType(): void {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            this.DeviceType = DeviceTypeEnum.Mobile;
        }
        else {
            this.DeviceType = DeviceTypeEnum.Desktop;
        }
    }

    /*
     * For future proofing, do recommend reading over this if we need to revisit this.
     * user agent checking typically isn't recommended since it can be easily altered.
     * https://stackoverflow.com/questions/7838680/detecting-that-the-browser-has-no-mouse-and-is-touch-only/52854585
     */
}