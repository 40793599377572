<div ngForm #uploadDocumentForm="ngForm" class="main-inner-wrapper">
    <p class="header">Please upload any documents or photos you would like to submit as part of your new claim</p>
     <div class="row w-150">
        <div class="col-12">
            <div class="fieldLabel-wrapper">
                <label>
                    If you are unable to upload your documents at this time, please email as soon as possible to
                    <span><strong><a href="mailto:{{claimsEmail}}" role="button" class="cursor">{{claimsEmail}}</a></strong></span>
                    with your claim number as the subject line.
                </label>
            </div>
            <div class="text-center">Documents and photos are <em class="emphasis-bold">not</em> required for claim submission.</div>
            <file-upload height="25vh" [isDeviceMobile]=isDeviceMobile></file-upload>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(uploadDocumentForm)">Continue</button>
    </div>
</div>