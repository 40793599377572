




import { BaseModel } from './BaseModel';

import { PaymentModel } from './PaymentModel';

export class ExposureModel extends BaseModel {
    
    public AdjusterFirstName: string = null;
    public AdjusterLastName: string = null;
    public AdjusterEmail: string = null;
    public AdjusterPhone: string = null;
    public ExposureClosedDateTime: Date = null;
    public ReserveStatus: string = null;
    public ExposureCategory: string = null;
    public AppraiserFirstName: string = null;
    public AppraiserLastName: string = null;
    public AppraiserEmail: string = null;
    public AssignedToRole: string = null;
    public Payments: PaymentModel[] = [];
    public IsOfficialCoverage: boolean = null;
    public UnofficialCoverage: string = null;
    public AssignmentType: string = null;
}