
<div class="container ina">
    <div class="row inaTitle">
        <div class=" inaRed ">
            Incident Not Available
        </div>
    </div>
    <div class="row inaBreak">
        <br />
    </div>
    <div class="row">
        <div class=" inaText">
            Please reach out to your adjuster at
        </div>
    </div>
    <div class="row">
        <div class=" inaText">
            <span class="bold">{{phoneNumber | mask: "(000) 000-0000" }}</span> for further assistance
        </div>
    </div>
</div>