import { Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
    selector: '[DisallowSpecialCharacters]',
})

export class DisallowCharactersDirective {
    // x08 is backspace; x09 is tab, x0D is carriage return
    private specialCharacters: Array<string> = ['\x08', '\x09', '\0D', ' '];
    private regexString = 'A-Za-z0-9';
    private regex: RegExp = new RegExp('^[' + this.regexString + ']');

    constructor(private el: ElementRef) {
        let specialCharArray = Array<string>();
        let allowedChars = el.nativeElement.getAttribute('allowedCharacters');
        if (allowedChars != null) {
            specialCharArray = allowedChars.split('');
        }
        specialCharArray.forEach(ch => this.specialCharacters.push(ch));

        if (el.nativeElement.getAttribute('disallowWhiteSpace') == 'true') {
            this.specialCharacters = this.specialCharacters.filter(x => x != ' ');
        }
    }


    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        // Allow special characters
        if (e.keyCode !== undefined) {
            let hexCode = '\\x0' + e.keyCode.toString(16);
            if (this.specialCharacters.indexOf(hexCode) !== -1 || this.specialCharacters.indexOf(e.key) !== -1 ||

                (e.key.match(this.regex) != null && e.key.match(this.regex).toString() != '')

            ) {
                return;
            }
            else {
                e.preventDefault();
            }
        }
    }

    @HostListener('paste', ['$event'])
    blockPaste(e: ClipboardEvent) {
        this.validateFields(e);
    }

    validateFields(event) {
        setTimeout(() => {
            let pastedText = this.el.nativeElement.value;
            let outputText = '';
            let allowedChars = '';

            this.specialCharacters.forEach(ch => allowedChars += ch);

            let patternAllowedRegex = '[^' + this.regexString;
            let patternMatch = new RegExp(patternAllowedRegex + allowedChars + ']', 'g');

            outputText = pastedText.replace(patternMatch, '');
            this.el.nativeElement.value = outputText;
            document.execCommand('insertHTML', false, "");
            event.preventDefault();
        }, 100)
    }

}