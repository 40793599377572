import { Component, Input, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ParticipantApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { AddressValidationService, ConstantsService } from '@Common/services/';
import { BaseSelectContactComponent } from './baseSelectContact.component';
import { ClaimTypeCategoryEnum, AssignmentModel } from '@ClaimsModels/index';

@Component({
    selector: 'selectOtherContact',
    templateUrl: '../../views/participant/selectOtherContact.component.html',
    providers: [FNOLRoutingService],
   // changeDetection: ChangeDetectionStrategy.OnPush


})
export class SelectOtherContactComponent extends BaseSelectContactComponent implements OnInit {

    constructor(api: ParticipantApi, spinner: Ng4LoadingSpinnerService, addressValidationService: AddressValidationService,
        fnolRouting: FNOLRoutingService, fnolAutoService: FNOLAutoService, constantsService: ConstantsService) {
        super(api, spinner, addressValidationService, fnolRouting, fnolAutoService, constantsService);
        super.setIsOnPolicy(false);

    }

    @Input() public isThirdParty: boolean;
    public isPedestrian: boolean = false;
    public assignmentType: string = this.fnolRouting.getRoute().snapshot.queryParams.assignmentType;
    public displayInsuranceQuestions(): boolean {
        switch (this.fnolAutoService.getClaimTypeCategory()) {
            case ClaimTypeCategoryEnum.Theft:
                return false;
            default:
                return true;

        }
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.checkClaimType();

    }

    ngDoCheck(): void {
        if (this.returnVM().AssignmentType == this.constantsService.ThirdPartyOwner) {
            this.isThirdParty = true;
        }
    }

    public checkClaimType(): void {
        switch (this.fnolAutoService.getClaimTypeCategory()) {
            case ClaimTypeCategoryEnum.Pedestrian:
                this.isPedestrian = true;
                break;

            default:
                break;
        }
    }

}