import { BaseComponent } from "@Common/base/base.component";
import { Component, Output, EventEmitter, Input } from "@angular/core";
import { ClaimPaymentsModel, PaymentModel, SessionModel, ClaimModel } from '@ClaimsModels/index';
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { SessionService } from '@Common/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'home',
    templateUrl: '../views/home.component.html',
    styleUrls: ['../styles/mc360styling.css']
})

export class HomeComponent extends BaseComponent {
    constructor(private location: Location, private route: ActivatedRoute, private myClaimsApi: MyClaimsApi, private router: Router, private sessionService: SessionService, private dashboardService: DashboardService) {
        super();


    }
    public ngOnInit(): void {
    }
}