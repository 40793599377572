
import { ContactInfoModel } from './ContactInfoModel';
import { AddressModel } from './AddressModel';

export class PedestrianInformationModel {

    public PolicyPersonId: number = 0;
    public FirstName: string = null;
    public MiddleName: string = null;
    public LastName: string = null;
    public IsOnPolicy: boolean = false;
    public IsSelected: boolean = false;
    public IsInjured: boolean = null;
    public LossDescription: string = null; 
    public DateOfBirth?: Date = null;
    public Gender: string = null;
    public ContactInfo: ContactInfoModel = null;
    public Address: AddressModel = null;
    public AccidentRole: string = "";
    public ParticipantRole: string = "";
    public InjuryDescription: string = null;
}