import { Injectable } from "@angular/core";
import { BaseApi } from "@Common/base/base.api";
import { AddressValidationModel } from "@ClaimsModels/index";

@Injectable()
export class AddressValidationApi extends BaseApi {

    public validateAddress(model: AddressValidationModel): Promise<AddressValidationModel> {
        return this.httpPost("Common/AddressValidation/ValidateAddress", model);
    }
}