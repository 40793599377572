import { Component } from "@angular/core";
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { NgForm, FormsModule } from '@angular/forms';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { StyleManagerService, CommonClaimApi, SessionService, ContactService, ConstantsService } from '@Common/services';
import { CallInClaimModel, BrandEnum  } from "@ClaimsModels/index";


@Component({
    selector: 'policyCallInClaim',
    templateUrl: '../views/policyCallInClaim.component.html'
})
export class PolicyCallInClaimComponent extends BaseFormComponent {

    public vm: CallInClaimModel = new CallInClaimModel();

    constructor(private spinner: Ng4LoadingSpinnerService, private styleManager: StyleManagerService, private api: CommonClaimApi, private session: SessionService, private contactService: ContactService, private constants: ConstantsService) {
        super();
    }

    public isEncompassBrand: boolean;
    public isFNOLOther: boolean;

    public ngOnInit(): void {

        if (this.session.getWorkflowType() == this.constants.FNOLOther) {
            this.isFNOLOther = true;
        }
        if (BrandEnum.EncompassInsurance === this.session.getBrand()) {
            this.isEncompassBrand = true;
        }

        this.spinner.show();
        this.api.getCallInClaimModel().then(response => {
            this.spinner.hide();
            this.vm.ReturnUrl = response.ReturnUrl;

            if (!response.Success || this.IsNullOrEmptyString(response.ReturnUrl)) {
                this.vm.ReturnUrl = this.getDefaultReturnUrl();
            }
        });
    }

    public submit(form: NgForm): void { }

    public goingBackWithLocation(): void {
        window.history.back();
    }

    private getDefaultReturnUrl(): string {
        let returnUrl: string;

        this.styleManager.returnUrl.subscribe(url => {
            returnUrl = url;
        })

        return returnUrl;
    }

    public getPageHeader(): string {
        let header: string;
        let brand: string = this.session.getBrand();

        let isFNOLOther = this.session.getWorkflowType() == this.constants.FNOLOther ? true : false;

        if (isFNOLOther) {
            header = 'Please call the number below to report your claim.'
        }
        else {
        switch (brand) {
            case BrandEnum.SafeAuto:
            case BrandEnum.Esurance:
                header = `Please click the back button below and select Personal Auto, or you may call to report your claim.`;
                break;
            default:
                header = `Please click the back button and select the Personal Auto or Home / Dwelling option to report a new claim. You can also choose one of the options below.`;
            }
        }

        return header;
    }

    public getClaimPhoneNumber(): string {
        return this.contactService.getClaimsPhone();
    }

}