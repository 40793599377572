import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonDirectivesModule } from '../../ClaimsWeb.Areas.Common/app/commonDirectives.module';
import { CommonComponentsModule } from '../../ClaimsWeb.Areas.Common/app/commonComponents.module';
import { CommonModule } from '@Common/common.module';
import { NgxMaskModule } from 'ngx-mask';
import { AuthGuardService } from '@Common/services/';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    CustomerValidationComponent, CustomerValidationErrorComponent, MyClaimsHomeComponent, ClaimDetailComponent,
    VehicleDamageComponent, MessagesComponent, PaymentsComponent, DocumentsComponent, DocumentUploadComponent, DocumentDetailsComponent, ClaimStatusBarComponent,
    ContactsComponent, DashboardComponent, ClaimOverviewComponent, NavHeaderComponent, HomeDocumentsComponent, SMSTextOptionComponent, HomeComponent, BridgeComponent,
    IncidentNotAvailableComponent
    
} from './components';
import { SMSModalService } from '../app/services/smsModal.service';
import { MyClaimsApi, DashboardService } from './services';
import { PhoneDirective } from './directives/phone.directive';
 
const myClaims360Routes: Routes = [
    {
        path: 'myClaims360/customerValidation/:id',
        component: CustomerValidationComponent,
        canActivate: [AuthGuardService]
    },
    { path: 'myClaims360/customerValidationError', component: CustomerValidationErrorComponent },
    { path: 'myClaims360/myClaimHome/:sessionId', component: MyClaimsHomeComponent },
    { path: 'myClaims360/claimDetails/:sessionId', component: ClaimDetailComponent },
    { path: 'myClaims360/messages', component: MessagesComponent },
    { path: 'myClaims360/payments/:lossParticipantId', component: PaymentsComponent },
    { path: 'myClaims360/documents/:lossParticipantId', component: DocumentsComponent },
    { path: 'myClaims360/documentUpload/:lossParticipantId', component: DocumentUploadComponent },
    { path: 'myClaims360/documentDetails/:documentId', component: DocumentDetailsComponent },
    { path: 'myClaims360/bridge/:sessionId', component: BridgeComponent },
    { path: 'myClaims360/incidentNotAvailable', component: IncidentNotAvailableComponent },
    {
        path: 'myClaims360/dashboard', component: DashboardComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent },
            { path: 'payments', component: PaymentsComponent },
            {path: 'documents',component:HomeDocumentsComponent}
        ]
    }
];

@NgModule({
    declarations: [
        CustomerValidationComponent,
        MyClaimsHomeComponent,
        ClaimDetailComponent,
        VehicleDamageComponent,
        MessagesComponent,
        PaymentsComponent,
        DocumentsComponent,
        ClaimStatusBarComponent,
        DocumentDetailsComponent,
        DocumentUploadComponent,
        ContactsComponent,
        DashboardComponent,
        ClaimOverviewComponent,
        NavHeaderComponent,
        HomeDocumentsComponent,
        SMSTextOptionComponent,
        HomeComponent,
        BridgeComponent,
        IncidentNotAvailableComponent,
        PhoneDirective
    ],
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forChild(myClaims360Routes),
        FormsModule,
        NgxMaskModule.forRoot(),
        CommonDirectivesModule,
        MatPaginatorModule,
        CommonComponentsModule,
        MatInputModule,
        MatTableModule,
        MatToolbarModule,
        BrowserAnimationsModule
    ],
    providers: [
        MyClaimsApi, DashboardService, SMSModalService, SMSTextOptionComponent
    ]
})
export class MyClaims360Module { }