<div ngForm #claimTypeForm="ngForm" class="main-inner-wrapper">
    <p class="header" *ngIf="!firstSelectionMade  || firstSelectionMade && isCollision ">Please select a category which best describes your incident.</p>

    <p class="header" *ngIf="firstSelectionMade && isWeatherEvent">What type of weather event damaged your vehicle?</p>
    <p class="header" *ngIf="firstSelectionMade && (isTowing || isSingleCar || isMultipleVehicle || isPedestrian || isTheftVandalism || isMiscDamage || selectedSubCategoryName !== '')">Please tell us more about the incident.</p>
    <span *ngIf="hasError" class="red">Please select one of the following options to continue.</span>

    <span class="text-info mb-3" *ngIf="!firstSelectionMade && selectedSubCategoryName === '' || firstSelectionMade && isCollision || isTheftVandalism">
        <i class="fas fa-info-circle"></i>
        Click on each tile for more details.
    </span>

    <!-- Top level selection -->
    <!-- Order of divs depends on claimType.scss flexbox-->
    <div id="claimTypeFlex">
        <div class="d-flex flex-wrap justify-content-center claims-wrapper" *ngIf="!firstSelectionMade && selectedSubCategoryName === ''">
            <div id="collision" class="button-wrapper" (click)="selectTypeService($event, collision)">
                <div id="collision" class="icon cursor" [ngClass]="{'selectedService': collision.isSelected }">
                    <img src="/content/images/multipleVehicleAccident-outline.svg" title="multipleVehicleAccident image" alt="collision image"
                         width="100" height="100" *ngIf="!collision.isSelected" />
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><strong>Collision</strong></div>
                        <div class="claims-innertext" *ngIf="collision.isSelected">
                            <p>{{ collision.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="weatherEventSection" class="button-wrapper" (click)="selectTypeService($event, weatherEvent)">
                <div id="weatherEvent" class="icon cursor" [ngClass]="{'selectedService': weatherEvent.isSelected }">
                    <img src="/content/images/weatherEvent-outline.svg" title="weatherEvent image"
                         alt="weatherEvent image" width="100" height="100" *ngIf="!weatherEvent.isSelected" />
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><strong>Weather Event</strong></div>
                        <div class="claims-innertext" *ngIf="weatherEvent.isSelected">
                            <p>{{ weatherEvent.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="theftVandalismSection" class="button-wrapper" (click)="selectTypeService($event, theftVandalism)">
                <div id="theftVandalism" class="icon cursor" [ngClass]="{'selectedService': theftVandalism.isSelected }">
                    <img src="/content/images/theft-outline.svg" title="theft image" alt="theft image"
                         width="100" height="100" *ngIf="!theftVandalism.isSelected" />
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><strong>Theft/Vandalism</strong></div>
                        <div class="claims-innertext" *ngIf="theftVandalism.isSelected">
                            <p>{{ theftVandalism.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="fireSection" class="button-wrapper" (click)="selectTypeService($event, fire)">
                <div id="fire" class="icon cursor" [ngClass]="{'selectedService': fire.isSelected }">
                    <img src="/content/images/fire-outline.svg" title="fire image" alt="fire image"
                         width="100" height="100" *ngIf="!fire.isSelected" />
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><Strong>Fire Damage</Strong></div>
                        <div class="claims-innertext" *ngIf="fire.isSelected">
                            <p>{{ fire.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="miscRVSection" class="button-wrapper" (click)="selectTypeService($event, miscRV)" *ngIf="isRVWorkflow">
                <div id="miscRV" class="icon cursor" [ngClass]="{'selectedService': miscRV.isSelected }">
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><Strong>Damages not related to Weather Event or Fire</Strong></div>
                        <div class="claims-innertext" *ngIf="miscRV.isSelected">
                            <p>{{ miscRV.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="firstSelectionMade && isTheftVandalism" class="d-flex flex-wrap justify-content-center claims-wrapper">
            <div id="theftSection" class="button-wrapper" (click)="selectTypeService($event, theft)">
                <div id="theft" class="icon cursor" [ngClass]="{'selectedService': theft.isSelected }">
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><strong>The vehicle was stolen</strong></div>
                    </div>
                </div>
            </div>
            <div id="vandalismSection" class="button-wrapper" (click)="selectTypeService($event, vandalism)">
                <div id="vandalism" class="icon cursor" [ngClass]="{'selectedService': vandalism.isSelected }">
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><Strong>The vehicle was broken into or vandalized</Strong></div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="firstSelectionMade && isCollision" class="d-flex flex-wrap justify-content-center claims-wrapper">
            <div id="singlecarSection" class="button-wrapper" (click)="selectTypeService($event, singlecar)">
                <div id="singlecar" class="icon cursor" [ngClass]="{'selectedService': singlecar.isSelected }">
                    <img src="/content/images/singlecar-outline.svg" title="singlecar image" alt="singlecar image"
                         width="100" height="100" *ngIf="!singlecar.isSelected" />
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><Strong>Single Vehicle Accident</Strong></div>
                        <div class="claims-innertext" *ngIf="singlecar.isSelected">
                            <p>{{ singlecar.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="multipleVehicleAccidentSection" class="button-wrapper" (click)="selectTypeService($event, multipleVehicleAccident)">
                <div id="multipleVehicleAccident" class="icon cursor" [ngClass]="{'selectedService': multipleVehicleAccident.isSelected }">
                    <img src="/content/images/multipleVehicleAccident-outline.svg" title="multipleVehicleAccident image" alt="multipleVehicleAccident image"
                         width="100" height="100" *ngIf="!multipleVehicleAccident.isSelected" />
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><strong>Multiple Vehicle Accident</strong></div>
                        <div class="claims-innertext" *ngIf="multipleVehicleAccident.isSelected">
                            <p>{{ multipleVehicleAccident.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div id="pedestrianSection" class="button-wrapper" (click)="selectTypeService($event, pedestrian)">
                <div id="pedestrian" class="icon cursor" [ngClass]="{'selectedService': pedestrian.isSelected }">
                    <img src="/content/images/pedestrian-outline.svg" title="pedestrian image" alt="pedestrian image"
                         width="100" height="100" *ngIf="!pedestrian.isSelected" />
                    <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                        <div class="claims-innertext"><strong>Pedestrian Accident</strong></div>
                        <div class="claims-innertext" *ngIf="pedestrian.isSelected">
                            <p>{{ pedestrian.helpText }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- Weather Events -->
    <div id="claimTypeWeatherFlex" *ngIf="firstSelectionMade && isWeatherEvent" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">

        <div id="earthquakeSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': earthquake.isSelected }" (click)="selectTypeService($event, earthquake)">
                <span>Earthquake</span>
                <i *ngIf="earthquake.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="hailSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': hail.isSelected }" (click)="selectTypeService($event, hail)">
                <span>Hail</span>
                <i *ngIf="hail.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="floodSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': flood.isSelected }" (click)="selectTypeService($event, flood)">
                <span>Flood/Rising water</span>
                <i *ngIf="flood.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="windTornadoSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': windTornado.isSelected }" (click)="selectTypeService($event, windTornado)">
                <span>Wind/Tornado</span>
                <i *ngIf="windTornado.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="snowSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': snow.isSelected }" (click)="selectTypeService($event, snow)">
                <span>Snow/Ice</span>
                <i *ngIf="snow.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="lightningSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': lightning.isSelected }" (click)="selectTypeService($event, lightning)">
                <span>Lightning</span>
                <i *ngIf="lightning.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
    </div>

    <!-- Single Vehicle Accidents -->
    <!-- Order of divs depends on claimType.scss flexbox-->
    <div id="claimTypeAutoFlex" *ngIf="firstSelectionMade && isSingleCar" class="d-flex justify-content-center flex-wrap flex-row input-wrapper">
        <div id="tireBlowoutAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': tireBlowout.isSelected }" (click)="selectTypeService($event, tireBlowout)">
                <span>Tire Blowout</span>
                <i *ngIf="tireBlowout.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="roadHazardAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <div class="subCategory" [ngClass]="{'selected': roadHazard.isSelected }" (click)="selectTypeService($event, roadHazard)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Road Hazard</span>
                    <span><em>Debris or potholes</em></span>
                </div>
                <i *ngIf="roadHazard.isSelected" class="fas fa-check-circle"></i>
            </div>
        </div>
        <div id="hitAndRunAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': hitAndRun.isSelected }" (click)="selectTypeService($event, hitAndRun)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Hit and Run</span>
                    <span><em>Struck by an unknown vehicle</em></span>
                </div>
                <i *ngIf="hitAndRun.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="fallingObjectAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <div class="subCategory" [ngClass]="{'selected': fallingObject.isSelected }" (click)="selectTypeService($event, fallingObject)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Falling Object </span>
                    <span><em>Tree branches, an item flew off another vehicle</em></span>
                </div>
                <i *ngIf="fallingObject.isSelected" class="fas fa-check-circle"></i>
            </div>
        </div>
        <div id="animalCollisionAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': animalCollision.isSelected }" (click)="selectTypeService($event, animalCollision)">
                <span>Struck an Animal</span>
                <i *ngIf="animalCollision.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="fixedPropertyAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <div class="subCategory" [ngClass]="{'selected': fixedProperty.isSelected }" (click)="selectTypeService($event, fixedProperty)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Struck Fixed Property</span>
                    <span *ngIf="!isDeviceMobile"><em>Mailbox, fence, pole</em></span>
                </div>
                <i *ngIf="fixedProperty.isSelected" class="fas fa-check-circle"></i>
            </div>
        </div>
        <div id="singleVehicleOtherAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': singleVehicleOther.isSelected }" (click)="selectTypeService($event, singleVehicleOther)">
                <span>Other</span>
                <i *ngIf="singleVehicleOther.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
    </div>

    <!-- Multiple Vehicle Accidents -->
    <!-- Order of divs depends on claimType.scss flexbox-->
    <div id="claimTypeAutoMultiFlex" *ngIf="firstSelectionMade && isMultipleVehicle && selectedSubCategoryName === ''" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        <div id="rearEndedAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': rearEndedCategory.isSelected }" (click)="selectTypeService($event, rearEndedCategory)">
                <span>Rear-end</span>
                <i *ngIf="rearEndedCategory.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="parkingLotAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <div class="subCategory" [ngClass]="{'selected': parkingLotCategory.isSelected }" (click)="selectTypeService($event, parkingLotCategory)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Parking Lot</span>
                </div>
                <i *ngIf="parkingLotCategory.isSelected" class="fas fa-check-circle"></i>
            </div>
        </div>
        <div id="backingAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <div class="subCategory" [ngClass]="{'selected': backingCategory.isSelected }" (click)="selectTypeService($event, backingCategory)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Backing</span>
                </div>
                <i *ngIf="backingCategory.isSelected" class="fas fa-check-circle"></i>
            </div>
        </div>
        <div id="intersectionAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <div class="subCategory" [ngClass]="{'selected': intersection.isSelected }" (click)="selectTypeService($event, intersection)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Intersection</span>
                    <span><em>T-bone or turning accident</em></span>
                </div>
                <i *ngIf="intersection.isSelected" class="fas fa-check-circle"></i>
            </div>
        </div>
        <div id="headOnAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': headOn.isSelected }" (click)="selectTypeService($event, headOn)">
                <span>Head on Collision</span>
                <i *ngIf="headOn.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="passingMergingAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': passingMergingCategory.isSelected}" (click)="selectTypeService($event, passingMergingCategory)">
                <span>Passing/Merging</span>
                <i *ngIf="passingMergingCategory.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="sideswipeAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': sideswipeCategory.isSelected}" (click)="selectTypeService($event, sideswipeCategory)">
                <span>Sideswipe</span>
                <i *ngIf="sideswipeCategory.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="mvHitAndRunAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': mvHitAndRun.isSelected }" (click)="selectTypeService($event, mvHitAndRun)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Hit and Run</span>
                    <span><em>Struck by an unknown vehicle</em></span>
                </div>
                <i *ngIf="mvHitAndRun.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="otherAutoMultiSection" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': otherCategory.isSelected}" (click)="selectTypeService($event, otherCategory)">
                <span>Other</span>
                <i *ngIf="otherCategory.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
    </div>

    <!-- Multiple Vehicle Category selection -->
    <div [ngSwitch]="selectedSubCategoryName" *ngIf="!isCollision" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        <ng-container *ngSwitchCase="'rearEndedCategory'">
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': rearEndedNAF.isSelected }" (click)="selectTypeService($event, rearEndedNAF)">
                    <span>I was <em>rear-ended</em></span>
                    <i *ngIf="rearEndedNAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': rearEndedAF.isSelected }" (click)="selectTypeService($event, rearEndedAF)">
                    <span>I <em>rear-ended</em> someone else's vehicle</span>
                    <i *ngIf="rearEndedAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'parkingLotCategory'">
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': parkingLotAF.isSelected }" (click)="selectTypeService($event, parkingLotAF)">
                    <span>I struck another vehicle in a <em>parking lot</em></span>
                    <i *ngIf="parkingLotAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': parkingLotNAF.isSelected }" (click)="selectTypeService($event, parkingLotNAF)">
                    <span>Someone struck my vehicle in a <em>parking lot</em></span>
                    <i *ngIf="parkingLotNAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'backingCategory'">
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': backingAF.isSelected }" (click)="selectTypeService($event, backingAF)">
                    <span>I <em>backed</em> into another vehicle</span>
                    <i *ngIf="backingAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': backingNAF.isSelected }" (click)="selectTypeService($event, backingNAF)">
                    <span>Someone <em>backed</em> into my vehicle</span>
                    <i *ngIf="backingNAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'sideswipeCategory'">
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': sideSwipeAF.isSelected }" (click)="selectTypeService($event, sideSwipeAF)">
                    <span>My vehicle <em>sideswiped</em> a vehicle</span>
                    <i *ngIf="sideSwipeAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': sideSwipeNAF.isSelected }" (click)="selectTypeService($event, sideSwipeNAF)">
                    <span>Another vehicle <em>sideswiped</em> my vehicle</span>
                    <i *ngIf="sideSwipeNAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'passingMergingCategory'">
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': passingNAF.isSelected }" (click)="selectTypeService($event, passingNAF)">
                    <span>My vehicle was damaged when another vehicle was attempting to <em>merge or pass</em> my vehicle</span>
                    <i *ngIf="passingNAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': passingAF.isSelected }" (click)="selectTypeService($event, passingAF)">
                    <span>My vehicle was damaged while <em>passing</em> another vehicle or making a lane change</span>
                    <i *ngIf="passingAF.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'otherCategory'">
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': inMotion.isSelected }" (click)="selectTypeService($event, inMotion)">
                    <span>My vehicle was <em>in motion</em> when a collision occurred</span>
                    <i *ngIf="inMotion.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div class="button-wrapper col-lg-12 col-xs-12">
                <button class="subCategory" [ngClass]="{'selected': notInMotion.isSelected }" (click)="selectTypeService($event, notInMotion)">
                    <span>My vehicle was <em><strong>not</strong> in motion</em> when a collision occurred</span>
                    <i *ngIf="notInMotion.isSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
        </ng-container>
    </div>

    <!-- Pedestrian Incidents -->
    <div *ngIf="firstSelectionMade && isPedestrian" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': hitPedestrian.isSelected }" (click)="selectTypeService($event, hitPedestrian)">
                <span>I hit a pedestrian with my vehicle</span>
                <i *ngIf="hitPedestrian.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': wasHit.isSelected }" (click)="selectTypeService($event, wasHit)">
                <span>I was hit as a pedestrian by another vehicle.</span>
                <i *ngIf="wasHit.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
    </div>
    <div *ngIf="firstSelectionMade && isWindshield" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        For Glass Only please call - <a href="tel:+1-855-326-1882">1-855-326-1882</a>
    </div>

    <!-- Towing and Roadside Services-->
    <div *ngIf="firstSelectionMade && isTowing" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': mechanicalBreakdown.isSelected }" (click)="selectTypeService($event, mechanicalBreakdown)">
                <div class="subCategory-innertext-wrapper">
                    <span class="mb-2">Towing due to Mechanical Breakdown</span>
                </div>
                <i *ngIf="mechanicalBreakdown.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': lockOut.isSelected }" (click)="selectTypeService($event, lockOut)">
                <span>Lock Out</span>
                <i *ngIf="lockOut.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': jumpStart.isSelected }" (click)="selectTypeService($event, jumpStart)">
                <span>Jump Start</span>
                <i *ngIf="jumpStart.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': flatTire.isSelected }" (click)="selectTypeService($event, flatTire)">
                <span>Flat Tire/Tire Change</span>
                <i *ngIf="flatTire.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': gasDelivery.isSelected }" (click)="selectTypeService($event, gasDelivery)">
                <span>Gas Delivery</span>
                <i *ngIf="gasDelivery.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': winch.isSelected }" (click)="selectTypeService($event, winch)">
                <div class="subCategory-innertext-wrapper">
                    <span>Winch</span>
                    <span><em> Recover vehicle stuck in mud, ice or snow</em></span>
                </div>
                <i *ngIf="winch.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': illegallyParkedTow.isSelected }" (click)="selectTypeService($event, illegallyParkedTow)">
                <span>Illegally Parked Tow</span>
                <i *ngIf="illegallyParkedTow.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': impounded.isSelected }" (click)="selectTypeService($event, impounded)">
                <div class="subCategory-innertext-wrapper">
                    <span>Impounded</span>
                    <span><em>Storage charges related to vehicle impound</em></span>
                </div>
                <i *ngIf="impounded.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': other.isSelected }" (click)="selectTypeService($event, other)">
                <div class="subCategory-innertext-wrapper">
                    <span>Other</span>
                    <span><em>Towing or Roadside Assistance for accident and weather/fire related damages</em></span>
                </div>
                <i *ngIf="other.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
    </div>

    <!-- misc RV damages -->
    <div *ngIf="firstSelectionMade && isMiscDamage" class="d-flex justify-content-center flex-wrap flex-row input-wrapper">
        <div id="miscAnimalsAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': miscAnimals.isSelected }" (click)="selectTypeService($event, miscAnimals)">
                <span>Damages caused by Animals/Rodents</span>
                <i *ngIf="miscAnimals.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="miscWaterAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': miscWater.isSelected }" (click)="selectTypeService($event, miscWater)">
                <div class="subCategory-innertext-wrapper">
                    <span>Water Damage</span>
                    <span><em>Burst Pipe, Roof/Slide out leak, etc.</em></span>
                </div>
                <i *ngIf="miscWater.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="miscElectricalAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': miscElectrical.isSelected }" (click)="selectTypeService($event, miscElectrical)">
                <div class="subCategory-innertext-wrapper">
                    <span>Electrical</span>
                    <span><em>Power Surge, etc.</em></span>
                </div>
                <i *ngIf="miscElectrical.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
        <div id="miscOtherAutoFlex" class="button-wrapper col-lg-12 col-xs-12">
            <button class="subCategory" [ngClass]="{'selected': miscOther.isSelected }" (click)="selectTypeService($event, miscOther)">
                <span>Other</span>
                <i *ngIf="miscOther.isSelected" class="fas fa-check-circle"></i>
            </button>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" *ngIf="firstSelectionMade" (click)="goBack()">Back</button>
        <button id="backBtn" class="btn btn-primary" *ngIf="!firstSelectionMade" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">Continue</button>
    </div>
</div>