import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { Component } from "@angular/core";
import { Location } from '@angular/common';
import { LossParticipantMessagesModel, MessageModel } from '@ClaimsModels/index';

@Component({
    selector: 'messages',
    templateUrl: '../views/messages.component.html'
})

export class MessagesComponent extends BaseFormComponent {
    public vm: LossParticipantMessagesModel;

    constructor(private location: Location, private route: ActivatedRoute,
        private myClaimsApi: MyClaimsApi, private router: Router, private dashboardService: DashboardService) {
        super();
    }

    public ngOnInit(): void {
        this.vm = new LossParticipantMessagesModel();
        this.vm.Messages = [];
        this.vm.NewMessage = new MessageModel();

        this.myClaimsApi.getMessages(+this.dashboardService.getLossParticipantId()).then((response) => {
            this.vm = response;
        });
    }

    public goingBackWithLocation(): void {
        this.location.back();
    }

    protected submit(form: NgForm): void {
      //  this.myClaimsApi.sa
    }
}