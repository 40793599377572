import { Component } from '@angular/core';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { HomeService, HomeRoutingService, LossApi } from '@FNOLHome/services/';
import { BreadcrumbService, ConstantsService, ClaimTypeService, SessionService } from '@Common/services/';
import { HomeClaimTypeModel, ClaimTypeCategoryEnum, FNOLClaimType, ClaimTypeEnum, BrandEnum } from '@ClaimsModels/index';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'claimType',
    templateUrl: '../../views/loss/claimType.component.html',
    providers: [HomeRoutingService]
})
export class ClaimTypeComponent extends BaseFormComponent {

    public hasError: boolean = false;
    public firstSelectionMade: boolean = false;
    public userWeatherSelection: boolean = null;
    public userPersonalPropertySelection: string = null;
    public isWeatherRelated: boolean = null;
    public isPropertyRelated: boolean = null;
    public showWildfireQuestion: boolean = false;

    public vm: HomeClaimTypeModel = new HomeClaimTypeModel();
    public claimTypes: FNOLClaimType[];
    public equipmentBreakdownImage: FNOLClaimType = { IsSelected: false, ClaimTypeCategory: null, ClaimTypeCode: null, Name: this.constantsService.HomeEquipment };
    public otherStructuresImage: FNOLClaimType = { IsSelected: false, ClaimTypeCategory: null, ClaimTypeCode: null, Name: this.constantsService.HomeOtherStructure };
    public personalPropertyImage: FNOLClaimType = { IsSelected: false, ClaimTypeCategory: null, ClaimTypeCode: null, Name: this.constantsService.HomePersonalProperty };
    public imageChoices: FNOLClaimType[] = [this.equipmentBreakdownImage, this.otherStructuresImage, this.personalPropertyImage];

    constructor(private api: LossApi, private spinner: Ng4LoadingSpinnerService, private fnolHomeService: HomeService, private fnolRouting: HomeRoutingService,
        private breadcrumbService: BreadcrumbService, private constantsService: ConstantsService, private claimTypeService: ClaimTypeService, private sessionService: SessionService) {
        super();
    }

    public toggleImageSelection(selectedImage: FNOLClaimType): void {
        let previousImage: FNOLClaimType = this.imageChoices.find(image => image.IsSelected);

        // if any are selected, deselect
        if (previousImage) {
            previousImage.IsSelected = !previousImage.IsSelected; // deselect
        }

        selectedImage.IsSelected = !selectedImage.IsSelected;

        if (this.personalPropertyImage.IsSelected) {
            this.isWeatherRelated = null;
            this.userWeatherSelection = null;
        }
    }

    // so we can use the ClaimTypeCategory in the Template
    public get ClaimTypeCategory(): typeof ClaimTypeCategoryEnum {
        return ClaimTypeCategoryEnum;
    }

    public filterClaimTypes(claimTypeCategory: ClaimTypeCategoryEnum): FNOLClaimType[] {

        // if we have it, return the filter
        if (claimTypeCategory) {
            return this.claimTypes.filter(category => category.ClaimTypeCategory == claimTypeCategory);
        }

        // if claimType category is null, we're filtering based on whatever menu we are on

        // if we are doing a weather query
        if (this.isWeatherRelated != null) {

            // Dwelling - Weather Related: Yes
            if (this.isPropertyRelated != null && this.isPropertyRelated && this.isWeatherRelated && !this.otherStructuresImage.IsSelected) {
                return this.claimTypes.filter(x => x.ClaimTypeCategory == ClaimTypeCategoryEnum.DwellingWeatherRelated);
            };

            // Dwelling - Weather Related: No
            if (this.isPropertyRelated != null && this.isPropertyRelated && !this.isWeatherRelated && !this.otherStructuresImage.IsSelected) {
                return this.claimTypes.filter(x => x.ClaimTypeCategory == ClaimTypeCategoryEnum.DwellingWeatherNotRelated);
            };

            // Other Structures - Weather Related: Yes
            if (this.otherStructuresImage.IsSelected && this.isWeatherRelated != null && this.isWeatherRelated) {
                return this.claimTypes.filter(x => x.ClaimTypeCategory == ClaimTypeCategoryEnum.OtherStructureWeatherRelated);
            };

            // Other Structures - Weather Related: No
            if (this.otherStructuresImage.IsSelected && this.isWeatherRelated != null && !this.isWeatherRelated) {
                return this.claimTypes.filter(x => x.ClaimTypeCategory == ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated);
            };
        }

    }

    public selectClaimType(claimType: FNOLClaimType): void {

        // Menu level selection
        if (this.isPropertyRelated == null && this.personalPropertyImage.IsSelected) {
            this.userPersonalPropertySelection = claimType.ClaimTypeCode;
        }

        // Individual item selection
        let selectedClaimType: FNOLClaimType = this.claimTypes.find(x => x.IsSelected);

        // Deselect the claim type
        if (selectedClaimType) {
            selectedClaimType.IsSelected = !selectedClaimType.IsSelected;
        }

        this.checkForWildfire(claimType);

        // Select the new one
        claimType.IsSelected = !claimType.IsSelected;
    }

    public getSelected(): FNOLClaimType {
        return this.claimTypes.find(x => x.IsSelected)
    }

    public ngOnInit(): void {
        this.spinner.show();
        this.initializeClaimTypes();

        if (this.fnolHomeService.getDamageToTheHome()) {
            this.firstSelectionMade = true;
            this.isPropertyRelated = true;
        }

        this.api.getClaimTypeModel().then(response => {

            this.spinner.hide();
            this.vm = response;
            if (this.haveDataFor(response.ClaimType)) {
                let selected = this.claimTypes.find(x => x.ClaimTypeCode === response.ClaimType);
                selected.IsSelected = false;

                // put us on the claim type image tile
                this.firstSelectionMade = false;

                if (this.fnolHomeService.getDamageToTheHome()) {
                    this.firstSelectionMade = true;
                    this.isPropertyRelated = true;

                    switch (selected.ClaimTypeCategory) {
                        case ClaimTypeCategoryEnum.DwellingWeatherRelated:
                            this.userWeatherSelection = true;
                            this.isWeatherRelated = true;
                            this.isPropertyRelated = true;
                            selected.IsSelected = true;
                            this.checkForWildfire(selected);
                            break;
                        case ClaimTypeCategoryEnum.DwellingWeatherNotRelated:
                            this.userWeatherSelection = false;
                            this.isWeatherRelated = false;
                            this.isPropertyRelated = true;
                            selected.IsSelected = true;
                            break;
                    }

                    return;
                }

                switch (selected.ClaimTypeCategory) {

                    // Equipment Breakdown
                    case ClaimTypeCategoryEnum.EquipmentBreakdown:
                        this.equipmentBreakdownImage.IsSelected = true;
                        break;

                    // Personal Property
                    case ClaimTypeCategoryEnum.PersonalProperty:
                        this.personalPropertyImage.IsSelected = true;
                        this.isPropertyRelated = null;
                        this.firstSelectionMade = true;
                        selected.IsSelected = true;
                        break;

                    // Other Structure
                    case ClaimTypeCategoryEnum.OtherStructureWeatherRelated:
                        this.otherStructuresImage.IsSelected = true;
                        this.userWeatherSelection = true;
                        this.isWeatherRelated = true;
                        this.firstSelectionMade = true;
                        selected.IsSelected = true;
                        this.checkForWildfire(selected);
                        break;
                    case ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated:
                        this.otherStructuresImage.IsSelected = true;
                        this.userWeatherSelection = false;
                        this.isWeatherRelated = false;
                        this.firstSelectionMade = true;
                        selected.IsSelected = true;
                        break;
                }
            }
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public goBack(): void {
        this.scrollToTopOfPage();

        // we need to do this in the inverse order, assuming that we are at the claim type selection

        // first we assume we had a claim type selected. If so, deselect it
        this.claimTypes
            .filter(x => x.IsSelected)
            .forEach(claimType => claimType.IsSelected = false);

        // if the wildfire question is showing, hide that
        this.showWildfireQuestion = false;

        // Other Structures
        if (this.firstSelectionMade && this.isWeatherRelated != null && this.otherStructuresImage.IsSelected) {
            this.isWeatherRelated = null;
            return;
        }

        // Dwelling - take them to weather screen
        if (this.firstSelectionMade && this.isWeatherRelated != null && this.isPropertyRelated != null) {
            this.isWeatherRelated = null;
            return;
        }

        // Dwelling - route back to previous component
        if (this.firstSelectionMade && this.isWeatherRelated == null && this.isPropertyRelated != null) {
            this.goingBackWithLocation();
            return;
        }

        // Personal Property
        if (this.firstSelectionMade && this.isPropertyRelated != null && this.otherStructuresImage.IsSelected) {
            this.isPropertyRelated = null;
            this.userPersonalPropertySelection = null;
            return;
        }

        // Put us back on the main menu
        if (this.firstSelectionMade) {
            this.firstSelectionMade = false;
        }

        // Just in case any images were selected, deselect those too...
        this.imageChoices
            .filter(x => x.IsSelected)
            .forEach(x => x.IsSelected = false);
    }

    public submit(form: NgForm): void {
        this.scrollToTopOfPage();
        // stop if form is invalid
        if (!this.validate(form)) {
            return;
        }

        // check and see if we have a selected claim type.
        let selectedClaimType: FNOLClaimType = this.claimTypes.find(x => x.IsSelected);

        if (selectedClaimType) {
            this.submitClaim(selectedClaimType);
            return;
        }

        // get the selected image
        let selectedImage: FNOLClaimType = this.imageChoices.find(image => image.IsSelected);

        // if no selected image and it is not propertyRelated, error
        if (!selectedImage && this.isPropertyRelated == null) {
            this.hasError = true;
            return;
        }

        // user is selecting the first icon
        if (!this.firstSelectionMade) {
            this.firstSelectionMade = true;

            if (selectedImage.Name == this.constantsService.HomeEquipment) {
                // Process Equipment Modal
                let equipment: FNOLClaimType = this.claimTypes.find(x => x.ClaimTypeCode == ClaimTypeEnum.Home.EquipmentBreakdown);
                this.submitClaim(equipment);
                return;
            }
        }

        // swap to the weather menu if its otherStructure or property related
        if (this.userWeatherSelection != null) {// && (this.otherStructuresImage.IsSelected || this.isPropertyRelated)
            this.isWeatherRelated = this.userWeatherSelection;
            return;
        }

        // swap to personal property
        if (this.userPersonalPropertySelection != null) { //  && this.personalPropertyImage.IsSelected
            this.isPropertyRelated = true;
            return;
        }
    }

    private submitClaim(claimType: FNOLClaimType) {

        this.vm.ClaimType = claimType.ClaimTypeCode;

        let claimTypeCategory: ClaimTypeCategoryEnum = this.fnolHomeService.getClaimTypeCategory();

        if (claimTypeCategory != null && claimTypeCategory != claimType.ClaimTypeCategory) {
            this.breadcrumbService.clearBreadcrumbs();
        }

        this.fnolHomeService.setClaimTypeCategory(claimType.ClaimTypeCategory);
        this.fnolHomeService.setClaimTypeCode(claimType.ClaimTypeCode);
        this.fnolRouting.initialize(claimType.ClaimTypeCategory);

        this.spinner.show();
        this.api.saveClaimType(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                this.fnolRouting.next();
            }
        });
    }

    private initializeClaimTypes(): void {
        let claimTypes: Record<string, FNOLClaimType> = this.claimTypeService.getClaimTypes(ClaimTypeEnum.ClaimType.Home);

        // this is just the claim types
        this.claimTypes = Object.values(claimTypes);
    }

    public getHelpText(claimType: FNOLClaimType): string {
        let helpText: string = this.constantsService.EmptyString;

        switch (claimType.Name) {
            case this.constantsService.HomeEquipment:
                helpText = this.constantsService.EquipmentBreakdownHelpText;
                break;
            case this.constantsService.HomeOtherStructure:
                helpText = this.constantsService.OtherStructuresHelpText;
                break;
            case this.constantsService.HomePersonalProperty:
                helpText = this.constantsService.PersonalPropertyHelpText;
                break;
            default:
                break;
        }

        return helpText;
    }

    private checkForWildfire(claimType: FNOLClaimType): void {

        if (!this.firstSelectionMade) {
            return;
        }

        // Show wildfire for Weather related: Yes and Other Structures: Yes only.
        switch (claimType.ClaimTypeCode) {
            case this.claimTypeService.HomeClaimType.Dwelling_WeatherRelated_Fire:
            case this.claimTypeService.HomeClaimType.OtherStructure_WeatherRelated_Fire:
                this.showWildfireQuestion = true;
                break;
            default:
                // Do not show the wildfire question
                // to handle a scenario where they select a value then change claim type
                this.vm.IsWildfireRelated = null;
                this.showWildfireQuestion = false;
                break;
        }
    }
}