import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: 'input[name="DateOfBirth"]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateOfBirthDirective, multi: true }]
})

export class DateOfBirthDirective {
    constructor() { }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.value == null || control.value == '') {
            return;
        }

        let errorMessages = {};
         
        if (this.isInvalidYear(control.value)) {
            errorMessages['InvalidYear'] = 'Invalid year provided.';

            return errorMessages;
        }

        let enteredDOB = moment(control.value).format('L');
        let currentDate = moment().format('L');
        let isFutureDate = moment(enteredDOB).isAfter(currentDate);

        if (enteredDOB === 'Invalid date') {
            errorMessages['Invalid Date'] = 'Entered date is not valid.';
        }
       
        if (isFutureDate) {
            errorMessages['FutureDate'] = 'Future date not allowed.';
        }

        if (Object.keys(errorMessages).length == 0) {
            return null;
        }

        return errorMessages;
    }

    public isInvalidYear(dateOfBirth: string): boolean {
        let isInvalid = false;

        let invalidYear = parseInt("999");
        let oldestYearAllowed = 1900;
        let dobYear = parseInt(dateOfBirth.split('/')[2]);

        if (dobYear === undefined || isNaN(dobYear)) {
            isInvalid = true;

            return isInvalid;
        }

        if (dobYear <= invalidYear || dobYear <= oldestYearAllowed) {
            isInvalid = true;
        }

        return isInvalid;
    }
}