<div class="main-inner-wrapper">
    <div *ngIf="isSafeAutoBrand || isEsuranceBrand">
        <p class="header">Please click the back button below and select Personal Auto, or you may call to report your claim.</p>
        <p class="h5" style="padding-bottom:10px;"><b>Call</b>: <a href="tel:+1{{ getClaimPhoneNumber() }}">1{{ getClaimPhoneNumber() | mask: "(000) 000-0000" }}</a></p>
    </div>

    <div *ngIf="isEncompassBrand">
        <p class="header">Please click the back button and select the Personal Auto or Home / Dwelling option to report a new claim. You can also choose one of the options below.</p>
        <p class="h5" style="padding-bottom:10px;"><b>Call</b>: <a href="tel:+1{{ getClaimPhoneNumber() }}">1{{ getClaimPhoneNumber() | mask: "(000) 000-0000" }}</a></p>
        <p class="h5"><b>Email</b>: ClaimsLossReporting@EncompassIns.com</p>
    </div>

    <div class="button-wrapper">
        <button id="continueBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
    </div>
</div>