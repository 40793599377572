import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as moment from 'moment-timezone';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { SessionService, LoggerApi, ConstantsService } from '@Common/services';
import { BaseDateOfLossModel, PageTrackingModel } from '@ClaimsModels/index';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { LossApi, HomeRoutingService } from '../../services';

declare let _detector: any;

@Component({
    selector: 'policySearch',
    templateUrl: '../../views/loss/dateOfLoss.component.html',
    providers: [HomeRoutingService]
})
export class DateOfLossComponent extends BaseFormComponent {

    constructor(private sessionService: SessionService, private location: Location, private homeRouting: HomeRoutingService,
        private spinner: Ng4LoadingSpinnerService, private loggerApi: LoggerApi, private api: LossApi, private constants: ConstantsService, private router: Router) {
        super();
    }

    public model: BaseDateOfLossModel = new BaseDateOfLossModel();
    public incidentDate: string;
    public incidentTime: string;
    public incidentHour: string;
    public incidentMinute: string;
    public incidentAMPM: string = null;
    public daterange: any = {};
    public dateOptions: any = {
        locale: {
            format: 'MM/DD/YYYY'
        },
        singleDatePicker: true,
        alwaysShowCalendar: true,
        timePicker: false,
        maxDate: new Date,
        autoApply: true
    }
    public maxDate: Date = new Date();
    public minDate: Date = new Date(1900, 1, 1);


    public goingBackWithLocation(): void {
        this.location.back();
    }

    public selectedDate(value: any, datepicker?: any) {
        // any object can be passed to the selected event and it will be passed back here
        datepicker.start = value.start;
        datepicker.end = value.end;
        this.incidentDate = moment(value.end).format('MM/DD/YYYY');
        // or manupulate your own internal property
        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
    }

    public ngOnInit(): void {

        this.spinner.show();
        this.api.getDateOfLossModel()
            .then(response => {
                this.spinner.hide();
                this.model.TimeZones = response.TimeZones;
            });
    }

    public submit(form: NgForm): void {
        
        if (this.validate(form)) {

            if (!this.performValidations(form)) {
                return;
            }

            this.spinner.show();
            var incidentDate = moment(this.incidentDate).format('MM/DD/YYYY');
            this.incidentTime = `${this.incidentHour}:${this.incidentMinute}`;
            this.model.DateOfLoss = this.convertToUTCDate(`${incidentDate} ${this.incidentTime}`);
            this.model.IncidentAMPM = this.incidentAMPM;

            this.api.saveDateOfLoss(this.model)
                .then(response => {
                    this.spinner.hide();
                    if (response && response.Success) {
                        this.setupSession(response);

                        if (typeof _detector !== 'undefined') {
                            const claimParams = {
                                ClaimId: response.Session.ClaimId
                            };
                            _detector.triggerCustomEventMap('claimInfo', claimParams);
                        }

                        if (response.Policy.HasOpenClaim) {
                            this.homeRouting.routeToSpecificPage('/fnolHome/hasOpenClaim');
                        } else {
                            this.homeRouting.routeToSpecificPage('/fnolHome/callIn');
                        }
                    }
                    else if (response == null || !response.Success) {
                        this.router.navigateByUrl('/reporterDetails?claimType=home');
                    }
                });
        }
    }

    public onHourBlur(): void {
        // Pad Hour with "0" if it's a valid single digit
        if (this.incidentHour !== null && this.incidentHour !== undefined && this.incidentHour.length === 1) {
            if (+this.incidentHour > 0 && +this.incidentHour < 13) {
                this.incidentHour = "0" + this.incidentHour;
            }
        }
    }

    private resetDateForm(form: NgForm): void {
        if (form.touched) {
            //form.controls["IncidentHour"].setErrors(null);
            //form.controls["DateOfLoss"].setErrors(null);
            //form.controls["AMPM"].setErrors(null);
        }
    }
    public checkFormValid(form: NgForm): void {
        let selectedAMPM = form.controls['AMPM'].value;
        this.incidentAMPM = selectedAMPM;
        this.resetDateForm(form);
        let validDate: moment.Moment = moment(this.incidentDate, "MM-DD-YYYY");
        let validTime: boolean = this.isValidTime();
        if (this.isValidTimezone()) {

            if (!validDate || !validTime || !selectedAMPM) {

                if (!selectedAMPM) {
                    form.controls["AMPM"].setErrors({ 'invalidAMPM': true });
                }

                if (!validDate) {
                    // apply a custom error so the field is invalidated.
                    form.controls["DateOfLoss"].setErrors({ 'invalidDate': true });
                }

                if (!validTime) {
                    // apply a custom error so the field is invalidated.
                    form.controls["IncidentHour"].setErrors({ 'invalidTime': true });
                }

            }

        }
    }
    private performValidations(form: NgForm): boolean {
        let validDate: moment.Moment = moment(this.incidentDate, "MM-DD-YYYY");
        let validTime: boolean = this.isValidTime();
        let validTZ: boolean = this.isValidTimezone();

        // exit if the date or time are invalid
        if (!validDate || !validTime || !validTZ) {

            if (!validDate) {
                // apply a custom error so the field is invalidated.
                form.controls["DateOfLoss"].setErrors({ 'invalidDate': true });
            }

            if (!validTime) {
                // apply a custom error so the field is invalidated.
                form.controls["IncidentHour"].setErrors({ 'invalidTime': true });
            }

            if (!validTZ) {
                // apply a custom error so the field is invalidated.
                form.controls["IncidentHour"].setErrors({ 'futureTime': true });
            }

            return false;
        }

        return true;
    }

    private isValidTime(): boolean {
        let hours: number = +this.incidentHour;
        let minutes: number = +this.incidentMinute;

        if (hours > 12 || hours < 1) {
            return false;
        }

        if (minutes > 59 || minutes < 0) {
            return false;
        }

        return true;
    }

    private isValidTimezone(): boolean {
        if (!this.model.TimeZone)
            return false;
        // This lookup below takes our databound "displayCode" number value and gets us the dispaly value
        // then removes any of the irrelevant strings from it
        let selectedTimeZone = this.model.TimeZones.find(x => x.DisplayCode == this.model.TimeZone).DisplayValue
            .replace(/\(US & Canada\)/g, '')
            .replace(/\(East\)/g, '')
            .replace(/Time/g, '')
            .trim();

        let possibleTz: string;

        // If the selected TZ is not in Guam or Indiana, select the appropriate US TZ.
        if (!["Guam", "Indiana"].includes(selectedTimeZone)) {
            possibleTz = moment.tz.names().find(x => x.includes(`US/${selectedTimeZone}`));
        }
        else if (selectedTimeZone == "Indiana") { // We hardcode Indiana as Eastern
            possibleTz = "US/Eastern";
        }
        else { // otherwise find it in the array and look it up
            possibleTz = moment.tz.names().find(x => x.includes(`${selectedTimeZone}`));
        }

        // Create date/time with specified timezone. Compare against DateTime.Now in that timezone
        let tzDate = moment(`${this.incidentDate} ${this.incidentHour}:${this.incidentMinute} ${this.incidentAMPM}`).tz(possibleTz, true);
        let now = moment().tz(possibleTz, false); // keeping browser time as the local time in that timezone

        if (tzDate.isAfter(now)) {
            return false;
        }

        return true;
    }

    private setupSession(response: BaseDateOfLossModel) {
        response.Session.IsExpired = this.sessionService.isSessionExpired();
        response.Session.IsInitialized = true;
        response.Session.WorkflowType = this.constants.FNOLHome;
        this.sessionService.setExistingSession(response.Session);
    }

    private logExternalPageTracking(externalUrl: string): void {
        let page: PageTrackingModel = new PageTrackingModel();
        page.PageName = externalUrl;
        page.SessionId = this.sessionService.getSession().SessionId;

        this.loggerApi.trackPageNavigation(page);
    }
}