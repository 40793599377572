




import { BaseModel } from './BaseModel';


export class OpenClaimDetailsModel extends BaseModel {
    
    public HasOpenClaim: boolean = false;
    public ClaimNumber: string = null;
    public AdjusterFullName: string = null;
    public AdjusterWorkPhone: string = null;
    public AdjusterEmail: string = null;
    public LossDateTime: Date = new Date(0);
    public ReturnUrl: string = null;
}