import { Component } from '@angular/core';
import { SMSConsentModel } from '@ClaimsModels/index';
import { SessionService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LossApi, FNOLRoutingService } from '@FNOLAuto/services/';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'smsConsent',
    templateUrl: '../../views/loss/smsConsent.component.html',
    providers: [FNOLRoutingService]
})
export class SMSConsentComponent extends BaseFormComponent {
    constructor(private api: LossApi,
        private spinner: Ng4LoadingSpinnerService,
        private session: SessionService,
        private fnolRouting: FNOLRoutingService) {
        super();
    }

    public vm: SMSConsentModel = new SMSConsentModel();
    public hasError: boolean = false;

    ngOnInit(): void {
        this.spinner.show();
        this.api.getSMSConsentModel().then(response => {
            this.vm = response;
            this.spinner.hide();
        });
        let selectedUserType = sessionStorage.getItem("userType");
        let goingBackFromReview = sessionStorage.getItem("goingBackFromReview");
        if (selectedUserType == "Agent") {
            if (goingBackFromReview != null && goingBackFromReview == "true") {
                sessionStorage.setItem("goingBackFromReview", "false");
                this.fnolRouting.back();
            } else {
                this.fnolRouting.next();
            }            
        }
    }

    public submit(form: NgForm): void {

        if (!this.validate(form)) {
            this.hasError = true;
            return;
        }

        this.spinner.show();
        this.api.saveSMSConsentModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                if (this.vm.IsEligibleForMCP && this.vm.SMSConsent) {
                    this.fnolRouting.routeToSpecificPage('fnolAuto/mcpLink');
                } else {
                    this.fnolRouting.next();
                }
            }
        })
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }
}