




import { BaseModel } from './BaseModel';


export class LossPropertyModel {
    
    public PropertyId: number = 0;
    public PropertyClaimId: number = 0;
    public DamageDescription: string = null;
}