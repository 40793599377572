import { Injectable } from '@angular/core';
import { ClaimTypeEnum, FNOLClaimType, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { ConstantsService } from './constants.service';

@Injectable()
export class ClaimTypeService {

    constructor(private constantsService: ConstantsService) { }

    // This lets use see intellisense correctly at the namespace level
    public get ClaimType(): typeof ClaimTypeEnum {
        return ClaimTypeEnum;
    }

    // Intellisense for Auto
    public get AutoClaimType(): typeof ClaimTypeEnum.Auto {
        return ClaimTypeEnum.Auto;
    }

    public get AutoClaimTypeCategory(): typeof ClaimTypeEnum.AutoCategory {
        return ClaimTypeEnum.AutoCategory;
    }

    // Intellisense for Home 
    public get HomeClaimType(): typeof ClaimTypeEnum.Home {
        return ClaimTypeEnum.Home;
    }

    public get HomeClaimTypeCategory(): typeof ClaimTypeEnum.HomeCategory {
        return ClaimTypeEnum.HomeCategory;
    }

    public getClaimTypes(claimType: ClaimTypeEnum.ClaimType): Record<string, FNOLClaimType> {

        let results: Record<string, FNOLClaimType> = {};

        switch (claimType) {
            case ClaimTypeEnum.ClaimType.Auto:
                results = this._AutoClaimTypes;
                break;
            case ClaimTypeEnum.ClaimType.Home:
                results = this._HomeClaimTypes;
                break;
            case ClaimTypeEnum.ClaimType.Life:
                results = this._LifeClaimTypes;
                break;
        }

        return results;
    }

    private _AutoClaimTypes: Record<string, FNOLClaimType> = {
        
    }

    // Create a dictionary containing all of the claim types for Home
    // Key doesn't matter, its for us. the value is the claimType object we create
    private _HomeClaimTypes: Record<string, FNOLClaimType> = {

        // Personal Property
        PropertyTheft: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.PersonalProperty,
            ClaimTypeCode: ClaimTypeEnum.Home.PropertyTheft,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.PropertyTheft
        },
        MysteriousDisappearance: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.PersonalProperty,
            ClaimTypeCode: ClaimTypeEnum.Home.PropertyMysteriousDisappearance,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.PropertyMissingLost
        },
        PropertyFire: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.PersonalProperty,
            ClaimTypeCode: ClaimTypeEnum.Home.PropertyFire,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.PropertyFire
        },

        // Equipment Breakdown
        EquipmentBreakdown: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.EquipmentBreakdown,
            ClaimTypeCode: ClaimTypeEnum.Home.EquipmentBreakdown,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.EquipmentBreakdown
        },

        // Dwelling - Weather Related: No
        DwellingNotWeatherRelated_Explosion: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_Explosion,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Explosion
        },
        DwellingNotWeatherRelated_Riot: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_Riot,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Riot
        },
        DwellingNotWeatherRelated_Fire: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_Fire,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.NoWeather_Fire
        },
        DwellingNotWeatherRelated_Mold: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_Mold,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Mold
        },
        DwellingNotWeatherRelated_Sinkhole: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_Sinkhole,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.SinkHole
        },
        DwellingNotWeatherRelated_Smoke: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_Smoke,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Smoke
        },
        DwellingNotWeatherRelated_Vandalism: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_Vandalism,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Vandalism
        },
        DwellingNotWeatherRelated_CrashIntoHome: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_CrashIntoHome,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.CrashIntoHome
        },
        DwellingNotWeatherRelated_WaterDamage: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_NotWeatherRelated_WaterDamage,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.NoWeather_WaterDamage
        },

        // Dwelling - Weather Related: Yes
        DwellingWeatherRelated_Earthquake: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_WeatherRelated_Earthquake,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Earthquake
        },
        DwellingWeatherRelated_FireWildfire: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_WeatherRelated_Fire,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.FireWildfire
        },
        DwellingWeatherRelated_LightningNoFire: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_WeatherRelated_LightningNoFire,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.LightningNoFire
        },
        DwellingWeatherRelated_Hail: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_WeatherRelated_Hail,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Hail
        },
        DwellingWeatherRelated_SnowIce: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_WeatherRelated_SnowIce,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.SnowIce
        },
        DwellingWeatherRelated_WaterDamage: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_WeatherRelated_WaterDamage,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.WaterDamage
        },
        DwellingWeatherRelated_Windstorm: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.DwellingWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.Dwelling_WeatherRelated_Windstorm,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.WindTornado
        },

        // Other Structures - Weather Related: No
        OtherStructureNotWeatherRelated_Explosion: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_Explosion,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Explosion
        },
        OtherStructureNotWeatherRelated_Riot: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_Riot,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Riot
        },
        OtherStructureNotWeatherRelated_Fire: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_Fire,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.NoWeather_Fire
        },
        OtherStructureNotWeatherRelated_Mold: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_Mold,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Mold
        },
        OtherStructureNotWeatherRelated_Sinkhole: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_Sinkhole,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.SinkHole
        },
        OtherStructureNotWeatherRelated_Smoke: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_Smoke,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Smoke
        },
        OtherStructureNotWeatherRelated_Vandalism: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_Vandalism,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Vandalism
        },
        OtherStructureNotWeatherRelated_CrashIntoHome: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_CrashIntoHome,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.CrashIntoHome
        },
        OtherStructureNotWeatherRelated_WaterDamage: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_NotWeatherRelated_WaterDamage,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.NoWeather_WaterDamage
        },

        // Other Structures - Weather Related: Yes
        OtherStructureWeatherRelated_Earthquake: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_WeatherRelated_Earthquake,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Earthquake
        },
        OtherStructureWeatherRelated_FireWildfire: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_WeatherRelated_Fire,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.FireWildfire
        },
        OtherStructureWeatherRelated_LightningNoFire: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_WeatherRelated_LightningNoFire,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.LightningNoFire
        },
        OtherStructureWeatherRelated_Hail: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_WeatherRelated_Hail,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.Hail
        },
        OtherStructureWeatherRelated_SnowIce: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_WeatherRelated_SnowIce,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.SnowIce
        },
        OtherStructureWeatherRelated_WaterDamage: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_WeatherRelated_WaterDamage,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.WaterDamage
        },
        OtherStructureWeatherRelated_Windstorm: {
            ClaimTypeCategory: ClaimTypeCategoryEnum.OtherStructureWeatherRelated,
            ClaimTypeCode: ClaimTypeEnum.Home.OtherStructure_WeatherRelated_Windstorm,
            IsSelected: false,
            Name: this.constantsService.HomeClaimTypeNames.WindTornado
        }
    }

    private _LifeClaimTypes: Record<string, FNOLClaimType> = {

    }
}