<div class="main-inner-wrapper">
    <div class="input-wrapper">
        <div class="row justify-content-center">
            <div class="col col-lg-12 col-xs-12 ">
                <div>
                    Please call <a href="tel:+1{{ contactNumber }}">{{ contactNumber | mask: '(000) 000-0000' }}</a> to report this claim.
                </div>
            </div>
        </div>
    </div>
</div>