import { Component } from '@angular/core';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { OpenClaimDetailsModel, BrandEnum } from '@ClaimsModels/index';
import { StyleManagerService, ConstantsService, SessionService, UnsubmittedClaimService } from '@Common/services';
import { HomeRoutingService, LossApi } from '@FNOLHome/services';


@Component({
    selector: 'hasOpenClaim',
    templateUrl: '../../views/loss/hasOpenClaim.component.html',
    providers: [HomeRoutingService]
})
export class HasOpenClaimComponent extends BaseFormComponent {

    constructor(private api: LossApi, private spinner: Ng4LoadingSpinnerService, private styleManager: StyleManagerService, private fnolRouting: HomeRoutingService, private constantsService: ConstantsService, private session: SessionService,
                private unsubmittedClaim: UnsubmittedClaimService) {
        super();
    }

    public vm: OpenClaimDetailsModel = new OpenClaimDetailsModel();
    public showAdjuster: boolean = false;
    public lossDate: string;
    public showAdjusterPhone: boolean = false;
    public claimsEmail: string;
    public claimsPhone: string;

    ngOnInit(): void {
        this.spinner.show();
        this.api.getOpenClaimDetailsModel().then(response => {
            this.spinner.hide();

            this.claimsEmail = this.getClaimsEmail();
            this.claimsPhone = this.getClaimsPhone();

            this.vm = response;

            this.lossDate = moment(this.vm.LossDateTime).format('MM/DD/YYYY');

            this.showAdjuster = this.haveDataFor(this.vm.AdjusterFullName);

            this.showAdjusterPhone = this.haveDataFor(this.vm.AdjusterWorkPhone) && this.showAdjuster;

            // add the word 'Ext' into the phone number for the mask..
            if (this.vm.AdjusterWorkPhone.length > 10) {
                this.vm.AdjusterWorkPhone = `${this.vm.AdjusterWorkPhone.slice(0, 10)}Ext${this.vm.AdjusterWorkPhone.slice(10)}`;
            }

            if (this.vm.ReturnUrl === "") {
                this.styleManager.returnUrl.subscribe(url => {
                    this.vm.ReturnUrl = url;
                });
            }
        });
    }

    public submit(): void {
        this.fnolRouting.routeToSpecificPage('/fnolHome/callIn');
    }

    public goingBackWithLocation(): void {
        this.unsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
    }

    public sendEmail(): void {
        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.EncompassInsurance:
                window.location.href = "mailto:encompassclaimsmail@encompassins.com";
            default:
                window.location.href = "mailto:Claims@NGIC.com";
        }
    }

    private getClaimsEmail(): string {
        let claimsEmail: string = this.constantsService.ClaimsEmail.Default;

        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.EncompassInsurance:
                claimsEmail = this.constantsService.ClaimsEmail.Encompass;
                break;
            default:
                claimsEmail = this.constantsService.ClaimsEmail.Default;
                break;
        }

        return claimsEmail;
    }

    private getClaimsPhone(): string {
        let claimsPhone: string = this.constantsService.ClaimsPhone.Default;

        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.EncompassInsurance:
                claimsPhone = this.constantsService.ClaimsPhone.Encompass;
                break;
            default:
                claimsPhone = this.constantsService.ClaimsPhone.Default;
                break;
        }

        return claimsPhone;
    }
}