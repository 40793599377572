import { Component } from "@angular/core";
import { AddressModel, ClaimModel, MC360TabOptions, SessionModel } from '@ClaimsModels/index';
import { BaseComponent } from "@Common/base/base.component";
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ConstantsService, LayoutService, SessionService, SessionBridgeApi } from '@Common/services';
import { Location } from '@angular/common';


@Component({
    selector: 'bridge',
    templateUrl: '../views/bridge.component.html',
    styleUrls: ['../styles/bridge.component.css']
})

export class BridgeComponent extends BaseComponent {

    constructor(private location: Location, private constantsService: ConstantsService,
        private sessionService: SessionService,
        private bridgeApi: SessionBridgeApi,
        private route: ActivatedRoute,
        private spinner: Ng4LoadingSpinnerService,
        private myClaimsApi: MyClaimsApi,
        private dashboardService: DashboardService,
        private router: Router,
        private layoutService: LayoutService)
    {
        super();
    }

    public TabOptions = MC360TabOptions;
    public selectedTab: string = "HOME";
    public showOptIn: boolean = true;


    private session: SessionModel;

    public vm: ClaimModel;

    public claimStatus = this.constantsService.ClaimStatusClosed;

    public driverName: string;

    public ngOnInit(): void {

        this.session = this.sessionService.getSession();

        let dashboardSessionGuid = this.route.snapshot.paramMap.get("sessionId");

        this.session.IsExpired = false;
        this.session.IsInitialized = false;
        this.sessionService.setSession(this.session);

        this.initializeModel();
        this.layoutService.setBrand(this.constantsService.MC360);

        this.spinner.show();
        this.vm.DashboardSessionGuid = dashboardSessionGuid;
        this.myClaimsApi.initiateDashboard(this.vm).then((response: ClaimModel) =>
        {

            this.spinner.hide();
            this.dashboardService.setLossParticipantId(response.LossParticipantId.toString());

            this.dashboardService.setDashboardSessionGuid(dashboardSessionGuid);
            response.Session.IsInitialized = true;
            this.sessionService.setSession(response.Session);
            this.vm = response;
            this.router.navigate(['myClaims360/dashboard/']);
            this.spinner.hide();
        });


    }


    private setClaimValues(claimResponse: ClaimModel) {

        this.claimStatus = claimResponse.ClaimStatus;
        this.driverName = claimResponse.LossParticipants[0].FirstName + claimResponse.LossParticipants[0].LastName;
    }

    private initializeModel(): void {
        this.vm = new ClaimModel();
        this.vm.LossAddress = new AddressModel();
        this.vm.Exposures = [];
        this.vm.LossParticipants = [];
        this.vm.RecentDocuments = [];
    }



    public changeTab(newTab: string) {
        this.selectedTab = newTab;
    }

    public showOptInScreen(isOptIn: boolean): void {
        this.showOptIn = isOptIn;
    }
}