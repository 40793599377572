




import { BaseModel } from './BaseModel';


export class SessionModel {
    
    public ClaimId: number = 0;
    public ClaimNumber: string = null;
    public SessionId: string = null;
    public IsExpired: boolean = false;
    public WorkflowType: string = null;
    public WorkflowStage: string = null;
    public Source: string = null;
    public SourceParty: string = null;
    public IsInitialized: boolean = false;
    public IsSubmitted: boolean = false;
}