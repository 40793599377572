import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LossApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { LossAddressModel, AddressModel, LossDetailsModel, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services';


@Component({
    selector: 'lossAddress',
    templateUrl: '../../views/loss/lossAddress.component.html',
    providers: [FNOLRoutingService]
})
export class LossAddressComponent extends BaseFormComponent {
    [x: string]: any;

    constructor(private api: LossApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, private constantsService: ConstantsService,
                private fnolAutoService: FNOLAutoService) {
        super();

        this.vm = new LossAddressModel();
        this.vm.Address = new AddressModel();
    }

    public vm: LossAddressModel = new LossAddressModel();
    public lossDateTime: string = this.constantsService.EmptyString;
    public isTheftClaim: boolean = false;
    public vehicleStolenFromResidence: boolean = false;

    ngOnInit(): void {
        this.spinner.show();

        let claimTypeCategory = this.fnolAutoService.getClaimTypeCategory();

        if (claimTypeCategory == ClaimTypeCategoryEnum.Theft) {
            this.vehicleStolenFromResidence = this.getVehicleStolenFromResidenceValue();
            this.isTheftClaim = true;
        }

        this.api.getLossAddressModel().then(response => {
            this.spinner.hide();
            this.vm = response;
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.api.saveLossAddressModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.fnolAutoService.setVehicleStolenFromResidence(this.vehicleStolenFromResidence.toString());
                        this.fnolRouting.next();
                }
            });
        }
    }

    public getHeaderTextByClaimType(): string {

        let output: string = "Where did the incident take place?";

        switch (this.fnolAutoService.getClaimTypeCategory()) {
            case ClaimTypeCategoryEnum.Theft:
                output = "Please enter the last known location of your vehicle prior to the theft.";
                break;
        }

        return output;
    }

    public fillLossAddressWithPrimaryAddress(event: any): void {
        let isSameAddress: boolean = event.target.checked;

        this.vehicleStolenFromResidence = isSameAddress;
        this.vm.Address = new AddressModel();

        if (isSameAddress) {
            this.vm.Address = this.vm.PolicyPrimaryAddress;
        }
    }

    private getVehicleStolenFromResidenceValue(): boolean {
        let sessionValue = this.fnolAutoService.getVehicleStolenFromResidence();

        if (sessionValue == null || sessionValue == undefined || sessionValue != this.constantsService.TrueStringValue) {
            return false;
        }

        if (sessionValue == this.constantsService.TrueStringValue) {
            return true;
        }
    }

}