




import { BaseModel } from './BaseModel';


export class CallInClaimModel extends BaseModel {
    
    public ReturnUrl: string = null;
}