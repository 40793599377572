<div ngForm #glassOnlyForm="ngForm" class="main-inner-wrapper">
    <p class="header">Are you reporting a <em class="emphasis-bold">Glass Only</em> claim?</p>
    <span *ngIf="isGlassOnly" class="text-info mb-3 glassonly emergencyHeader">
        <b> If your vehicle has damages other than Glass or Windshield, please select <b>No</b> from below options.</b>
    </span>
    <span *ngIf="hasError" class="red">Please select one of the following options to continue.</span>

    <div class="input-wrapper">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="setGlassOnly(true);" [ngClass]="{'selected': isGlassOnly != null && isGlassOnly}">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="setGlassOnly(false)" [ngClass]="{'selected': isGlassOnly != null && !isGlassOnly}">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">Continue</button>
    </div>
</div>