<div ngForm #selectPropertyOwnerForm="ngForm" class="main-inner-wrapper no-mobile">
    <p class="header">Please select or add the owner of the home</p>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="noOwnerSelected" (close)="noOwnerSelected = false" [type]="danger">
        <strong>Error!</strong> Please select a Property Owner.
    </ngb-alert>

    <div class="input-wrapper">
        <div class="row">
            <div *ngFor="let participant of vm.LossParticipants">
                <button class="person" [class.selected]="participant.IsSelected" (click)="selectPerson(participant)" required>
                    <div *ngIf="!isDeviceMobile">
                        <div *ngIf="participant.Gender === 'M'">
                            <maleGenderIcon></maleGenderIcon>
                        </div>
                        <div *ngIf="participant.Gender === 'F'">
                            <femaleGenderIcon></femaleGenderIcon>
                        </div>
                    </div>
                    <span class="gbmask">{{participant.Name}}</span>
                    <i *ngIf="participant.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div>
                <button class="person addNew" (click)="addNew()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add New</span>
                </button>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(selectPropertyOwnerForm)">Continue</button>
    </div>
</div>