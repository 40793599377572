<div ngForm #selectVehicleOwner="ngForm" class="main-inner-wrapper">
    <div *ngIf="!isThirdParty">
        <p class="header" [hidden]="!(vm.VehicleInfo.Year != null && vm.VehicleInfo.Make != null && vm.VehicleInfo.Model != null)">
            Please <span class="bold" *ngIf="!isThirdParty">select or add</span> <span class="bold" *ngIf="isThirdParty">provide</span> the <em class="emphasis-bold">owner</em> of the <em class="emphasis-bold">{{ vm.VehicleInfo.Year }} {{ vm.VehicleInfo.Make }} {{ vm.VehicleInfo.Model }}</em>.
            <span class="bold" *ngIf="!hasAlreadySelected"> If the owner is <em class="emphasis-bold">unknown</em>, click continue.</span>
        </p>
        <p class="header" [hidden]="!(vm.VehicleInfo.Year == null || vm.VehicleInfo.Make == null || vm.VehicleInfo.Model == null)">
            Please <span class="bold" *ngIf="!isThirdParty">select or add</span> the <em class="emphasis-bold">owner</em> of the vehicle.
            <span class="bold" *ngIf="!hasAlreadySelected"> If the owner is <em class="emphasis-bold">unknown</em>, click continue.</span>
        </p>
    </div>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="displayAlreadySelectedMessage" (close)="displayAlreadySelectedMessage = false" [type]="danger">
        <strong>Error!</strong> Please select an Owner.
    </ngb-alert>

    <div class="input-wrapper" *ngIf="!isThirdParty && isLoaded">
        <div class="row">
            <div *ngFor="let participant of vm.OwnerOptions">
                <button class="person" [class.selected]="participant.IsSelected" (click)="selectPerson(participant)" required>
                    <div *ngIf="!isDeviceMobile">
                        <div *ngIf="participant.Gender === 'M'">
                            <maleGenderIcon></maleGenderIcon>
                        </div>
                        <div *ngIf="participant.Gender === 'F'">
                            <femaleGenderIcon></femaleGenderIcon>
                        </div>
                    </div>
                    <span class="gbmask">{{participant.Name}}</span>
                    <i *ngIf="participant.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>

            <div>
                <button class="person addNew" (click)="addNew()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Owner Not Listed</span>
                </button>
            </div>

        </div>
    </div>
    <selectOtherContact *ngIf="isThirdParty" [isThirdParty]="isThirdParty"></selectOtherContact>


    <div class="button-wrapper" *ngIf="!isThirdParty">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(selectVehicleOwner)">Continue</button>
    </div>
</div>