<div class="container">

    <div class="jumbotron">
        <h1 class="display-2 text-center">Fatal Error</h1>
        <hr class="my-4">
        <div class="text-center">
            <h2>Uh oh...</h2>
            <p class="lead">
                Something went wrong on our end. We are very sorry.
                <userCallIn></userCallIn>
            </p>
        </div>
    </div>

</div>