




import { BaseModel } from './BaseModel';


export class EmergencyResponseModel extends BaseModel {
    
    public HasEmergencyResponse: boolean = false;
    public Fire: boolean = false;
    public Police: boolean = false;
    public EMS: boolean = false;
    public ReportNumber: string = null;
    public Department: string = null;
}