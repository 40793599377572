import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { VehicleClaimInfoModel } from '@ClaimsModels/index';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { StyleManagerService, ConstantsService, UnsubmittedClaimService, ContactService } from '@Common/services';
import { FNOLAutoService } from '@FNOLAuto/services/';
import { ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { FNOLRoutingService, LossApi } from '../../services';

@Component({
    selector: 'isWindshieldOnlyDamages',
    templateUrl: '../../views/loss/isWindshieldOnlyDamages.component.html',
    providers: [FNOLRoutingService]
})
export class IsWindshieldOnlyDamagesComponent extends BaseFormComponent {

    public contactNumber: string;
    public vm: VehicleClaimInfoModel = new VehicleClaimInfoModel();

    constructor(private api: LossApi, private router: Router, private constantsService: ConstantsService, private fnolAutoService: FNOLAutoService,
        private unsubmittedClaim: UnsubmittedClaimService, private contactService: ContactService, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService) {
        super();
    }

    public IsWindshieldOnlyDamages: boolean | null; 
    public hasError: boolean;
    public displayPage: boolean;


    public ngOnInit(): void {
        if (sessionStorage.getItem('isWindshieldOnlyDamages') != null){
            this.IsWindshieldOnlyDamages = (sessionStorage.getItem('isWindshieldOnlyDamages') === 'true' ? true : false);
        }
        this.displayPage = true
        this.spinner.hide();
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(): void {
        this.spinner.show();
        if (this.IsWindshieldOnlyDamages == null) {
            this.hasError = true;
            this.spinner.hide();
            return;
        }

        sessionStorage.setItem('isWindshieldOnlyDamages', this.IsWindshieldOnlyDamages.toString());
        if (this.IsWindshieldOnlyDamages) {
            this.fnolRouting.next();
        }

        else {
            this.router.navigate(['/fnolAuto/isDamagesOtherThanWindshield']);
        }
    }

    public setIsWindshieldOnlyDamages(value: boolean): void {
        this.IsWindshieldOnlyDamages = value;
    }

}