




import { BaseModel } from './BaseModel';

import { DisplayModeEnum } from './DisplayModeEnum';

export class BaseLossPropertyModel extends BaseModel {
    
    public PropertyClaimId: number = 0;
    public PropertyId: number = 0;
    public DamageDescription: string = null;
    public PropertyDescription: string = null;
    public OwnerContactId: number = 0;
    public IsActive: boolean = false;
    public DisplayMode: DisplayModeEnum = null;
}