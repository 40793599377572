<div ngForm #policyVehiclesForm="ngForm" class="main-inner-wrapper">
    <p class="header">Please select or add <em class="emphasis-bold">all</em> vehicles involved in the incident.</p>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="displayMultiVehicleRequirements" (close)="displayMultiVehicleRequirements = false" [type]="danger">
        <strong>Error!</strong> For a multiple vehicle accident. You must have more than one vehicle.
    </ngb-alert>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="hasMultiplePolicyVehiclesSelected" (close)="hasMultiplePolicyVehiclesSelected = false" [type]="danger">
        <strong>Error!</strong> You have selected multiple policy vehicles. Only one policy vehicle per claim.
    </ngb-alert>

    <div class="input-wrapper">
        <div class="row">
            <div *ngFor="let vehicle of vm.Vehicles">
                <button class="vehicle" [class.selected]="vehicle.IsSelected" (click)="selectVehicle(vehicle)">
                    <vehicleIcon *ngIf="!isDeviceMobile"></vehicleIcon>
                    <img *ngIf="isDeviceMobile" src="/content/images/vehicle-mobile.png" title="vehicle" alt="ems image" width="59" height="26" />
                    <span class="policyMember">{{ vehicle.IsOnPolicy ? "Insured" : "Other" }}</span>
                    <span *ngIf="isVehicleInfoKnown(vehicle)">{{vehicle.VehicleInfo}}</span>
                    <span *ngIf="!isVehicleInfoKnown(vehicle)">Unknown</span>
                    <i *ngIf="vehicle.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div>
                <button class="vehicle addNew" (click)="addNew()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add New</span>
                </button>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(policyVehiclesForm)">Continue</button>
    </div>
</div>