import { BaseComponent } from "../../../ClaimsWeb.Areas.Common/app/base/base.component";
import { Component, Input, Output, EventEmitter, OnChanges} from "@angular/core";
import { SMSConsentModel } from '@ClaimsModels/index';

import { LossApi } from '@FNOLAuto/services/';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SMSModalService } from '../services/smsModal.service';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

@Component({
    selector: 'smsTextOption',
    templateUrl: '../views/SMSTextOption.component.html',
    styleUrls: ['../styles/mc360styling.css']
})

export class SMSTextOptionComponent extends BaseComponent implements OnChanges {
    @Input() isOptIn: boolean;
    @Input() homeOptInStatus: boolean;
    public modalIsOpen: boolean = false;
    public showOptIn: boolean = true;
    public dismissed: boolean = false;
    public vm: SMSConsentModel = new SMSConsentModel ();
    public modalref: NgbModalRef;
    public isMediaMobileWidth: boolean = false;
    public readonly MEDIA = '(max-width: 991.98px)';
    
    @Output() optInStatus: EventEmitter<boolean> = new EventEmitter();

    constructor(private ngbModalService: NgbModal, private api: LossApi, private modalService: SMSModalService, private spinner: Ng4LoadingSpinnerService) {
        super();
    }

    public ngOnInit(): void {
       this.checkMediaQuery();
    }
    
    public ngOnChanges() {
       
    }

    public toggleOptInStatus(optStatus: boolean): void {
        if (this.homeOptInStatus == undefined || this.modalIsOpen == true) {

            this.updateSMSOptionConsent(optStatus);

        } else {

            this.openModal(optStatus);
        }
    }

    openModal(optIn: boolean) {
        this.modalref = this.ngbModalService.open(SMSTextOptionComponent, { backdrop: 'static' });
        this.modalref.componentInstance.homeOptInStatus = optIn;
        this.modalref.componentInstance.modalIsOpen = true;
        this.modalService.getCloseEvent().subscribe(($e) => {
            this.optInStatus.emit(optIn); 
            this.homeOptInStatus = optIn;
        })
    }

    public updateSMSOptionConsent(optIn: boolean) {
        this.spinner.show();
        this.api.getSMSConsentModel().then(response => {

            this.vm = response;
            this.homeOptInStatus = optIn;
            this.showOptIn = optIn;
            if (response.Success) {

                if (response.SMSConsent != this.homeOptInStatus) {
                    
                    this.vm.SMSConsent = optIn;
                    this.api.saveSMSConsentModel(this.vm).then((response) => {
                        if (response.Success) {
                            this.dismiss();
                            this.showOptIn = !optIn;
                            this.optInStatus.emit(!optIn);
                            this.homeOptInStatus = !response.SMSConsent;
                            this.modalService.emit(!optIn);
                            this.spinner.hide();
                        }
                    });

                } else {
                    this.dismiss();
                }
                
            }
        });

    }

    public dismiss(): void {
        this.dismissed = true;
        this.ngbModalService.dismissAll();
        this.modalIsOpen = false;
    }

    checkMediaQuery() {
        const mediaQuery = window.matchMedia(this.MEDIA);

        if (mediaQuery.matches) {

            this.isMediaMobileWidth = true;
        }
    }
}