<div class="reviewSubmissionPopUp">
    <h5 class="text-center">Your session is about to expire!</h5>
    <p class="text-center mt-4">Any claim information provided will not be saved.</p>

    <div class="d-flex justify-content-center">
        <div class="button-wrapper">
            <button class="btn btn-primary review-button" (click)="endSession()" id="origBtn">End Session</button>
            <button class="btn btn-primary review-button" (click)="extendSessionTime()" id="newBtn">Continue Session</button>
        </div>
    </div>
</div>