




import { BaseModel } from './BaseModel';

import { MessageModel } from './MessageModel';

export class LossParticipantMessagesModel extends BaseModel {
    
    public Messages: MessageModel[] = [];
    public NewMessage: MessageModel = null;
    public IsInbound: boolean = false;
    public LossParticipantId: number = 0;
}