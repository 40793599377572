import { Injectable } from "@angular/core";
import { BaseApi } from "../../base/base.api";
import { UserLoginModel, AgentLoginModel } from "@ClaimsModels/index";


@Injectable()
export class UserLoginApi extends BaseApi {

    public validateUserLoginModel(model: UserLoginModel): Promise<UserLoginModel> {
        return this.httpPost("/Common/UserLogin/ValidateUserLoginModel", model);
    }

    public loginAgent(model: AgentLoginModel): Promise<AgentLoginModel> {
        return this.httpPost("/Common/UserLogin/LoginAgent", model);
    }
}