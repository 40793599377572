




import { BaseModel } from './BaseModel';


export class PageTrackingModel {
    
    public SessionId: string = null;
    public PageName: string = null;
}