import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { SessionService } from '../../services';

@Component({
    selector: 'file-upload',
    templateUrl: '../../views/shared/fileUpload.component.html'
})
export class FileUploadComponent implements OnInit {

    // Pass the height parameter in if you want to override the height of the box
    @Input('height')
    public height: string;
    @Input('uploadUrl')
    public uploadUrl: string;
    @Input('isDeviceMobile')
    public isDeviceMobile: boolean = false;
    public hasSizeError: boolean = false;
    public hasGenericError: boolean = false;
    public hasTypeError: boolean = false;

    @Output() progress: EventEmitter<number> = new EventEmitter<number>();

    public fileUploadUrl: string = "/Common/Document/UploadFiles";
    public hasFileOver: boolean = false;
    public uploader: FileUploader = new FileUploader({
        url: this.fileUploadUrl,
        disableMultipart: false,
        autoUpload: true,
        headers: [{ name: 'session', value: this.sessionService.getSerializedSession() }]
    });


    constructor(private sessionService: SessionService) {}

    public ngOnInit(): void {

        let fileUploadArea: HTMLElement = document.querySelector(".fileUploadArea");

        if (this.uploadUrl) {
            this.uploader = new FileUploader({
                url: this.uploadUrl,
                disableMultipart: false,
                autoUpload: true,
                headers: [{ name: 'session', value: this.sessionService.getSerializedSession() }]
            });
        }

        if (this.height) {
            fileUploadArea.style.minHeight = this.height;
        }

        this.uploader.onSuccessItem = (item, response, status) => {
            let result = JSON.parse(response);

            if (!result.success) {
                this.hasSizeError = true;
                this.uploader.removeFromQueue(item);
            }

            if (result.hasInvalidFile) {
                this.hasTypeError = true;
                this.uploader.removeFromQueue(item);
            }

            this.progress.emit(101);
        }

        this.uploader.onProgressAll = (progress) => {
            this.progress.emit(progress);
        }

        this.uploader.onErrorItem = (item, response) => {
            this.progress.emit(-1);
            this.uploader.removeFromQueue(item);

            if (response === "The page was not displayed because the request entity is too large.") {
                this.hasSizeError = true;
            } else {
                this.hasGenericError = true;
            }
        }

    }

    public fileOverDrop(event: any): void {
        this.hasSizeError = false;
        this.hasGenericError = false;
        this.hasTypeError = false;
        this.hasFileOver = event;
    }

    public openFileBrowser(event: Event): void {
        event.preventDefault();

        let fileSelector: HTMLInputElement = document.querySelector('#fileUploader');
        fileSelector.click();
    }

    public processFiles(files: File[]) {
    }
}