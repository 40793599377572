import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { ClaimTypeCategoryEnum, RouteCrumb } from "@ClaimsModels/index";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "@Common/services";
import { BaseService } from "./base.service";

@Injectable()
export abstract class BaseRoutingService {

    protected routes: RouteCrumb[] = [];

    constructor(private location: Location, public router: Router, public route: ActivatedRoute, public breadcrumbService: BreadcrumbService, private baseService: BaseService) {
        let claimTypeCategory: ClaimTypeCategoryEnum = this.baseService.getClaimTypeCategory();
        let claimTypeCode = this.baseService.getClaimTypeCode(); 
        if (claimTypeCategory != undefined) {

            this.initialize(claimTypeCategory, claimTypeCode); // populate the routes in the specific routing service

            this.updateBreadcrumbs(); // update the breadcrumb service with the routes
        }
    }

    public updateBreadcrumbs(): void {

        this.routes.forEach((route: RouteCrumb) => {
            this.breadcrumbService.addBreadcrumb(route);
        });

        this.breadcrumbService.update(this.route.routeConfig.path);
    }

    public next(params: Record<string, string> = null, navigationExtras: NavigationExtras = null): void {
        let pageRoute: string = this.route.routeConfig.path;

        // for pages that have queryStrings add in the lookup to support that
        if (window.location.search != "") {
            pageRoute = `${pageRoute}${window.location.search}`;
        }

        let currentRoute: RouteCrumb = this.routes.find(route => route.Route == pageRoute);
        let nextRoute: RouteCrumb = currentRoute.NextPage;

        if (params) {
            Object.keys(params).forEach(key => {
                nextRoute.Route = nextRoute.Route.replace(`:${key}`, params[key]);
            });
        }

        if (navigationExtras) {
            this.router.navigate([nextRoute.Route], navigationExtras);
            return;
        }

        this.router.navigate([nextRoute.Route]);
    }

    public routeToSpecificPage(page: string, navigationExtras: NavigationExtras = null): void {

        if (navigationExtras) {
            this.router.navigate([page], navigationExtras);
            return;
        }

        this.router.navigate([page]);
    }

    public back(): void {
        this.location.back();
    }

    public getRoute() {
        return this.route;
    }

    public replaceCurrentState(newUrl: string): void {
        this.location.replaceState(newUrl);
    }

    public abstract initialize(claimType: ClaimTypeCategoryEnum, claimTypeCode): void;
}