




import { BaseModel } from './BaseModel';

export class RVVehicleDamageModel extends BaseModel {
    public DmgWindshield: boolean = null;
}

export class VehicleDamageModel extends BaseModel {
    
    public Dmg1RightFront: boolean = null;
    public Dmg1RightSide: boolean = null;
    public Dmg1RightRear: boolean = null;
    public Dmg1Top: boolean = null;
    public Dmg1Hood: boolean = null;
    public Dmg1LeftRear: boolean = null;
    public Dmg2Front: boolean = null;
    public Dmg2Side: boolean = null;
    public Dmg2Hood: boolean = null;
    public Dmg2Trunk: boolean = null;
    public Dmg2Roof: boolean = null;
    public Dmg2Rear: boolean = null;
    public Dmg1LeftSide: boolean = null;
    public Dmg1LeftFront: boolean = null;
    public Dmg1Rear: boolean = null;
    public Dmg1Front: boolean = null;
    public Dmg1LeftFender: boolean = null;
    public Dmg1RightFender: boolean = null;
    public Dmg1LeftQuarterPiece: boolean = null;
    public Dmg1RightQuarterPiece: boolean = null;
}