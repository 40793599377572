




import { BaseModel } from './BaseModel';
import { BaseVehiclePartyInfoModel } from './BaseVehiclePartyInfoModel'
import { ParticipantModel } from './ParticipantModel';

export class VehiclePassengersModel extends BaseVehiclePartyInfoModel {
    
    public Passengers: ParticipantModel[] = [];
    public IsUnknownPassengerAdd: boolean = false;
    public NewPassengerId: number = 0;
}