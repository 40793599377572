<div ngForm #injuryForm="ngForm" class="main-inner-wrapper no-mobile">
    <p class="header">Are you reporting an injury to someone visiting your home like a slip/fall, a dog bite to a neighbor/visitor, or other injury to a visitor?</p>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="displayError" (close)="displayError = false" [type]="danger">
        Please select Yes or No
    </ngb-alert>

    <div class="input-wrapper">
        <div class="row justify-content-center">
            <div class="button wrapper">
                <button class="subCategory justify-content-center" [class.selected]="hasInjury" (click)="selectYes()">
                    <span><b>Yes</b></span>
                </button>
            </div>
            <div class="button wrapper">
                <button class="subCategory justify-content-center" [class.selected]="hasInjury != null && hasInjury == false" (click)="selectNo()">
                    <span><b>No</b></span>
                </button>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(injuryForm)">Continue</button>
    </div>
</div>