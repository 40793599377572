<div class="main-inner-wrapper">
    <div class="input-wrapper">
        <div class="row" *ngIf="isAuthenticated">
            <div class="col-xs-12">
                <form #BridgeSessionForm action="/Common/Bridge/BridgeSession" method="post">
                    <input hidden name="ApplicationName" readonly />
                    <input hidden name="Token" readonly />
                    <label>Policy Number:</label><br />

                    <select name="PolicyNumber">
                        <option value="1PA9833132">1PA9833132 - NFU(XM) 1 Driver, 1 Vehicle - Canceled</option>
                        <option value="1PA9163927">1PA9163927 - NFU(XM) 2 Drivers, 1 Vehicle - Active</option>
                        <option value="1PA0402206">1PA0402206 - NFU(XM) 4 Drivers, 8 Vehicles - Active</option>
                        <option value="2002267830">2002267830 - NPS 4 Drivers, 4 Vehicles - Canceled</option>
                        <option value="2006452905">2006452905 - NPS 1 Driver, 1 Vehicle - Active</option>
                        <option value="2002260368">2002260368 - NPS 1 Driver, 1 Vehicle - Active</option>
                        <option value="2002303752">2002303752 - NPS 1 Driver, 1 Vehicle - Active</option>
                        <option value="FLAD200910703">FLAD200910703 - DG - DoL 06/12/2019</option>
                    </select>

                    <label>Policy not in the drop down? Enter it below.</label>
                    <input placeholder="(i.e. 1PA9833132)" type="text" name="PolicyInput" DisallowWhiteSpace  />

                    <label *ngIf="showBrand">Select a brand to utilize going forward</label>
                    <select name="FNOLBranding" *ngIf="showBrand">
                        <option value="https://m.directauto.com/UnifiedMyAccount#!/login">Direct Auto</option>
                        <option value="https://claims.nationalgeneral.com/">National General Insurance</option>
                        <option value="https://goodsamrvinsurance.com/">Good Sam RV Insurance</option>
                        <option value="https://farmersunioninsurance.com/">Farmers Union Insurance</option>
                        <option value="https://aie-ny.com">Adirondack Insurance Exchange</option>
                        <option value="https://mvic-aie.com">Mountain Valley Indemnity Company</option>
                        <option value="https://njsi.com">New Jersey Skylands Insurance</option>
                        <option value="https://EncompassInsurance.com">Encompass Insurance</option>
                        <option value="https://esurance.com">Esurance</option>
                        <option value="https://safeauto.com">SafeAuto</option>
                        <option value="https://custom360.com">Custom 360</option>
                        <option value="http://localhost:49566/fnolDashboard">No Branding</option>
                    </select>

                    <label>Select Insured or Agent</label>
                    <select name="userType">
                        <option value="Insured">Insured</option>
                        <option value="Agent">Agent</option>
                        <option value="NPSFNOL">FNOL Agent</option>
                    </select>

                    <label>Select Source Party</label>
                    <select name="sourceParty">
                        <option value=""></option>
                        <option value="INSURED">Insured</option>
                        <option value="OTHERPARTY">Other Party</option>
                        <option value="AGENT">Agent</option>
                        <option value="ADVERSECARRIER">Adverse Carrier</option>
                        <option value="OTHER">Other</option>
                    </select>


                    <div class="button-wrapper">
                        <button id="continueBtn" (click)="submitForm(BridgeSessionForm)" class="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row" *ngIf="!isAuthenticated">
            <p class="header">Please sign in</p>
            <div class="col-xs-12">

                <div class="invalidMessage" *ngIf="hasError">
                    <i class="fas fa-exclamation-circle"></i>
                    Incorrect username or password.
                </div>

                <form #signInForm="ngForm" method="post">
                    <div class="form-group">
                        <label>Username</label>
                        <input name="username" type="text" [(ngModel)]="user.Username" placeholder="NGIC ID Number" #Username="ngModel" Validate required autocomplete="on" autofocus />
                    </div>

                    <div class="form-group">
                        <label>Password</label>
                        <input name="password" type="password" [(ngModel)]="user.Password" placeholder="NGIC Password" #Password="ngModel" Validate required autocomplete="off" />
                    </div>

                    <div class="button-wrapper">
                        <button id="signInBtn" (click)="signIn(signInForm)" class="btn btn-primary">Sign in</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>