import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

import { UnsubmittedClaimBottomSheet } from '../components/modals/unsubmittedClaim.component';
import { SessionService } from './session/session.service';
import { SessionModel, BrandEnum, DeviceTypeEnum } from '@ClaimsModels/index';
import { DeviceDetectionService } from './deviceDetection.service';

@Injectable({
    providedIn: 'root'
})
export class UnsubmittedClaimService {
    private matBottomSheetRef: MatBottomSheetRef;
    private ignoreAlert: boolean;

    constructor(private bottomSheet: MatBottomSheet, private sessionService: SessionService, private router: Router, private deviceDetection: DeviceDetectionService) {
    }

    public leaveMccApp(returnUrl: string): void {

        let brand: string = this.sessionService.getBrand();

        if (brand === BrandEnum.Esurance && this.deviceDetection.DeviceType === DeviceTypeEnum.Mobile) {
            this.setIgnoreAlert();
            this.router.navigate(['closeTab']);
            return;
        }

        this.matBottomSheetRef = this.bottomSheet.open(UnsubmittedClaimBottomSheet, { disableClose: false });

        this.matBottomSheetRef.afterDismissed().subscribe(result => {
            if (!result || !result.event) {
                return;
            }

            this.sessionService.clearSession();
            
            switch (brand) {
                case BrandEnum.Esurance:
                case BrandEnum.SafeAuto:
                    this.router.navigate(['closeTab']);
                    return;
                default:
                    location.replace(returnUrl);
                    break;
            }
        });
    }

    public gotToSafelite(safeliteUrl: string): void {
        this.matBottomSheetRef = this.bottomSheet.open(UnsubmittedClaimBottomSheet,
            { data: { IsGlassClaim: true }, disableClose: false });

        this.matBottomSheetRef.afterDismissed().subscribe(result => {
            if (!result || !result.event) {
                if (this.sessionService.get('isGlassOnlySelected') == 'true') {
                    this.router.navigate(['/fnolAuto/glassOnly']);
                    return;
                }
                history.back();
                return;
            }

            this.setIgnoreAlert();
            location.replace(safeliteUrl);
        });
    }

    public displayDefaultBrowserAlert(): boolean {
        if (this.ignoreAlert) {
            this.ignoreAlert = false;
            return true;
        }

        let session: SessionModel = this.sessionService.getSession();

        if (window.performance && window.performance.navigation.type == window.performance.navigation.TYPE_RELOAD) {
            return true; // page is being refreshed: allow, no alert
        }

        if (session.ClaimId > 0 && session.IsSubmitted === false) {
            return false; // show default browser alert;
        }

        return true;
    }

    public setIgnoreAlert(): void {
        this.ignoreAlert = true;
    }
}