<div class="container">

    <div class="form_wrap">
        <div class="text-center">
            <p class="lead">
                Something went wrong.  Please try the link again later.  If this problem continues, please contact your adjuster.
            </p>
        </div>
    </div>

</div>