<div ngForm #additionalPartyVehicle="ngForm" class="main-inner-wrapper">
    <p class="header">Please provide as much of the vehicle information as possible.</p>
    <div class="input-wrapper">
        <div class="row">
            <div class="col-lg-8 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(LicenseNumber)">License Plate Number</label>
                </div>
                <input Validate type="text" name="PlateNumber" [(ngModel)]="vm.PlateNumber" #LicenseNumber="ngModel" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>State</label>
                </div>
                <select [(ngModel)]="vm.PlateStateCode" #State="ngModel" name="State" class="state gbmask">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                </select>
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Year)">Year</label>
                </div>
                <input Validate type="text" OnlyNumbers name="Year" [(ngModel)]="vm.Year" #Year="ngModel" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Make)">Make</label>
                </div>
                <input Validate type="text" OnlyLetters name="Make" [(ngModel)]="vm.Make" #Make="ngModel" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Model)">Model</label>
                </div>
                <input Validate type="text" name="Model" [(ngModel)]="vm.Model" #Model="ngModel" />
            </div>
            <div *ngIf="displayDescriptionQuestion()" class="col-lg-12 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Description of Damage</label>
                </div>
                <textarea Validate name="DamageDescription" rows="3" [(ngModel)]="vm.VehicleClaimInfo.DamageDescription" #Description="ngModel"></textarea>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(additionalPartyVehicle)">Continue</button>
    </div>
</div>