<div ngForm #emergencyResponseForm="ngForm" class="main-inner-wrapper">

    <p class="header" *ngIf="!selectionMade || !hasEmergencyService">Did any <em class="emphasis-bold">Police, Fire, or Emergency Medical Service</em> respond to the incident?</p>

    <p class="header" *ngIf="selectionMade && hasEmergencyService">Please select which <em class="emphasis-bold">Emergency Services</em> responded to the incident.</p>

    <!-- Gives users the option to select whether Emergency Services responded or not.  -->
    <div class="input-wrapper" *ngIf="!selectionMade || !hasEmergencyService">
            <div class="row">
                <div>
                    <button class="vehicle addNew button-reversed" (click)="selectYes()" [ngClass]="{'selected': hasEmergencyService != null && hasEmergencyService}">
                        <span><b>Yes</b></span>
                    </button>
                </div>
                <div>
                    <button class="vehicle addNew button-reversed" (click)="selectNo()" [ngClass]="{'selected': hasEmergencyService != null && !hasEmergencyService}">
                        <span><b>No</b></span>
                    </button>
                </div>
            </div>
    </div>
    
    <!-- Shows once user selects Emergency Services did show up to the incident. -->
    <div *ngIf="selectionMade && hasEmergencyService" class="emergencyResponse">
        <div class="button-wrapper" (click)="selectTypeService($event, ems)">
            <div id="ems" class="icon cursor" [ngClass]="{'selectedService': this.vm.EMS === true}">
                <img src="/content/images/ems-outline.svg" title="ems image" alt="ems image" width="100" height="100" />
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>EMS</strong></div>
                </div>
            </div>
        </div>
        <div class="button-wrapper" (click)="selectTypeService($event, police)">
            <div id="police" class="icon cursor" [ngClass]="{'selectedService': this.vm.Police === true}">
                <img src="/content/images/police-outline.svg" title="police image" alt="police image" width="100" height="100" />
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Police</strong></div>
                </div>
            </div>
        </div>
        <div class="button-wrapper" (click)="selectTypeService($event, firefighter)">
            <div id="firefighter" class="icon cursor" [ngClass]="{'selectedService': this.vm.Fire === true}">
                <img src="/content/images/firefighter-outline.svg" title="firefighter image" alt="firefighter image" width="100" height="100" />
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Fire</strong></div>
                </div>
            </div>
        </div>  
    </div>
    <br />
    <div *ngIf="selectionMade && hasEmergencyService" class="emergencyResponseReportNum-wrapper">
        <div class="row justify-content-center">
            <div class="fieldLabel-wrapper reportNumber-wrapper col-lg-6 col-xs-12" *ngIf="this.vm.Police === true">
                <label>Department</label>
                <input Validate type="text" [(ngModel)]="this.vm.Department" name="Department" />
            </div>
            <div class="fieldLabel-wrapper reportNumber-wrapper col-lg-6 col-xs-12" *ngIf="this.vm.Police === true">
                <label>Report Number</label>
                <input Validate type="text" [(ngModel)]="this.vm.ReportNumber" name="ReportNumber" />
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button *ngIf="selectionMade && hasEmergencyService" id="continueBtn" class="btn btn-primary" (click)="submit(emergencyResponseForm)">Continue</button>
    </div>
</div>