




import { BaseModel } from './BaseModel';

import { AddressModel } from './AddressModel';
import { ValueDetailModel } from './ValueDetailModel';
import { BaseLossDetailsModel } from './BaseLossDetailsModel';

export class BaseLossDescriptionModel extends BaseModel {
    
    public Address: AddressModel = null;
    public States: ValueDetailModel[] = [];
    public LossDetails: BaseLossDetailsModel = null;
}