




import { BaseModel } from './BaseModel';

import { PhoneModel } from './PhoneModel';
import { EmailModel } from './EmailModel';

export class ContactInfoModel {
    
    public Phones: PhoneModel[] = [];
    public Emails: EmailModel[] = [];
}