import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import cssVars from 'css-vars-ponyfill';
import { BrandEnum } from '@ClaimsModels/index';

@Injectable({ providedIn: 'root'})
export class StyleManagerService {
    constructor() { }

    public contactPhoneNumber: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public returnUrl: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public natGenOldForm: BehaviorSubject<string> = new BehaviorSubject<string>("");

    public applyBrand(brand: string) {
        let stylesheet: HTMLLinkElement;

        switch (brand.toLowerCase()) {
            case BrandEnum.Farmers:
                stylesheet = this.createLinkElement(`farmers.css`);
                break;
            case BrandEnum.GoodSam:
                stylesheet = this.createLinkElement(`goodSam.css`);
                break;
            case BrandEnum.DirectAuto:
                stylesheet = this.createLinkElement(`direct.css`);
                break;
            case BrandEnum.NationalGeneral:
                stylesheet = this.createLinkElement(`ngic.css`);
                break;
            //case BrandEnum.MC360:
            //    stylesheet = this.createLinkElement(`mc360.css`);
            //    break;
            case BrandEnum.CustomerValidation:
                stylesheet = this.createLinkElement(`mc360.css`);
            case BrandEnum.AIE:
                stylesheet = this.createLinkElement(`aie.css`);
                break;
            case BrandEnum.MVIC:
                stylesheet = this.createLinkElement(`mvic.css`);
                break;
            case BrandEnum.NJSI:
                stylesheet = this.createLinkElement(`njsi.css`);
                break;
            case BrandEnum.EncompassInsurance:
                stylesheet = this.createLinkElement('encompassInsurance.css');
                break;
            case BrandEnum.Esurance:
                stylesheet = this.createLinkElement('esurance.css');
                break;
            case BrandEnum.SafeAuto:
                stylesheet = this.createLinkElement('safeauto.css');
                break;
            case BrandEnum.NoBrand:
                stylesheet = this.createLinkElement('noBrand.css');
                break;
            case BrandEnum.Custom360:
                stylesheet = this.createLinkElement('c360.css');
                break;
            default:
                // do we throw an error here if we do not have a stylesheet for the domain coming in?
                stylesheet = this.createLinkElement('noBrand.css');
                break;
        }

        if (stylesheet) {

            // see if the file is already loaded, is so, do not load again
            let loadedSheet = document.head.querySelector(`link[href='${stylesheet.getAttribute("href")}']`)
            if (loadedSheet == null || loadedSheet == undefined) {
                document.head.appendChild(stylesheet);
            }
        }
    }


    private createLinkElement(href: string): HTMLLinkElement {
        let linkEl: HTMLLinkElement = document.createElement('link');

        linkEl.type = 'text/css';
        linkEl.rel = 'stylesheet';
        linkEl.href = href;

        linkEl.onload = function () {
            // update the values when the stylesheet is loaded into the DOM
            let favIcon: HTMLLinkElement = document.head.querySelector("link[rel='icon']")

            if (window.navigator.userAgent.indexOf('Trident') >= 0 || window.navigator.userAgent.indexOf("MSIE") >= 0) {
                cssVars({
                    onlyLegacy: true,
                    preserveVars: true,
                    onComplete: function (cssText, styleElements, cssVariables, benchmark) {
                        document.title = cssVariables["--pageTitle"].cleanCssVariable();
                        favIcon.href = cssVariables["--favIcon"].cleanCssVariable();
                        this.contactPhoneNumber.next(cssVariables["--contactNumber"].cleanCssVariable());
                        this.returnUrl.next(cssVariables["--returnUrl"].cleanCssVariable());
                        this.natGenOldForm.next(cssVariables["--natGenOldForm"].cleanCssVariable());
                    }.bind(this)
                });
            }
            else {
                let elementStyles: CSSStyleDeclaration = getComputedStyle(document.querySelector(":root"));
                document.title = this.getCssVariable(elementStyles, "--pageTitle");
                favIcon.href = this.getCssVariable(elementStyles, "--favIcon");

                this.setCssVariables(elementStyles);
            }
        }.bind(this);

        return linkEl;
    }

    // since we are in the onLoad function of the stylesheet, pull out 
    private setCssVariables(elementStyles: CSSStyleDeclaration) {
        this.contactPhoneNumber.next(this.getCssVariable(elementStyles, "--contactNumber"));
        this.returnUrl.next(this.getCssVariable(elementStyles, "--returnUrl"));
        this.natGenOldForm.next(this.getCssVariable(elementStyles, "--natGenOldForm"));
    }

    // this is used above
    private getCssVariable(elementStyles: CSSStyleDeclaration, cssVariable: string) {
        return elementStyles.getPropertyValue(cssVariable).cleanCssVariable();
    }
}

/*
 * Add our clean method to the string interface.
 * We can now clean up our string variables a bit
 */
declare global {
    interface String {
        cleanCssVariable(): string;
    }
}


String.prototype.cleanCssVariable = function (): string {
    return this.trim()
        .replace(/\'/g, "") // eliminate '
        .replace(/\"/g, ""); // eliminate "
}