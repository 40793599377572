import { BaseComponent } from "@Common/base/base.component";
import { Component, Output, EventEmitter } from "@angular/core";
import { MC360TabOptions } from '@ClaimsModels/index';


@Component({
    selector: 'navHeader',
    templateUrl: '../views/navHeader.component.html',
    styleUrls: ['../styles/dashboard.component.css']
})

export class NavHeaderComponent extends BaseComponent {

    @Output()
    selectedTabEvent = new EventEmitter<string>();

    public activeButton: string = "HOME";
    public TabOptions = MC360TabOptions;

    public ngOnInit(): void {
        this.selectedTabEvent.emit(this.TabOptions.Home)
    };

    public ChangeTab(tab: string): void {
        this.selectedTabEvent.emit(tab);
        this.activeButton = tab;
    }
}