import { Directive, ElementRef, HostListener } from '@angular/core';
import { UnsubmittedClaimService } from '../services';

@Directive({
    selector: 'a'
})

export class BypassHrefAlert {

    private tagsToIgnore: Array<string> = ['mailto', 'tel'];

    constructor(public el: ElementRef, private unsubbmitedClaim: UnsubmittedClaimService) {
    }

    @HostListener("click") OnClick() {
        let anchor: HTMLAnchorElement = this.el.nativeElement;

        if (this.tagsToIgnore.some(tag => anchor.href.includes(tag))) {
            this.unsubbmitedClaim.setIgnoreAlert();
            return;
        }
    }

}