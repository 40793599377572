import { BaseModel } from './BaseModel';

import { DeviceTrackingModel } from './DeviceTrackingModel';
import { SessionSettingsModel } from './SessionSettingsModel';
import { RecordingSessionModel } from './RecordingSessionModel';

export class NewClaimThirdPartyModel extends BaseModel {
    
    public Brand: string = null;
    public DeviceTracking: DeviceTrackingModel = null;
    public ClaimType: string = null;
    public IsInitialized: boolean = false;
    public RedirectUrl: string = null;
    public QueryParameter: string = null;
    public SessionSettings: SessionSettingsModel = null;
    public ReturnUrl: string = null;
    public RecordingSession: RecordingSessionModel = null;

}