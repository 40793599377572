<div ngForm #claimTypeForm="ngForm" class="main-inner-wrapper no-mobile">
    <p class="header" *ngIf="!firstSelectionMade">Please select one category which describes the item with the most damage, or that is lost or missing.</p>
    <p class="header" *ngIf="firstSelectionMade && isWeatherRelated == null && (otherStructuresImage.IsSelected || isPropertyRelated)">Was the damage weather related?</p>
    <p class="header" *ngIf="firstSelectionMade && isPropertyRelated == null && personalPropertyImage.IsSelected">Please select how Personal Property was damaged.</p>
    <p class="header" *ngIf="firstSelectionMade && isWeatherRelated">Please select weather related damage.</p>
    <p class="header" *ngIf="firstSelectionMade && isWeatherRelated == false">Please select damage category which occurred.</p>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="hasError" (close)="hasError = false" [type]="danger">
        Please select one of the following options to continue.
    </ngb-alert>

    <!-- Top level selection -->
    <div *ngIf="!firstSelectionMade" class="d-flex flex-wrap justify-content-center claims-wrapper">
        <div class="button-wrapper" (click)="toggleImageSelection(equipmentBreakdownImage)">
            <div id="equipment" class="icon cursor" [class.selectedService]="equipmentBreakdownImage.IsSelected">
                <img *ngIf="!equipmentBreakdownImage.IsSelected" src="/content/images/equipment-outline.svg" title="equipment image" alt="equipment image" width="100" height="100" />
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Equipment Breakdown</strong></div>
                    <div class="claims-innertext" *ngIf="equipmentBreakdownImage.IsSelected">
                        <span>{{getHelpText(equipmentBreakdownImage)}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-wrapper" (click)="toggleImageSelection(otherStructuresImage)">
            <div id="otherStructure" class="icon cursor" [class.selectedService]="otherStructuresImage.IsSelected">
                <img *ngIf="!otherStructuresImage.IsSelected" src="/content/images/otherStructure-outline.svg" title="otherStructure image" alt="otherStructure image" width="100" height="100" />
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Other Structures</strong></div>
                    <div class="claims-innertext" *ngIf="otherStructuresImage.IsSelected">
                        <span>{{getHelpText(otherStructuresImage)}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-wrapper" (click)="toggleImageSelection(personalPropertyImage)">
            <div id="personalProperty" class="icon cursor" [class.selectedService]="personalPropertyImage.IsSelected">
                <img *ngIf="!personalPropertyImage.IsSelected" src="/content/images/personalProperty-outline.svg" title="personalProperty image" alt="personalProperty image" width="100" height="100" />
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Personal Property</strong></div>
                    <div class="claims-innertext" *ngIf="personalPropertyImage.IsSelected">
                        <span>{{getHelpText(personalPropertyImage)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Weather Question Menu -->
    <!-- Show if its other structures or property related -->
    <div *ngIf="firstSelectionMade && isWeatherRelated == null && (otherStructuresImage.IsSelected || isPropertyRelated)" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        <div class="button wrapper">
            <button class="subCategory justify-content-center" [class.selected]="userWeatherSelection != null && userWeatherSelection" (click)="userWeatherSelection = true">
                <span><strong>Yes</strong></span>
            </button>
        </div>
        <div class="button wrapper">
            <button class="subCategory justify-content-center" [class.selected]="userWeatherSelection != null && !userWeatherSelection" (click)="userWeatherSelection = false">
                <span><strong>No</strong></span>
            </button>
        </div>
    </div>

    <!-- Personal Property Menu -->
    <div *ngIf="firstSelectionMade && isPropertyRelated == null && personalPropertyImage.IsSelected" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        <ng-container *ngFor="let claimType of filterClaimTypes(ClaimTypeCategory.PersonalProperty)">
            <div class="button wrapper">
                <button class="subCategory justify-content-center" [class.selected]="claimType.IsSelected" (click)="selectClaimType(claimType)">
                    <span><strong>{{ claimType.Name }}</strong></span>
                </button>
            </div>
        </ng-container>
    </div>

    <!-- This will show the relevant claim types for dwelling & other structure -->
    <div *ngIf="firstSelectionMade && isWeatherRelated != null" class="d-flex justify-content-center flex-row flex-wrap input-wrapper">
        <ng-container *ngFor="let claimType of filterClaimTypes()">
            <div class="button wrapper">
                <!--col-lg-6 col-xs-12-->
                <button class="subCategory justify-content-center" [class.selected]="claimType.IsSelected" (click)="selectClaimType(claimType)">
                    <span><strong>{{ claimType.Name }}</strong></span>
                </button>
            </div>
        </ng-container>
    </div>

    <div *ngIf="showWildfireQuestion" class="mt-4">
        <div class="fieldLabel-wrapper">
            <div class="fieldLabel-wrapper">
                <label for="injured" [ngClass]="childHasInvalid(IsWildfireRelated)">Is this wildfire related?</label>
                <i [ngClass]="childHasInvalid(IsWildfireRelated)" class="fas fa-asterisk" title="*Required Field"></i>
            </div>
        </div>
        <div class="container-wrapper">
            <div class="row">
                <div class="col-lg-6 d-flex">
                    <label class="container mr-4">
                        <input type="radio" name="isWildfireRelated" [value]="true" required
                               [(ngModel)]="vm.IsWildfireRelated" #IsWildfireRelated="ngModel">
                        <span class="checkmark"></span>
                        Yes
                    </label>
                    <label class="container">
                        <input type="radio" name="isWildfireRelated" [value]="false" required
                               [(ngModel)]="vm.IsWildfireRelated">
                        <span class="checkmark"></span>
                        No
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" *ngIf="firstSelectionMade" (click)="goBack()">Back</button>
        <button id="backBtn" class="btn btn-primary" *ngIf="!firstSelectionMade" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(claimTypeForm)">Continue</button>
    </div>
</div>