<div ngForm #uploadReceiptForm="ngForm" class="main-inner-wrapper">
    <p class="header">Please upload your receipt for towing and labor expenses.</p>
    <div class="row w-150">
        <div class="col-12">
            <div class="fieldLabel-wrapper highlight-ui">
                <label>
                    If you are unable to upload your receipt at this time, you may submit to
                    <span><strong><a href="mailto:{{claimsEmail}}" role="button" class="cursor">{{claimsEmail}}</a></strong></span>
                    with your claim number as the subject line.
                    &nbsp;Documents and photos are <em class="emphasis-bold">not</em> required for claim submission.
                </label>
            </div>
            <file-upload height="25vh" [isDeviceMobile]="isDeviceMobile"></file-upload>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(uploadReceiptForm)">Continue</button>
    </div>
</div>