




import { BaseModel } from './BaseModel';


export class VehicleModel {
    
    public VehicleInfo: string = null;
    public VehicleId: number = 0;
    public IsSelected: boolean = false;
    public IsOnPolicy: boolean = false;
    public IsUnlisted: boolean = false;
}