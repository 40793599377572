<div ngForm #occupationInformationForm="ngForm" class="main-inner-wrapper">
    <p *ngIf="claimTypeCategory == 'theft'" class="header">Were there any <em class="emphasis-bold">occupants</em> in your vehicle at the time of the theft?</p>
    <p *ngIf="claimTypeCategory == 'weather' || claimTypeCategory == 'other'" class="header">Were there any <em class="emphasis-bold">occupants</em> in your vehicle at the time of the incident?</p>

    <span *ngIf="hasError" class="red">Please select one of the following options to continue.</span>

    <!--Occupation question-->
    <div class="input-wrapper">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="setHasOccupantsInVehicle(true);" [ngClass]="{'selected': hasOccupantsInVehicle != null && hasOccupantsInVehicle}">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="setHasOccupantsInVehicle(false)" [ngClass]="{'selected': hasOccupantsInVehicle != null && !hasOccupantsInVehicle}">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(occupationInformationForm)">Continue</button>
    </div>
</div>
