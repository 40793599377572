import { Injectable, EventEmitter } from "@angular/core";
import { SessionService } from "../services";
import { ClaimTypeCategoryEnum } from '@ClaimsModels/index';


@Injectable()
export abstract class BaseService {

    public isDeviceMobile: EventEmitter<boolean> = new EventEmitter();
    private _claimTypeCategoryKey: string = "ClaimTypeCategory";
    private _claimTypeCodeKey: string = "ClaimTypeCode";

    constructor(protected sessionService: SessionService) {}

    public getClaimTypeCategory(): ClaimTypeCategoryEnum {
        return ClaimTypeCategoryEnum[this.sessionService.get(this._claimTypeCategoryKey)];
    }

    public getClaimTypeCode(): string {
        return this.sessionService.get(this._claimTypeCodeKey);
    }

    public setClaimTypeCategory(value: ClaimTypeCategoryEnum): void {
        this.sessionService.set(this._claimTypeCategoryKey, ClaimTypeCategoryEnum[value]);
    }

    public setClaimTypeCode(value: string): void {
        this.sessionService.set(this._claimTypeCodeKey, value);
    }

    public emitIsDeviceMobile(isMobile) {
        this.isDeviceMobile.emit(isMobile);
    }

    public getIsDeviceMobile() {
        return this.isDeviceMobile;
    }
}