import { ErrorHandler, Injectable } from '@angular/core';

import { LoggerApi } from '../utility/logger.api';
import { UIErrorModel } from '@ClaimsModels/UIErrorModel';

@Injectable()
export class ErrorLoggerService implements ErrorHandler {

    constructor(private api: LoggerApi) { }

    public handleError(error: Error): void {

        let errorLog = new UIErrorModel();
        errorLog.Message = error.message;
        errorLog.StackTrace = error.stack;

        this.api.logError(errorLog);

        console.error(error);
    }
}