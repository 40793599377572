




import { BaseModel } from './BaseModel';

import { AddressModel } from './AddressModel';
import { VehicleClaimInfoModel, RVVehicleClaimInfoModel } from './VehicleClaimInfoModel';
import { ParticipantModel } from './ParticipantModel';
import { DisplayModeEnum } from './DisplayModeEnum';
import { ValueDetailModel } from './ValueDetailModel';

export class RVVehicleInfoModel extends BaseModel {

    public PropertyId: number = 0;
    public VehicleType: string = null;
    public PlateStateCode: string = null;
    public Make: string = null;
    public Model: string = null;
    public PlateNumber: string = null;
    public VehicleClassCode: string = null;
    public VIN: string = null;
    public Year: string = null;
    public Color: string = null;
    public Style: string = null;
    public VehicleUse: string = null;
    public VehicleLocationAddress: AddressModel = null;
    public VehicleClaimInfo: RVVehicleClaimInfoModel = null;
    public LossParticipants: ParticipantModel[] = [];
    public Passengers: ParticipantModel[] = [];
    public SelectedPassengers: ParticipantModel[] = [];
    public DisplayMode: DisplayModeEnum = null;
    public States: ValueDetailModel[] = [];
    public VehicleLocationTypes: ValueDetailModel[] = [];
    public VehicleMakes: ValueDetailModel[] = [];
    public PolicyPrimaryAddress: AddressModel = null;
}

export class VehicleInfoModel extends BaseModel {
    
    public PropertyId: number = 0;
    public VehicleType: string = null;
    public PlateStateCode: string = null;
    public Make: string = null;
    public Model: string = null;
    public PlateNumber: string = null;
    public VehicleClassCode: string = null;
    public VIN: string = null;
    public Year: string = null;
    public Color: string = null;
    public Style: string = null;
    public VehicleUse: string = null;
    public VehicleLocationAddress: AddressModel = null;
    public VehicleClaimInfo: VehicleClaimInfoModel = null;
    public LossParticipants: ParticipantModel[] = [];
    public Passengers: ParticipantModel[] = [];
    public SelectedPassengers: ParticipantModel[] = [];
    public DisplayMode: DisplayModeEnum = null;
    public States: ValueDetailModel[] = [];
    public VehicleLocationTypes: ValueDetailModel[] = [];
    public VehicleMakes: ValueDetailModel[] = [];
    public PolicyPrimaryAddress: AddressModel = null;
}