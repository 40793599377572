import { Component } from '@angular/core';
import { LossApi } from '../../services/loss.api';
import { SessionService, ConstantsService, BreadcrumbService, ContactService } from '@Common/services';
import { ConfirmationInfoModel, BrandEnum } from '@ClaimsModels/index';
import { BaseComponent } from '@Common/base/base.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { FNOLAutoService } from '@FNOLAuto/services/';

@Component({
    selector: 'confirmation',
    templateUrl: '../../views/loss/confirmation.component.html'
})
export class ConfirmationComponent extends BaseComponent {

    constructor(private sessionService: SessionService, private lossApi: LossApi, private breadcrumbService: BreadcrumbService, private constantsService: ConstantsService, private fnolAutoService: FNOLAutoService,
                private contactService: ContactService) {
        super();
    }

    public vm: ConfirmationInfoModel = new ConfirmationInfoModel();

    public timedOut: boolean = false;
    public dataComplete: boolean = false;
    public timerProgress: number = 0;
    public claimTypeCode: string;
    public claimsEmail: string;
    public isEsurance: boolean;

    public ngOnInit(): void {
        this.breadcrumbService.clearBreadcrumbs();
        let session = this.sessionService.getSession();
        session.IsSubmitted = true;
        this.sessionService.setSession(session);
        this.claimTypeCode = this.getClaimTypeCodeValue();
        this.claimsEmail = this.getClaimsEmail();

        let iterator = 0;

        if (BrandEnum.Esurance === this.sessionService.getBrand()) {
            this.isEsurance = true;
        }
        let spinnerInterval = setInterval(() => {
            this.timerProgress += 100 / 130;//maximum fill divided by ticks in retry window below
            if (this.timedOut || this.dataComplete) {
                clearTimeout(spinnerInterval);
            }
        }, 500);

        let intervalId = setInterval(() => {
            this.lossApi.getClaimInfo().then(response => {
                iterator++;
                if (response.Success) {
                    this.vm.ClaimNumber = response.ClaimNumber;
                    this.vm.ReporterEmail = response.ReporterEmail;

                    // Only want the Adjuster details if the claim is open
                    if (response.IsClaimOpen) {
                        this.vm = response;
                    }

                    // add the word 'Ext' into the phone number for the mask..
                    if (this.haveDataFor(this.vm.AdjusterPhone) && this.vm.AdjusterPhone.length > 10) {
                        this.vm.AdjusterPhone = `${this.vm.AdjusterPhone.slice(0, 10)}Ext${this.vm.AdjusterPhone.slice(10)}`;
                    }
                }

                this.dataComplete = this.haveDataFor(response.ClaimNumber)
                    && this.haveDataFor(response.AdjusterFirstName)
                    && this.haveDataFor(response.AdjusterLastName)
                    && response.IsClaimOpen;

                if (response.Success && this.dataComplete) {
                    iterator = 0;
                    clearTimeout(intervalId);
                }
            });

            if (iterator > 20 && !this.dataComplete) {
                clearTimeout(intervalId);
                this.timedOut = true;
            }
        }, 3000);
    }

    public getClaimsEmail(): string {
        return this.contactService.getClaimsEmail();
    }

    private getClaimTypeCodeValue(): string {
        return this.fnolAutoService.getClaimTypeCode();
    }
}