import { BaseComponent } from "@Common/base/base.component";
import { Component, Output, EventEmitter, Input } from "@angular/core";
import { ClaimPaymentsModel, PaymentModel, SessionModel, ClaimModel } from '@ClaimsModels/index';
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { SessionService } from '@Common/services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginatorModule, MatPaginator, PageEvent } from '@angular/material/paginator';
import { Location } from '@angular/common';
import { MatTableDataSource, _MatTableDataSource } from '@angular/material/table';
import { ViewChild, AfterViewInit } from '@angular/core';


@Component({
    selector: 'payments',
    templateUrl: '../views/payments.component.html',
    styleUrls: ['../styles/mc360styling.css']
})

export class PaymentsComponent extends BaseComponent implements AfterViewInit
{

    constructor(private location: Location, private route: ActivatedRoute, private myClaimsApi: MyClaimsApi, private router: Router,
        private sessionService: SessionService, private dashboardService: DashboardService, private matPaginator: MatPaginatorModule) {
        super();

    }
    
    public vm: ClaimPaymentsModel;
    public payments: PaymentModel;
    private lossParticipantId: number;
    public displayedColumns: string[];
    public orderedPayments: PaymentModel[];
    title = 'material-data-table';
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    dataSource= new MatTableDataSource<PaymentModel>();
    public limit: number = 10;
    public length: number = 100;
    pageCount = 1;
    pageSize = 10;
    itemsPerPage = 10;

    pageSizeOptions = [10, 25, 50, 100];
    pageIndex = 0;
    hidePageSize = false;
    showPageSizeOptions = true;
    showFirstLastButtons = true;
    pageEvent: PageEvent;

    handlePageChange(e: PageEvent) {
        this.pageEvent = e;
        this.length = e.length;
        this.pageSize = e.pageSize;
        this.pageIndex = e.pageIndex;
    }

    setPageSizeOptions(setPageSizeOptionsInput: string) {
        if (setPageSizeOptionsInput) {
            this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
        }
    }

    @Input() ShowAllPayments = true; // decorate the property with @Input()

    public ngOnInit(): void {
        this.vm = new ClaimPaymentsModel();
        this.vm.Payments = [];
        this.displayedColumns = ['PaidTo', 'Date', 'Amount'];
        this.lossParticipantId = parseInt(this.dashboardService.getLossParticipantId());

        this.myClaimsApi.getLossParticipantPayments(this.lossParticipantId).then((response) => {
            this.vm = response;
            this.pageCount = Math.ceil(this.vm.Payments.length / this.limit);
            this.length = this.vm.Payments.length;
            if (!(this.ShowAllPayments)) {
                this.vm.Payments = this.vm.Payments.slice(0, 5);
            }
        })
    }

    public ngAfterViewInit(): void
    {
        this.myClaimsApi.getLossParticipantPayments(this.lossParticipantId).then((response) => {
            this.vm = response;
            this.pageCount = Math.ceil(this.vm.Payments.length / this.limit);
            this.length = this.vm.Payments.length;
            if (!(this.ShowAllPayments)) {
                this.vm.Payments = this.vm.Payments.slice(0, 5);  
            }

            this.dataSource = new MatTableDataSource(this.vm.Payments);
            this.dataSource.paginator = this.paginator;
        })
    };
}