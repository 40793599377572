import { BaseModel } from './BaseModel';
import { BasePedestrianInformationModel } from './BasePedestrianInformationModel';
import { ValueDetailModel } from './ValueDetailModel';

export class BasePedestrianModel extends BaseModel {
    
    public Persons: BasePedestrianInformationModel[] = [];
    public Pedestrian: BasePedestrianInformationModel = null;
    public States: ValueDetailModel[] = [];
    public PhoneTypes: ValueDetailModel[] = [];
}