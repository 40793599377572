




import { BaseModel } from './BaseModel';

import { ClaimModel } from './ClaimModel';
import { SessionModel } from './SessionModel';

export class DashboardModel extends BaseModel {
    
    public OpenClaims: ClaimModel[] = [];
    public PreviousClaims: ClaimModel[] = [];
    public Session: SessionModel = null;
}