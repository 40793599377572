




import { BaseModel } from './BaseModel';


export class BaseParticipantClaimDetailsModel {
    
    public LossParticipantId: number = 0;
    public ParticipantRole: string = null;
    public AccidentRole: string = null;
    public Fatality: boolean = false;
    public InjuryLevel: string = null;
    public Transported: string = null;
    public InjuryDescription: string = null;
    public IsOnPolicy: boolean = false;
}