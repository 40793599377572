import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseContactFormComponent } from '@Common/base/baseContactForm.component';
import { ParticipantApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { AddressValidationService, ConstantsService } from '@Common/services/';
import {
    LossParticipantModel, AddressModel, AddressValidationModel, AssignmentModel, InsuranceInformationModel,
    ContactInfoModel, PhoneModel, EmailModel, ParticipantClaimDetailsModel, ParticipantModel, ValueDetailModel, ClaimTypeCategoryEnum
} from '@ClaimsModels/index';

@Component({
    template: ''
})
export abstract class BaseSelectContactComponent extends BaseContactFormComponent {
    public isNotInEditMode: boolean = true;

    constructor(protected api: ParticipantApi, protected spinner: Ng4LoadingSpinnerService, protected addressValidationService: AddressValidationService,
        protected fnolRouting: FNOLRoutingService, protected fnolAutoService: FNOLAutoService,
        protected constantsService: ConstantsService) {
        super();

        this.vm = new AssignmentModel();
        this.vm.LossParticipant = new LossParticipantModel();
        this.vm.LossParticipant.Address = new AddressModel();
        this.vm.LossParticipant.ContactInfo = new ContactInfoModel();
        this.vm.LossParticipant.ParticipantClaimDetails = new ParticipantClaimDetailsModel();
        this.vm.LossParticipant.InsuranceInformation = new InsuranceInformationModel();

        this.addressValidationService.initialize(this.continuePastAddressValidation.bind(this), this.updateAddress.bind(this), this.isValidAddress.bind(this));
    }

    public vm: AssignmentModel = new AssignmentModel();
    public phone: string = this.constantsService.EmptyString;
    public email: string = this.constantsService.EmptyString;
    public dateOfBirth: string = this.constantsService.EmptyString;
    public injured: boolean = null;
    public injuryLevel: number;
    public isSameAsPrimary: boolean = false;
    public isOnPolicy: boolean;
    public assignmentType = this.fnolRouting.getRoute().snapshot.queryParams.assignmentType;

    public unknown: ParticipantModel = new ParticipantModel();

    ngOnInit(): void {

        this.spinner.show();
        let participantId = this.fnolRouting.getRoute().snapshot.params.participantId;

        this.api.getLossParticipantModel(participantId).then(response => {
            this.spinner.hide();

            this.vm.LossParticipant = response;

            if (this.vm.LossParticipant.InsuranceInformation == null) {
                this.vm.LossParticipant.InsuranceInformation = new InsuranceInformationModel();
            }

            this.vm.LossParticipant.ParticipantClaimDetails.IsOnPolicy = this.isOnPolicy;
            this.vm.LossParticipant.ParticipantClaimDetails.ParticipantRole = this.constantsService.Insured;
            this.vm.AssignmentType = this.assignmentType;
            this.vm.VehicleId = this.fnolRouting.getRoute().snapshot.params.vehicleId;

            // skip DOB if it is null
            if (this?.vm?.LossParticipant?.DateOfBirth != null) {
                this.dateOfBirth = moment(this.vm.LossParticipant.DateOfBirth).format("MM/DD/YYYY");
            }

            if (this.vm.AssignmentType == this.constantsService.ThirdPartyOwner ||
                this.vm.AssignmentType == this.constantsService.ThirdPartyDriver ||
                this.vm.AssignmentType == this.constantsService.ThirdPartyPassenger) {
                this.vm.LossParticipant.ParticipantClaimDetails.ParticipantRole = this.constantsService.Claimant;
                this.checkUnknownOwner();
            }
            if (this.vm.LossParticipant.ContactInfo != null &&
                this.vm.LossParticipant.ContactInfo.Emails != null &&
                this.vm.LossParticipant.ContactInfo.Emails.length > 0) {
                this.email = this.vm.LossParticipant.ContactInfo.Emails[0].EmailAddress;
            }

            this.injured = this.determineIfInjured(this.vm.LossParticipant.ParticipantClaimDetails.InjuryDescription);

            if (!this.checkPrefilledData()) {
                this.isNotInEditMode = false;
            }
        });
    }
    public returnVM(): AssignmentModel {
        return this.vm;
    }

    public setIsOnPolicy(isOnPolicy: boolean) {
        this.isOnPolicy = isOnPolicy;
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public updateAddress(address: AddressModel): void {
        this.vm.LossParticipant.Address = address;
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.mapPhoneAndEmail();
            // skip DOB if it is null
            if (this.haveDataFor(this.dateOfBirth)) {
                this.vm.LossParticipant.DateOfBirth = this.convertToUTCDate(this.dateOfBirth);
            } else {
                this.vm.LossParticipant.DateOfBirth = null;
            }

            if (this.injured != null && !this.injured) {
                this.vm.LossParticipant.ParticipantClaimDetails.InjuryLevel = this.constantsService.EmptyString;
                this.vm.LossParticipant.ParticipantClaimDetails.InjuryDescription = this.constantsService.EmptyString;
            }

            if (!this.isNotInEditMode) {
                var validationAddress: AddressValidationModel = new AddressValidationModel();
                validationAddress.AddressToValidate = this.vm.LossParticipant.Address;
                if (validationAddress.AddressToValidate.Address1 == null || validationAddress.AddressToValidate.Address1 == "") {
                    this.continuePastAddressValidation();
                    return;
                }
                this.spinner.show();

                this.addressValidationService.checkAddress(validationAddress).then(addressValidationResponse => {
                    this.spinner.hide();

                    let addresses = {
                        originalAddress: this.vm.LossParticipant.Address,
                        suggestedAddress: addressValidationResponse.AddressToValidate
                    };

                    this.addressValidationService.validateAddress(addressValidationResponse, addresses, form);
                });
            }
            else {
                this.continuePastAddressValidation();
            }
        }
    }

    public sameAsPrimary(): void {

        this.vm.LossParticipant.Address.Address1 = this.constantsService.EmptyString;
        this.vm.LossParticipant.Address.City = this.constantsService.EmptyString;
        this.vm.LossParticipant.Address.State = null;
        this.vm.LossParticipant.Address.Zip = this.constantsService.EmptyString;

        // due to model binding happening after the click event, we have to invert our logic here
        if (!this.isSameAsPrimary) {
            this.vm.LossParticipant.Address.Address1 = this.vm.LossParticipant.PrimaryContactAddress.Address1;
            this.vm.LossParticipant.Address.City = this.vm.LossParticipant.PrimaryContactAddress.City;
            this.vm.LossParticipant.Address.State = this.vm.LossParticipant.PrimaryContactAddress.State;
            this.vm.LossParticipant.Address.Zip = this.vm.LossParticipant.PrimaryContactAddress.Zip;
        }

    }

    public handleInjurySlider(event: any) {
        this.injuryLevel = event.value;
        this.vm.LossParticipant.ParticipantClaimDetails.InjuryLevel = `Level ${event.value}`;
    }

    public continuePastAddressValidation(): void {
        this.spinner.show();

        if (this.unknown.IsSelected) {
            this.addUnknown();
        }

        this.api.saveAssignmentModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                if (this.vm.AssignmentType == this.constantsService.Owner.toLowerCase()) {
                    this.fnolRouting.next({ vehicleId: this.vm.VehicleId.toString() }, { replaceUrl: true });

                } else if (this.vm.AssignmentType == this.constantsService.Driver.toLowerCase()) {
                    this.fnolRouting.next({ vehicleId: this.vm.VehicleId.toString() }, { replaceUrl: true });

                } else if (this.vm.AssignmentType == this.constantsService.ThirdPartyOwner) {
                    let newUrl = `fnolAuto/vehicle/${this.vm.VehicleId}/thirdPartyOwner/${response.LossParticipant.LossParticipantId}?assignmentType=thirdPartyOwner`;
                    this.fnolRouting.replaceCurrentState(newUrl);

                    this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vm.VehicleId}/thirdPartyDriver`);

                } else if (this.vm.AssignmentType == this.constantsService.ThirdPartyDriver) {
                    this.fnolRouting.next({ vehicleId: this.vm.VehicleId.toString() }, { replaceUrl: true });

                } else {
                    this.fnolRouting.back();
                }
            }
        });
    }

    // checks to see if we need to expand the Address/Name form based on the prefilled data
    private checkPrefilledData(): boolean {
        if (this.IsNullOrEmptyString(this.vm.LossParticipant.FirstName) ||
            this.IsNullOrEmptyString(this.vm.LossParticipant.LastName) ||
            this.IsNullOrEmptyString(this.vm.LossParticipant.Address.Address1) ||
            this.IsNullOrEmptyString(this.vm.LossParticipant.Address.City) ||
            this.IsNullOrEmptyString(this.vm.LossParticipant.Address.State) ||
            this.IsNullOrEmptyString(this.vm.LossParticipant.Address.Zip)) {
            return false;
        }

        return true;
    }

    // todo: we need a way to handle phones and emails
    private mapPhoneAndEmail() {
        let emailType: string = null;

        if (this.vm.LossParticipant.ContactInfo.Emails != null && this.vm.LossParticipant.ContactInfo.Emails.length > 0) {
            emailType = this.vm.LossParticipant.ContactInfo.Emails[0].EmailType;
        }

        this.vm.LossParticipant.ContactInfo.Emails = [];

        let emailModel = new EmailModel();
        emailModel.EmailAddress = this.email;
        emailModel.EmailType = emailType != null ? emailType : this.constantsService.EmailTypePER;
        this.vm.LossParticipant.ContactInfo.Emails.push(emailModel);
    }

    public canAddPhone(): boolean {
        if (this.vm.LossParticipant.ContactInfo.Phones.length >= 3) {
            return false;
        }
        return true;
    }

    public addPhone(): void {
        let newPhone = new PhoneModel();
        newPhone.PhoneNumber = null;
        newPhone.PhoneType = null;
        newPhone.IsActive = true;
        this.vm.LossParticipant.ContactInfo.Phones.push(newPhone);
    }

    public availablePhoneTypes(phone: PhoneModel): ValueDetailModel[] {
        let activePhones = this.vm.LossParticipant.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.LossParticipant.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public removePhone(index: number): void {
        this.vm.LossParticipant.ContactInfo.Phones.splice(index, 1);
    }

    public track(index: number) {
        return index;
    }

    public canDisplayRemoveButton(): boolean {
        return this.vm.LossParticipant.ContactInfo.Phones.filter(phone => {
            return phone.IsActive
        }).length > 1
    }

    public displayEditMode(): void {
        this.isNotInEditMode = !this.isNotInEditMode;
    }

    public addUnknown(): void {
        this.unknown.IsSelected = !this.unknown.IsSelected;
        this.fnolAutoService.setUnknownClaimantOwner(this.unknown.IsSelected.toString());
    }

    public checkUnknownOwner(): void {
        let unknownOwner: string = this.fnolAutoService.getUnknownClaimantOwner();

        if (unknownOwner == null || unknownOwner == undefined || unknownOwner != this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = false;
        }

        if (unknownOwner == this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = true;
        }
    }
}