<div class="container review-container-wrapper no-mobile">
    <p class="reviewPageHeading">Please review and submit your claim.</p>
    <!--todo-->
    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="hasError" (close)="hasError = false" [type]="danger">
        We are currently unable to process your Claim. Please try again later or call <a href="tel:+1{{ contactNumber }}">{{ contactNumber | mask: '(000) 000-0000' }}</a> to report your Claim.
    </ngb-alert>

    <div class="row">
        <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="review-input-wrapper" [class.selected]="isEditingClaimReporter">
                <header>
                    <span class="reportLabel">Claim Details</span>
                    <button *ngIf="!isEditingClaimReporter" class="editBtn" (click)="editClaimReporter()">Edit</button>
                    <button *ngIf="isEditingClaimReporter" class="editBtn selected" (click)="updateClaimReporter(claimReporter, FirstName, LastName, MiddleName, Address, Email, Phone)">Update</button>
                </header>
                <div class="cardFields-wrapper d-flex flex-wrap selected">
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Type</label>
                        <input type="text" [(ngModel)]="claimType" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Date and Time</label>
                        <input type="text" [(ngModel)]="lossDate" disabled="disabled" />
                        <input type="text" [(ngModel)]="lossTime" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Location</label>
                        <input type="text" [(ngModel)]="vm.LossAddress.Address1" disabled="disabled" />
                        <input type="text" [(ngModel)]="lossCityStateZip" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <span class="claimSubType-label">Claim Sub-type</span>
                        <span class="claimSubType">{{ claimSubType }}</span>
                    </div>
                    <div class="labelInputWrapper col-lg-12 col-xs-12">
                        <span>Claim Reporter</span>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>First Name</label>
                        <input type="text" name="FirstName" OnlyLetters Validate required [(ngModel)]="claimReporter.FirstName" #FirstName="ngModel" [disabled]="!isEditingClaimReporter">
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Last Name</label>
                        <input type="text" name="LastName" OnlyLetters Validate required [(ngModel)]="claimReporter.LastName" #LastName="ngModel" [disabled]="!isEditingClaimReporter" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Middle Name</label>
                        <input type="text" name="MiddleName" OnlyLetters Validate [(ngModel)]="claimReporter.MiddleName" #MiddleName="ngModel" [disabled]="!isEditingClaimReporter" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Address</label>
                        <input type="text" Validate required name="Address" [(ngModel)]="claimReporter.Address.Address1" #Address="ngModel" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>City/State/Zip</label>
                        <input type="text" [value]="getParticipantDisplayAddress(claimReporter)" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Email Address:</label>
                        <input type="email" Validate required name="Email" [(ngModel)]="claimReporter.ContactInfo.Emails[0].EmailAddress" #Email="ngModel" [disabled]="!isEditingClaimReporter" />
                    </div>
                    <ng-container *ngFor="let participantPhone of claimReporter.ContactInfo.Phones; let i = index">
                        <div class="labelInputWrapper col-lg-6 col-xs-12" *ngIf="participantPhone != null && participantPhone.IsActive">
                            <label [ngClass]="childHasInvalid(Phone)">Primary Phone/Ext</label>
                            <input required name="participantPhoneNumber{{claimReporter.LossParticipantId}}" type="tel" mask="(000) 000-0000" [(ngModel)]="participantPhone.PhoneNumber" #Phone="ngModel" [disabled]="!isEditingClaimReporter" />
                        </div>
                        <div class="labelInputWrapper col-lg-3 col-xs-12" *ngIf="!isEditingClaimReporter && participantPhone.IsActive">
                            <label>Phone Type</label>
                            <input type="text" [(ngModel)]="participantPhone.PhoneType" disabled />
                        </div>
                        <div class="labelInputWrapper col-lg-3 col-xs-12" *ngIf="isEditingClaimReporter && participantPhone.IsActive">
                            <label [ngClass]="childHasInvalid(PhoneType)">Phone Type</label>
                            <select required [(ngModel)]="participantPhone.PhoneType" name="participantPhoneType{{claimReporter.LossParticipantId}}" #PhoneType="ngModel">
                                <option [ngValue]="null">Select Phone Type</option>
                                <option *ngFor="let pt of availablePhoneTypes(claimReporter, participantPhone)" [ngValue]="pt.DisplayCode">{{ pt.DisplayValue }}</option>
                            </select>
                        </div>
                        <div style="margin-top:20px;" class="col-lg-3" *ngIf="isEditingClaimReporter && canDisplayRemoveButton(claimReporter) && participantPhone.IsActive">
                            <a class="normalLink" (click)="removePhone(participantPhone)">Remove</a>
                        </div>
                    </ng-container>
                    <div class="col-lg-12 col-xs-12" *ngIf="canAddPhone(claimReporter) && isEditingClaimReporter">
                        <button class="btn" (click)="addPhone(claimReporter)">Add Phone</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="review-input-wrapper" [class.selected]="isEditingLossDescription">
                <header>
                    <span class="reportLabel">Loss Information</span>
                    <button *ngIf="!isEditingLossDescription" class="editBtn" (click)="editLossDescription()">Edit</button>
                    <button *ngIf="isEditingLossDescription" class="editBtn selected" (click)="updateLossDescription()">Update</button>
                </header>
                <div class="cardFields-wrapper d-flex flex-wrap selected">
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Location</label>
                        <input type="text" [(ngModel)]="vm.LossAddress.Address1" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>City/State/Zip</label>
                        <input type="text" [(ngModel)]="lossCityStateZip" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-12 col-xs-12">
                        <label>How did the loss happen?</label>
                        <textarea Validate required type="text" name="LossHappenedBecause" #LossHappenedBecause="ngModel" [(ngModel)]="vm.LossDetails.LossDescription" [disabled]="!isEditingLossDescription"></textarea>
                    </div>
                    <div class="labelInputWrapper col-lg-12 col-xs-12">
                        <label>What specific damage did this cause?</label>
                        <textarea Validate required type="text" name="SpecificDamageCaused" #SpecificDamageCaused="ngModel" [(ngModel)]="vm.PropertyInfoModels[0].DamageDescription" [disabled]="!isEditingLossDescription"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12">
            <div class="review-input-wrapper" [class.selected]="isEditingPropertyOwner">
                <header>
                    <span class="reportLabel">Property Owner</span>
                    <button class="editBtn" *ngIf="!isEditingPropertyOwner" (click)="editPropertyOwner()">Edit</button>
                    <button class="editBtn selected" *ngIf="isEditingPropertyOwner" (click)="updatePropertyOwner(propertyOwner, FirstName, LastName, MiddleName, Address, Email, Phone, InjuryDescription)">Update</button>
                </header>
                <div class="cardFields-wrapper d-flex flex-wrap selected">
                    <div *ngIf="propertyOwner.IsOnPolicy" class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Policy Number</label>
                        <input type="text" disabled="disabled" [value]="vm.Policy.PolicyNumber" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>First Name</label>
                        <input type="text" name="FirstName" OnlyLetters Validate required [(ngModel)]="propertyOwner.FirstName" #FirstName="ngModel" [disabled]="!isEditingPropertyOwner">
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Last Name</label>
                        <input type="text" name="LastName" OnlyLetters Validate required [(ngModel)]="propertyOwner.LastName" #LastName="ngModel" [disabled]="!isEditingPropertyOwner" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Middle Name</label>
                        <input type="text" name="MiddleName" OnlyLetters Validate [(ngModel)]="propertyOwner.MiddleName" #MiddleName="ngModel" [disabled]="!isEditingPropertyOwner" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Address</label>
                        <input type="text" Validate required name="Address" [(ngModel)]="propertyOwner.Address.Address1" #Address="ngModel" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>City/State/Zip</label>
                        <input type="text" [value]="getParticipantDisplayAddress(propertyOwner)" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Email Address:</label>
                        <input type="email" Validate required name="Email" [(ngModel)]="propertyOwner.ContactInfo.Emails[0].EmailAddress" #Email="ngModel" [disabled]="!isEditingPropertyOwner" />
                    </div>
                    <ng-container *ngFor="let participantPhone of propertyOwner.ContactInfo.Phones; let i = index">
                        <div class="labelInputWrapper col-lg-6 col-xs-12" *ngIf="participantPhone != null && participantPhone.IsActive">
                            <label [ngClass]="childHasInvalid(Phone)">Primary Phone/Ext</label>
                            <input required name="participantPhoneNumber{{propertyOwner.LossParticipantId}}" type="tel" mask="(000) 000-0000" [(ngModel)]="participantPhone.PhoneNumber" #Phone="ngModel" [disabled]="!isEditingPropertyOwner" />
                        </div>
                        <div class="labelInputWrapper col-lg-3 col-xs-12" *ngIf="!isEditingPropertyOwner && participantPhone.IsActive">
                            <label>Phone Type</label>
                            <input type="text" [(ngModel)]="participantPhone.PhoneType" disabled />
                        </div>
                        <div class="labelInputWrapper col-lg-3 col-xs-12" *ngIf="isEditingPropertyOwner && participantPhone.IsActive">
                            <label [ngClass]="childHasInvalid(PhoneType)">Phone Type</label>
                            <select required [(ngModel)]="participantPhone.PhoneType" name="participantPhoneType{{propertyOwner.LossParticipantId}}" #PhoneType="ngModel">
                                <option [ngValue]="null">Select Phone Type</option>
                                <option *ngFor="let pt of availablePhoneTypes(propertyOwner, participantPhone)" [ngValue]="pt.DisplayCode">{{ pt.DisplayValue }}</option>
                            </select>
                        </div>
                        <div style="margin-top:20px;" class="col-lg-3" *ngIf="isEditingPropertyOwner && canDisplayRemoveButton(propertyOwner) && participantPhone.IsActive">
                            <a class="normalLink" (click)="removePhone(participantPhone)">Remove</a>
                        </div>
                    </ng-container>
                    <div class="col-lg-12 col-xs-12" *ngIf="canAddPhone(propertyOwner) && isEditingPropertyOwner">
                        <button class="btn" (click)="addPhone(propertyOwner)">Add Phone</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">Submit</button>
    </div>
</div>