




import { BaseModel } from './BaseModel';


export class CallInEmergencyModel extends BaseModel {
    
    public PolicySubType: string = null;
    public ReturnUrl: string = null;
}