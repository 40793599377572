export namespace ClaimTypeEnum {

    export enum ClaimType {
        Home, Auto, Life
    }

    export enum AutoCategory {
        SingleVehicle,
        FixedProperty,
        MultipleVehicleAccident,
        Pedestrian,
        Weather,
        Theft,
        Fire,
        Wildfire,
        Vandalism,
        Glass,
        MechanicalBreakdown
    }

    export enum HomeCategory {
        PersonalProperty,
        EquipmentBreakdown,
        DwellingWeatherNotRelated,
        DwellingWeatherRelated,
        OtherStructureWeatherNotRelated,
        OtherStructureWeatherRelated
    }

    export enum LifeCategory {

    }


    export enum Auto {

    }

    export enum Home {
        // Property
        PropertyTheft = "PPTHEFT",
        PropertyMysteriousDisappearance = "PPMISS",
        PropertyFire = "PPFIRE",

        // Equipment Breakdown
        EquipmentBreakdown = "HMEQBR",

        // Dwelling - Weather Related: No
        Dwelling_NotWeatherRelated_Explosion = "DWEXPL",
        Dwelling_NotWeatherRelated_Riot = "DWRIOT",
        Dwelling_NotWeatherRelated_Fire = "DWFIRE",
        Dwelling_NotWeatherRelated_Mold = "DWMOLD",
        Dwelling_NotWeatherRelated_Sinkhole = "DWSINK",
        Dwelling_NotWeatherRelated_Smoke = "DWSMOKE",
        Dwelling_NotWeatherRelated_Vandalism = "DWVANDAL",
        Dwelling_NotWeatherRelated_CrashIntoHome = "DWCRASH",
        Dwelling_NotWeatherRelated_WaterDamage = "DWWATER",

        // Dwelling - Weather Related: Yes
        Dwelling_WeatherRelated_Earthquake = "DWEQ",
        Dwelling_WeatherRelated_Fire = "DWWLDFR",
        Dwelling_WeatherRelated_LightningNoFire = "DWLGHTFR",
        Dwelling_WeatherRelated_Hail = "DWHAIL",
        Dwelling_WeatherRelated_SnowIce = "DWSNWICE",
        Dwelling_WeatherRelated_WaterDamage = "DWWRWTR",
        Dwelling_WeatherRelated_Windstorm = "DWWIND",

        // Other Structures - Weather Related: No
        OtherStructure_NotWeatherRelated_Explosion = "OSEXPL",
        OtherStructure_NotWeatherRelated_Riot = "OSRIOT",
        OtherStructure_NotWeatherRelated_Fire = "OSFIRE",
        OtherStructure_NotWeatherRelated_Mold = "OSMOLD",
        OtherStructure_NotWeatherRelated_Sinkhole = "OSSINK",
        OtherStructure_NotWeatherRelated_Smoke = "OSSMOKE",
        OtherStructure_NotWeatherRelated_Vandalism = "OSVANDAL",
        OtherStructure_NotWeatherRelated_CrashIntoHome = "OSCRASH",
        OtherStructure_NotWeatherRelated_WaterDamage = "OSWATER",

        // Other Structures - Weather Related: Yes
        OtherStructure_WeatherRelated_Earthquake = "OSEQ",
        OtherStructure_WeatherRelated_Fire = "OSWLDFR",
        OtherStructure_WeatherRelated_LightningNoFire = "OSLGHTFR",
        OtherStructure_WeatherRelated_Hail = "OSHAIL",
        OtherStructure_WeatherRelated_SnowIce = "OSSNWICE",
        OtherStructure_WeatherRelated_WaterDamage = "OSWRWTR",
        OtherStructure_WeatherRelated_Windstorm = "OSWIND"
    }

    export enum Life {

    }
}