import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { GlassOnlyModel } from '@ClaimsModels/index';
import { LossApi } from '../../services/loss.api';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { StyleManagerService, ConstantsService, UnsubmittedClaimService, ContactService, SessionService } from '@Common/services';
import { FNOLAutoService } from '@FNOLAuto/services/';
import { ClaimTypeModel, ClaimTypeCategoryEnum } from '@ClaimsModels/index';

@Component({
    selector: 'glassOnly',
    templateUrl: '../../views/loss/glassOnly.component.html'
})
export class GlassOnlyComponent extends BaseFormComponent {

    public contactNumber: string;
    public vm: GlassOnlyModel = new GlassOnlyModel();

    constructor(private api: LossApi, private router: Router, private constantsService: ConstantsService, private fnolAutoService: FNOLAutoService,
        private unsubmittedClaim: UnsubmittedClaimService, private contactService: ContactService, private spinner: Ng4LoadingSpinnerService,
        private constants: ConstantsService) {
        super();
    }

    public hasError: boolean;
    public displayPage: boolean;
    public sessionService: string;
    public isGlassOnly: boolean | null;

    public ngOnInit(): void {
        if (sessionStorage.getItem('isGlassOnlySelected') != null){ 
            this.isGlassOnly = (sessionStorage.getItem('isGlassOnlySelected') === 'true' ? true : false);
        }
        this.displayPage = true
        this.spinner.hide();
    }

    public goingBackWithLocation(): void {

       this.unsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
    }

    public submit(): void {
        this.spinner.show();
        if (this.isGlassOnly == null) {
            this.hasError = true;
            this.spinner.hide();
            return;
        }

        sessionStorage.setItem('isGlassOnlySelected', this.isGlassOnly.toString());

        if (this.isGlassOnly) {
            /* check for RVFLOW worktype from sessionstorage */
            if (sessionStorage.getItem(this.constantsService.WorkflowTypeKey) === this.constants.FNOLRV) {
                this.fnolAutoService.setClaimTypeCategory(ClaimTypeCategoryEnum.Glass);
                this.fnolAutoService.setClaimTypeCode(this.constantsService.ClaimTypeCodeGlass);

                let model: ClaimTypeModel = new ClaimTypeModel();
                model.ClaimType = this.constantsService.ClaimTypeCodeGlass;

                this.api.saveClaimType(model).then(response => {
                    this.spinner.hide();
                    if (response.Success) {
                        this.router.navigate(['/fnolAuto/isWindshieldOnlyDamages']);
                    }
                });

            }
            else {
                this.api.getGlassOnlyModel().then(response => {
                    this.spinner.hide();
                    this.vm = response;

                    if (this.vm.Success && !this.IsNullOrEmptyString(this.vm.SafeliteUrl)) {
                        this.isGlassOnly = null;
                        this.unsubmittedClaim.gotToSafelite(this.vm.SafeliteUrl);
                        return;
                    }
                });
            }
        }
        else {

            this.router.navigate(["/fnolAuto/towingAndLabor"]);
        }
    }

    public setGlassOnly(value: boolean): void {
        this.isGlassOnly = value;
    }

}