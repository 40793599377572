import { Component } from '@angular/core';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { OpenClaimDetailsModel, BrandEnum } from '@ClaimsModels/index';
import { LossApi } from '@FNOLAuto/services/';
import { StyleManagerService, ConstantsService, SessionService, UnsubmittedClaimService, ContactService } from '@Common/services';


@Component({
    selector: 'hasOpenClaim',
    templateUrl: '../../views/loss/hasOpenClaim.component.html',
})
export class HasOpenClaimComponent extends BaseFormComponent {

    constructor(private router: Router, private api: LossApi, private spinner: Ng4LoadingSpinnerService, private styleManager: StyleManagerService,
        private constantsService: ConstantsService, private session: SessionService, private unsubmittedClaim: UnsubmittedClaimService,
        private contactService: ContactService) {
        super();
    }

    public vm: OpenClaimDetailsModel = new OpenClaimDetailsModel();
    public showAdjuster: boolean = false;
    public lossDate: string;
    public showAdjusterPhone: boolean = false;
    public claimsEmail: string;
    public claimsPhone: string;

    ngOnInit(): void {
        this.spinner.show();
        this.api.getOpenClaimDetailsModel().then(response => {
            this.spinner.hide();
            this.claimsEmail = this.getClaimsEmail();
            this.claimsPhone = this.getClaimsPhone();
            this.vm = response;

            this.lossDate = moment(this.vm.LossDateTime).format('MM/DD/YYYY');

            this.showAdjuster = this.haveDataFor(this.vm.AdjusterFullName);

            this.showAdjusterPhone = this.haveDataFor(this.vm.AdjusterWorkPhone) && this.showAdjuster;

            // add the word 'Ext' into the phone number for the mask..
            if (this.vm.AdjusterWorkPhone.length > 10) {
                this.vm.AdjusterWorkPhone = `${this.vm.AdjusterWorkPhone.slice(0, 10)}Ext${this.vm.AdjusterWorkPhone.slice(10)}`;
            }

            if (this.vm.ReturnUrl === this.constantsService.EmptyString) {
                this.styleManager.returnUrl.subscribe(url => {
                    this.vm.ReturnUrl = url;
                });
            }
        });
    }

    public submit(): void {
        this.router.navigate(['/fnolAuto/glassOnly']);
    }

    public goingBackWithLocation(): void {
        this.unsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
    }

    private getClaimsEmail(): string {
        return this.contactService.getClaimsEmail();
    }

    private getClaimsPhone(): string {
        return this.contactService.getClaimsPhone();
    }
}