import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BaseComponent } from '../../base/base.component';
import { Router } from '@angular/router';

@Component({
    selector: 'unsubmittedClaim',
    templateUrl: '../../views/modals/unsubmittedClaim.component.html'
})
export class UnsubmittedClaimBottomSheet extends BaseComponent {
    constructor(public sheetRef: MatBottomSheetRef, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private router: Router) {
        super();
    }

    public isGlassClaim: boolean;

    public ngOnInit(): void {
        this.isGlassClaim = this.data?.IsGlassClaim;
    }

    public cancel(): void {
        let isLeavingSite: boolean = false;
        this.sheetRef.dismiss({ event: isLeavingSite })
    }

    public leaveSite(): void {
        let isLeavingSite: boolean = true;
        this.sheetRef.dismiss({ event: isLeavingSite });
    }


}