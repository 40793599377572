import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LayoutService, ConstantsService } from '@Common/services/';
import { ParticipantApi } from '@FNOLAuto/services';
import { PrimaryContactModel, AddressModel, ContactInfoModel, PhoneModel, EmailModel } from '@ClaimsModels/index';

@Component({
    selector: 'primaryContactInfo',
    templateUrl: '../../views/participant/primaryContactInfo.component.html'
})
export class PrimaryContactInfoComponent extends BaseFormComponent {

    constructor(private api: ParticipantApi, private router: Router, private location: Location,
        private spinner: Ng4LoadingSpinnerService, private layoutService: LayoutService, private constantsService: ConstantsService) {
        super();
        this.vm = new PrimaryContactModel();
        this.vm.Address = new AddressModel();
        this.vm.ContactInfo = new ContactInfoModel();
    }

    public vm: PrimaryContactModel = new PrimaryContactModel();
    public phone: string = this.constantsService.EmptyString;
    public email: string = this.constantsService.EmptyString;

    ngOnInit(): void {
        this.spinner.show();

        this.api.getPrimaryContactModel().then(response => {
            this.spinner.hide();
            this.vm = response;

            if (this.vm.ContactInfo != null &&
                this.vm.ContactInfo.Phones != null &&
                this.vm.ContactInfo.Phones.length > 0) {
                this.phone = this.vm.ContactInfo.Phones[0].PhoneNumber;
            }

            if (this.vm.ContactInfo != null &&
                this.vm.ContactInfo.Emails != null &&
                this.vm.ContactInfo.Emails.length > 0) {
                this.email = this.vm.ContactInfo.Emails[0].EmailAddress;
            }

            this.layoutService.setUsername(this.vm.PrimaryContactName);
            this.layoutService.setShowUsername(true);
        });
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.mapPhoneAndEmail();
            this.spinner.show();
            this.api.savePrimaryContact(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.router.navigate(['/fnolAuto/lossAddress']);
                }
            });
        }
    }

    // todo: we need a way to handle phones and emails
    private mapPhoneAndEmail() {
        let phoneType: string = null;
        let emailType: string = null;

        if (this.vm.ContactInfo.Phones != null && this.vm.ContactInfo.Phones.length > 0) {
            phoneType = this.vm.ContactInfo.Phones[0].PhoneType;
        }

        if (this.vm.ContactInfo.Emails != null && this.vm.ContactInfo.Emails.length > 0) {
            emailType = this.vm.ContactInfo.Emails[0].EmailType;
        }

        this.vm.ContactInfo.Phones = [];
        this.vm.ContactInfo.Emails = [];

        let phoneModel = new PhoneModel();
        phoneModel.PhoneNumber = this.phone;
        phoneModel.PhoneType = phoneType != null ? phoneType : this.constantsService.PhoneTypeHome;

        this.vm.ContactInfo.Phones.push(phoneModel);

        let emailModel = new EmailModel();
        emailModel.EmailAddress = this.email;
        emailModel.EmailType = emailType != null ? emailType : this.constantsService.EmailTypePER;
        this.vm.ContactInfo.Emails.push(emailModel);
    }

    public goingBackWithLocation(): void {
        
    }
}