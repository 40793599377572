import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BaseLossApi } from '@Common/services/api/baseLoss.api';
import { SessionService } from '@Common/services';
import { ErrorHandlerService } from '@Common/services';
import {
    HomeLossDescriptionModel, HomeReviewModel, ConfirmationInfoModel, CallInEmergencyModel, HomeClaimTypeModel
} from '@ClaimsModels/index';

@Injectable()
export class LossApi extends BaseLossApi {

    constructor(httpClient: HttpClient, error: ErrorHandlerService, session: SessionService, router: Router) {
        super(httpClient, error, session, router);
    }

    public getLossDescriptionModel(): Promise<HomeLossDescriptionModel> {
        return this.httpGet("FNOLHome/Loss/GetLossDescriptionModel");
    }

    public saveLossDescriptionModel(model: HomeLossDescriptionModel): Promise<HomeLossDescriptionModel> {
        return this.httpPost("FNOLHome/Loss/SaveLossDescriptionModel", model);
    }

    public getClaimInfo(): Promise<ConfirmationInfoModel> {
        return this.httpGet("Common/Claim/GetConfirmationInfo");
    }

    // override the submit review model for Common
    public submitReviewModel(model: HomeReviewModel): Promise<HomeReviewModel> {
        return this.httpPost("FNOLHome/Loss/SubmitReviewModel", model);
    }

    public getCallInEmergencyModel(): Promise<CallInEmergencyModel> {
        return this.httpGet("FNOLHome/Loss/GetCallInEmergencyModel");
    }

    public getClaimTypeModel(): Promise<HomeClaimTypeModel> {
        return this.httpGet("FNOLHome/Loss/GetClaimTypeModel");
    }

    public saveClaimType(model: HomeClaimTypeModel): Promise<HomeClaimTypeModel> {
        return this.httpPost("FNOLHome/Loss/SaveClaimTypeModel", model);
    }
}