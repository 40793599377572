import { OnInit, AfterViewInit, Component } from "@angular/core";

@Component({
    template: ''
})
export abstract class BaseComponent implements OnInit, AfterViewInit {
    public abstract ngOnInit(): void;
    public loadedInfo: string = 'default';

    constructor() {

    }

    public IsNullOrEmptyString(value: string): boolean {
        if (value === null || value === undefined || value === '') {
            return true;
        }

        return false;
    }

    public scrollToTopOfPage() {
        window.scroll({
            top: 0, left: 0, behavior: 'smooth'
        })
    }

    public haveDataFor(value: string): boolean {
        if (value !== null && value !== "" && value !== undefined) {
            return true;
        }

        return false;
    }

    // this runs after the components view has been initialized
    public ngAfterViewInit(): void {
        this.scrollToTopOfPage();
    }

    public parseBoolean(value?: string | number | boolean | null) {
    value = value?.toString().toLowerCase();
    return value === 'true' || value === '1';
    }
}