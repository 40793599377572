<div >
    <div class="d-flex justify-content-between" *ngIf="!this.isActiveTab">
        <div class="flexColumn">
            <h6>Documents</h6>
            <span>Recently uploaded documents</span>
        </div>
        <button type="button" class="button" (click)="changeTabToDocuments(TabOptions.Documents)">View All Documents</button>
    </div>
    <div *ngIf="this.isActiveTab">
        <div class="flexColumn">
            <h6>Documents</h6>
            <span>Uploaded Documents</span>
        </div>
    </div>
    <div *ngIf="!this.isActiveTab">
        <div *ngFor="let file of files | slice:0:5; let i = index" class="d-flex justify-content-between" >
            <div class="d-flex">
                <span>img</span>
                <div class="flexColumn">
                    <span>{{file.FileName}}</span>
                    <span>Uploaded {{file.Date | date: 'shortDate' }}</span>
                    <span>{{file.Date | date: 'E hh:mm a'}}</span>
                </div>
            </div>
            <button type="button" (click)="deleteFile(i)">Delete</button>
        </div>
    </div>
    <div *ngIf="this.isActiveTab">
        <div *ngFor="let file of files; let i = index" class="d-flex justify-content-between" >
            <div class="d-flex">
                <span>img</span>
                <div class="flexColumn">
                    <span>{{file.FileName}}</span>
                    <span>Uploaded {{file.Date | date: 'shortDate' }}</span>
                    <span>{{file.Date | date: 'E hh:mm a'}}</span>
                </div>
            </div>
            <button type="button" (click)="deleteFile(i)">Delete</button>
        </div>
    </div>
    <div>
        <div *ngFor="let file of filesToUpload; let i = index;" class="flexColumn">
            <div  class="d-flex justify-content-between">
                <div class="d-flex">
                    <span>img</span>
                    <div class="flexColumn">
                        <span>{{file.name}}</span>
                        <span>{{file.size}}</span>
                    </div>
                </div>
                <button type="button" (click)="deleteUploadingFile(i)">img</button>
            </div>
            <mat-progress-bar value="50" mode="determinate"></mat-progress-bar>
        </div>
    </div>
    <div (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)" class="d-flex flex-column align-items-center container-div">
        <input type="file" #fileDropRef id="fileDropRef" multiple  class="hiddenInput" />
        <span>img</span>
        <span><strong><label for="fileDropRef">Click to upload</label></strong> or drag and drop</span>
        <span>SVG, PNG, JPG, PDF, DOC or GIF</span>
    </div>
    
</div>