<div ngForm #otherPartyInfoForm="ngForm" class="main-inner-wrapper">
    <p class="header">Do you have the other party's information?</p>
    <div class="input-wrapper">
        <div class="row">
            <div>
                <button id="yesBtn" class="vehicle addNew button-reversed" (click)="hasClaimantInfo(true)">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button id="noBtn" class="vehicle addNew button-reversed" (click)="hasClaimantInfo(false)">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
    </div>
</div>