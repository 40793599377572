<div ngForm #isWindshieldOnlyDamagesForm="ngForm" class="main-inner-wrapper">
    <p class="header">Windshield Only Damages?</p>
    <span *ngIf="hasError" class="red">Please select one of the following options to continue.</span>

    <div class="input-wrapper">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="setIsWindshieldOnlyDamages(true);" [ngClass]="{'selected': IsWindshieldOnlyDamages != null && IsWindshieldOnlyDamages}">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="setIsWindshieldOnlyDamages(false);" [ngClass]="{'selected': IsWindshieldOnlyDamages != null && !IsWindshieldOnlyDamages}">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">Continue</button>
    </div>
</div>