import { Directive, HostListener, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[DatePattern]',
})

export class DatePatternDirective{
    public inputValue: string;
    private arrowKeysCode: number[] = [37, 39]
    private specialKeys: string[] = ['Backspace','Tab']
    constructor(private el: ElementRef) {
        
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(ev: KeyboardEvent) {
        if (this.arrowKeysCode.includes(ev.keyCode)) {
            return;
        }
        if (this.specialKeys.includes(ev.key)) {
            return;
        }
        this.inputValue = this.el.nativeElement.value;
        if (this.inputValue.length === 2) {
            this.el.nativeElement.value = this.inputValue + '/';
        } else if (this.inputValue.length === 5) {
            this.el.nativeElement.value = this.inputValue + '/';
        } else if (this.inputValue.length === 10) {
            ev.preventDefault();
        }



    }
}