import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LossApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { BaseLossDescriptionModel, LossDetailsModel } from '@ClaimsModels/index';

@Component({
    selector: 'lossDescription',
    templateUrl: '../../views/loss/lossDescription.component.html',
    providers: [FNOLRoutingService]
})
export class LossDescriptionComponent extends BaseFormComponent {

    constructor(private api: LossApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, private fnolAutoService: FNOLAutoService) {
        super();

        this.vm = new BaseLossDescriptionModel();
        this.vm.LossDetails = new LossDetailsModel();
    }

    public vm: BaseLossDescriptionModel = new BaseLossDescriptionModel();

    ngOnInit(): void {
        this.spinner.show();
        this.api.getLossDescriptionModel().then(response => {
            this.spinner.hide();
            this.vm = response;
        });        
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.api.saveLossDescriptionModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
        }
    }

    public getHeaderTextByClaimType(): string {

        let output: string = "Please tell us how this incident occurred.";
        return output;
    }
}