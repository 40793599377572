<ng-container *ngIf="displayPage">
    <div class="main-inner-wrapper">

        <p class="header">
            Click on the Send Link button to receive a link to the virtual appraisal tool MyClaimsPics.
        </p>

        <p class="consent-text white-wrapper">
            Once you complete the new loss report and get a claim number, you will receive the link and
            instructions on how to complete the appraisal by using a smartphone to take pictures of the vehicle
            damages. The estimate is based on visual damages and if during the course of the repairs
            additional damages are found, they will be addressed by one of our claims professionals.
        </p>

        <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="hasError" (closed)="hasError = false" [type]="danger">
            <strong>Error!</strong> A selection is required.
        </ngb-alert>

        <div class="row justify-content-around margin-top mb-4 side-by-side">
            <div class="button-wrapper">
                <div class="container-wrapper">
                    <label class="container">
                        <input type="radio" name="send-mcp-link" [value]="true" [(ngModel)]="vm.SendLink" required />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="fieldLabel-wrapper">
                    <span [ngClass]="{'invalid-text': hasError }">Send Link</span>
                </div>
            </div>
            <div class="button-wrapper">
                <div class="container-wrapper">
                    <label class="container">
                        <input type="radio" name="send-mcp-link" [value]="false" [(ngModel)]="vm.SendLink" required />
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="fieldLabel-wrapper">
                    <span [ngClass]="{'invalid-text': hasError }">No Thanks</span>
                </div>
            </div>
        </div>

        <div class="button-wrapper">
            <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
            <button id="continueBtn" class="btn btn-primary" (click)="submit()">Continue</button>
        </div>
    </div>
</ng-container>