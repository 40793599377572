<div class="card squared mb-3">
    <div class="card-body">
        <div class="card-title section-header">
            <h5 class="title-wrap">
                Contacts
                <sub>Have any questions or concerns?</sub>
            </h5>
        </div>
        <p class="card-text contact-section"></p>
        <div *ngFor="let contact of contacts;" >
            <div class="d-flex position-relative contact-person-item mb-3">
                <div class="initials">
                    <div *ngIf="contact.AdjusterFirstName" >
                        <span>{{contact.AdjusterFirstName | slice: 0 : 1}}{{contact.AdjusterLastName | slice: 0 : 1}}</span>
                    </div>
                    <div *ngIf="!contact.AdjusterFirstName" >
                        <span>{{this.constants.ClaimsDepartmentInitialString}}</span>
                    </div>
                </div>
                <div class="contact-info-wrapper">
                    <div *ngIf="contact.AdjusterFirstName">
                        <div  class="help-name">{{contact.AdjusterFirstName}} {{contact.AdjusterLastName}}</div>
                    </div>
                    <div *ngIf="!contact.AdjusterFirstName">
                        <div  class="help-name">{{this.constants.ClaimsDepartmentString}}</div>
                    </div>
                    <div class="contact-details">
                        <div *ngIf="contact.AdjusterFirstName">
                            <span>{{contact.AssignedToRole}}</span><br />
                        </div>
                        <span [dashesPhoneFormat]="contact.AdjusterPhone"></span><br />
                        <span><u>{{contact.AdjusterEmail}}</u></span>
                    </div>
                </div>
            </div>        
        </div>
    </div>
</div>