<div class="main-inner-wrapper">
    <div class="input-wrapper">
        <div class="row">
            <div class="col-xs-12">
                <form #MyClaims360BridgeFrom action="/Common/Bridge/BridgeMC360Session" method="post">
                    <input hidden name="ApplicationName" readonly />
                    <input hidden name="Token" readonly />
                    This is the MC360 Dashboard Component

                    <label>SessionID:</label>
                    <input type="text" name="SessionID" />

                    <div class="button-wrapper">
                        <button id="continueBtn" (click)="submitForm(MyClaims360BridgeFrom)" class="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
