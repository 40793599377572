<div class="col-xs-12 col-lg-12">
    <div class="car-detail-wrapper">
        <svg id="vehicleDamageImage">
            <path *ngFor="let component of vehicleComponents"
                  class="image-area clickable"
                  [attr.id]="component.name"
                  [attr.d]="component.dimensions"
                  [class.area-background]="component.isClicked" />
        </svg>
    </div>
</div>

<div *ngFor="let component of getDamagedZones()">
    <span>{{component}}</span><br />
</div>