<div ngForm #smsConsentForm="ngForm" class="main-inner-wrapper">
    <div>
        <p class="header">
            Opt in for SMS Text
        </p>


        <ng-container *ngIf="vm.IsEligibleForMCP">
            <p class="consent-text opt-header-ui">
                It appears your vehicle qualifies for our <em class="emphasis-bold">virtual appraisal process</em>. To participate,
                we will need to send you a link via text. Message and data rates may apply.
            </p>
        </ng-container>

        <ng-container *ngIf="!vm.IsEligibleForMCP">
            <p class="consent-text opt-header-ui">
                We would like your permission to contact you about your claim <em class="emphasis-bold"> via text message </em>at the number provided. Message and data rates may apply; you can opt out anytime. If you agree, you'll receive a link to the MyClaim360 website. This site contains representative contact information as well as real time information about your claim. You may also receive additional text communication as your claim progresses. If you agree please Opt In.
            </p>
        </ng-container>
    </div>
    <div class="phone-input-wrapper mb-5">
        <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="hasError" (closed)="hasError = false" [type]="danger">
            <strong>Error!</strong> A phone number and selection is required.
        </ngb-alert>

        <ng-container *ngIf="vm.SMSConsent">
            <div class="d-flex margin-top">
                <label for="phone" [ngClass]="childHasInvalid(CellPhoneNumber)">Cell Phone Number</label>
                <input type="tel" Validate autofocus required name="phone" mask="(000) 000-0000" [ngClass]="childHasInvalid(CellPhoneNumber)" [(ngModel)]="vm.CellPhoneNumber" #CellPhoneNumber="ngModel" />
            </div>
        </ng-container>

        <div class="row justify-content-around mt-3 option-selector-wrapper-ui">
            <div class="fieldLabel-wrapper">
                <span [ngClass]="{'invalid-text': hasError }">Opt In</span>
            </div>
            <div class="container-wrapper">
                <label class="container">
                    <input type="radio" name="sms-consent" [value]="true" [(ngModel)]="vm.SMSConsent" required />
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="fieldLabel-wrapper">
                <span [ngClass]="{'invalid-text': hasError }">Opt Out</span>
            </div>
            <div class="container-wrapper">
                <label class="container">
                    <input type="radio" name="sms-consent" [value]="false" [(ngModel)]="vm.SMSConsent" required />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
    <div>
        <!--<p class="highlight-ui smsConsentFont" style="">
            By opting in, you will also receive a link to MyClaim360 which provides real time
            information about your claim. 
        </p>-->
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(smsConsentForm)">Continue</button>
    </div>
</div>