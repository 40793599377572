import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseContactFormComponent } from '@Common/base/baseContactForm.component';
import { ParticipantApi, FNOLRoutingService } from '@FNOLAuto/services';
import { AddressValidationService, ConstantsService } from '@Common/services/';
import { AddressModel, ContactInfoModel, PhoneModel, EmailModel, ClaimReporterModel, ReporterInformationModel, AddressValidationModel, ValueDetailModel } from '@ClaimsModels/index';

@Component({
    selector: 'claimReporter',
    templateUrl: '../../views/participant/claimReporter.component.html',
    providers: [FNOLRoutingService]
})
export class ClaimReporterComponent extends BaseContactFormComponent {

    constructor(private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private addressValidationService: AddressValidationService, private fnolRouting: FNOLRoutingService, private constantsService: ConstantsService) {
        super();
        this.vm = new ClaimReporterModel();
        this.vm.ClaimReporter = new ReporterInformationModel();
        this.vm.Persons = [];

        this.addressValidationService.initialize(this.continuePastAddressValidation.bind(this), this.updateAddress.bind(this), this.isValidAddress.bind(this));
    }

    public isOther: boolean = false;
    public isSelectPerson: boolean = true;
    public hasError: boolean = false;
    public selectedPerson: ReporterInformationModel;

    public ngOnInit(): void {
        this.initializeReporter();
        this.spinner.show();
        this.api.getClaimReportModel().then(response => {
            this.spinner.hide();
            this.vm = response;
            let selectedPerson = this.vm.Persons.find(x => x.IsSelected);
            
            if (selectedPerson !== undefined && selectedPerson !== null) {
                this.selectedPerson = selectedPerson;

                if (this.vm.IsAgent) {
                    this.selectedPerson.ContactInfo.Phones = this.fillEmptyPhones(this.selectedPerson);
                }
            }
        });
    }

    public vm: ClaimReporterModel = new ClaimReporterModel();

    public selectPerson(person: ReporterInformationModel): void {
        this.scrollToTopOfPage();
        if (person.ContactInfo.Emails === null || person.ContactInfo.Emails.length === 0) {
            person.ContactInfo.Emails = [];
            person.ContactInfo.Emails.push(new EmailModel());
        }

        person.ContactInfo.Phones = this.fillEmptyPhones(person);

        person.IsSelected = !person.IsSelected;
        this.hasError = false;

        if (person.IsSelected) {
            this.vm.Persons.filter(x => x.IsSelected && x.PolicyPersonId !== person.PolicyPersonId).map(y => y.IsSelected = false); // set the others to false
            this.selectedPerson = person;
            this.isSelectPerson = false;
        } else {
            this.initializeReporter(); // we need to reset the reporter since they are now deselected.
        }
    }

    public availablePhoneTypes(phone: PhoneModel): ValueDetailModel[] {
        let activePhones = this.selectedPerson.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public addNewPerson(): void {
        this.scrollToTopOfPage();
        this.initializeReporter();
        this.isSelectPerson = false;
        this.hasError = false;
        this.isOther = true;
    }

    public addPhone(): void {
        let newPhone = new PhoneModel();
        newPhone.PhoneNumber = null;
        newPhone.PhoneType = null;
        newPhone.IsActive = true;
        this.selectedPerson.ContactInfo.Phones.push(newPhone);
    }

    public removePhone(index: number): void {
        this.selectedPerson.ContactInfo.Phones.splice(index, 1);
    }
    public track(index: number) {
        return index;
    }
    public canDisplayRemoveButton(): boolean {
        return this.selectedPerson.ContactInfo.Phones.filter(phone => {
            return phone.IsActive
        }).length > 1
    }

    public canAddPhone(): boolean {
        let activePhones = (this.selectedPerson.ContactInfo.Phones.filter(x => x.IsActive));
        if (activePhones.length >= 3) {
            return false;
        }
        else {
            return true;
        }
    }

    public saveContactInfo(form: NgForm): void {
        if (this.validate(form)) {

            var validationAddress: AddressValidationModel = new AddressValidationModel();
            validationAddress.AddressToValidate = this.selectedPerson.Address;
            this.spinner.show();

            this.addressValidationService.checkAddress(validationAddress).then(addressValidationResponse => {
                this.spinner.hide();

                let addresses = {
                    originalAddress: this.selectedPerson.Address,
                    suggestedAddress: addressValidationResponse.AddressToValidate
                };

                this.addressValidationService.validateAddress(addressValidationResponse, addresses, form);
            });
        }
    }

    public updateAddress(address: AddressModel): void {
        this.selectedPerson.Address = address;
    }

    public continuePastAddressValidation(form: NgForm): void {
        this.mapPhoneAndEmail();

        this.vm.Persons.filter(x => x.IsSelected).map(y => y.IsSelected = false); // set anything selected to false
        this.selectedPerson.IsSelected = true;

        // if they exist in the list, just update, else add
        let person = this.vm.Persons.find(x => x.FirstName == this.selectedPerson.FirstName
            && x.MiddleName == this.selectedPerson.MiddleName
            && x.LastName == this.selectedPerson.LastName);

        if (person == null) {
            this.vm.Persons.push(this.selectedPerson);
        }

        if (!this.selectedPerson.IsSelected) {
            this.hasError = true;
            return;
        }

        if (this.validate(form)) {
            this.spinner.show();

            this.vm.ClaimReporter = this.selectedPerson;
            this.mapPhoneAndEmail();
            this.api.saveClaimReportModel(this.vm).then(response => {
                this.spinner.hide();

                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
        }
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {

        if (!this.selectedPerson.IsSelected && !this.vm.IsAgent) {
            this.hasError = true;
            return;
        }

        if (this.validate(form)) {
            this.spinner.show();

            this.vm.ClaimReporter = this.selectedPerson;
            this.api.saveClaimReportModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
        }
    }

    private mapPhoneAndEmail() {
        let emailModel = new EmailModel();
        let emailType: string = null;

        if (this.selectedPerson.ContactInfo.Emails != null && this.selectedPerson.ContactInfo.Emails.length > 0) {
            emailModel.EmailType = this.constantsService.EmailTypePER;
            emailModel.EmailAddress = this.selectedPerson.ContactInfo.Emails[0].EmailAddress;
        }

        this.selectedPerson.ContactInfo.Emails = [];

        this.selectedPerson.ContactInfo.Emails.push(emailModel);
    }

    public cancel(): void {
        this.scrollToTopOfPage();
        this.vm.Persons.filter(x => x.IsSelected).forEach(x => x.IsSelected = false); // deselect everybody
        this.isSelectPerson = true;
        this.isOther = false;
    }

    private initializeReporter(): void {
        this.selectedPerson = new ReporterInformationModel();
        this.selectedPerson.Address = new AddressModel();
        this.selectedPerson.ContactInfo = new ContactInfoModel();

        let activePhone = new PhoneModel();
        activePhone.IsActive = true;

        this.selectedPerson.ContactInfo.Phones = [activePhone, new PhoneModel(), new PhoneModel()];
        this.selectedPerson.ContactInfo.Emails = [new EmailModel()];
    }
}