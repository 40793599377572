import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[OnlyNumbers]'
})

export class OnlyNumbersDirective {

    private specialKeys: Array<string> = ['Backspace', 'Tab', 'ArrowLeft','ArrowRight'];
    private regex: RegExp = new RegExp(/^[0-9]*/g); // Allow 0-9 and dash

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {

        // Chrome Form selection sometimes causes this to fire. Exit if this happens
        if (!e.key) { 
            return;
        }

        // Allow special characters
        if (this.specialKeys.indexOf(e.key) !== -1) {
            return;
        }
        
        // match the incoming key against the regex pattern. 
        // If invalid, reject
        if (e.key.match(this.regex).toString() == '') {
            e.preventDefault();
        }
    }
}