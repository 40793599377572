import { Component } from '@angular/core';
import { SessionService, LoggerApi } from '@Common/services'
import { PageTrackingModel } from '@ClaimsModels/index';

@Component({
    selector: 'fatalError',
    templateUrl: '../views/fatalError.component.html'
})
export class FatalErrorComponent {

    constructor(private sessionService: SessionService, private loggerApi: LoggerApi) {
        this.logExternalPageTracking("/fatalError");
    }

    private logExternalPageTracking(externalUrl: string): void {
        let page: PageTrackingModel = new PageTrackingModel();
        page.PageName = externalUrl;
        page.SessionId = this.sessionService.getSession().SessionId;

        this.loggerApi.trackPageNavigation(page);
    }
}