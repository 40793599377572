




import { BaseModel } from './BaseModel';


export class SessionSettingsModel extends BaseModel {
    
    public SessionExpirationMinutes: number = 0;
    public SessionIdleMinutes: number = 0;
    public WarningTimeOutMinutes: number = 0;
    public IsDashboardEnabled: boolean = false;
}