<div ngForm #reporterDetailsForm="ngForm" class="main-inner-wrapper">
    <p class="header" *ngIf="!isAgent" style="width:100vw;padding-left:16px;padding-right:16px;">Please fill out the following form with <em class="emphasis-bold">your</em> information.</p>
    <p class="header" *ngIf="isAgent">As an <em class="emphasis-bold">agent</em> reporting the claim, please add <em class="emphasis-bold">your</em> name and contact information.</p>
    <div class="input-wrapper">
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Name)">Name</label><i [ngClass]="childHasInvalid(Name)" class="fas fa-asterisk" title="*Required Field"></i>
                </div>
                <input type="text" Validate OnlyLetters required name="Name" ngModel="Name" #Name="ngModel" [(ngModel)]="vm.Name" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Email)">Email</label><i [ngClass]="childHasInvalid(Email)" class="fas fa-asterisk" title="*Required field"></i>
                </div>
                <input type="email" email Validate placeholder="example@example.com" name="Email" required [(ngModel)]="vm.Email" #Email="ngModel" />
            </div>
            <div class="col-lg-5 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Phone)">Phone Number</label><i [ngClass]="childHasInvalid(Phone)" class="fas fa-asterisk" title="*Required field"></i>
                </div>
                <input type="tel" mask="(000) 000-0000" required Validate name="Phone" [(ngModel)]="vm.Phone" #Phone="ngModel" />
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="continueBtn" class="btn btn-primary" (click)="submit(reporterDetailsForm)">Continue</button>
    </div>
</div>
