import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import * as moment from 'moment';

import { BaseContactFormComponent } from '@Common/base/baseContactForm.component';
import { ParticipantApi, FNOLRoutingService } from '@FNOLAuto/services';
import { AddressValidationService, ConstantsService } from '@Common/services/';
import {
    LossParticipantModel, AddressModel,
    ContactInfoModel, PhoneModel, EmailModel, ParticipantClaimDetailsModel, AddressValidationModel, InsuranceInformationModel, ValueDetailModel
} from '@ClaimsModels/index';

@Component({
    selector: 'lossParticipant',
    templateUrl: '../../views/participant/lossParticipant.component.html',
    providers: [FNOLRoutingService]
})
export class LossParticipantComponent extends BaseContactFormComponent {

    constructor(private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private route: ActivatedRoute, private addressValidationService: AddressValidationService,
        private fnolRouting: FNOLRoutingService, private constantsService: ConstantsService) {
        super();

        this.vm = new LossParticipantModel();
        this.vm.Address = new AddressModel();
        this.vm.ContactInfo = new ContactInfoModel();
        this.vm.ParticipantClaimDetails = new ParticipantClaimDetailsModel();
        this.vm.InsuranceInformation = new InsuranceInformationModel();

        this.addressValidationService.initialize(this.continuePastAddressValidation.bind(this), this.updateAddress.bind(this), this.isValidAddress.bind(this));
    }

    public vm: LossParticipantModel = new LossParticipantModel();
    public phone: string = this.constantsService.EmptyString;
    public email: string = this.constantsService.EmptyString;
    public dateOfBirth: string = this.constantsService.EmptyString;
    public injured: boolean = null;
    public injuryLevel: number;

    @Input() public isThirdParty: boolean;

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    ngOnInit(): void {
        this.spinner.show();

        let id = this.route.snapshot.params.id;

        if (id === null || id === undefined) {
            id = 0;
        }

        this.api.getLossParticipantModel(id).then(response => {
            this.spinner.hide();
            this.vm = response;

            if (this.vm.DateOfBirth != null) {
                this.dateOfBirth = moment(this.vm.DateOfBirth).format("MM/DD/YYYY");
            }

            if (this.vm.ContactInfo != null &&
                this.vm.ContactInfo.Phones != null) {
                // fill in the empty phones with inactive phones
                while (this.vm.ContactInfo.Phones.length < 3) {
                    let newPhone = new PhoneModel();
                    newPhone.IsActive = false;
                    this.vm.ContactInfo.Phones.push(newPhone);
                }
            }

            if (this.vm.ContactInfo != null &&
                this.vm.ContactInfo.Emails != null &&
                this.vm.ContactInfo.Emails.length > 0) {
                this.email = this.vm.ContactInfo.Emails[0].EmailAddress;
            }

            this.injured = this.determineIfInjured(this.vm.ParticipantClaimDetails.InjuryDescription);
        });
    }

    public handleInjurySlider(event: any) {
        this.injuryLevel = event.value;
        this.vm.ParticipantClaimDetails.InjuryLevel = `Level ${event.value}`;
    }

    public canAddPhone(): boolean {
        if (this.vm.ContactInfo.Phones.length >= 3) {
            return false;
        }
        return true;
    }

    public addPhone(): void {
        let newPhone = new PhoneModel();
        newPhone.PhoneNumber = null;
        newPhone.PhoneType = null;
        newPhone.IsActive = true;
        this.vm.ContactInfo.Phones.push(newPhone);
    }

    public availablePhoneTypes(phone: PhoneModel): ValueDetailModel[] {
        let activePhones = this.vm.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public removePhone(index: number): void {
        this.vm.ContactInfo.Phones.splice(index,1);
    }

    public track(index: number) {
        return index;
    }

    public updateAddress(address: AddressModel): void {
        this.vm.Address = address;
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.vm.ParticipantClaimDetails.IsOnPolicy = false;
            var validationAddress: AddressValidationModel = new AddressValidationModel();
            validationAddress.AddressToValidate = this.vm.Address;
            this.spinner.show();

            this.addressValidationService.checkAddress(validationAddress).then(addressValidationResponse => {
                this.spinner.hide();

                let addresses = {
                    originalAddress: this.vm.Address,
                    suggestedAddress: addressValidationResponse.AddressToValidate
                };

                this.addressValidationService.validateAddress(addressValidationResponse, addresses, form);
            });
        }
    }

    private continuePastAddressValidation(): void {
        this.spinner.show();
        this.mapPhoneAndEmail();

        if (this.dateOfBirth !== null && this.dateOfBirth !== this.constantsService.EmptyString) {
            this.vm.DateOfBirth = this.convertToUTCDate(this.dateOfBirth);
        } else {
            this.vm.DateOfBirth = null;
        }

        if (this.injured != null && !this.injured) {
            this.vm.ParticipantClaimDetails.InjuryLevel = this.constantsService.EmptyString;
            this.vm.ParticipantClaimDetails.InjuryDescription = this.constantsService.EmptyString;
        }

        this.api.saveLossParticipantModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                this.fnolRouting.back();
            }
        });
    }

    // todo: we need a way to handle phones and emails
    private mapPhoneAndEmail() {
        let emailType: string = null;

        if (this.vm.ContactInfo.Emails != null && this.vm.ContactInfo.Emails.length > 0) {
            emailType = this.vm.ContactInfo.Emails[0].EmailType;
        }

        this.vm.ContactInfo.Emails = [];

        if (this.email !== null && this.email !== this.constantsService.EmptyString) {
            let emailModel = new EmailModel();
            emailModel.EmailAddress = this.email;
            emailModel.EmailType = emailType != null ? emailType : this.constantsService.EmailTypePER;
            this.vm.ContactInfo.Emails.push(emailModel);
        }
    }
}