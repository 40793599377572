import { Injectable } from "@angular/core";
import { BaseService } from '@Common/base/base.service';
import { SessionService } from '@Common/services';

@Injectable()
export class HomeService extends BaseService {

    private _incidentOccuredAtInsuredPropertyKey: string = "IncidentOccuredAtInsuredProperty";
    private _wasDamageToTheHomeKey: string = "DamageToTheHome";
    private _propertyIdKey: string = "Property";

    constructor(sessionService: SessionService) {
        super(sessionService);
    }

    // Incident Occurred At Insured Property (Loss Description)
    public getIncidentOccuredAtInsuredProperty(): boolean {

        let value: string = this.sessionService.get(this._incidentOccuredAtInsuredPropertyKey)

        if (value == null) {
            return null;
        }

        return Boolean(JSON.parse(value));
    }

    public setIncidentOccuredAtInsuredProperty(value: boolean): void {
        this.sessionService.set(this._incidentOccuredAtInsuredPropertyKey, value.toString());
    }

    // Damage to the Home
    public getDamageToTheHome(): boolean {
        let value: string = this.sessionService.get(this._wasDamageToTheHomeKey);

        if (value == null) {
            return null;
        }

        return Boolean(JSON.parse(value));
    }

    public setDamageToTheHome(value: boolean): void {
        this.sessionService.set(this._wasDamageToTheHomeKey, value.toString());
    }

    // Property Id
    public getPropertyId(): number {
        return +this.sessionService.get(this._propertyIdKey);
    }

    public setPropertyId(propertyId: number): void {
        this.sessionService.set(this._propertyIdKey, propertyId.toString());
    }
}