import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BaseApi } from '@Common/base/base.api';
import { SessionService } from '@Common/services';
import { ErrorHandlerService } from '@Common/services';
import {
    DateOfLossModel, LossAddressModel, BaseLossDescriptionModel,
    EmergencyResponseModel, ReviewModel, LossDetailsModel, ConfirmationInfoModel, ClaimTypeModel,
    OpenClaimDetailsModel, GlassOnlyModel, PropertyInfoModel, SMSConsentModel, MyClaimsPicModel, VehicleClaimInfoModel
} from '@ClaimsModels/index';

@Injectable()
export class LossApi extends BaseApi {

    constructor(httpClient: HttpClient, error: ErrorHandlerService, session: SessionService, router: Router) {
        super(httpClient, error, session, router);
    }

    public getDateOfLossModel(): Promise<DateOfLossModel> {
        return this.httpGet("FNOLAuto/Loss/GetDateOfLoss");
    }

    public saveDateOfLoss(model: DateOfLossModel): Promise<DateOfLossModel> {
        return this.httpPost("FNOLAuto/Loss/SaveDateOfLoss", model);
    }

    public getLossAddressModel(): Promise<LossAddressModel> {
        return this.httpGet("FNOLAuto/Loss/GetLossAddressModel");
    }

    public saveLossAddressModel(model: LossAddressModel): Promise<LossAddressModel> {
        return this.httpPost("FNOLAuto/Loss/SaveLossAddressModel", model);
    }

    public getLossDescriptionModel(): Promise<BaseLossDescriptionModel> {
        return this.httpGet("FNOLAuto/Loss/GetLossDescriptionModel");
    }

    public saveLossDescriptionModel(model: BaseLossDescriptionModel): Promise<BaseLossDescriptionModel> {
        return this.httpPost("FNOLAuto/Loss/SaveLossDescriptionModel", model);
    }

    public getReviewModel(): Promise<ReviewModel> {
        return this.httpGet("FNOLAuto/Loss/GetReviewModel");
    }

    public saveHasOccupantsInVehicle(model: BaseLossDescriptionModel): Promise<ReviewModel> {
        return this.httpPost("FNOLAuto/loss/SaveHasOccupantsInVehicle", model);
    }

    public getHasOccupantsInVehicle(): Promise<ReviewModel> {
        return this.httpGet("FNOLAuto/loss/GetHasOccupantsInVehicle");
    }

    public saveLossDetailsModel(model: LossDetailsModel): Promise<LossDetailsModel> {
        return this.httpPost("FNOLAuto/Loss/SaveLossDetails", model);
    }

    public submitReviewModel(model: ReviewModel): Promise<ReviewModel> {
        return this.httpPost("FNOLAuto/Loss/SubmitReviewModel", model);
    }

    public getEmergencyResponseDetailsModel(): Promise<EmergencyResponseModel> {
        return this.httpGet("FNOLAuto/Loss/GetEmergencyResponseDetails");
    }

    public saveEmergencyResponseDetailsModel(model: EmergencyResponseModel): Promise<EmergencyResponseModel> {
        return this.httpPost("FNOLAuto/Loss/SaveEmergencyResponseDetails", model);
    }

    public getClaimInfo(): Promise<ConfirmationInfoModel> {
        return this.httpGet("Common/Claim/GetConfirmationInfo");
    }

    public saveClaimType(model: ClaimTypeModel): Promise<ClaimTypeModel> {
        return this.httpPost("FNOLAuto/Loss/SaveClaimTypeModel", model);
    }

    public getClaimTypeModel(): Promise<ClaimTypeModel> {
        return this.httpGet("FNOLAuto/Loss/GetClaimTypeModel");
    }

    public getOpenClaimDetailsModel(): Promise<OpenClaimDetailsModel> {
        return this.httpGet("/FNOLAuto/Loss/GetOpenClaimDetails");
    }

    public getGlassOnlyModel(): Promise<GlassOnlyModel> {
        return this.httpGet("/FNOLAuto/Loss/GetGlassOnlyModel");
    }

    public savePropertyInfo(model: PropertyInfoModel): Promise<PropertyInfoModel> {
        return this.httpPost("FNOLAuto/Loss/SavePropertyInfoMode", model);
    }

    public getPropertyInfo(): Promise<PropertyInfoModel> {
        return this.httpGet("FNOLAuto/Loss/GetPropertyInfo/");
    }

    public getSMSConsentModel(): Promise<SMSConsentModel>{
        return this.httpGet("FNOLAuto/Loss/GetSMSConsentModel/");
    }

    public saveSMSConsentModel(model: SMSConsentModel): Promise<SMSConsentModel> {
        return this.httpPost("FNOLAuto/Loss/SaveSMSConsentModel", model);
    }

    public getMyClaimsPicModel(): Promise<MyClaimsPicModel> {
        return this.httpGet("FNOLAuto/Loss/GetMyClaimsPicModel/");
    }

    public saveMyClaimsPicModel(model: MyClaimsPicModel): Promise<MyClaimsPicModel> {
        return this.httpPost("FNOLAuto/Loss/SaveMyClaimsPicModel", model);
    }

    public getVehicleClaimInfoModel(): Promise<VehicleClaimInfoModel> {
        return this.httpGet("/FNOLAuto/Loss/GetVehicleClaimInfoModel");
    }

    public saveVehicleClaimInfoModel(model: VehicleClaimInfoModel): Promise<VehicleClaimInfoModel> {
        return this.httpPost("FNOLAuto/Loss/SaveVehicleClaimInfoModel", model);
    }
}