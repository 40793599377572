import { Breadcrumb } from "./Breadcrumb";

export class RouteCrumb {
    public Route: string;
    public Crumb: Breadcrumb;
    public NextPage: RouteCrumb;

    constructor(route: string, breadcrumb: Breadcrumb, nextPage: RouteCrumb) {
        this.Route = route;
        this.Crumb = breadcrumb;
        this.NextPage = nextPage;
    }
}