<div ngForm #hasPassengersForm="ngForm" class="main-inner-wrapper">
    <p class="header">Were there any <em class="emphasis-bold">passengers</em> in the <span class="bold" *ngIf="isThirdParty">other </span>vehicle at the time of the incident?</p>
    <div class="input-wrapper">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="passengers(true)">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="passengers(false)">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
    </div>
</div>