import { Component } from '@angular/core';

import { LoggerApi } from '../services/utility/logger.api';

import { BaseComponent } from '@Common/base/base.component';

@Component({
    selector: 'dashboard',
    templateUrl: '../views/dashboard.component.html'
})
export class DashboardComponent extends BaseComponent {

    constructor(private logger: LoggerApi) {
        super();
    }

    ngOnInit(): void {
        this.logger.getServerStatus()
            .then(response => {
                this.message = response;
            });
    }

    public message: string = "Message from the dashboard";
}