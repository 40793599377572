import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: 'input[type=text]'
})

export class CapitalizeFirstLetterDirective {
    constructor(private el: ElementRef) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(value: string) {

        // ignore username input for loginPage
        if (this.el.nativeElement.name === 'username') {
            return;
        }

        // only if we have a value
        if (!value) {
            return;
        }

        let firstCharacter = value[0]; // get the first letter

        // dont capitalize a space or number
        if (firstCharacter == ' ' || Number(firstCharacter) || !isNaN(+firstCharacter)) {
            return;
        }

        // rebuild the input
        this.el.nativeElement.value = value[0].toLocaleUpperCase() + value.substr(1);
    }
}