import { Component } from '@angular/core';

import { FNOLRoutingService } from '../../services';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';

@Component({
    selector: 'thirdPartyVehicle',
    templateUrl: '../../views/vehicle/thirdPartyVehicle.component.html',
    providers: [FNOLRoutingService]
})
export class ThirdPartyVehicleComponent {

    constructor(private fnolRouting: FNOLRoutingService) {
    }

    public isThirdParty: boolean = true;

}
