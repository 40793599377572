import { Component, QueryList, ViewChildren } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { BaseParticipantApi, ConstantsService, ClaimTypeService } from '@Common/services';
import { HomeRoutingService, HomeService, LossApi } from '@FNOLHome/services';
import { StyleManagerService } from '@Common/services';
import { ReviewSubmissionBottomSheet } from '@Common/components/modals/reviewSubmission.component';

import {
    HomeReviewModel, LossDetailsModel, AddressModel, BasePhoneModel, BaseLossParticipantModel,
    ValueDetailModel, BaseEmailModel, BasePropertyInfoModel, BaseContactInfoModel, BaseParticipantClaimDetailsModel, ClaimTypeCategoryEnum, ClaimTypeEnum, ClaimReporterModel, BaseClaimReporterModel, ParticipantModel, BaseReporterInformationModel
} from '@ClaimsModels/index';

@Component({
    selector: 'review',
    templateUrl: '../../views/loss/review.component.html',
    providers: [HomeRoutingService]
})
export class ReviewComponent extends BaseFormComponent {

    constructor(private lossApi: LossApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: HomeRoutingService, private fnolService: HomeService,
        private participantApi: BaseParticipantApi, private styleManager: StyleManagerService, private bottomSheet: MatBottomSheet, private constant: ConstantsService,
        private claimTypeService: ClaimTypeService) {
        super();

        // Set the objects up for the initial databinding
        this.vm = new HomeReviewModel();
        this.vm.LossDetails = new LossDetailsModel();
        this.vm.LossParticipants = [];
        this.vm.LossAddress = new AddressModel();
        this.vm.PropertyInfoModels = [];

        this.propertyOwner.Address = new AddressModel();
        this.propertyOwner.ContactInfo = new BaseContactInfoModel();
        this.propertyOwner.ParticipantClaimDetails = new BaseParticipantClaimDetailsModel();
        this.propertyOwner.ContactInfo.Phones = [];
        this.propertyOwner.ContactInfo.Emails = [];

        this.claimReporter.Address = new AddressModel();
        this.claimReporter.ContactInfo = new BaseContactInfoModel();
        this.claimReporter.ContactInfo.Phones = [];
        this.claimReporter.ContactInfo.Emails = [];

        // Add in blank objects for the databinding
        this.propertyOwner.ContactInfo.Phones.push(new BasePhoneModel());
        this.propertyOwner.ContactInfo.Emails.push(new BaseEmailModel());

        this.claimReporter.ContactInfo.Phones.push(new BasePhoneModel());
        this.claimReporter.ContactInfo.Emails.push(new BaseEmailModel());

        this.vm.PropertyInfoModels.push(new BasePropertyInfoModel());
    }

    public vm: HomeReviewModel = new HomeReviewModel();
    public isEditingLossDescription: boolean;
    public isEditingPropertyOwner: boolean;
    public isEditingClaimReporter: boolean = false;
    public lossDate: string = this.constant.EmptyString;
    public lossTime: string = this.constant.EmptyString;
    public lossCityStateZip = this.constant.EmptyString;
    public hasError: boolean = false;
    public contactNumber: string;
    public claimType: string = this.constant.EmptyString;
    public claimSubType: string = this.constant.EmptyString;
    public propertyOwner: BaseLossParticipantModel = new BaseLossParticipantModel();
    public claimReporter: BaseLossParticipantModel = new BaseLossParticipantModel();
    @ViewChildren("Phone") private phoneNumbers: QueryList<NgModel>;
    @ViewChildren("PhoneType") private phoneTypes: QueryList<NgModel>;

    ngOnInit(): void {
        this.spinner.show();
        this.lossApi.getReviewModel().then(response => {
            this.spinner.hide();
            this.vm = response;

            this.lossDate = moment(new Date(this.vm.LossDetails.LossDate)).format("MM/DD/YYYY");
            this.lossTime = moment(new Date(this.vm.LossDetails.LossDate)).format("h:mm a");

            this.lossCityStateZip = this.vm.LossAddress.State;
            if (this.haveDataFor(this.vm.LossAddress.City)) {
                this.lossCityStateZip = `${this.vm.LossAddress.City}, ${this.lossCityStateZip}`;
            }

            if (this.haveDataFor(this.vm.LossAddress.Zip)) {
                this.lossCityStateZip += ` ${this.vm.LossAddress.Zip}`;
            }

            this.styleManager.contactPhoneNumber.subscribe(contactPhoneNumber => {
                this.contactNumber = contactPhoneNumber;
            })

            this.getClaimType();

            // Set the Property Owner
            let property: BasePropertyInfoModel = this.vm.PropertyInfoModels.find(x => true);
            this.propertyOwner = this.vm.LossParticipants.find(person => property.OwnerContactId == person.LossParticipantId)
            this.claimReporter = this.vm.LossParticipants.find(person => person.LossParticipantId == this.vm.ClaimReporterId);

            this.vm.LossParticipants.forEach(x => {

                // Setup Contact Information
                if (x.ContactInfo.Emails === null || x.ContactInfo.Emails.length === 0) {
                    x.ContactInfo.Emails.push(new BaseEmailModel());
                }

                while (x.ContactInfo.Phones === null || x.ContactInfo.Phones.length < 3) {
                    let newPhone = new BasePhoneModel();
                    newPhone.IsActive = false;
                    x.ContactInfo.Phones.push(newPhone);
                }
            });
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public editLossDescription(): void {
        this.isEditingLossDescription = true;
    }

    public updateLossDescription(): void {
        this.spinner.show();

        let saveLossDetails = this.lossApi.saveLossDetailsModel(this.vm.LossDetails);
        let savePropertyInfo = this.lossApi.savePropertyInfo(this.vm.PropertyInfoModels[0]);

        forkJoin([saveLossDetails, savePropertyInfo]).subscribe(result => {
            this.spinner.hide();
            this.isEditingLossDescription = false;
        })
    }

    public editPropertyOwner(): void {
        this.isEditingPropertyOwner = true;
    }

    public updatePropertyOwner(participant: BaseLossParticipantModel, ...ngModels): void {
        let phoneNumberModels: NgModel[] = this.phoneNumbers.filter(x => x.name == this.constant.ParticipantPhoneNumber + participant.LossParticipantId);
        let phoneTypeModels: NgModel[] = this.phoneTypes.filter(x => x.name == this.constant.ParticipantPhoneType + participant.LossParticipantId)

        if (phoneNumberModels != null) {
            phoneNumberModels.forEach(phoneNumber => ngModels.push(phoneNumber));
        }

        if (phoneTypeModels != null) {
            phoneTypeModels.forEach(phoneType => ngModels.push(phoneType));
        }

        if (this.hasInvalidModels(ngModels)) {
            return;
        }

        if (participant.FirstName.trim() != '' && participant.LastName.trim() != '') {
            this.spinner.show();
            this.participantApi.saveLossParticipantModel(participant).then(response => {
                this.spinner.hide();
                this.isEditingPropertyOwner = false;
            });
        }
    }

    public getParticipantDisplayAddress(participant: BaseLossParticipantModel): string {

        if (participant !== null && participant !== undefined && participant.Address !== null
            && !this.IsNullOrEmptyString(participant.Address.City) && !this.IsNullOrEmptyString(participant.Address.State)
            && !this.IsNullOrEmptyString(participant.Address.Zip)) {
            return `${participant.Address.City}, ${participant.Address.State} ${participant.Address.Zip}`;
        }

        return "";
    }

    public availablePhoneTypes(participant: BaseLossParticipantModel, phone: BasePhoneModel): ValueDetailModel[] {
        let activePhones = participant.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public removePhone(phone: BasePhoneModel): void {
        phone.IsActive = false;
    }

    public canDisplayRemoveButton(participant: BaseLossParticipantModel): boolean {
        return participant.ContactInfo.Phones.filter(phone => {
            return phone.IsActive
        }).length > 1
    }

    public canAddPhone(participant: BaseLossParticipantModel): boolean {
        if (participant.ContactInfo === null || participant.ContactInfo.Phones === null) {
            return false;
        }

        let activePhones = participant.ContactInfo.Phones.filter(x => x.IsActive);

        return activePhones.length < 3;
    }

    public addPhone(participant: BaseLossParticipantModel): void {
        for (let i = 0; i < participant.ContactInfo.Phones.length; i++) {
            if (!participant.ContactInfo.Phones[i].IsActive) {
                participant.ContactInfo.Phones[i].IsActive = true;
                break;
            }
        }
    }

    public submit(): void {
        var currentSheet: MatBottomSheetRef = this.bottomSheet.open(ReviewSubmissionBottomSheet);
        currentSheet.afterDismissed().subscribe(result => {
            if (result?.event == this.constant.Submit) {
                this.spinner.show();
                this.lossApi.submitReviewModel(this.vm).then((response) => {
                    this.spinner.hide();
                    if (response.Success && response.IsSuccessfulSubmit) {
                        this.fnolRouting.next();
                    } else {
                        this.hasError = true;
                    }
                });
            }
        });
    }

    private hasInvalidModels(ngModels: NgModel[]): boolean {
        return ngModels.filter((model: NgModel) => {
            if (!model) { // if its undefined or null, exit.
                return false;
            }

            if (model.invalid) {
                model.control.markAsDirty();
                model.control.markAsTouched();
                return true;
            }

            return false;
        }).length > 0; // return the count of any invalid models
    }

    public getClaimType(): void {
        let homeClaimTypes = this.claimTypeService.getClaimTypes(ClaimTypeEnum.ClaimType.Home);

        let claimType = Object.values(homeClaimTypes).filter(x => x.ClaimTypeCode === this.vm.ClaimType.ClaimType);

        claimType.forEach(x => {

            this.claimSubType = x.Name;

            switch (x.ClaimTypeCategory) {
                case ClaimTypeCategoryEnum.PersonalProperty:
                    this.claimType = this.constant.PersonalProperty;
                    break;
                case ClaimTypeCategoryEnum.EquipmentBreakdown:
                    this.claimType = this.constant.Equipment;
                    break;
                case ClaimTypeCategoryEnum.OtherStructureWeatherNotRelated:
                case ClaimTypeCategoryEnum.OtherStructureWeatherRelated:
                    this.claimType = this.constant.OtherStructure;
                    break;
                case ClaimTypeCategoryEnum.DwellingWeatherNotRelated:
                case ClaimTypeCategoryEnum.DwellingWeatherRelated:
                    this.claimType = this.constant.Dwelling;
                default:
                    break;
            }
        })
    }

    public editClaimReporter(): void {
        this.isEditingClaimReporter = true;
    }

    public updateClaimReporter(reporter: BaseReporterInformationModel, ...ngModels): void {

        let phoneNumberModels: NgModel[] = this.phoneNumbers.filter(x => x.name == this.constant.ParticipantPhoneNumber + this.claimReporter.LossParticipantId);
        let phoneTypeModels: NgModel[] = this.phoneTypes.filter(x => x.name == this.constant.ParticipantPhoneType + this.claimReporter.LossParticipantId)

        if (phoneNumberModels != null) {
            phoneNumberModels.forEach(phoneNumber => ngModels.push(phoneNumber));
        }

        if (phoneTypeModels != null) {
            phoneTypeModels.forEach(phoneType => ngModels.push(phoneType));
        }

        if (this.hasInvalidModels(ngModels)) {
            return;
        }

        let updatedClaimReporter = new BaseClaimReporterModel();

        updatedClaimReporter.ClaimReporter = reporter;
        updatedClaimReporter.ClaimReporter.PolicyPersonId = this.claimReporter.LossParticipantId;

        if (updatedClaimReporter.ClaimReporter.FirstName.trim() != this.constant.EmptyString ||
            updatedClaimReporter.ClaimReporter.LastName.trim() != this.constant.EmptyString) {

            this.spinner.show();

            this.participantApi.saveClaimReportModel(updatedClaimReporter).then(() => {
                this.spinner.hide();
                this.isEditingClaimReporter = false;
            });
        }
    }
}