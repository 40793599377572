import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { VehicleApi, FNOLAutoService, FNOLRoutingService } from '@FNOLAuto/services';
import { SelectVehiclesModel, VehicleModel, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services';

@Component({
    selector: 'insuredVehicleSelect',
    templateUrl: '../../views/vehicle/selectInsuredVehicle.component.html',
    providers: [FNOLRoutingService]
})
export class SelectInsuredVehicle extends BaseFormComponent {

    public vm: SelectVehiclesModel = new SelectVehiclesModel();
    public isOtherVehicle: boolean = false;
    public hasMultiplePolicyVehiclesSelected: boolean = false;

    public hasError: boolean = false;
    public errorMessage: string;

    constructor(private api: VehicleApi, private spinner: Ng4LoadingSpinnerService, private fnolAuto: FNOLAutoService, private fnolRouting: FNOLRoutingService,
                private constantsService: ConstantsService) {
        super();
    }

    public ngOnInit(): void {
        this.spinner.show();
        this.api.getSelectVehiclesModel().then(response => {
            this.spinner.hide();
            this.vm = response;
            this.checkForMultipleVehicles(true);

            if (this.fnolAuto.getClaimTypeCategory() == ClaimTypeCategoryEnum.MultipleVehicleAccident) {
                this.vm.Vehicles = this.vm.Vehicles.filter(x => x.IsOnPolicy || x.IsUnlisted);
            }
        });
    }

    public goingBackWithLocation(): void {
        this.unselectCurrentVehicle();
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {
        let vehicleNotSelected = this.validateInsuredVehicleSelected();

        if (vehicleNotSelected) {
            return;
        }

        this.spinner.show();
        this.api.saveSelectVehiclesModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                let selectedVehicle: VehicleModel = this.vm.Vehicles.find(x => x.IsSelected)
                this.fnolRouting.routeToSpecificPage(`/fnolAuto/vehicle/${selectedVehicle.VehicleId}/selectOwner`);
            }
        });
    }

    public selectVehicle(vehicle: VehicleModel): void {
        this.unselectCurrentVehicle();

        vehicle.IsSelected = !vehicle.IsSelected;

        if (vehicle.IsSelected) {
            if (vehicle.IsOnPolicy) {
                switch (this.fnolAuto.getClaimTypeCategory()) {
                    case ClaimTypeCategoryEnum.Theft:
                        this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicleTheftDetails/${vehicle.VehicleId}/${true}`);
                        break;
                    default:
                        this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicleDetail/${vehicle.VehicleId}`);
                }
            } else {
                switch (this.fnolAuto.getClaimTypeCategory()) {
                    case ClaimTypeCategoryEnum.Theft:
                        this.fnolRouting.routeToSpecificPage(`fnolAuto/nonPolicyVehicleTheft/${vehicle.VehicleId}/${false}`);
                        break;
                    default:
                        this.fnolRouting.routeToSpecificPage(`fnolAuto/nonPolicyVehicle/${vehicle.VehicleId}`);
                }                
            }
        }
    }

    private unselectCurrentVehicle(): void {
        let currentSelection = this.vm.Vehicles.find(x => x.IsSelected);

        if (currentSelection !== null && currentSelection !== undefined) {
            currentSelection.IsSelected = false;

            this.spinner.show();
            this.api.saveSelectVehiclesModel(this.vm).then(response => {
                this.spinner.hide();
            });
        }
    }

    private isVehicleInfoKnown(vehicle: VehicleModel): boolean {
        return vehicle.VehicleInfo != undefined && vehicle.VehicleInfo.trim() != this.constantsService.EmptyString;
    }

    public addNew(): void {
        this.unselectCurrentVehicle();

        switch (this.fnolAuto.getClaimTypeCategory()) {
            case ClaimTypeCategoryEnum.Theft:
                this.fnolRouting.routeToSpecificPage('fnolAuto/nonPolicyVehicleTheft/');
                break;
            default:
                this.fnolRouting.routeToSpecificPage('fnolAuto/nonPolicyVehicle/');
                break;
        }
    }

    public checkForMultipleVehicles(updateSelected: boolean): void {
        let policySelections: VehicleModel[] = this.vm.Vehicles.filter(x => x.IsOnPolicy && x.IsSelected);

        if (policySelections.length >= 2) {
            this.hasMultiplePolicyVehiclesSelected = true;
            // deselect their other policy selections
            if (updateSelected) {
                policySelections // deselect all policy vehicles for now until we can establish which was the "last" one selected
                    .forEach((value) => {
                        value.IsSelected = false;
                    });
                this.spinner.show();
                this.api.saveSelectVehiclesModel(this.vm).then(response => {
                    this.spinner.hide();
                });
            }
        }
    }

    private validateInsuredVehicleSelected(): boolean {
        let numberOfSelectedVehicles: number = this.vm.Vehicles.filter(x => x.IsSelected).length;

        let isNotSelected: boolean = false;

        if (numberOfSelectedVehicles != 1) {
            this.setErrorMessage();
            this.hasError = true;
            isNotSelected = true;
        }

        return isNotSelected;
    }

    private setErrorMessage(): void {
        let claimTypeCategory: ClaimTypeCategoryEnum = this.fnolAuto.getClaimTypeCategory();

        switch (claimTypeCategory) {
            case ClaimTypeCategoryEnum.SingleVehicle:
            case ClaimTypeCategoryEnum.FixedProperty:
            case ClaimTypeCategoryEnum.MechanicalBreakdown:
                this.errorMessage = 'For a single vehicle accident. You must select a vehicle.';
                break;
            case ClaimTypeCategoryEnum.Weather:
                this.errorMessage = 'For a weather incident. You must select a vehicle.';
                break;
            case ClaimTypeCategoryEnum.MultipleVehicleAccident:
                this.errorMessage = 'For a multiple vehicle accident. You must select a vehicle.';
                break;
            case ClaimTypeCategoryEnum.Theft:
                this.errorMessage = 'For a theft incident. You must select a vehicle.';
                break;
            case ClaimTypeCategoryEnum.Pedestrian:
                this.errorMessage = ' You must select a vehicle.';
                break;
            default:
                this.errorMessage = ' You must select a vehicle.';
                break;
        }
    }
}