import { Component } from '@angular/core';
import { HomeRoutingService, LossApi } from '@FNOLHome/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { CallInEmergencyModel, BrandEnum } from '@ClaimsModels/index';
import { StyleManagerService, ConstantsService, UnsubmittedClaimService, ContactService } from '@Common/services';

@Component({
    selector: 'callInEmergency',
    templateUrl: '../../views/loss/callInEmergency.component.html',
    providers: [HomeRoutingService],
})
export class CallInEmergency extends BaseFormComponent {
    constructor(
        private spinner: Ng4LoadingSpinnerService,
        private api: LossApi,
        private styleManager: StyleManagerService,
        private fnolRouting: HomeRoutingService,
        private constants: ConstantsService,
        private contact: ContactService,
        private UnsubmittedClaim: UnsubmittedClaimService    ) {
        super();
    }

    public vm: CallInEmergencyModel = new CallInEmergencyModel();
    public contactNumber: string = this.constants.EmptyString;

    public ngOnInit(): void {
        this.spinner.show();
        this.api.getCallInEmergencyModel().then(response => {
            this.spinner.hide();
            this.vm = response;

            this.contactNumber = this.contact.getContactNumber(this.vm.PolicySubType);

            if (this.vm.ReturnUrl === this.constants.EmptyString) {
                this.styleManager.returnUrl.subscribe(url => {
                    this.vm.ReturnUrl = url;
                });
            }
        });
    }

    public submit(): void {
        this.fnolRouting.routeToSpecificPage('fnolHome/dwelling');
    };

    public goingBackWithLocation(): void {
        this.UnsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
    }
};