import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BaseApi } from '@Common/base/base.api';
import { SessionService } from '@Common/services';
import { ErrorHandlerService } from '@Common/services';
import {
    PrimaryContactModel, SelectParticipantsModel, LossParticipantModel, VehicleOwnerModel,
    VehicleDriverModel, VehiclePassengersModel, ClaimReporterModel, AssignmentModel, AdditionalPropertyNoteModel, FixedPropertyOwnerModel, PedestrianModel
} from '@ClaimsModels/index';

@Injectable()
export class ParticipantApi extends BaseApi {

    constructor(httpClient: HttpClient, error: ErrorHandlerService, session: SessionService, router: Router) {
        super(httpClient, error, session, router);
    }

    public getPrimaryContactModel(): Promise<PrimaryContactModel> {
        return this.httpGet("FNOLAuto/Participant/GetPrimaryContactInfoModel");
    }

    public savePrimaryContact(model: PrimaryContactModel): Promise<PrimaryContactModel> {
        return this.httpPost("FNOLAuto/Participant/SavePrimaryContactInfo", model);
    }

    public getLossParticipantModel(id: number): Promise<LossParticipantModel> {
        return this.httpGet("FNOLAuto/Participant/GetLossParticipantModel/" + id);
    }

    public saveLossParticipantModel(model: LossParticipantModel): Promise<LossParticipantModel> {
        return this.httpPost("FNOLAuto/Participant/SaveLossParticipantModel", model);
    }

    public saveAssignmentModel(model: AssignmentModel): Promise<AssignmentModel> {
        return this.httpPost("FNOLAuto/Participant/SaveAssignmentModel", model);
    }

    public getClaimReportModel(): Promise<ClaimReporterModel> {
        return this.httpGet("FNOLAuto/Participant/GetClaimReporterModel");
    }

    public saveClaimReportModel(model: ClaimReporterModel): Promise<ClaimReporterModel> {
        return this.httpPost("FNOLAuto/Participant/SaveClaimReporterModel", model);
    }

    public getSelectParticipantsModel(): Promise<SelectParticipantsModel> {
        return this.httpGet("FNOLAuto/Participant/GetSelectParticipantsModel");
    }

    public saveSelectParticipantsModel(model: SelectParticipantsModel): Promise<SelectParticipantsModel> {
        return this.httpPost("FNOLAuto/Participant/SaveSelectParticipantsModel", model);
    }

    public getVehicleOwnerModel(id: number): Promise<VehicleOwnerModel> {
        return this.httpGet("FNOLAuto/Participant/GetVehicleOwnerModel/" + id);
    }

    public saveVehicleOwnerModel(model: VehicleOwnerModel): Promise<VehicleOwnerModel> {
        return this.httpPost("FNOLAuto/Participant/SaveVehicleOwnerModel", model);
    }

    public getVehicleDriverModel(id: number): Promise<VehicleDriverModel> {
        return this.httpGet("FNOLAuto/Participant/GetVehicleDriverModel/" + id);
    }

    public saveVehicleDriverModel(model: VehicleDriverModel): Promise<VehicleDriverModel> {
        return this.httpPost("FNOLAuto/Participant/SaveVehicleDriverModel", model);
    }

    public getVehiclePassengersModel(id: number): Promise<VehiclePassengersModel> {
        return this.httpGet("FNOLAuto/Participant/GetVehiclePassengersModel/" + id);
    }

    public saveVehiclePassengersModel(model: VehiclePassengersModel): Promise<VehiclePassengersModel> {
        return this.httpPost("FNOLAuto/Participant/SaveVehiclePassengersModel", model);
    }

    public saveHasAdditionalPropertyModel(model: AdditionalPropertyNoteModel): Promise<AdditionalPropertyNoteModel> {
        return this.httpPost("FNOLAuto/Participant/SaveAdditionalPropertyNoteModel", model);
    }

    public getFixedPropertyOwnerModel(id: number): Promise<FixedPropertyOwnerModel> {
        return this.httpGet("FNOLAuto/Participant/GetFixedPropertyOwnerModel/" + id);
    }

    public getPedestrianModel(): Promise<PedestrianModel> {
        return this.httpGet("FNOLAuto/Participant/GetPedestrianModel/");
    }

    public savePedestrianModel(model: PedestrianModel): Promise<PedestrianModel> {
        return this.httpPost("FNOLAuto/Participant/SavePedestrianModel", model);
    }

}