<div class="main-inner-wrapper">
    <p class="header">{{ getPageHeader() }}</p>
    <ng-container *ngIf="!isDeviceMobile">  
        <p class="h5" style="padding-bottom:10px;"><b>Call</b>: 1{{ getClaimPhoneNumber() | mask: "(000) 000-0000" }}</p>
    </ng-container>
        <ng-container *ngIf="isDeviceMobile">
            <p class="h5" style="padding-bottom:10px;"><b>Call</b>: <a href="tel:+1{{ getClaimPhoneNumber() }}">1{{ getClaimPhoneNumber() | mask: "(000) 000-0000" }}</a></p>
        </ng-container>
        <ng-container *ngIf="isEncompassBrand && !isFNOLOther">
        <p class="h5"><b>Email</b>: ClaimsLossReporting@EncompassIns.com</p>
        </ng-container>
    <div class="button-wrapper">
        <button id="continueBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
    </div>
</div>