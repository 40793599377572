




import { BaseModel } from './BaseModel';

import { ClaimTypeModel } from './ClaimTypeModel';
import { LossDetailsModel } from './LossDetailsModel';
import { AddressModel } from './AddressModel';
import { VehicleInfoModel } from './VehicleInfoModel';
import { LossParticipantModel } from './LossParticipantModel';
import { PolicyModel } from './PolicyModel';
import { ValueDetailModel } from './ValueDetailModel';
import { PropertyInfoModel } from './PropertyInfoModel';

export class ReviewModel extends BaseModel {
    
    public AddUnknownDriver: boolean = false;
    public AddUnknownOwner: boolean = false;
    public AddUnknownClaimantDriver: boolean = false;
    public AddUnknownClaimantOwner: boolean = false;
    public AddUnknownFixedPropertyOwner: boolean = false;
    public AddUnknownPedestrian: boolean = false;
    public DisplayLossDescriptionBox: boolean = false;
    public ClaimType: ClaimTypeModel = null;
    public LossDetails: LossDetailsModel = null;
    public LossAddress: AddressModel = null;
    public Vehicles: VehicleInfoModel[] = [];
    public LossParticipants: LossParticipantModel[] = [];
    public Policy: PolicyModel = null;
    public States: ValueDetailModel[] = [];
    public PhoneTypes: ValueDetailModel[] = [];
    public PropertyInfoModels: PropertyInfoModel[] = [];
    public IsSuccessfulSubmit: boolean = false;
    public HasClaimantInfo: boolean = false;
}