import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ClaimTypeModel, ClaimTypeCategoryEnum, BrandEnum } from '@ClaimsModels/index';
import { GlassOnlyModel } from '@ClaimsModels/index';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { ConstantsService, StyleManagerService, UnsubmittedClaimService, SessionService, ContactService } from '@Common/services';
import { LossApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';


@Component({
    selector: 'awningOnly',
    templateUrl: '../../views/loss/awningOnly.component.html'
})
export class AwningOnlyComponent extends BaseFormComponent {
    constructor(private api: LossApi, private router: Router, private fnolAutoService: FNOLAutoService, 
        private styleManager: StyleManagerService, private constantsService: ConstantsService,
        private unsubmittedClaim: UnsubmittedClaimService, private session: SessionService, private contactService: ContactService, private spinner: Ng4LoadingSpinnerService) {
        super();
    }

    public isAwning: boolean | null;
    public hasError: boolean = false;
    public claimTypeCategory: string;

    public isEsurance: boolean;
    public roadsidePhoneNumber: string;

    // For now, using the Glass only model to get returnURL.
    public vm: GlassOnlyModel = new GlassOnlyModel();

    public ngOnInit(): void {
        if (sessionStorage.getItem('isAwning') === "true")
            this.setAwning(true)
        if (sessionStorage.getItem('isAwning') === "false")
            this.setAwning(false);
        this.hasError = false;
        this.spinner.hide();
        if (BrandEnum.Esurance === this.session.getBrand()) {
            this.isEsurance = true;
        }
        this.roadsidePhoneNumber = this.getRoadsidePhoneNumber();
        this.api.getGlassOnlyModel().then(response => {
            this.vm.ReturnUrl = response.ReturnUrl;

            if (this.vm.ReturnUrl == this.constantsService.EmptyString) {
                this.styleManager.returnUrl.subscribe(url => {
                    this.vm.ReturnUrl = url;
                })
            }
        });
    }

    public goingBackWithLocation(): void {
        //this.unsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
        this.router.navigateByUrl('fnolAuto/towingAndLabor')
    }

    public submit() {
        this.spinner.show();
        if (this.isAwning == null) {
            this.hasError = true;
            this.spinner.hide();

            return;
        }

        sessionStorage.setItem('isAwning', this.isAwning.toString());


        if (this.isAwning) {
            this.fnolAutoService.setClaimTypeCategory(ClaimTypeCategoryEnum.Awning);
            this.fnolAutoService.setClaimTypeCode(this.constantsService.ClaimTypeCodeAwning);

            let model: ClaimTypeModel = new ClaimTypeModel();
            model.ClaimType = this.constantsService.ClaimTypeCodeAwning;

            this.api.saveClaimType(model).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.router.navigate(['/fnolAuto/occupantInformation']);
                }
            });

    
        } else {            
            this.router.navigate(['/fnolAuto/claimType']);
        }
    }

    private getRoadsidePhoneNumber(): string {
        let phoneNumber: string = this.contactService.GetRoadSidePhoneNumber();

        let brand: string = this.session.getBrand();

        //switch (brand) {
        //    case BrandEnum.NationalGeneral:
        //        phoneNumber = this.getRoadSidePhoneNumberByPolicySubtype();
        //        break;
        //}

        return phoneNumber;
    }

    public setAwning(value: boolean): void {
        this.isAwning = value;
        this.hasError = false;
    }
}