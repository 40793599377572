




import { BaseModel } from './BaseModel';


export class UIErrorModel {
    
    public Message: string = null;
    public StackTrace: string = null;
}