<nonPolicyVehicle [hidden]="displayTheftComponent" (useTheftComponent)="setupAndShowTheftComponent($event)"></nonPolicyVehicle>

<div *ngIf="displayTheftComponent" ngForm #vehicleTheftDetailsForm="ngForm" class="content-wrapper">
    <p class="header">Please provide the following information for your vehicle.</p>
    <div class="carInfo">
        <p>{{vm.Year}} {{vm.Make}} {{vm.Model}}</p>
    </div>

    <div class="container">
        <div class="col-lg-12 col-xs-12">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(HasKeys)">Are all keys for the vehicle accounted for?</label>
                        <i [ngClass]="childHasInvalid(HasKeys)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="hasKeys" value="yes" required
                                           [(ngModel)]="hasKeysSelection"
                                           #HasKeys="ngModel">
                                    <span class="checkmark theftRadioButton"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="hasKeys" value="no" required
                                           [(ngModel)]="hasKeysSelection">
                                    <span class="checkmark theftRadioButton"></span>
                                    No
                                </label>
                                <label class="container">
                                    <input type="radio" name="hasKeys" value="unknown" required
                                           [(ngModel)]="hasKeysSelection">
                                    <span class="checkmark theftRadioButton"></span>
                                    Unknown
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(WindowsUp)">Were the windows rolled up at the time of the theft?</label>
                        <i [ngClass]="childHasInvalid(WindowsUp)" class="fas fa-asterisk"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="windowsUp" [(ngModel)]="rolledUpWindowsSelection" #WindowsUp="ngModel"
                                           value="yes" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="windowsUp" [(ngModel)]="rolledUpWindowsSelection"
                                           value="no" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    No
                                </label>
                                <label class="container">
                                    <input type="radio" name="windowsUp"
                                           [(ngModel)]="rolledUpWindowsSelection" value="unknown"
                                           required>
                                    <span class="checkmark theftRadioButton"></span>
                                    Unknown
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(LockedDuringTheft)">Was the vehicle locked at the time of the theft?</label><i [ngClass]="childHasInvalid(LockedDuringTheft)" class="fas fa-asterisk"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="lockedDuringTheft" [(ngModel)]="lockedVehicleSelection" #LockedDuringTheft="ngModel"
                                           value="yes" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="lockedDuringTheft" [(ngModel)]="lockedVehicleSelection"
                                           value="no" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    No
                                </label>
                                <label class="container">
                                    <input type="radio" name="lockedDuringTheft" [(ngModel)]="lockedVehicleSelection"
                                           value="unknown" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    Unknown
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(DamagedPriorToTheft)">Did the vehicle have any prior damage?</label><i [ngClass]="childHasInvalid(DamagedPriorToTheft)" class="fas fa-asterisk"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="damagedPriorToTheft" [(ngModel)]="priorDamageSelection"
                                           value="yes" #DamagedPriorToTheft="ngModel" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="damagedPriorToTheft" [(ngModel)]="priorDamageSelection"
                                           value="no" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    No
                                </label>
                                <label class="container">
                                    <input type="radio" name="damagedPriorToTheft" [(ngModel)]="priorDamageSelection"
                                           value="unknown" required>
                                    <span class="checkmark theftRadioButton"></span>
                                    Unknown
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center" *ngIf="displayPriorDamageDescription()">
                <div class="col-lg-8 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label for="description" [ngClass]="childHasInvalid(DamagePriorToTheftDescription)">Description of damages</label>
                        <i [ngClass]="childHasInvalid(DamagePriorToTheftDescription)" class="fas fa-asterisk"></i>
                    </div>
                    <textarea Validate name="DamagePriorToTheftDescription" required rows="3" [(ngModel)]="vm.VehicleClaimInfo.DamagePriorToTheftDescription" #DamagePriorToTheftDescription="ngModel"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(vehicleTheftDetailsForm)">Continue</button>
    </div>
</div>