<div ngForm #fixedPropertyOwnerForm="ngForm" class="main-inner-wrapper">
    <p *ngIf="propertyDescription === null" class="header">Please add the <em class="emphasis-bold">owner</em> of the property. If the owner is <em class="emphasis-bold">unknown</em>, click Continue.</p>
    <p *ngIf="propertyDescription !== null" class="header">Please add the <em class="emphasis-bold">owner</em> of the {{propertyDescription}}. If the owner is <em class="emphasis-bold">unknown</em>, click Continue.</p>

    <div class="input-wrapper">
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(FirstName)">First Name</label><i [ngClass]="childHasInvalid(FirstName)" class="fas" title=""></i>
                </div>
                <input type="text" Validate OnlyLetters name="FirstName" ngModel="FirstName" #FirstName="ngModel" [(ngModel)]="vm.FirstName" />
            </div>
            <div class="col-lg-2 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>MI</label>
                </div>
                <input type="text" OnlyLetters Validate name="MiddleName" [(ngModel)]="vm.MiddleName" #MiddleName="ngModel" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(LastName)">Last Name</label><i [ngClass]="childHasInvalid(LastName)" class="fas" title=""></i>
                </div>
                <input type="text" name="LastName" OnlyLetters Validate ngModel="LastName" #LastName="ngModel" [(ngModel)]="vm.LastName" />
            </div>
            <div class="col-lg-12 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Address</label>
                </div>
                <input type="text" name="Address" Validate [(ngModel)]="vm.Address.Address1" />
            </div>
            <div class="col-lg-6 col-xs-12">
                <input OnlyLetters type="text" Validate name="City" #City="ngModel" [(ngModel)]="vm.Address.City" />
            </div>
            <div class="col-lg-2 col-xs-12">
                <select [(ngModel)]="vm.Address.State" #State="ngModel" name="State" class="state gbmask">
                    <option [ngValue]="null">State</option>
                    <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                </select>
            </div>
            <div class="col-lg-4 col-xs-12">
                <input OnlyNumbers type="tel" Validate name="Zip" #Zip="ngModel" [(ngModel)]="vm.Address.Zip" />
            </div>
            <ng-container *ngFor="let contactPhone of this.vm.ContactInfo.Phones; trackBy : track; let i = index">
                <div class="col-lg-5 col-xs-12" *ngIf="contactPhone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Phone)">Phone</label><i [ngClass]="childHasInvalid(Phone)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <input type="tel" required name="ContactPhone{{i}}" mask="(000) 000-0000" [(ngModel)]="contactPhone.PhoneNumber" #Phone="ngModel" /><a class="normalLink" (click)="removePhone(i)"><u>Remove</u></a>
                </div>
                <div class="col-lg-6 col-xs-12" *ngIf="contactPhone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(PhoneType)">Phone Type</label><i [ngClass]="childHasInvalid(PhoneType)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <select name="phoneType{{i}}" required [(ngModel)]="contactPhone.PhoneType" #PhoneType="ngModel">
                        <option [ngValue]="null">Select Phone Type</option>
                        <option *ngFor="let pt of availablePhoneTypes(contactPhone) trackBy : track;" [ngValue]="pt.DisplayCode">{{ pt.DisplayValue }}</option>
                    </select>
                </div>
            </ng-container>
            <div *ngIf="canAddPhone();">
                <button class="phone addNew" (click)="addPhone();">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add Phone</span>
                </button>
            </div>
            <div class="col-lg-6 col-xs-12">
                <label>Email</label>
                <input type="email" Validate email name="Email" [(ngModel)]="email" #Email="ngModel" />
            </div>
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Date of Birth</label>
                </div>
                <input type="text" Validate mask="M0/d0/0000" [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" name="DateOfBirth" #DateOfBirth="ngModel" [(ngModel)]="dateOfBirth" />
            </div>
            <div class="col-lg-6 col-xs-12 container">
                <div class="fieldLabel-wrapper">
                    <label>Gender</label>
                </div>
                <div class="container-wrapper">
                    <label class="container">
                        <input type="radio" name="gender" [(ngModel)]="vm.Gender" value="F">
                        <span class="checkmark"></span>
                        <span>Female</span>
                    </label>
                    <label class="container">
                        <input type="radio" name="gender" [(ngModel)]="vm.Gender" value="M">
                        <span class="checkmark"></span>
                        <span>Male</span>
                    </label>
                </div>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(fixedPropertyOwnerForm)">Continue</button>
    </div>
</div>