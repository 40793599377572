<div class="content-wrapper">
    <div class="claim-box">
        <div class="box-header">
            <h2 class="col-10">Documents for Claim # {{vm.ClaimNumber}}</h2>
        </div>
        <div class="upload-row">
            <button class="button button-doc" (click)="uploadDocument()">+</button>
            <span>Upload new document</span>
        </div>

        <div class="claim-card">
            <h3>Previous Documents</h3>
            <div *ngFor="let document of vm.Documents" style="border: 1px solid lightgrey;border-radius:10px;padding:10px;margin-left:15px;">
                <span>{{document.DocumentName}}</span><br />
                <span style="font-size:smaller;">Uploaded {{document.DateUploaded | date}}</span>&nbsp;
                <button (click)="viewDocumentDetails(document)">View</button>
            </div>
        </div>
        <div class="button-wrapper">
            <button class="button button-primary" (click)="goingBackWithLocation()">Back</button>
        </div>
    </div>
</div>