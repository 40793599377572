<div *ngIf="homeOptInStatus && !dismissed" data-backdrop="static" class="modal-content">
    <div class="alert alert-success">
        <button type="button" class="btn btn-close smsTextOptionDismissButton" data-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
        <strong>Need to Opt In to our messaging?</strong><br />
        <span [hidden]="isMediaMobileWidth && !modalIsOpen"> We may need to text you about your claim. Can we use the phone number you provided? Texting is optional and standard rates may apply.</span>
        <div>
            <a [routerLink]="" class="alert-link smsTextOptionButtonLink" (click)="toggleOptInStatus(true);">Opt In</a>
        </div>
        <div [hidden]="(isMediaMobileWidth && !modalIsOpen) || (!isMediaMobileWidth && !modalIsOpen)">
            <a [routerLink]="" class="alert-link smsTextOptionButtonLink" (click)="dismiss();">Cancel</a>
        </div>
    </div>
</div>   
<div *ngIf="!homeOptInStatus && !dismissed" data-backdrop="static" class="modal-content">
    <div class="alert alert-danger">
        <button type="button" class="btn btn-close smsTextOptionDismissButton" data-dismiss="modal" aria-label="Close" (click)="dismiss()"></button>
        <strong>Need to Opt Out of our messaging?</strong><br />
        <span [hidden]="isMediaMobileWidth && !modalIsOpen">You are currently subscribed to our messaging service. If you no longer wish to receive updates or notifications simply click Opt Out and we will promptly remove you from our list.</span>
        <div>
            <a [routerLink]="" class="alert-link smsTextOptionButtonLink" (click)="toggleOptInStatus(false)">Opt Out</a>
        </div>
        <div [hidden]="(isMediaMobileWidth && !modalIsOpen) || (!isMediaMobileWidth && !modalIsOpen)">
            <a [routerLink]="" class="alert-link smsTextOptionButtonLink" (click)="dismiss();">Cancel</a>
        </div>
    </div>
</div>
