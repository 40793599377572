import { BaseComponent } from "@Common/base/base.component";
import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { MC360TabOptions } from '@ClaimsModels/index';

declare var bootstrap: any;

@Component({
    selector: 'homeDocuments',
    templateUrl: '../views/homeDocuments.component.html',
})

export class HomeDocumentsComponent extends BaseComponent implements OnInit {

    public TabOptions = MC360TabOptions;
    @Input() isActiveTab: boolean = true;
    @Output() selectedTabEvent = new EventEmitter<string>();
    public files: Array<Object> = [
        {
            FileName: 'Document 1.pdf',
            Date: new Date(2023, 2, 3, 14, 0)
        },
        {
            FileName: 'Accident.jpg',
            Date: new Date(2023, 2, 3, 14, 0)
        },
        {
            FileName: 'Photo of left side.png',
            Date: new Date(2023, 2, 3, 14, 0)
        },
        {
            FileName: 'Photo of right side.png',
            Date: new Date(2023, 2, 3, 14, 0)
        },
        {
            FileName: 'Photo of back.png',
            Date: new Date(2023, 2, 3, 14, 0)
        },
    ];

    public filesToUpload: Array<File> = [];

    private static bootstrapLoaded = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
    
    }
    
    public changeTabToDocuments(tab: string): void {
        this.isActiveTab = true;
        this.selectedTabEvent.emit(tab);
    }

    public deleteFile(fileIndex: number): void {
        this.files.splice(fileIndex, 1);
    }

    public dropHandler(e) {
        e.preventDefault();
        if (e.dataTransfer.files) {
            [...e.dataTransfer.files].forEach((file, i) => {
                this.filesToUpload.push(file);
            })
        }
    }

    public dragOverHandler(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    public deleteUploadingFile(i: number) {
        this.filesToUpload.splice(i, 1);
    }

}