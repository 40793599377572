import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, AbstractControl } from '@angular/forms';

@Directive({
    selector: 'input[type=email]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailDirective, multi: true }]
})

export class EmailDirective {
    constructor() {}

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.value == null) {
            return;
        }

        // do notice were not testing for "required" attribute since
        // that attribute takes care of itself. We just perform any 
        // validations once text is entered, if any.

        let errorMessages = {};
        let input: string = control.value;
        let hasText: boolean = input.length > 0 ? true : false;
        let numberOfAtSigns: number = input.split("@").length - 1;
        let domainName: string = input.substring(input.lastIndexOf("@")); // returns full string if not found
        let numberOfPeriods: number = domainName.split(".").length - 1;

        // invalid @ signs
        if (hasText && numberOfAtSigns > 1) {
            errorMessages["At"] = "Fix your @";
        } 

        // invalid .
        // 0 period means no domain name
        // todo: find cleaner way to ascertain TLD (top level domain, like ".com" ".net" )
        if (hasText && numberOfPeriods == 0) {
            errorMessages["Period"] = "No domain name specified";
        }

        if (hasText && numberOfPeriods > 1) {
            // ensure we have text between each period
            let numberOfParts = domainName.split('.').filter(x => x.trim().length > 0).length - 1;

            // 2 periods should be 3 text parts
            // 1 period should be 2 text parts
            // we subtract 1 from the number of parts so they should match
            if (numberOfPeriods != numberOfParts) {
                errorMessages["Domain"] = "Need text between domain names";
            }

        }

        // no character between @ and .
        if (hasText && domainName.indexOf("@") - domainName.indexOf(".") == -1) {
            errorMessages["Domain"] = "Add a domain name between the @ and .";
        }

        // its a valid email;
        if (Object.keys(errorMessages).length == 0) {
            return null;
        }

        return errorMessages;
    }
}