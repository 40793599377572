import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { BaseParticipantApi } from '../services/api/baseParticipant.api';
import { ReporterDetailsModel, PageTrackingModel, ReporterTypeEnum, BrandEnum } from '@ClaimsModels/index';
import { SessionService, LoggerApi, ConstantsService } from '@Common/services';

@Component({
    selector: 'reporterDetails',
    templateUrl: '../views/reporterDetails.component.html'
})
export class ReporterDetailsComponent extends BaseFormComponent {

    constructor(private route: ActivatedRoute, private api: BaseParticipantApi,
        private spinner: Ng4LoadingSpinnerService, private loggerApi: LoggerApi, private sessionService: SessionService, private router: Router, private constants: ConstantsService) {
        super();
    }

    private claimType: string;
    private redirectUrl: string;

    public vm: ReporterDetailsModel = new ReporterDetailsModel();
    public isAgent: boolean = false;

    public ngOnInit(): void {
        this.claimType = this.route.snapshot.queryParams.claimType;

        this.spinner.show();
        this.api.getReporterDetailsModel().then((response: ReporterDetailsModel) => {
            this.spinner.hide();
            this.vm = response;

            let brand: string = this.sessionService.get(this.constants.SessionKeys.ClaimsWebBrand);

            if (brand == BrandEnum.EncompassInsurance) {
                this.router.navigateByUrl('/callIn');
            }

            if (this.vm.ReportedBy == ReporterTypeEnum.Agent) {
                this.isAgent = true;
            }

            if (this.claimType === 'auto') {
                this.redirectUrl = `${response.BaseUrl}/${response.AutoForm}`;
            }
            else if (this.claimType === 'thirdpartyauto') {
                this.redirectUrl = `${response.BaseUrl}/${response.ThirdPartyAutoForm}`;
            }
            else {
                this.redirectUrl = `${response.BaseUrl}/${response.HomeForm}`;
            }
        });
    }

    public goingBackWithLocation(): void { throw new Error("Not implemented"); }

    public submit(form: NgForm): void {
        if (this.validate(form)) {
            this.redirectUrl += `?reporterName=${this.vm.Name}&reporterEmail=${this.vm.Email}&reporterPhone=${this.vm.Phone}&howInvolvedInLoss=${this.vm.ReportedBy}#step-1`;

            this.routeExternal(this.redirectUrl);
        }
    }

    public routeExternal(url: string): void {
        this.logExternalPageTracking('/oldForm');
        window.location.href = url;
    }

    private logExternalPageTracking(externalUrl: string): void {
        let page: PageTrackingModel = new PageTrackingModel();
        page.PageName = externalUrl;
        page.SessionId = this.sessionService.getSession().SessionId;

        this.loggerApi.trackPageNavigation(page);
    }
}