




import { BaseModel } from './BaseModel';

import { ReporterInformationModel } from './ReporterInformationModel';
import { ValueDetailModel } from './ValueDetailModel';

export class ClaimReporterModel extends BaseModel {
    
    public Persons: ReporterInformationModel[] = [];
    public ClaimReporter: ReporterInformationModel = null;
    public States: ValueDetailModel[] = [];
    public PhoneTypes: ValueDetailModel[] = [];
    public InsuranceCarriers: ValueDetailModel[] = [];
    public IsAgent: boolean = false;
}