import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { BaseComponent } from '../base/base.component';

@Component({
    selector: 'pageNotFound',
    templateUrl: '../views/pageNotFound.component.html'
})
export class PageNotFoundComponent extends BaseComponent {

    constructor(private location: Location) {
        super();
    }

    public ngOnInit(): void {

    }

    public return(): void {
        this.location.back();
    }

}