import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[OnlyLetters]'
})

export class OnlyLettersDirective {

    private specialKeys: Array<string> = ['Backspace', 'Tab', ' '];
    private regex: RegExp = new RegExp(/^[A-Za-z]*/) // allow A-Z a-z and dash.

    constructor(private el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {

        // Chrome Form selection sometimes causes this to fire. Exit if this happens
        if (!e.key) {
            return;
        }

        // Allow special characters
        if (this.specialKeys.indexOf(e.key) !== -1) {
            return;
        }

        // match the incoming key against the regex pattern. 
        // If invalid, reject
        if (e.key.match(this.regex).toString() == '') {
            e.preventDefault();
        }
    }

    @HostListener('paste', ['$event']) 
    oninput(ev: ClipboardEvent) {
        ev.preventDefault();
        let pastedText = ev.clipboardData.getData("text/plain");
        let unallowedRegex = new RegExp(/[^A-Za-z]*/g);
        let modifiedText = pastedText.replace(unallowedRegex, '');
        document.execCommand('insertText',false,modifiedText);
    }

}