<div ngForm #insuredVehicleForm="ngForm" class="main-inner-wrapper">
    <p class="header">Please select <em class="emphasis-bold">your</em> vehicle that was involved in the incident.</p>

    <div class="alert alert-danger alert-dismissible fade show" *ngIf="hasMultiplePolicyVehiclesSelected" (close)="hasMultiplePolicyVehiclesSelected = false" [type]="danger">
        <strong>Error!</strong> You have selected multiple policy vehicles. Only one policy vehicle per claim.
    </div>

    <div class="alert alert-danger alert-dismissible fade show" *ngIf="hasError" (close)="hasError = false" [type]="danger">
        <strong>Error!</strong> {{ errorMessage }}
    </div>

    <div class="input-wrapper">
        <div class="row">
            <div *ngFor="let vehicle of vm.Vehicles">
                <button class="vehicle" [class.selected]="vehicle.IsSelected" (click)="selectVehicle(vehicle)">
                    <vehicleIcon *ngIf="!isDeviceMobile"></vehicleIcon>
                    <img *ngIf="isDeviceMobile" src="/content/images/vehicle-mobile.png" title="vehicle" alt="ems image" width="59" height="26" />

                    <!--<span class="policyMember">{{ vehicle.IsOnPolicy ? "Insured" : "Other" }}</span>-->
                    <span *ngIf="isVehicleInfoKnown(vehicle)">{{vehicle.VehicleInfo}}</span>
                    <span *ngIf="!isVehicleInfoKnown(vehicle)">Unknown</span>
                    <i *ngIf="vehicle.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>

            <div>
                <button class="vehicle" (click)="addNew()">
                    <vehicleIcon  *ngIf="!isDeviceMobile"></vehicleIcon>
                    <img *ngIf="isDeviceMobile" src="/content/images/vehicle-mobile.png" title="vehicle" alt="ems image" width="59" height="26" />

                    <span>Vehicle Not Listed</span>
                </button>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(insuredVehicleForm)">Continue</button>
    </div>
</div>