import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { LayoutService, SessionService, ConstantsService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';

import { MyClaimsApi } from '@MyClaims360/services';
import { CustomerValidationModel, SessionModel, ValidationSubmissionTypeEnum, RecipientInfoModel } from '../../../../ClaimsWeb.Areas.Models/models';

@Component({
    selector: 'customerValidation',
    templateUrl: '../../views/validation/customerValidation.component.html',
    styleUrls: ['../../styles/CustomerValidation.scss']
})

export class CustomerValidationComponent extends BaseFormComponent {
    constructor(private route: ActivatedRoute, private layoutService: LayoutService, private myClaimsApi: MyClaimsApi,
        private spinner: Ng4LoadingSpinnerService, private sessionService: SessionService, private router: Router, private constantsService: ConstantsService) {
        super();
    }

    public externalId: string;
    public vm: CustomerValidationModel;
    public session: SessionModel = new SessionModel();
    public hasExceededAttempts: boolean = false;
    public displayCancelScreen: boolean = false;
    public displayCancelValidation: boolean = false;
    public validPin: boolean = true;
    public isAlreadySigned = false;
    public isExpired = false;
    public hasRequestedNewPin = false;
    public hidePin = true;

    public ngOnInit(): void {
        this.initializeValidation();
        this.spinner.show();
        this.externalId = this.route.snapshot.params.id;
        this.session = this.sessionService.getSession();
        this.session.IsInitialized = false;
        this.sessionService.setSession(this.session);
        this.layoutService.setBrand(this.constantsService.CustomerValidation);
        if (this.externalId) {
            this.myClaimsApi.getCustomerValidationInfo(this.externalId).then(response => {
                if (response.PageValidationEnabled && response.RecipientInfo != null) {
                    this.vm = response;
                    if (this.vm.RecipientInfo.HasAlreadySigned) {
                        this.isAlreadySigned = true;
                    }
                    else if (this.vm.RecipientInfo.ResendsLeft == 0 || this.vm.RecipientInfo.RetriesLeft == 0 || this.vm.RecipientInfo.HasCanceled) {
                        this.isExpired = true;
                    }
                    this.spinner.hide();
                }
                else {
                    this.spinner.hide();
                    this.router.navigate(['404']);
                }
            });
        }
        else {
            this.spinner.hide();
            this.router.navigate(['404']);
        }
    }

    public initializeValidation(): void {
        this.vm = new CustomerValidationModel();
        this.vm.RecipientInfo = new RecipientInfoModel();
    }

    public requestPin(): void {
        this.spinner.show();
        this.validPin = true;
        this.vm.HasBusinessRuleError = false;
        this.vm.BusinessRuleMessages = [];
        this.hasRequestedNewPin = false;
        this.hasExceededAttempts = this.vm.RecipientInfo.ResendsLeft == 0;
        if (!this.hasExceededAttempts) {
            this.vm.SubmissionType = ValidationSubmissionTypeEnum.SendPin;
            this.myClaimsApi.sendCustomerValidationInfo(this.vm).then(response => {
                this.vm = response;
                this.hasRequestedNewPin = true;
                this.spinner.hide();
            });
        }
        else {
            this.spinner.hide();
        }
    }

    public displayPinValidation(model: NgModel): boolean {
        if (model != null && model.control != null && model.control.invalid && (model.control.dirty || model.control.touched)) {
            this.validPin = true;
            return true;
        }
        return false;
    }

    public submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.vm.SubmissionType = ValidationSubmissionTypeEnum.ValidatePin;
            this.myClaimsApi.sendCustomerValidationInfo(this.vm).then(response => {
                this.vm = response;
                if (this.vm.RedirectUrl != null) {
                    this.validPin = true;
                    window.location.href = this.vm.RedirectUrl;
                }
                else {
                    this.spinner.hide();
                    this.validPin = false;
                    this.hasRequestedNewPin = false;
                    if (this.vm.RecipientInfo != null && this.vm.RecipientInfo.RetriesLeft == 0) {
                        this.hasExceededAttempts = true;
                    }
                }              
            })
        }
    }

    public cancel(): void {
        this.displayCancelScreen = true;
        this.hasRequestedNewPin = false;
    }

    public goingBackWithLocation(): void {
        this.displayCancelScreen = false;
    }

    public cancelValidation(): void {
        this.spinner.show();
        this.vm.SubmissionType = ValidationSubmissionTypeEnum.CancelPin;
        this.myClaimsApi.sendCustomerValidationInfo(this.vm).then(response => {
            this.vm = response;
            this.spinner.hide();
            this.displayCancelScreen = false;
            this.displayCancelValidation = true;
        });
    }
}