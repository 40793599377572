<div style="background-color:grey;padding:10px;flex-direction:row;" class="content-wrapper">
    <div style="background-color: white; box-shadow: 0 0 3px; margin: 10px; padding: 10px; width: 85%; float: left;border-radius:10px;">
        <!--<h4>{{vm.AdjusterFullName}}</h4>-->
        <hr />
        <div *ngFor="let message of vm.Messages">
            <div *ngIf="message.FromClaimContact" style="width:55%;float:left;border:2px solid black;box-shadow:0 0 3px;margin:10px;padding:10px;border-radius:10px;">
                {{message.Message}}<br />
                <i>{{message.DateTimeSent}}</i><br />
            </div>
            <div *ngIf="!message.FromClaimContact" style="width:55%;float:right;background-color:darkblue;box-shadow:0 0 3px;color:white;margin:10px;padding:10px;border-radius:10px;">
                {{message.Message}}<br />
                <i>{{message.DateTimeSent}}</i><br />
            </div>
            <br />
        </div>
        <div><input type="text" style="width:80%;margin:10px;" id="newMessageText" /><button class="btn btn-primary" [(ngModel)]="vm.NewMessage.Message">Send</button></div>
    </div>
    <div style="width:10%;float:right;">
        <button class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
    </div>
</div>