




import { BaseModel } from './BaseModel';


export class MessageModel extends BaseModel {
    
    public Message: string = null;
    public DateTimeSent: Date = new Date(0);
    public AdjusterId: string = null;
}