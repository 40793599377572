import { Directive, ElementRef, HostBinding } from '@angular/core';
import { FNOLAutoService } from '@FNOLAuto/services';
import { ConstantsService, SessionService } from '@Common/services/'
import { NgModel } from '@angular/forms';
import { ClaimTypeCategoryEnum } from '../../../ClaimsWeb.Areas.Models/models';

@Directive({
    selector: '[Validate]'
})

export class ValidateDirective {

    @HostBinding('class.invalid') get invalid() {
        // apply our validation generically to all elements that have this directive

        switch (this.el.nativeElement.nodeName.toLowerCase()) {
            case 'select':
                return !this.control.value && this.control.touched;
            default:
                return this.control.invalid && this.control.touched;
        }
    }

    constructor(private el: ElementRef, private control: NgModel, private fnolAutoService: FNOLAutoService, private constants: ConstantsService, private session: SessionService) {

        let input: HTMLInputElement;
        let select: HTMLSelectElement;
        let textarea: HTMLTextAreaElement;
        let tagName: string = el.nativeElement.getAttribute('name').toLocaleUpperCase();

        // handle variable initialization
        switch (el.nativeElement.nodeName.toLowerCase()) {
            case 'input':
                input = el.nativeElement;
                break;
            case 'select':
                select = el.nativeElement;
                break;
            case 'textarea':
                textarea = el.nativeElement;
                break;
        }

        /*
         * Known issues:
         * - Radio buttons.
         * - Required tag is not honored
         */

        // handle initializing the html validation settings
        switch (tagName) {

            // these use selects
            case 'DRIVER':
                break;
            case 'STATE':
                break;
            case 'TIMEZONE':
                break;
            case 'VEHICLELOCATION':
                break;

            // these use text areas
            case 'DAMAGEDESCRIPTION':
                textarea.maxLength = 250;
                textarea.placeholder = this.constants.DamageDescriptionPlaceholderText;
                break;
            case 'DAMAGEPRIORTOTHEFTDESCRIPTION':
                textarea.maxLength = 200;
                textarea.placeholder = this.constants.TheftPriorDamageDescriptionPlaceholderText;
                break;
            case 'LOSSDESCRIPTION':
                textarea.maxLength = 500;
                textarea.placeholder = this.getLossDescPlaceholderText();
                break;
            case 'INJURYDESCRIPTION':
                textarea.maxLength = 500;
                break;
            case 'PROPERTYDAMAGEDESCRIPTION':
                textarea.maxLength = 255;
                textarea.placeholder = this.constants.PropertyDamageDescriptionPlaceholderText;
                break;
            case 'LOSSHAPPENEDBECAUSE':
                textarea.maxLength = 100;
                textarea.placeholder = this.constants.LossHappenedPlaceholderText;
                break;
            case 'SPECIFICDAMAGECAUSED':
                textarea.maxLength = 100;
                textarea.placeholder = this.constants.SpecificDamagedCausedPlaceholderText;
                break;

            // these are inputs
            // siebel has a hard limit of 86 that we can send. It combines Address, City, and the State code.
            case 'ADDRESS':
                input.maxLength = 54;
                input.placeholder = this.constants.AddressPlaceholderText;
                break;
            case 'CITY':
                input.pattern = "^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$";
                input.placeholder = this.constants.CityPlaceholderText;
                input.maxLength = 30;
                break;
            case 'DATEOFBIRTH':
                input.placeholder = this.constants.DateFormatPlaceholderText;
                input.pattern = "(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d";
                break;
            case 'DATEOFLOSS':
                input.placeholder = this.constants.DateFormatPlaceholderText // loss address
                break;
            case 'DATEOFRECOVERY':
                input.placeholder = this.constants.DateFormatPlaceholderText;
            case 'DRIVEABLE':
                break;
            case 'EMAIL':
                input.maxLength = 254;
                input.placeholder = this.constants.EmailPlaceholderText;
                break;
            case 'FIRSTNAME':
                input.pattern = "^([^0-9]*)$";
                input.maxLength = 50;
                input.placeholder = this.constants.FirstNamePlaceholderText;
                break;
            case 'LASTNAME':
                input.maxLength = 50;
                input.placeholder = this.constants.LastNamePlaceholderText;
                break;
            case 'MAKE':
                input.placeholder = this.constants.MakePlaceholderText;
                input.maxLength = 30;
                input.pattern = "^([^0-9]*)$";
                break;
            case 'MODEL':
                input.placeholder = this.constants.ModelPlaceholderText;
                input.maxLength = 23;
                break;
            case 'MIDDLENAME':
                input.placeholder = this.constants.MiddleNamePlaceholderText;
                input.maxLength = 1;
                input.pattern = "^([^0-9]*)$";
                break;
            case 'PHONE':
                input.placeholder = this.constants.PhoneFormatPlaceholderText;
                break;
            case 'PLATENUMBER':
                input.placeholder = this.constants.PlateNumberFormatPlaceholderText;
                input.maxLength = 15;
                break;
            case 'YEAR':
                input.placeholder = this.constants.YearPlaceholderText;
                input.maxLength = 4;
                input.pattern = ".{4,}";
                break;
            case 'ZIP':
                input.pattern = ".{5}";
                input.maxLength = 5;
                input.placeholder = this.constants.ZipPlaceholderText;
                break;
            case 'DEPARTMENT':
                input.maxLength = 100;
                break;
            case 'REPORTNUMBER':
                input.maxLength = 25;
                break;
            case 'PROPERTYDESCRIPTION':
                input.placeholder = this.constants.PropertyDescriptionPlaceholderText;
                input.maxLength = 250;
                break;
            case 'BUSINESSNAME':
                input.maxLength = 50;
                break;
        }
    }

    public getLossDescPlaceholderText(): string {
        let claimType = this.fnolAutoService.getClaimTypeCode();
        let claimTypeCategory = this.fnolAutoService.getClaimTypeCategory();
        let isWorkflowRV: boolean = this.session.getWorkflowType() == this.constants.FNOLRV ? true : false;
        let placeholderText: string;
        switch (claimType) {
            case this.constants.ClaimTypeCodeMechanicalBreakdown:
                placeholderText = this.constants.MechanicalPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeEarthquake:
                placeholderText = this.constants.EarthquakePlaceholderText;
                break;
            case this.constants.ClaimTypeCodeHail:
                placeholderText = this.constants.HailPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeFloodRisingWater:
                placeholderText = this.constants.FloodPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeWindTornado:
                placeholderText = this.constants.WindPlaceholderText
                break;
            case this.constants.ClaimTypeCodeSnowIce:
                placeholderText = this.constants.SnowPlaceholderText
                break;
            case this.constants.ClaimTypeCodeLightning:
                placeholderText = this.constants.LightningPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeTheft:
                placeholderText = this.constants.TheftPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeFire:
                placeholderText = this.constants.FirePlaceholderText;
                break;
            case this.constants.ClaimTypeCodeVandalism:
                placeholderText = this.constants.VandalismPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeTireBlowout:
                placeholderText = this.constants.TireBlowOutPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeRoadHazard:
                placeholderText = this.constants.RoadHazardPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeRoadHazardNF:
                placeholderText = this.constants.RoadHazardPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeHitAndRun:
                placeholderText = this.constants.HitAndRunPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeMultiVehicleHitAndRun:
                placeholderText = this.constants.HitAndRunPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeFallingObject:
                placeholderText = this.constants.FallingObjectPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeMiscNF:
                placeholderText = this.constants.FallingObjectPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeAnimalCollision:
                placeholderText = this.constants.AnimalPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeFixedProperty:
                placeholderText = this.constants.FixedPropertyPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeSingleVehicleLoss:
                placeholderText = this.constants.OtherSVLPlaceholderText
                break;
            case this.constants.ClaimTypeCodeRearEndAccidentNF:
                placeholderText = this.constants.RearEndNotFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeRearEndAccidentAF:
                placeholderText = this.constants.RearEndFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeIntersectionAccident:
                placeholderText = this.constants.IntersectionPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeParkingLotAccidentAF:
                placeholderText = this.constants.ParkingLotFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeParkingLotAccidentNF:
                placeholderText = this.constants.ParkingLotNotFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeBackingAccidentAF:
                placeholderText = this.constants.BackingFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeBackingAccidentNF:
                placeholderText = this.constants.BackingNotFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeHeadOnCollision:
                placeholderText = this.constants.HeadOnCollisionPlaceholderText;
                break;
            case this.constants.ClaimTypeCodePassingMergingAccidentAF:
                placeholderText = this.constants.PassingMergingFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodePassingMergingAccidentNF:
                placeholderText = this.constants.PassingMergingNotFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeSideswipeAccidentAF:
                placeholderText = this.constants.SideswipeFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeSideswipeAccidentNF:
                placeholderText = this.constants.SideswipeNotFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeInMotion:
                placeholderText = this.constants.InMotionPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeNotInMotion:
                placeholderText = this.constants.NotInMotionPlaceholdertext;
                break;
            case this.constants.ClaimTypeCodePedAF:
                placeholderText = this.constants.PedestrianFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodePedNF:
                placeholderText = this.constants.PedestrianNotFaultPlaceholderText;
                break;
            case this.constants.ClaimTypeCodeRVAnimalsNF:
                placeholderText = this.constants.DamagesNotRelatedToWeatherOrFireText;
                break;
            case this.constants.ClaimTypeCodeRVElectricalNF:
                placeholderText = this.constants.DamagesNotRelatedToWeatherOrFireText;
                break;
            case this.constants.ClaimTypeCodeRVOtherNF:
                placeholderText = this.constants.DamagesNotRelatedToWeatherOrFireText;
                break;
            case this.constants.ClaimTypeCodeRVWater:
                placeholderText = this.constants.DamagesNotRelatedToWeatherOrFireText;
                break;
            default:
                placeholderText = this.constants.LossDescriptionPlaceholderText;
        }
        if (isWorkflowRV) {
            switch (claimTypeCategory) {
                case ClaimTypeCategoryEnum.Awning:
                case ClaimTypeCategoryEnum.Other:
                case ClaimTypeCategoryEnum.Impounded:
                case ClaimTypeCategoryEnum.Glass:
                    placeholderText = this.constants.EmptyString;
                    break;
                case ClaimTypeCategoryEnum.SingleVehicle:
                    placeholderText = this.constants.SingleVehicleRVPlaceholderText;
                    break;
                case ClaimTypeCategoryEnum.Weather:
                    placeholderText = this.constants.WeatherEventRVText;
                    break;
            }
        }

        return placeholderText;
    }
}