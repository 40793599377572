<div class="main-inner-wrapper">
    <div>
        <p *ngIf="!this.IsNullOrEmptyString(this.vm.ReporterEmail)" class="emailConfirmationNotice" style="word-wrap: break-word;">Your claim information will be emailed shortly to {{this.vm.ReporterEmail}}.</p>
    </div>
    <div class="input-wrapper">
        <div>
            <img src="../../../../../../../../content/images/checkmark-v3.gif" alt="checkmark" width="70%" height="70%" />
        </div>
        <p class="textConfirmation">Your claim has been submitted.</p>
        <div class="row justify-content-center">
            <div>
                <table class="table table-striped convert-flexbox">
                    <tr class="claimNumber">
                        <td *ngIf="this.IsNullOrEmptyString(this.vm.ClaimNumber) && !timedOut" colspan="2">Generating Claim Number</td>

                        <td *ngIf="!this.IsNullOrEmptyString(this.vm.ClaimNumber)" colspan="2">Claim Number:</td>
                        <td *ngIf="!this.IsNullOrEmptyString(this.vm.ClaimNumber)">{{ this.vm.ClaimNumber }}</td>

                        <td *ngIf="this.IsNullOrEmptyString(this.vm.ClaimNumber) && !timedOut">
                            <mat-progress-spinner mode="determinate" [value]="timerProgress" diameter="25"></mat-progress-spinner>
                        </td>

                        <td colspan="3" *ngIf="timedOut && this.IsNullOrEmptyString(this.vm.ClaimNumber)">Your Claim Number will be emailed shortly.</td>
                    </tr>

                    <tr *ngIf="(this.IsNullOrEmptyString(this.vm.AdjusterFirstName) || this.IsNullOrEmptyString(this.vm.AdjusterLastName)) && !timedOut">
                        <td colspan="2">Assigning Adjuster</td>
                        <td>
                            <mat-progress-spinner mode="determinate" [value]="timerProgress" diameter="25"></mat-progress-spinner>
                        </td>
                    </tr>
                    <tr *ngIf="(!this.IsNullOrEmptyString(this.vm.AdjusterFirstName) || !this.IsNullOrEmptyString(this.vm.AdjusterLastName)) && !timedOut" class="adjusterName">
                        <td colspan="2">Adjuster Name</td>
                        <td>{{ this.vm.AdjusterFirstName }} {{ this.vm.AdjusterLastName }}</td>
                    </tr>
                    <tr *ngIf="!this.IsNullOrEmptyString(this.vm.AdjusterPhone) && !timedOut" class="adjusterPhone">
                        <td colspan="2">Adjuster Phone</td>
                        <td *ngIf="this.vm.AdjusterPhone.length == 10">
                            {{ this.vm.AdjusterPhone | mask: "(000) 000-0000"  }}
                        </td>
                        <td *ngIf="this.vm.AdjusterPhone.length > 10">
                            {{ this.vm.AdjusterPhone | mask: '(000) 000-0000 SSS 9999999' }}
                        </td>
                    </tr>

                    <tr *ngIf="(this.IsNullOrEmptyString(this.vm.AdjusterFirstName) || this.IsNullOrEmptyString(this.vm.AdjusterLastName)) && timedOut">
                        <td colspan="3">Your Adjuster Information will be emailed shortly.</td>
                    </tr>

                    <tr *ngIf="dataComplete || timedOut">
                        <td colspan="3" *ngIf="claimTypeCode !== 'MECHBREAK' && !isEsurance">Your adjuster will contact you by the end of the next business day.</td>
                        <td colspan="3" *ngIf="claimTypeCode !== 'MECHBREAK' && isEsurance">Your adjuster will contact you by the end of the next two business days.</td>

                        <td colspan="3" *ngIf="claimTypeCode === 'MECHBREAK'">Thank you for your claim submission. Your claim will be reviewed over the next few business days. A representative will contact you if we have any questions about your claim or towing invoice.</td>
                    </tr>

                </table>
            </div>
        </div>
        <div class="row justify-content-center relDocInfo-wrapper" *ngIf="dataComplete || timedOut">
            <span *ngIf="!this.IsNullOrEmptyString(this.vm.ClaimNumber)">
                You can email any relevant documents to <a href="mailto:{{ getClaimsEmail() }}?Subject={{ vm.ClaimNumber }}" role="button" class="cursor">{{ getClaimsEmail() }}</a>. Please include your Claim Number in the subject line.
            </span>
        </div>
    </div>
</div>