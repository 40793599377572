import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { SessionModel } from '@ClaimsModels/SessionModel';
import { Router } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { SessionSettingsModel } from '@ClaimsModels/index';
import { RecordingSessionModel } from '../../../../ClaimsWeb.Areas.Models/models/RecordingSessionModel';

declare var window: any;

@Injectable()
export class SessionService {

    private sessionKey: string = "claimsWebSession";
    private sessionExpirationKey: string = "claimsWebSessionExpire";
    private returnUrlKey: string = "claimsWebReturnUrl";

    constructor(private router: Router, private constants: ConstantsService) { }

    public setSession(session: SessionModel): void {
        sessionStorage.setItem(this.sessionKey, JSON.stringify(session));
        sessionStorage.setItem(this.sessionExpirationKey, this.getSessionExpirationTime());

        if (typeof window.sessioncamConfiguration === 'undefined' || typeof window.sessioncamConfiguration.customDataObjects === 'undefined') {
            window.sessioncamConfiguration = {};
            window.sessioncamConfiguration.customDataObjects = [];
        }

        if (session.ClaimId !== 0) {
            if (window.sessioncamConfiguration.customDataObjects.length === 0) {
                let claimId = { key: "CV_ClaimID", value: session.ClaimId };
                window.sessioncamConfiguration.customDataObjects.push(claimId);
            }
        }
    }

    public setExistingSession(session: SessionModel): void {
        sessionStorage.setItem(this.sessionKey, JSON.stringify(session));

        if (typeof window.sessioncamConfiguration === 'undefined' || typeof window.sessioncamConfiguration.customDataObjects === 'undefined') {
            window.sessioncamConfiguration = {};
            window.sessioncamConfiguration.customDataObjects = [];
        }

        if (session.ClaimId !== 0) {
            if (window.sessioncamConfiguration.customDataObjects.length === 0) {
                let claimId = { key: "CV_ClaimID", value: session.ClaimId };
                window.sessioncamConfiguration.customDataObjects.push(claimId);
            }
        }
    }

    public getSession(): SessionModel {
        let session: SessionModel = JSON.parse(sessionStorage.getItem(this.sessionKey));

        if (session !== null && session !== undefined) {
            session.IsInitialized = true;

            if (typeof window.sessioncamConfiguration === 'undefined' || typeof window.sessioncamConfiguration.customDataObjects === 'undefined') {
                window.sessioncamConfiguration = {};
                window.sessioncamConfiguration.customDataObjects = [];
            }

            if (session.ClaimId !== 0) {
                if (window.sessioncamConfiguration.customDataObjects.length === 0) {
                    let claimId = { key: "CV_ClaimID", value: session.ClaimId };
                    window.sessioncamConfiguration.customDataObjects.push(claimId);
                }
            }
        } else {
            session = new SessionModel();
            session.IsInitialized = false;
        }

        session.IsExpired = this.isSessionExpired();

        return session;
    }

    public getSerializedSession(): string {
        let session: string = sessionStorage.getItem(this.sessionKey);

        if (session === null || session === undefined) {
            session = '';
        }

        return session;
    }

    public clearSession(): void {
        sessionStorage.clear();
    }

    public refreshSession(): void {
        sessionStorage.setItem(this.sessionExpirationKey, this.getSessionExpirationTime());
    }

    public set(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    public get(key: string): string {
        return sessionStorage.getItem(key);
    }

    public isSessionExpired(): boolean {
        let expirationDate: Date = new Date(this.get(this.sessionExpirationKey));
        let currentDate: Date = new Date();

        return expirationDate < currentDate;
    }

    public checkIfSessionIsExpired(): void {
        let session: SessionModel = this.getSession();
        let expirationDate: Date = new Date(this.get(this.sessionExpirationKey));
        let currentDate: Date = new Date();

        if (expirationDate < currentDate) {
            session.IsExpired = true;
            this.setSession(session);
        }
    }

    public navigateToSessionExpirationPage(): void {
        this.router.navigate(['sessionTimeout']);
    }

    private getSessionExpirationTime(): string {
        let sessionSetting: SessionSettingsModel = JSON.parse(sessionStorage.getItem(this.constants.SessionSettingKey));

        if (sessionSetting === null || sessionSetting === undefined) {
            sessionSetting = new SessionSettingsModel();
            sessionSetting.SessionExpirationMinutes = 30;
        }

        return moment().add(sessionSetting.SessionExpirationMinutes, 'm').toString();
    }

    public getSessionSettings(): SessionSettingsModel {
        return JSON.parse(sessionStorage.getItem(this.constants.SessionSettingKey));
    }

    public setSessionSettings(settings: SessionSettingsModel): void {
        sessionStorage.setItem(this.constants.SessionSettingKey, JSON.stringify(settings));
    }

    public getBrand(): string {
        return sessionStorage.getItem(this.constants.ClaimsWebBrandSessionKey);
    }

    public setReturnUrl(returnUrl: string) {
        sessionStorage.setItem(this.returnUrlKey, returnUrl);
    }

    public getRecordingSessionSettings(): RecordingSessionModel {
        return JSON.parse(sessionStorage.getItem(this.constants.RecordSessionKey));
    }

    public setRecordingSessionSettings(settings: RecordingSessionModel): void {
        sessionStorage.setItem(this.constants.RecordSessionKey, JSON.stringify(settings));
    }
    public getReturnUrl() {
        return sessionStorage.getItem(this.returnUrlKey);
    }

    public setWorkflowType(policyType: string): void {
        sessionStorage.setItem(this.constants.WorkflowTypeKey, policyType);
    }
    public getWorkflowType() {
        return sessionStorage.getItem(this.constants.WorkflowTypeKey);
    }
}