




import { BaseModel } from './BaseModel';

import { DocumentModel } from './DocumentModel';

export class ClaimDocumentsModel extends BaseModel {
    
    public ClaimID: number = 0;
    public ClaimNumber: string = null;
    public Documents: DocumentModel[] = [];
}