import { NgModule } from '@angular/core';

import {
    EmailDirective, CapitalizeFirstLetterDirective, OnlyNumbersDirective,
    OnlyLettersDirective, ValidateDirective, DisallowCharactersDirective, DisallowWhiteSpaceDirective, DateOfBirthDirective, BypassHrefAlert, DatePatternDirective
} from './directives'


@NgModule({
    declarations: [
        EmailDirective, CapitalizeFirstLetterDirective, OnlyNumbersDirective, OnlyLettersDirective, ValidateDirective, DisallowCharactersDirective, DisallowWhiteSpaceDirective, DateOfBirthDirective, BypassHrefAlert, DatePatternDirective
    ],
    exports: [
        EmailDirective, CapitalizeFirstLetterDirective, OnlyNumbersDirective, OnlyLettersDirective, ValidateDirective, DisallowCharactersDirective, DisallowWhiteSpaceDirective, DateOfBirthDirective, BypassHrefAlert, DatePatternDirective
    ]
})
export class CommonDirectivesModule { }