import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { NgForm, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { SessionModel, NewClaimModel, DeviceTrackingModel, PageTrackingModel, BrandEnum, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { SessionService, LayoutService, StyleManagerService, LoggerApi, ConstantsService, CommonClaimApi, SessionIdleService, UnsubmittedClaimService, ContactService } from '@Common/services';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RecordingSessionModel } from '../../../ClaimsWeb.Areas.Models/models/RecordingSessionModel';
declare let _detector: any;

@Component({
    selector: 'newClaim',
    templateUrl: '../views/newClaim.component.html'
})
export class NewClaimComponent extends BaseFormComponent {
    private session: SessionModel;
    public vm: NewClaimModel = new NewClaimModel();
    public roadsidePhoneNumber: string;

    public noClaimSelected: boolean;
    public isEsurance: boolean;
    public isThirdParty: boolean = false;



    constructor(private api: CommonClaimApi, private sessionService: SessionService, private location: Location, private spinner: Ng4LoadingSpinnerService,
        private layoutService: LayoutService, private styleManager: StyleManagerService, private router: Router, private route: ActivatedRoute,
        private loggerApi: LoggerApi, private constants: ConstantsService, private sessionIdle: SessionIdleService, private unsubmittedClaim: UnsubmittedClaimService,
        private contactService: ContactService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2) {
        super();
    }

    public goingBackWithLocation(): void {
        this.unsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
    }

    public routeExternal(url: string): void {
        this.logExternalPageTracking('/oldForm');
        window.location.href = url;
    }

    public ngOnInit(): void {
        this.session = this.sessionService.getSession(); // this will create a session if we don't have one
        let initializedOnInit: Boolean = this.session.IsInitialized;
        this.session.SessionId = this.route.snapshot.params.id;
        
        if (this.route.snapshot.params.sourceParty != null) {
            this.session.SourceParty = this.route.snapshot.params.sourceParty;
            this.isThirdParty = true;
        }

        if (this.session.ClaimId > 0) {
            this.session.IsInitialized = true;
        } else {
            this.session.IsInitialized = false;
        }

        this.sessionService.setSession(this.session);

        if (!initializedOnInit) {
            this.logExternalPageTracking(this.route.routeConfig.path);
        }

        this.spinner.show();
        this.api.getNewClaimModel()
            .then((response: NewClaimModel) => {
                this.spinner.hide();
                this.vm = response;
                this.sessionService.setSessionSettings(response.SessionSettings);
                this.sessionService.refreshSession();
                this.sessionIdle.startWatchingSession();
                
                this.layoutService.setBrand(response.Brand);

                this.roadsidePhoneNumber = this.contactService.GetRoadSidePhoneNumber();

                this.initializeClaimTypes();

                if (BrandEnum.Esurance === this.sessionService.getBrand()) {
                    this.isEsurance = true;
                }

                if (response.RecordingSession) {
                    if (response.RecordingSession.RecordSession) {

                        const script = this.renderer.createElement('script');
                        script.type = 'text/javascript';
                        script.src = response.RecordingSession.ScriptSource;                                             
                        script.setAttribute(response.RecordingSession.AttributeName, response.RecordingSession.AttributeValue);

                        this.renderer.appendChild(this.document.body, script);
                        this.sessionService.setRecordingSessionSettings(response.RecordingSession);

                        script.onload = () => {

                            const sessionParams = {
                                RecordingSessionGuid: response.RecordingSession.RecordingSessionGuid,
                                Environment: response.RecordingSession.Environment,
                                Brand: response.Brand
                            };
                            
                            _detector.triggerCustomEventMap('claimInfo', sessionParams);
                        }
                    }

                }

                if (this.vm.ReturnUrl == this.constants.EmptyString) {
                    this.styleManager.returnUrl.subscribe(url => {
                        this.vm.ReturnUrl = url;
                        this.sessionService.setReturnUrl(url);
                    })
                }

                if (response.IsInitialized) {
                    for (let selection of this.allSelections) {
                        if (selection.claimType === response.ClaimType) {
                            selection.isSelected = true;
                            break;
                        }
                    }
                }

                //If Third Party default to auto
                if (this.session.SourceParty != null) {

                    if (response.IsInitialized) {
                        this.unsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
                    }
                    else {
                        this.selectTypeService(null, this.personalThirdPartyAuto);

                        var form = <NgForm>{
                            valid: true,
                            controls: {}
                        };

                        this.submit(form);
                    }


                }



            });
    }

    public submit(form: NgForm): void {

        if (this.getSelected() == null) {
            this.noClaimSelected = true;
            return;
        }

        if (this.validate(form)) {
            this.spinner.show();

            let selected = this.getSelected();
            this.vm.ClaimType = selected.claimType;
            this.vm.DeviceTracking = this.setDeviceTrackingInfo();
            
            this.api.saveNewClaimModel(this.vm)
                .then((response: NewClaimModel) => {
                    this.spinner.hide();
                    if (response && response.Success) {
                        if (response.QueryParameter !== '' && response.QueryParameter !== null) {
                            let brand: string = this.sessionService.getBrand();
                            switch (brand) {
                                case BrandEnum.SafeAuto:
                                case BrandEnum.Esurance:
                                case BrandEnum.EncompassInsurance:
                                    this.router.navigateByUrl('/policyCallInClaim');
                                    break;
                                default:
                                    this.router.navigateByUrl('/' + response.RedirectUrl + '?claimType=' + response.QueryParameter);
                                    break;
                            }

                        }
                        else {
                            if (selected.claimType === this.constants.FNOLRV) {
                                let brand: string = this.sessionService.getBrand();
                                switch (brand) {
                                    case BrandEnum.GoodSam:
                                    case BrandEnum.AIE:
                                    case BrandEnum.NationalGeneral:
                                    case BrandEnum.Farmers:
                                    case BrandEnum.EncompassInsurance:
                                    case BrandEnum.MVIC:
                                    case BrandEnum.NJSI:
                                    case BrandEnum.MC360:
                                    case BrandEnum.Custom360:
                                        this.router.navigateByUrl('fnolAuto/dateOfLoss');
                                        break;
                                    case BrandEnum.SafeAuto:
                                    case BrandEnum.Esurance:
                                        {
                                            this.router.navigateByUrl('/rvMessage');
                                            break;
                                        }
                                    default:
                                        this.router.navigateByUrl('/reporterDetails?claimType=home');
                                        break;
                                }
                            } else
                                this.router.navigate([response.RedirectUrl]);
                        }
                    }
                });
        }
    }

    public personalAuto: NewClaimType = new NewClaimType();
    public home: NewClaimType = new NewClaimType();
    public watercraft: NewClaimType = new NewClaimType();
    public motorhome: NewClaimType = new NewClaimType();
    public motorcycle: NewClaimType = new NewClaimType();
    public commercialAuto: NewClaimType = new NewClaimType();
    public farm: NewClaimType = new NewClaimType();
    public life: NewClaimType = new NewClaimType();

    public personalThirdPartyAuto: NewClaimType = new NewClaimType();

    private newClaimSelections: NewClaimType[] = [this.personalAuto, this.home, this.watercraft, this.motorhome, this.motorcycle, this.commercialAuto, this.farm, this.life, this.personalThirdPartyAuto];

    private allSelections: NewClaimType[] = [
        ...this.newClaimSelections
    ];

    public selectTypeService(e, claimType: NewClaimType): void {
        let currentlySelected = this.getSelected();
        if (currentlySelected !== null && currentlySelected !== undefined) {
            this.toggleSelection(currentlySelected);
        }
        this.toggleSelection(claimType);
        this.setWorkflowType(claimType.claimType);
    }

    private initializeClaimTypes(): void {
        this.personalAuto.claimType = this.constants.FNOLAuto;
        this.home.claimType = this.constants.FNOLHome;
        this.watercraft.claimType = this.constants.FNOLWatercraft;
        this.motorhome.claimType = this.constants.FNOLRV;
        this.motorcycle.claimType = this.constants.FNOLMotorcycle;
        this.commercialAuto.claimType = this.constants.FNOLCommercialAuto;
        this.farm.claimType = this.constants.FNOLFarm;
        this.life.claimType = this.constants.FNOLLife;
        this.personalThirdPartyAuto.claimType = this.constants.FNOLThirdPartyAuto;

    }
    private setWorkflowType(claimType: string): void {
        this.sessionService.setWorkflowType(claimType);
    }
    private toggleSelection(claimType: NewClaimType): void {
        let isSelected = !claimType.isSelected;

        claimType.isSelected = isSelected;
    }

    private getSelected(): NewClaimType {
        return this.allSelections.find(x => x.isSelected);
    }

    private logExternalPageTracking(externalUrl: string): void {
        let page: PageTrackingModel = new PageTrackingModel();
        page.PageName = externalUrl;
        page.SessionId = this.sessionService.getSession().SessionId;

        this.loggerApi.trackPageNavigation(page);
    }
}

class NewClaimType {
    isSelected: boolean;
    claimType: string;
}