<div ngForm #additionalPartyVehicle="ngForm" class="main-inner-wrapper">
    <p class="header" *ngIf="!isThirdParty && !isRVWorkflow">Please provide as much of the party's vehicle information as possible.</p>
    <p class="header" *ngIf="isThirdParty && !isRVWorkflow">Please provide the other party's vehicle information.</p>
    <p class="header" *ngIf="isRVWorkflow">Please provide as much information about the vehicle as possible.</p>
                                           
    <div class="input-wrapper">
        <div class="row">
            <div class="col-lg-8 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(LicenseNumber)">License Plate Number</label>
                </div>
                <input Validate type="text" name="PlateNumber" [(ngModel)]="vm.PlateNumber" #LicenseNumber="ngModel" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>State</label>
                </div>
                <select [(ngModel)]="vm.PlateStateCode" #State="ngModel" name="State" class="state gbmask">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                </select>
            </div>
            <ng-container *ngIf="!isThirdParty">
                <div class="col-lg-12 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label>Driver</label>
                        &nbsp;<a (click)="addPerson()" role="button" tabindex="0" class="unlistedVehicleParticipant">My Driver isn't listed</a>
                    </div>
                    <select [(ngModel)]="vm.VehicleClaimInfo.DriverContactId" #Driver="ngModel" name="Driver">
                        <option [ngValue]="null">Select a Driver for this Vehicle</option>
                        <option *ngFor="let participant of vm.Passengers" [ngValue]="participant.Id">{{participant.Name}}</option>
                    </select>
                </div>
                <div class="col-lg-12 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label>Owner</label>
                        &nbsp;<a (click)="addPerson()" role="button" tabindex="0" class="unlistedVehicleParticipant">My Owner isn't listed</a>
                    </div>
                    <select [(ngModel)]="vm.VehicleClaimInfo.OwnerContactId" #Owner="ngModel" name="Owner">
                        <option [ngValue]="null">Select an Owner for this Vehicle</option>
                        <option *ngFor="let participant of vm.Passengers" [ngValue]="participant.Id">{{participant.Name}}</option>
                    </select>
                </div>
                <div class="col-lg-12 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label>Passengers</label>
                        &nbsp;<a (click)="addPerson()" role="button" tabindex="0" class="unlistedVehicleParticipant">My passenger isn't listed</a>
                    </div>
                    <mat-select multiple name="Passenger" class="multiselectDropdown" [(ngModel)]="vm.SelectedPassengers" [compareWith]="compareParticipants">
                        <mat-option *ngFor="let passenger of vm.Passengers" [value]="passenger">{{passenger.Name}}</mat-option>
                    </mat-select>
                </div>
            </ng-container>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Year)">Year</label>
                </div>
                <input Validate type="text" OnlyNumbers name="Year" [(ngModel)]="vm.Year" #Year="ngModel" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Make)">Make</label>
                </div>
                <input Validate type="text" OnlyLetters name="Make" [(ngModel)]="vm.Make" #Make="ngModel" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Model)">Model</label>
                </div>
                <input Validate type="text" name="Model" [(ngModel)]="vm.Model" #Model="ngModel" />
            </div>
            <div class="col-lg-12 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Description of Damage</label>
                </div>
                <textarea Validate name="DamageDescription" rows="3" [(ngModel)]="vm.VehicleClaimInfo.DamageDescription" #Description="ngModel"></textarea>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(additionalPartyVehicle)">Continue</button>
    </div>
</div>