import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AddressValidationBottomSheet } from '@Common/components/modals/addressValidation.component';
import { AddressValidationModel } from '@ClaimsModels/index';
import { ConstantsService } from '../services/constants.service';
import { AddressValidationApi } from '../services/api/addressValidation.api';

@Injectable()
export class AddressValidationService  {

    constructor(private bottomSheet: MatBottomSheet, private constantsService: ConstantsService, private addressValidationApi: AddressValidationApi) {
        
    }

    // this is variables for functions.
    // functions should be passed in using .bind(this) so that the execution happens in the Component they come from
    // below should be put in to the constructor for service initialization
    // ex: this.addressValidationService.initialize(this.continuePastAddressValidation.bind(this), this.updateAddress.bind(this), this.isValidAddress.bind(this));
    private continuePastAddressValidation: Function;
    private updateAddress: Function;
    private isValidAddress: Function;

    public initialize(continuePastAddress: Function, updateAddress: Function, isValidAddress: Function): void {
        this.continuePastAddressValidation = continuePastAddress;
        this.updateAddress = updateAddress;
        this.isValidAddress = isValidAddress;
    }

    // Just a wrapper around querying the address validation API so it's not injected with the service into a component.
    // It was just simpler to inject it here.
    public checkAddress(address: AddressValidationModel): Promise<AddressValidationModel> {
        return this.addressValidationApi.validateAddress(address);
    }

    // Ensure our functions are present and then validate if present
    public validateAddress(addressValidationResponse: AddressValidationModel, addresses: object, form: NgForm): void {

        // if any of these are null, then error out
        if (!this.continuePastAddressValidation || !this.updateAddress || !this.isValidAddress){
            console.error('Address validation functions are missing');
            return;
        }

        if (addressValidationResponse.AddressToValidate.City == null) {
            addressValidationResponse.AddressToValidate.City = ""
        };
        if (addressValidationResponse.AddressToValidate.State == null) {
            addressValidationResponse.AddressToValidate.State = "";
        };
        if (addressValidationResponse.AddressToValidate.Zip == null) {
            addressValidationResponse.AddressToValidate.Zip = "";
        };
        if (addressValidationResponse.AddressToValidate.Address1 == null) {
            addressValidationResponse.AddressToValidate.Address1 = "";
        };


        if (addressValidationResponse.AddressToValidate.City.length == 0 ||
            addressValidationResponse.AddressToValidate.State.length == 0 ||
            addressValidationResponse.AddressToValidate.Zip.length == 0 ||
            addressValidationResponse.AddressToValidate.Address1.length == 0) {
            addressValidationResponse.Success = false;
            }
        
        if (addressValidationResponse.Success) {
            if (addressValidationResponse.IsSuggestedAddress) {
                var currentSheet: MatBottomSheetRef = this.bottomSheet.open(AddressValidationBottomSheet,
                    {
                        data: addresses
                    });

                currentSheet.afterDismissed().subscribe(result =>
                {

                    if (result === null || result === undefined) {
                        return;
                    }

                    if (result.event == this.constantsService.Keep) { // What user entered
                        this.continuePastAddressValidation(form);
                    } else if (result.event == this.constantsService.Change) { // Recommended button

                        this.updateAddress(addressValidationResponse.AddressToValidate); // update the address in the component

                        if (!this.isValidAddress(form, addressValidationResponse.AddressToValidate)) {
                            return;
                        }

                        this.continuePastAddressValidation(form);
                    }
                });
            }
            else {
                this.continuePastAddressValidation(form);
            }
        } else {
            this.continuePastAddressValidation(form);
        }
    }
}