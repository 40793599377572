<div ngForm #claimReporterForm="ngForm" class="main-inner-wrapper no-mobile">
    <p class="header" *ngIf="isSelectPerson && !vm.IsAgent" style="width: 100vw; padding-left: 16px; padding-right: 16px;">
        Please select <em class="emphasis-bold">your name</em> or <em class="emphasis-bold">add it</em> by clicking "Add New".
    </p>
    <p class="header" *ngIf="isSelectPerson && vm.IsAgent" style="width: 100vw; padding-left: 16px; padding-right: 16px;">
        As an agent reporting the loss, please add <em class="emphasis-bold">your name</em> and <em class="emphasis-bold">contact</em> information.
    </p>

    <p class="header" *ngIf="!isSelectPerson && !isOther" style="width: 100vw;padding-left:16px;padding-right:16px;">Please review and complete the following information for <em class="emphasis-bold">{{selectedPerson.FirstName}}</em>.</p>
    <p class="header" *ngIf="!isSelectPerson && isOther" style="width:100vw;padding-left:16px;padding-right:16px;">Please fill out the following form with <em class="emphasis-bold">your</em> information.</p>
    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="hasError" (close)="hasError = false" [type]="danger">
        <strong>Error!</strong> Please select a Claim Reporter.
    </ngb-alert>
    <div class="input-wrapper">
        <div class="row" *ngIf="isSelectPerson && !vm.IsAgent">
            <div *ngFor="let person of vm.Persons">
                <button class="person" [class.selected]="person.IsSelected" (click)="selectPerson(person)">
                    <span class="gbmask">{{person.FirstName}} {{person.LastName}}</span>
                    <i *ngIf="person.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div>
                <button class="person addNew" (click)="addNewPerson()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add New</span>
                </button>
            </div>
        </div>
        <div *ngIf="!isSelectPerson || vm.IsAgent">
            <div *ngIf="isOther || vm.IsAgent" class="row">
                <div class="col-lg-6 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(FirstName)">First Name</label><i [ngClass]="childHasInvalid(FirstName)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <input type="text" Validate OnlyLetters required name="FirstName" ngModel="FirstName" #FirstName="ngModel" [(ngModel)]="selectedPerson.FirstName" />
                </div>
                <div class="col-lg-2 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label>MI</label>
                    </div>
                    <input type="text" OnlyLetters Validate name="MiddleName" [(ngModel)]="selectedPerson.MiddleName" #MiddleName="ngModel" />
                </div>
                <div class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(LastName)">Last Name</label><i [ngClass]="childHasInvalid(LastName)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <input type="text" name="LastName" OnlyLetters Validate required ngModel="LastName" #LastName="ngModel" [(ngModel)]="selectedPerson.LastName" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="addressSetIsInvalid(Address, City, State, Zip)">Address</label>
                        <i [ngClass]="addressSetIsInvalid(Address, City, State, Zip)" class="fas fa-asterisk" title="*Required field"></i>
                    </div>
                </div>
                <div class="col-lg-12 col-xs-12">
                    <input Validate type="text" required placeholder="Address" name="Address" [(ngModel)]="selectedPerson.Address.Address1" #Address="ngModel" />
                </div>
                <div class="col-lg-6 col-xs-12">
                    <input Validate OnlyLetters type="text" required name="City" [(ngModel)]="selectedPerson.Address.City" #City="ngModel" />
                </div>
                <div class="col-lg-2 col-xs-12">
                    <select [(ngModel)]="selectedPerson.Address.State" required Validate #State="ngModel" name="State" class="state gbmask">
                        <option [ngValue]="null">State</option>
                        <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                    </select>
                </div>
                <div class="col-lg-4 col-xs-12">
                    <input OnlyNumbers type="text" name="Zip" required Validate [(ngModel)]="selectedPerson.Address.Zip" #Zip="ngModel" />
                </div>
            </div>
            <div class="row justify-content-center" *ngFor="let phone of this.selectedPerson.ContactInfo.Phones; trackBy: track; let i = index">
                <div class="col-lg-5 col-xs-12" *ngIf="phone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Phone)">Phone Number</label><i [ngClass]="childHasInvalid(Phone)" class="fas fa-asterisk" title="*Required field"></i>
                    </div>
                    <input type="tel" mask="(000) 000-0000" required name="MultiPhone{{i}}" [(ngModel)]="phone.PhoneNumber" #Phone="ngModel" />
                    <a class="normalLink" *ngIf="canDisplayRemoveButton()" (click)="removePhone(i)"><u>Remove</u></a>
                </div>
                <div class="col-lg-6 col-xs-12" *ngIf="phone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(MultiPhoneType)">Phone Type</label><i [ngClass]="childHasInvalid(MultiPhoneType)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <select [(ngModel)]="phone.PhoneType" name="MultiPhoneType{{i}}" #MultiPhoneType="ngModel" required>
                        <option [ngValue]="null">Select Phone Type</option>
                        <option *ngFor="let pt of availablePhoneTypes(phone) trackBy: track;" [ngValue]="pt.DisplayCode">{{ pt.DisplayValue }}</option>
                    </select>
                </div>
            </div>
            <div class="row" *ngIf="canAddPhone();">
                <div>
                    <button class="phone addNew" (click)="addPhone();">
                        <i class="fas fa-plus-circle"></i>
                        <span>Add Phone</span>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Email)">Email</label><i [ngClass]="childHasInvalid(Email)" class="fas fa-asterisk" title="*Required field"></i>
                    </div>
                    <input type="email" email Validate placeholder="example@example.com" name="Email" required [(ngModel)]="this.selectedPerson.ContactInfo.Emails[0].EmailAddress" #Email="ngModel" />
                </div>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="cancel()" *ngIf="!isSelectPerson">Back</button>
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()" *ngIf="isSelectPerson">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="saveContactInfo(claimReporterForm)" *ngIf="!isSelectPerson">Continue</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(claimReporterForm)" *ngIf="isSelectPerson">Continue</button>
    </div>
</div>