import { Component, Input, EventEmitter, SimpleChange } from "@angular/core";
import { BaseComponent } from "../../../ClaimsWeb.Areas.Common/app/base/base.component";
import { SessionService, ConstantsService } from "../../../ClaimsWeb.Areas.Common/app/services";
import { ClaimModel, AddressModel, SessionModel, LossAddressModel, DashboardModel, ExposureModel } from '@ClaimsModels/index';
import { MyClaimsApi } from '@MyClaims360/services';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneDirective } from '../directives/phone.directive';

@Component({
    selector: 'contacts',
    templateUrl: '../views/contacts.component.html',
    styleUrls: ['../styles/mc360styling.css']
})

export class ContactsComponent extends BaseComponent {
    @Input() claimModelData;
    private sessionId: string;
    private session: SessionModel;
    public vm: ClaimModel = new ClaimModel();

    public brand: string;
    public phoneNumber: string = "";
    public emailAddress: string = "";

    public contacts: any[] = [];

    constructor(private sessionService: SessionService, private constants: ConstantsService,
        private myClaimsApi: MyClaimsApi, private route: ActivatedRoute) {
        super();
    }


    public ngOnInit() {

        this.vm.LossAddress = new AddressModel();
        this.session = this.sessionService.getSession();
    }

    ngOnChanges(changes: SimpleChange) {
        let change: SimpleChange = changes['claimModelData'];
        // console.log(change);
        if (change.currentValue != null) {
            this.vm = change.currentValue;
            let isVehicle = this.IsVehicleClaimType();

            //#37790
            this.RoleAssignment(isVehicle);
            this.PhoneAndEmailAssignment(isVehicle);


            const unique = (arr, props = []) => [...new Map(arr.map(entry => [props.map(k => entry[k]).join('|'), entry])).values()];
            const uniqueContacts = unique(this.vm.Exposures, ['AdjusterFirstName', 'AdjusterLastName', 'AdjusterPhone','AdjusterEmail']);

            this.contacts = uniqueContacts;

        }
    }

    public IsVehicleClaimType(): boolean {
        let isVehicle = false;

        if ((this.constants.ClaimTypeSingleVehicle.indexOf(this.vm.ClaimType) > -1) ||
            (this.constants.ClaimTypeMultiVehicle.indexOf(this.vm.ClaimType) > -1) ||
            (this.constants.ClaimTypePedestrian.indexOf(this.vm.ClaimType) > -1) ||
            (this.constants.ClaimTypeMechanicalBreakdown.indexOf(this.vm.ClaimType) > -1) ||
            (this.constants.ClaimTypeWeather.indexOf(this.vm.ClaimType) > -1) ||
            (this.constants.ClaimTypeRVMisc.indexOf(this.vm.ClaimType) > -1) ||
            (this.constants.ClaimTypeGlass.indexOf(this.vm.ClaimType) > -1) ||
            (this.constants.ClaimTypeTheftVandalismFire.indexOf(this.vm.ClaimType) > -1))
        {
            isVehicle = true;
        }      

        return isVehicle;        
    }

    public RoleAssignment(isVehicle: boolean): void {
        if (isVehicle) {
            for (let key in this.vm.Exposures) {
                let child = this.vm.Exposures[key];
                switch (child.AssignmentType) {
                    case this.constants.UnofficialCoverageMaterialDamageFirstParty: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.VehicleAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoverageMaterialDamageThirdParty: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.VehicleAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoverageMedicalPayments: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.InjuryAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoveragePersonalInjuryProtection: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.InjuryAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoverageBodilyInjury: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.InjuryAdjuster;
                        break;
                    }
                    default: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.VehicleAdjuster;
                        break;
                    }
                }
            }
        } else {
            // HOME CLAIM TYPE
            for (let key in this.vm.Exposures) {
                let child = this.vm.Exposures[key];
                switch (child.AssignmentType) {
                    case this.constants.UnofficialCoverageMaterialDamageFirstParty: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.PropertyAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoverageMaterialDamageThirdParty: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.PropertyAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoverageMedicalPayments: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.InjuryAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoveragePersonalInjuryProtection: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.InjuryAdjuster;
                        break;
                    }
                    case this.constants.UnofficialCoverageBodilyInjury: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.InjuryAdjuster;
                        break;
                    }
                    default: {
                        this.vm.Exposures[key].AssignedToRole = this.constants.PropertyAdjuster;
                        break;
                    }
                }
            }
        }
    }

    public PhoneAndEmailAssignment(isVehicle: boolean) {

        for (let key in this.vm.Exposures) {
            let child = this.vm.Exposures[key];

            if (child.AdjusterFirstName != null || child.AdjusterLastName != null) {
                continue;
            } else {

                switch (this.claimModelData.ConsumerBrand) {
                    case this.constants.NationalGeneral: {
                        this.vm.Exposures[key].AdjusterEmail = this.constants.ClaimsEmail.Default;
                        if (isVehicle) {
                            this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.Default;
                        }
                        else {
                            this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.NationalGeneralHome;
                        }

                        break;
                    }
                    case this.constants.Encompas: {
                        this.vm.Exposures[key].AdjusterEmail = this.constants.ClaimsEmail.Encompass;
                        this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.Encompass;
                        break;
                    }
                    case this.constants.Esurance: {
                        this.vm.Exposures[key].AdjusterEmail = this.constants.ClaimsEmail.Esurance;
                        this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.Esurance;
                        break;
                    }
                    case this.constants.FarmersUnion: {
                        this.vm.Exposures[key].AdjusterEmail = this.constants.ClaimsEmail.Default;
                        if (isVehicle) {
                            this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.Default;
                        }
                        else {
                            this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.FarmersUnionHome;
                        }

                        break;
                    }
                    case this.constants.SafeAuto: {
                        this.vm.Exposures[key].AdjusterEmail = this.constants.ClaimsEmail.SafeAuto;
                        if (isVehicle) {
                            this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.SafeAuto;
                        }
                        else {
                            this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.Default;
                        }

                        break;
                    }
                    default: {
                        this.vm.Exposures[key].AdjusterEmail = this.constants.ClaimsEmail.Default;
                        this.vm.Exposures[key].AdjusterPhone = this.constants.ClaimsPhone.Default;
                    }
                }
            }
        }

    }
}