import { Component } from '@angular/core';
import { BreadcrumbService } from '@Common/services/breadcrumb.service';
import { Breadcrumb } from '@ClaimsModels/index';
import { BaseComponent } from '../base/base.component';


@Component({
    selector: 'breadcrumb',
    templateUrl: '../views/breadcrumb.component.html'
})
export class BreadcrumbComponent extends BaseComponent {

    public breadcrumbs: Breadcrumb[];
    public mobileBreadcrumb: Breadcrumb;

    public ngOnInit(): void {
        this.getBreadcrumbs();
        this.getMobileBreadcrumb();
    }

    constructor(private breadcrumbService: BreadcrumbService) {
        super();
    }

    private getBreadcrumbs() {
        return this.breadcrumbService.getBreadcrumbs().subscribe(breadcrumbs => {
            this.breadcrumbs = breadcrumbs;
        });
    }

    private getMobileBreadcrumb() {
        return this.breadcrumbService.getMobileBreadcrumb().subscribe(mobileBreadcrumb => {
            this.mobileBreadcrumb = mobileBreadcrumb;
        });
    }
}