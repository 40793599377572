import { Component } from "@angular/core";
import { FNOLRoutingService, FNOLAutoService, ParticipantApi } from "@FNOLAuto/services";
import { Ng4LoadingSpinnerService } from "@Common/external/spinner";
import { ConstantsService } from "@Common/services";
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { PedestrianInsuredModel, ParticipantModel, PedestrianModel, PedestrianInformationModel, PhoneModel, ValueDetailModel, ContactInfoModel, AddressModel, EmailModel } from "@ClaimsModels/index";
import { NgForm } from "@angular/forms";
import { BaseContactFormComponent } from "@Common/base/baseContactForm.component";
import * as moment from "moment";
import { empty } from "rxjs";

@Component({
    selector: 'selectPedestrian',
    templateUrl: '../../views/participant/selectPedestrian.component.html',
    providers: [FNOLRoutingService]
})

export class SelectPedestrianComponent extends BaseContactFormComponent {

    constructor(private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, public fnolAutoService: FNOLAutoService,
        private constantsService: ConstantsService, private api: ParticipantApi) {
        super();
    }

    public vm: PedestrianModel = new PedestrianModel();
    public unknown: PedestrianInformationModel = new PedestrianInformationModel();
    public contactRole: string = this.fnolAutoService.getClaimTypeCode() == "PEDDRVR" ? this.constantsService.Claimant : this.constantsService.Insured;
    public isScreenOne: boolean = this.contactRole == this.constantsService.Insured ? true : false;
    public isClaimant: boolean = this.contactRole == this.constantsService.Claimant ? true : false;
    public injured: boolean = null;
    public dateOfBirth: string = this.constantsService.EmptyString;
    public email: string = this.constantsService.EmptyString;
    public canAddNew: boolean = true;
    public isNewPedestrian: boolean = false;
    public personIndex: number;
    public noPedestrianSelected: boolean = false;
    public isPedestrian: boolean;

    public ngOnInit(): void {
        this.spinner.show();
        if (this.constantsService.ClaimTypePedestrian.indexOf(this.fnolAutoService.getClaimTypeCode())>-1)
            this.isPedestrian = true;
        else 
            this.isPedestrian = false;
        this.vm = new PedestrianModel();
        this.vm.Persons = [];
        this.vm.Pedestrian = new PedestrianInformationModel();
        this.initializePedestrianModel(this.vm.Pedestrian);

        this.api.getPedestrianModel().then(response => {

            this.vm = response;
            if (this.isClaimant) {
                this.vm.Pedestrian = response.Persons.find(x => x.ParticipantRole == this.constantsService.Claimant)
                if (this.vm.Pedestrian == undefined) {
                    this.vm.Pedestrian = new PedestrianInformationModel();
                    this.vm.Pedestrian.Address = new AddressModel();
                    this.vm.Pedestrian.ContactInfo = new ContactInfoModel();
                }
                this.populatePedestrianFields();
            }
            this.vm.Persons = response.Persons.filter(x => x.ParticipantRole != this.constantsService.Claimant);

        })
        this.spinner.hide();
    }

    public goingBackWithLocation(): void {
        if (!this.isScreenOne) {
            if (this.isClaimant) {
                this.fnolRouting.back();
                return;
            }
            if (!this.isNewPedestrian && !this.isClaimant) {
                this.vm.Persons[this.personIndex].IsSelected = false;
            }
            //this.vm.Pedestrian = new PedestrianInformationModel();
            //this.email = this.constantsService.EmptyString;
            //this.dateOfBirth = this.constantsService.EmptyString;
            //this.vm.Pedestrian.IsSelected = null;
            //this.isNewPedestrian = false;
            this.isScreenOne = !this.isScreenOne;

            this.initializePedestrianModel(this.vm.Pedestrian);
            return;
        }
        this.fnolRouting.back();
    }

    public addUnknown(): void {
        this.fnolAutoService.setUnknownPedestrian("true");
    }

    public submit(form: NgForm): void {
        this.spinner.show();
        this.vm.Pedestrian.AccidentRole = this.constantsService.AccidentRolePedestrian;
        if (this.vm.Pedestrian.IsSelected == false) {
            this.addUnknown();
        }
        if (this.isScreenOne) {
            this.spinner.hide();
            this.vm.Persons.forEach(x => x.IsOnPolicy = true);
            this.api.savePedestrianModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.canAddNew = false;
                    this.fnolRouting.next({ id: "0" });
                }
            })
            return;
        }
        if (!this.validate(form)) {
            this.spinner.hide();
            return;
        }
        if (!this.IsNullOrEmptyString(this.dateOfBirth)) {
            this.vm.Pedestrian.DateOfBirth = this.convertToUTCDate(this.dateOfBirth);
        }

        this.mapPhoneAndEmail(this.vm.Pedestrian.ContactInfo);
        this.vm.Pedestrian.IsInjured = this.injured;
        this.vm.Pedestrian.IsOnPolicy = true;
        this.vm.Pedestrian.ParticipantRole = this.contactRole;
        if (this.vm.Pedestrian.IsInjured === false) {
            this.vm.Pedestrian.InjuryDescription = "";
        }
        if (this.isNewPedestrian === true) {
            this.vm.Persons.push(this.vm.Pedestrian);
        }
        if (this.isClaimant) {
            this.vm.Pedestrian.IsOnPolicy = false;
            this.vm.Pedestrian.IsSelected = true;

            const claimantIndex: number = this.vm.Persons.findIndex(x => x.PolicyPersonId == this.vm.Pedestrian.PolicyPersonId)
            if (claimantIndex > -1) {
                this.vm.Persons[claimantIndex] = this.vm.Pedestrian;
            } else {
                this.vm.Persons.push(this.vm.Pedestrian);
            }
            this.api.savePedestrianModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.canAddNew = false;
                    this.fnolRouting.next({ id: "0" });
                    return;
                }
            });
            return;
        }
        if (this.injured !== null && this.isScreenOne === false) {
            this.spinner.hide();
            this.vm.Persons.forEach(x => x.IsOnPolicy = true);
            this.api.savePedestrianModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.canAddNew = false;
                    this.fnolRouting.next({ id: "0" });
                }
            })
            return;
        }
        this.isNewPedestrian = false;
        this.isScreenOne = true;
        this.spinner.hide();

    }

    public addNew(): void {
        this.isNewPedestrian = true;
        let newPedestrian: PedestrianInformationModel = new PedestrianInformationModel();
        this.selectPerson(newPedestrian);
    }

    public selectPerson(person: PedestrianInformationModel, index?: number) {
        this.vm.Persons.filter(x => x != person).forEach(x => x.IsSelected = false);
        person.IsSelected = !person.IsSelected;
        this.personIndex = index;

        if (person.IsSelected) {
            this.noPedestrianSelected = false;
            this.vm.Pedestrian = person;

            this.populatePedestrianFields();

            this.vm.Pedestrian = this.initializePedestrianModel(this.vm.Pedestrian);
            this.isScreenOne = !this.isScreenOne;
        }
    }

    public populatePedestrianFields(): void {
        this.injured = this.determineIfInjured(this.vm.Pedestrian.InjuryDescription);

        if (this?.vm?.Pedestrian?.DateOfBirth != null) {
            this.dateOfBirth = moment(this.vm.Pedestrian.DateOfBirth).format("MM/DD/YYYY");
        } else {
            this.dateOfBirth = this.constantsService.EmptyString;
        }
        if (this.vm.Pedestrian.ContactInfo != null &&
            this.vm.Pedestrian.ContactInfo.Emails != null &&
            this.vm.Pedestrian.ContactInfo.Emails.length > 0) {
            this.email = this.vm.Pedestrian.ContactInfo.Emails[0].EmailAddress;
        }
    }

    //assign empty contactInfo and Address models to use in the contact form inputs if they return as null.
    public initializePedestrianModel(model: PedestrianInformationModel): PedestrianInformationModel {
        if (model.ContactInfo == null) {
            let placeholderContactInfo: ContactInfoModel = {
                Phones: [],
                Emails: [],
            }
            model.ContactInfo = placeholderContactInfo;
        }
        if (model.Address == null) {
            let placeholderAddress: AddressModel = {
                Address1: "",
                Address2: "",
                State: null,
                City: "",
                Zip: "",
                AddressType: "",
                Country: "",
                County: ""
            }
            model.Address = placeholderAddress;
        }
        return model;
    }

    public canAddPhone(): boolean {
        if (this.vm.Pedestrian.ContactInfo.Phones.length >= 3) {
            return false;
        }
        return true;
    }

    public availablePhoneTypes(phone: PhoneModel): ValueDetailModel[] {
        let activePhones = this.vm.Pedestrian.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public addPhone(): void {
        let newPhone = new PhoneModel();
        newPhone.PhoneNumber = null;
        newPhone.PhoneType = null;
        newPhone.IsActive = true;
        this.vm.Pedestrian.ContactInfo.Phones.push(newPhone);
    }

    public removePhone(index: number): void {
        this.vm.Pedestrian.ContactInfo.Phones.splice(index, 1);
    }

    public track(index: number) {
        return index;
    }

    private mapPhoneAndEmail(contactinfo: ContactInfoModel) {
        let emailType: string = null;

        if (contactinfo.Emails != null && contactinfo.Emails.length > 0) {
            emailType = contactinfo.Emails[0].EmailType;
        }

        contactinfo.Emails = [];

        let emailModel = new EmailModel();
        emailModel.EmailAddress = this.email;
        emailModel.EmailType = emailType != null ? emailType : this.constantsService.EmailTypePER;
        contactinfo.Emails.push(emailModel);
    }
}