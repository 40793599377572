import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { FNOLRoutingService, ParticipantApi, FNOLAutoService } from '@FNOLAuto/services';
import { ParticipantModel, VehicleDriverModel, VehicleInfoModel } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services';

@Component({
    selector: 'selectVehicleDriver',
    templateUrl: '../../views/participant/selectVehicleDriver.component.html',
    providers: [FNOLRoutingService]
})
export class SelectVehicleDriver extends BaseFormComponent {

    public vm: VehicleDriverModel = new VehicleDriverModel();
    public unknown: ParticipantModel = new ParticipantModel();
    public noDriverSelected: boolean = false;
    public hasAlreadySelected: boolean = false;
    public displayAlreadySelectedMessage: boolean = false;
    private vehicleId: number;

    public isThirdParty: boolean = false;

    constructor(private fnolRouting: FNOLRoutingService, private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private fnolAutoService: FNOLAutoService,
                private constantsService: ConstantsService) {
        super();
    }

    public addNew(): void {
        this.fnolAutoService.setUnknownDriver(this.constantsService.FalseStringValue);
        if (this.noDriverSelected) {
            this.noDriver();
        }

        if (this.unknown.IsSelected) {
            this.addUnknown();
        }

        if (this.isThirdParty) {
            this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment`,
                { queryParams: { assignmentType: this.constantsService.ThirdPartyDriver } });

        } else {
            this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment`,
                { queryParams: { assignmentType: this.constantsService.Driver.toLowerCase() } });
        }
    }

    public addUnknown(): void {
        if (this.isThirdParty) {
            this.unknown.IsSelected = !this.unknown.IsSelected;
            this.fnolAutoService.setUnknownClaimantDriver(this.unknown.IsSelected.toString());

        } else {
            this.unknown.IsSelected = !this.unknown.IsSelected;
            this.fnolAutoService.setUnknownDriver(this.unknown.IsSelected.toString());
        }
    }

    public noDriver(): void {
        this.noDriverSelected = !this.noDriverSelected;
        if (this.isThirdParty) {
            this.fnolAutoService.setNoClaimantDriverSelected(this.noDriverSelected.toString());
        } else {
            this.fnolAutoService.setNoDriverSelected(this.noDriverSelected.toString());
        }

        if (this.unknown.IsSelected) {
            this.addUnknown();
        }
    }

    public ngOnInit(): void {
        this.vm = new VehicleDriverModel();
        this.vm.VehicleInfo = new VehicleInfoModel();
        this.vm.DriverOptions = [];
        this.vehicleId = this.fnolRouting.getRoute().snapshot.params.vehicleId;

        this.checkNoDriver();
        this.checkUnknownDriver();

        this.spinner.show();
        this.api.getVehicleDriverModel(this.vehicleId).then(response => {
            this.spinner.hide();
            this.vm = response;

            if (!this.vm.VehicleInfo.VehicleClaimInfo.IsUnlisted && !this.vm.VehicleInfo.VehicleClaimInfo.IsOnPolicy) {
                this.isThirdParty = true;
                this.checkNoDriver();
                this.checkUnknownDriver();
            }

            // if anybody is selected as a driver, turn off the no driver box
            if (this.vm.DriverOptions.filter(x => x.IsSelected).length > 0) {
                this.hasAlreadySelected = true;
                this.fnolAutoService.setUnknownDriver(this.constantsService.FalseStringValue);
            }

            if (this.isThirdParty) {
                let thirdPartyOwnerId = this.vm.VehicleInfo.VehicleClaimInfo.OwnerContactId;
                let thirdPartyDriverId = this.vm.VehicleInfo.VehicleClaimInfo.DriverContactId;

                this.vm.DriverOptions = this.vm.DriverOptions.filter(x => x.Id === thirdPartyOwnerId || x.Id === thirdPartyDriverId);
            } else {
                this.vm.DriverOptions = this.vm.DriverOptions.filter(x => x.ContactRole !== this.constantsService.Claimant);
            }
        });
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public shouldDisplayNoDriver(): boolean {
        let noDriverService: string = this.constantsService.EmptyString;

        if (this.isThirdParty) {
            noDriverService = this.fnolAutoService.getNoClaimantDriverSelected();
        } else {
            noDriverService = this.fnolAutoService.getNoDriverSelected();
        }

        if (this.noDriverSelected) {
            return true;
        }

        if (noDriverService == this.constantsService.HideUnknownOption || this.hasAlreadySelected) {
            return false;
        }

        return true;
    }

    public checkNoDriver(): void {
        let noDriver: string = this.constantsService.EmptyString;

        if (this.isThirdParty) {
            noDriver = this.fnolAutoService.getNoClaimantDriverSelected();
        } else {
            noDriver = this.fnolAutoService.getNoDriverSelected();
        }

        if (noDriver == null || noDriver == undefined || noDriver != this.constantsService.TrueStringValue) {
            this.noDriverSelected = false;
        }

        if (noDriver == this.constantsService.TrueStringValue) {
            this.noDriverSelected = true;
        }
    }

    public submit(form: NgForm): void {

        let driversSelected: ParticipantModel[] = this.vm.DriverOptions.filter(x => x.IsSelected);

        // need to assess these validations
        if (driversSelected.length == 0 && this.hasAlreadySelected && !this.noDriverSelected) {
            this.displayAlreadySelectedMessage = true;
            return;
        }

        if (driversSelected.length == 0 && !this.unknown.IsSelected && !this.hasAlreadySelected && !this.noDriverSelected) {
            this.addUnknown();
        }

        if (driversSelected.length == 1 && !this.noDriverSelected) {
            // turning off this feature
            this.fnolAutoService.setNoDriverSelected(this.constantsService.HideUnknownOption);
        }

        if ((driversSelected.length == 0 || this.hasAlreadySelected) && this.isThirdParty) {
            this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/thirdPartyHasPassengers`);
            return;
        }

        this.fnolRouting.next({
            vehicleId: this.vehicleId.toString()
        });
    }

    public selectPerson(person: ParticipantModel) {
        person.IsSelected = !person.IsSelected;

        // call these functions to set it back to false and update the session variable
        if (this.noDriverSelected) {
            this.noDriver(); 
        }

        if (this.unknown.IsSelected) {
            this.addUnknown();
        }

        if (person.IsSelected) {
            if (person.IsOnPolicy) {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/assignment/${person.Id}`,
                    { queryParams: { assignmentType: this.constantsService.Driver.toLowerCase() } });
            } else if (this.isThirdParty) {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment/${person.Id}`,
                    { queryParams: { assignmentType: this.constantsService.ThirdPartyDriver } });
            } else {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment/${person.Id}`,
                    { queryParams: { assignmentType: this.constantsService.Driver.toLowerCase() } });
            }
        }
    }

    public checkUnknownDriver(): void {
        let unknownDriver: string = this.constantsService.EmptyString;

        if (this.isThirdParty) {
            unknownDriver = this.fnolAutoService.getUnknownClaimantDriver();
        } else {
            unknownDriver = this.fnolAutoService.getUnknownDriver();
        }

        if (unknownDriver == null || unknownDriver == undefined || unknownDriver != this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = false;
        }

        if (unknownDriver == this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = true;
        }
    }
}