<main id="main" *ngIf="showErrors">
    <div class="main-inner-wrapper">
        <div class="input-wrapper">
            <div class="row justify-content-center">
                <div class="col col-lg-12 col-xs-12 ">
                    <div *ngFor="let error of errors">
                        <p style="font-size: 1em" class="red">{{error}}</p>
                        <p style="font-size: 1em"  
                           *ngIf="error === ''" class="red">An error occured processing your Claim. Please call 
                            <a href="tel:+1{{ contactNumber }}">{{ contactNumber | mask: '(000) 000-0000' }}</a> for assistance.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
