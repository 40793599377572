




import { BaseModel } from './BaseModel';

import { BaseContactInfoModel } from './BaseContactInfoModel';
import { AddressModel } from './AddressModel';
import { BaseParticipantClaimDetailsModel } from './BaseParticipantClaimDetailsModel';
import { DisplayModeEnum } from './DisplayModeEnum';
import { ValueDetailModel } from './ValueDetailModel';
import { InsuranceInformationModel } from './InsuranceInformationModel';

export class BaseLossParticipantModel extends BaseModel {
    
    public LossParticipantId: number = 0;
    public FirstName: string = null;
    public LastName: string = null;
    public MiddleName: string = null;
    public Prefix: string = null;
    public Suffix: string = null;
    public DateOfBirth: Date = null;
    public Gender: string = null;
    public DriverSequence: number = 0;
    public IsFullTimer: boolean = false;
    public ContactInfo: BaseContactInfoModel = null;
    public Address: AddressModel = null;
    public ParticipantClaimDetails: BaseParticipantClaimDetailsModel = null;
    public DisplayMode: DisplayModeEnum = null;
    public States: ValueDetailModel[] = [];
    public PrimaryContactAddress: AddressModel = null;
    public IsPrimaryContact: boolean = false;
    public IsPrimaryAddressAvailable: boolean = false;
    public InsuranceInformation: InsuranceInformationModel = null;
    public VehicleRole: string = null;
    public PhoneTypes: ValueDetailModel[] = [];
    public FixedPropertyId: number = 0;
    public IsOnPolicy: boolean = false;
}