




import { BaseModel } from './BaseModel';

export class RVVehicleClaimInfoModel {
    public Dmg1Front: boolean = null;
    public Dmg1Top: boolean = null;
    public Dmg1RightSide: boolean = null;
    public Dmg1LeftSide: boolean = null;
    public Dmg1Hood: boolean = null;
    public Dmg1LeftRear: boolean = null;
    public Dmg1LeftRearDoor: boolean = null;
    public Dmg1RightRearDoor: boolean = null;
    public Dmg1RightRearSidePanel: boolean = null;
    public Dmg1LeftRearSidePanel: boolean = null;
    public Dmg1LeftQuarterPiece: boolean = null;
    public Dmg1RightQuarterPiece: boolean = null;
    public Dmg1LeftQuarterRearPiece: boolean = null;
    public Dmg1RightQuarterRearPiece: boolean = null;
    public IsDriveable: boolean = null;
    public DamageSeverityLevel: string = null;
    public IsAirbagDeployed: boolean = null;
    public Dmg2Trunk: boolean = null;
    public Dmg1Rear: boolean = null;
    public Dmg1RightFront: boolean = null;
    public Dmg1LeftFront: boolean = null;
    public IsRecoveredFromTheft: boolean = null;
    public IsInteriorDamage: boolean = null;
    public IsEngineRunning: boolean = null;
}

export class VehicleClaimInfoModel extends BaseModel {
    
    public Dmg1RightFront: boolean = null;
    public Dmg1RightSide: boolean = null;
    public Dmg1RightRear: boolean = null;
    public Dmg1Undercarriage: boolean = null;
    public Dmg1Top: boolean = null;
    public Dmg1Hood: boolean = null;
    public Dmg1RollOver: boolean = null;
    public Dmg1Mechanical: boolean = null;
    public Dmg1LeftRear: boolean = null;
    public Dmg2Front: boolean = null;
    public Dmg2Side: boolean = null;
    public Dmg2Hood: boolean = null;
    public Dmg2Trunk: boolean = null;
    public Dmg2Roof: boolean = null;
    public DmgCauseFlood: boolean = null;
    public DmgCauseWind: boolean = null;
    public DmgCauseHail: boolean = null;
    public DmgCauseFallObjOther: boolean = null;
    public Dmg2Rear: boolean = null;
    public Dmg1LeftSide: boolean = null;
    public Dmg1LeftFront: boolean = null;
    public Dmg1Rear: boolean = null;
    public Dmg1LeftFender: boolean = null;
    public Dmg1RightFender: boolean = null;
    public Dmg1LeftQuarterPiece: boolean = null;
    public Dmg1RightQuarterPiece: boolean = null;
    public VehicleLocationType: string = null;
    public IsRental: boolean = null;
    public IsDriveable: boolean = null;
    public IsRepairable: boolean = null;
    public DamageDescription: string = null;
    public IsOnPremise: boolean = null;
    public IsUninhabitable: boolean = null;
    public IsEstimate: boolean = null;
    public EstimateAmount: number = null;
    public WaterCleanUpComp: string = null;
    public IsAirbagDeployed: boolean = null;
    public IsEngineRunning: boolean = null;
    public IsInteriorDamage: boolean = null;
    public DamageSeverityLevel: string = null;
    public VehicleCanBeMoved: boolean = null;
    public DamagesPrimary: string = null;
    public DamagesSecondary: string = null;
    public Dmg1Front: boolean = null;
    public DmgCauseVandTheft: boolean = null;
    public IsTheft: boolean = null;
    public RideShareUse: boolean = null;
    public DeliveryServiceUse: boolean = null;
    public DriverContactId: number = null;
    public OwnerContactId: number = null;
    public IsUnlisted: boolean = false;
    public IsOnPolicy: boolean = false;
    public IsActive: boolean = false;
    public IsRecoveredFromTheft: boolean = null;
    public RecoveryDate: Date = null;
    public VehicleLocationDescription: string = null;
    public HasKeys: boolean = null;
    public WindowUpDuringTheft: boolean = null;
    public LockedDuringTheft: boolean = null;
    public DamagedPriorToTheft: boolean = null;
    public DamagePriorToTheftDescription: string = null;
    public IsWindshieldOnlyDamages: boolean = null;
    public IsWindshieldTwoPieces: boolean = null;
    public IsDamagesOtherThanWindshield: boolean = null;
    public Dmg1LeftRearDoor: boolean = null;
    public Dmg1RightRearDoor: boolean = null;
    public Dmg1RightRearSidePanel: boolean = null;
    public Dmg1LeftRearSidePanel: boolean = null;
    public Dmg1LeftQuarterRearPiece: boolean = null;
    public Dmg1RightQuarterRearPiece: boolean = null;
    public GlassDamageAreaDescription: string = null;
}