import { Injectable } from '@angular/core';
import { SessionService } from './session/session.service';
import { ConstantsService } from './constants.service';
import { BrandEnum } from "@ClaimsModels/index";


@Injectable({
    providedIn: 'root'
})
export class ContactService {

    constructor(private constantsService: ConstantsService, private session: SessionService) {
    }

    public getClaimsEmail(): string {
        let email: string = this.constantsService.ClaimsEmail.Default;
        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.EncompassInsurance:
                email = this.constantsService.ClaimsEmail.Encompass;
                break;
            case BrandEnum.Esurance: 
                email = this.constantsService.ClaimsEmail.Esurance;
                break;
            case BrandEnum.SafeAuto:
                email = this.constantsService.ClaimsEmail.SafeAuto;
                break;
            default:
                email = this.constantsService.ClaimsEmail.Default;
                break;
        }

        return email;
    }

    public getClaimsPhone(): string {
        let phone: string = this.constantsService.ClaimsPhone.Default;
        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.EncompassInsurance:
                phone = this.constantsService.ClaimsPhone.Encompass;
                break;
            case BrandEnum.Esurance:
                phone = this.constantsService.ClaimsPhone.Esurance;
                break;
            case BrandEnum.SafeAuto:
                phone = this.constantsService.ClaimsPhone.SafeAuto;
                break;
            case BrandEnum.Custom360:
                phone = this.constantsService.ClaimsPhone.Custom360;
                break;
            default:
                phone = this.constantsService.ClaimsPhone.Default;
                break;
        }

        return phone;
    }

    public GetRoadSidePhoneNumber(): string {
        let phoneNumber: string = this.constantsService.RoadsidePhoneNumbers.Default;
        let brand: string = this.session.getBrand();

        switch (brand) {
            case BrandEnum.AIE:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Adirondack;
                break;
            case BrandEnum.NJSI:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.NJSkylands;
                break;
            case BrandEnum.EncompassInsurance:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Encompass;
                break;
            case BrandEnum.Esurance:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Esurance;
                break;
            case BrandEnum.SafeAuto:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.SafeAuto;
                break;
            case BrandEnum.Custom360:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Custom360;
                break;
            default:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Default;
                break;
        }

        return phoneNumber;
    }

    public getGlassContactNumber(): string {
        let phone: string = this.constantsService.GlassPhoneNumbers.Default;
        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.EncompassInsurance:
                phone = this.constantsService.GlassPhoneNumbers.Encompass;
                break;
            case BrandEnum.Custom360:
                phone = this.constantsService.GlassPhoneNumbers.Custom360;
                break;
            default:
                phone = this.constantsService.GlassPhoneNumbers.Default;
                break;
        }

        return phone;
    }

    public getContactNumber(policySubType: string = null): string {
        let phoneNumber = this.constantsService.OtherContactNumber;

        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.Farmers:
                phoneNumber = this.constantsService.FarmersContactNumber;
                break;
            case BrandEnum.EncompassInsurance:
                phoneNumber = this.constantsService.EncompassContactNumber;
                break;
            case BrandEnum.NationalGeneral:
                phoneNumber = this.getContactNumberByPolicySubType(policySubType, this.constantsService);
                break;
            case BrandEnum.Esurance:
                phoneNumber = this.constantsService.EsuranceContactNumber;
                break;
            case BrandEnum.SafeAuto:
                phoneNumber = this.constantsService.SafeAutoContactNumber;
                break;
            case BrandEnum.Custom360:
                phoneNumber = this.constantsService.Custom360ContactNumber;
                break;
            default:
                phoneNumber = this.constantsService.OtherContactNumber;
                break;
        }

        return phoneNumber;
    }

    private getContactNumberByPolicySubType(policySubType: string, constants: ConstantsService): string {
        let phoneNumber: string;

        switch (policySubType) {
            case constants.Preferred:
                phoneNumber = this.constantsService.PreferredContactNumber;
                break;
            case constants.Premier:
                phoneNumber = this.constantsService.PremierContactNumber;
                break;
            case constants.Imperial:
                phoneNumber = this.constantsService.ImperialContactNumber;
                break;
            case constants.PEIC:
                phoneNumber = this.constantsService.PEICContactNumber;
                break;
            default:
                phoneNumber = this.constantsService.OtherContactNumber;
                break;
        }

        return phoneNumber;
    }
}