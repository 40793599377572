export enum ClaimTypeCategoryEnum {

    // Auto Claim Categories
    SingleVehicle,
    FixedProperty,
    VehicleAccident,
    MultipleVehicleAccident,
    Pedestrian,
    Weather,
    Theft,
    Fire,
    Awning,
    Wildfire,
    Vandalism,
    Glass,
    Mechanical,
    MechanicalBreakdown,
    RearEndedAF,
    RearEndedNAF,
    TireBlowout,
    RoadHazard,
    RoadHazardNF,
    FallingObject,
    AnimalCollision,
    LockOut,
    JumpStart,
    FlatTire,
    GasDelivery,
    Winch,
    IllegallyParkedTow,
    Impounded,
    Other,
    MiscRV,


    // Home Categories
    PersonalProperty,
    EquipmentBreakdown,
    DwellingWeatherNotRelated,
    DwellingWeatherRelated,
    OtherStructureWeatherNotRelated,
    OtherStructureWeatherRelated,
}