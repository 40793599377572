<div class="addressValidationPopUp">
    <span>Use recommended address instead?</span>
    <hr />
    <div class="addressFlex col-lg-12 col-xs-12">
        <div style="padding-bottom: 30px;">
            <label class="gbmask">{{this.data.suggestedAddress.Address1}}</label><br />
            <label class="gbmask" *ngIf="this.data.suggestedAddress.Address2 != ''" class="secondaryAddy">{{this.data.suggestedAddress.Address2}}<br /></label>
            <label class="gbmask">{{this.data.suggestedAddress.City}}, {{this.data.suggestedAddress.State}}, {{this.data.suggestedAddress.Zip}}</label>
            <div class="button-wrapper">
                <button class="btn btn-primary" (click)="this.changeAddress()" id="newBtn">Recommended</button>
            </div>
        </div>
        <div>
            <label class="gbmask">{{this.data.originalAddress.Address1}}</label><br />
            <label class="gbmask" *ngIf="this.data.originalAddress.Address2 != ''">{{this.data.originalAddress.Address2}}<br /></label>
            <label class="gbmask">{{this.data.originalAddress.City}}, {{this.data.originalAddress.State}}, {{this.data.originalAddress.Zip}}</label>
            <div class="button-wrapper">
                <button class="btn btn-primary" (click)="this.keepOriginal()" id="origBtn">Entered</button>
            </div>
        </div>
    </div>
</div>
