import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";
import { LayoutService, SessionService, ErrorHandlerService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { MyClaimsApi } from '@MyClaims360/services';
import { DashboardModel, SessionModel } from '@ClaimsModels/index';

@Component({
    selector: 'myClaimsHome',
    templateUrl: '../views/myClaimsHome.component.html',
    styleUrls: ['../styles/dashboard.component.css']
})

export class MyClaimsHomeComponent extends BaseFormComponent {
    private sessionId: string;
    private session: SessionModel;
    public vm: DashboardModel;

    constructor(private route: ActivatedRoute, private layoutService: LayoutService, private myClaimsApi: MyClaimsApi,
        private sessionService: SessionService, private router: Router) {
        super();
    }

    public ngOnInit(): void {
        if (this.sessionService.getSerializedSession() === '') {
            this.session = this.sessionService.getSession();

            this.session.IsExpired = false;

            this.sessionService.setSession(this.session);
        }

        this.sessionId = this.route.snapshot.paramMap.get("sessionId");

        this.myClaimsApi.getDashboardModel(this.sessionId).then((response) => {
            this.vm = response;
            this.setupSession(response.Session);
        }).catch((reason) => {
            throw new Error("Debug info: " + reason.toString());
        });;
    }

    public showClaimDetails(id: number): void {
        this.router.navigate(["myClaims360/claimDetails/" + id]);
    }
    public goingBackWithLocation(): void {
        throw new Error("Method not implemented.");
    }

    public submit(form: NgForm): void {
        throw new Error("Method not implemented.");
    }

    public hasPreviousClaims(): boolean {
        return this.vm.PreviousClaims.length > 0;
    }

    private setupSession(response: SessionModel) {
        response.IsExpired = this.sessionService.isSessionExpired();
        response.IsInitialized = true;
        this.sessionService.setExistingSession(response);
    }
}
