




import { BaseModel } from './BaseModel';
import { BaseReviewModel } from './BaseReviewModel'

export class HomeReviewModel extends BaseReviewModel {
    
}