<div ngForm #additionalThirdPartyForm="ngForm" class="main-inner-wrapper">
    <p class="header" *ngIf="!vm.HasAdditionalProperty">Were there any other vehicles or property involved in the incident?</p>
    <p class="header" *ngIf="vm.HasAdditionalProperty">Your assigned adjuster will be in  contact with you to obtain the other party's information.</p>

    <div class="input-wrapper" *ngIf="!vm.HasAdditionalProperty">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="additionalClaimants(true); vm.HasAdditionalProperty = true">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="additionalClaimants(false)">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" *ngIf="vm.HasAdditionalProperty" (click)="submit(additionalThirdPartyForm)">Continue</button>
    </div>
</div>