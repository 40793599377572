import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { FNOLRoutingService } from "@FNOLAuto/services";
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ContactService } from "@Common/services";

@Component({
    selector: 'uploadDocument',
    templateUrl: '../../views/loss/uploadDocument.component.html',
    providers: [FNOLRoutingService]
})
export class UploadDocumentComponent extends BaseFormComponent {

    constructor(private fnolRouting: FNOLRoutingService, private spinner: Ng4LoadingSpinnerService, private contactService: ContactService) {
        super();
    }

    public claimsEmail: string;

    public ngOnInit(): void {
        this.claimsEmail = this.contactService.getClaimsEmail();
        this.setMobileConfig();
    }

    public goingBackWithLocation(): void {
        if (sessionStorage.getItem('additionalClaimantProperty') != null)
        {
            this.fnolRouting.routeToSpecificPage('fnolAuto/additionalClaimant/'+ sessionStorage.getItem('additionalClaimantProperty'), { replaceUrl: true });
        }
        else
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {

        // todo - do we need to spin to simulate some type of "uploading document(s)"
        // for the user or do we just add in a loading bar on the actual file upload tool itself.
        // tbd.

        if (this.validate(form)) {
            this.spinner.show();
            this.spinner.hide();            
            this.fnolRouting.next();            
        }
    }
}