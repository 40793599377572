<div class="main-inner-wrapper">
    <p class="header">{{ getPageHeader() }}</p>
    <p class="h5" style="padding-bottom:10px;"><b>Call</b>: <a href="tel:+1{{ getClaimPhoneNumber() }}">1{{ getClaimPhoneNumber() | mask: "(000) 000-0000" }}</a></p>

    <ng-container *ngIf="isEncompassBrand">
        <p class="h5"><b>Email</b>: <a href="mailto:ClaimsLossReporting@EncompassIns.com" class="text-break">ClaimsLossReporting@EncompassIns.com</a></p>
    </ng-container>
    <div class="button-wrapper">
        <button id="continueBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Return</button>
    </div>
</div>