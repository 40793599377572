import { Injectable } from "@angular/core";
import { BaseApi } from "../../base/base.api";


import {
    NewClaimModel, CallInClaimModel, NewClaimThirdPartyModel
} from '@ClaimsModels/index';

@Injectable()
export class CommonClaimApi extends BaseApi {

    public getNewClaimModel(): Promise<NewClaimModel> {
        return this.httpGet("Common/Claim/GetNewClaimModel");
    }

    public saveNewClaimModel(model: NewClaimModel): Promise<NewClaimModel> {
        return this.httpPost("Common/Claim/SaveNewClaimModel", model);
    }

    public getNewClaimThirdPartyModel(): Promise<NewClaimThirdPartyModel> {
        return this.httpGet("Common/Claim/GetNewClaimThirdPartyModel");
    }

    public saveNewClaimThirdPartyModel(model: NewClaimThirdPartyModel): Promise<NewClaimThirdPartyModel> {
        return this.httpPost("Common/Claim/SaveNewClaimThirdPartyModel", model);
    }

    public getCallInClaimModel(): Promise<CallInClaimModel> {
        return this.httpGet("Common/Claim/GetCallInClaimModel");
    }
}