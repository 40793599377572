import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { VehicleApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { VehicleInfoModel, VehicleClaimInfoModel, AddressModel } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services';

@Component({
    selector: 'vehicleInfo',
    templateUrl: '../../views/vehicle/vehicleInfo.component.html',
    styleUrls: ['../../styles/vehicleInfo.component.css'],
    providers: [FNOLRoutingService]
})
export class VehicleInfoComponent extends BaseVehicleComponent {

    constructor(private api: VehicleApi, private router: Router, private route: ActivatedRoute, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService,
                private constantsService: ConstantsService, private fnolAutoService: FNOLAutoService) {
        super();

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.vm = new VehicleInfoModel();
        this.vm.LossParticipants = [];
        this.vm.VehicleClaimInfo = new VehicleClaimInfoModel();
        this.vm.VehicleLocationAddress = new AddressModel();
    }

    public vm: VehicleInfoModel = new VehicleInfoModel();

    ngOnInit(): void {
        this.spinner.show();

        let id = this.route.snapshot.params.id;
        this.api.getVehicleInfo(id).then(response => {
            this.spinner.hide();
            this.vm = response;
            let claimTypeCategory = this.fnolAutoService.getClaimTypeCategory();
            let ClaimTypeCode = this.fnolAutoService.getClaimTypeCode();

            this.setVehicleDamages(response, claimTypeCategory, ClaimTypeCode, null);

            if (this.vm.VehicleClaimInfo.DamageSeverityLevel === null ||
                this.vm.VehicleClaimInfo.DamageSeverityLevel === this.constantsService.EmptyString) {
                this.vm.VehicleClaimInfo.DamageSeverityLevel = "Level 0";
                this.damageLevel = 0;
            } else {
                let damageValue = this.vm.VehicleClaimInfo.DamageSeverityLevel.substr(6, 7);
                this.damageLevel = +damageValue;
            }
        });       
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.vm.VehicleClaimInfo.IsOnPolicy = true;
            this.vm.VehicleClaimInfo.IsActive = true;
            this.api.saveVehicleInfo(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.router.navigate(['/fnolAuto/selectVehicles']);
                }
            });
        }
    }

    public addPerson(): void {
        this.spinner.show();
        this.api.saveVehicleInfo(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                this.router.navigate(['/fnolAuto/lossParticipant']);
            }
        });
    }

    public handleDamageSlider(event: any) {
        this.damageLevel = event.value;
        this.vm.VehicleClaimInfo.DamageSeverityLevel = `Level ${event.value}`;
    }

}