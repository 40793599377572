<div class="container">

    <div class="jumbotron">
        <h1 class="display-2 text-center">Session Expired</h1>
        <hr class="my-4">
        <div class="text-center">
            <!--<h2>Uh oh...</h2>-->
            <p class="lead">
                Your session has expired, please restart your claim.
            </p>
        </div>
        <!--<div class="button-wrapper">
            <button id="continueBtn" class="btn btn-primary" (click)="return()">Back</button>
        </div>-->
    </div>

</div>