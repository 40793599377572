import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { SessionService, StyleManagerService, UnsubmittedClaimService, CommonClaimApi, ContactService } from '../services';
import { Router } from '@angular/router';
import { SessionModel, NewClaimModel, BrandEnum, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
@Component({
    selector: 'rvMessage',
    templateUrl: '../views/rvMessage.component.html'
})
export class rvMessageComponent extends BaseComponent {
    public contactNumber: string;
    private session: SessionModel;
    public vm: NewClaimModel = new NewClaimModel();
    public isEncompassBrand: boolean;
    public isSafeAutoBrand: boolean;
    public isEsuranceBrand: boolean;

    constructor(private styleManager: StyleManagerService, private unsubmittedClaim: UnsubmittedClaimService,
        private contactService: ContactService, private api: CommonClaimApi, private sessionService: SessionService,
        private router: Router) {
        super();
    }

    public ngOnInit(): void {
        this.session = this.sessionService.getSession(); 
        this.styleManager.contactPhoneNumber.subscribe(contactPhoneNumber => {
            this.contactNumber = contactPhoneNumber;
        })
        let brand = this.sessionService.getBrand();

        switch (brand) {
            case BrandEnum.SafeAuto:
                this.isSafeAutoBrand = true;
                break;
            case BrandEnum.EncompassInsurance:
                this.isEncompassBrand = true;
                break;
            case BrandEnum.Esurance:
                this.isEsuranceBrand = true;
                break;
        }
    }

    public goingBackWithLocation(): void {
        this.router.navigateByUrl('/newClaim/' + this.session.SessionId);
    }
    public getClaimPhoneNumber(): string {
        return this.contactService.getClaimsPhone();
    }
}