<div ngForm #lossParticipantForm="ngForm" class="main-inner-wrapper no-mobile">
    <p class="header" *ngIf="vm.FirstName != null && vm.LastName != null">Please provide the following information for <span class="individualsName">{{vm.FirstName}} {{vm.LastName}}.</span></p>
    <p class="header" *ngIf="vm.FirstName === null && vm.LastName === null">Please provide the following information</p>

    <div class="input-wrapper">
        <div class="row">
            <div class="col-lg-12 col-xs-12 contactName" *ngIf="isNotInEditMode else editMode">
                <span class="contactNameDisplay gbmask">{{vm.FirstName}} {{vm.MiddleName}} {{vm.LastName}}</span>
                <i class="fas fa-pen-square edit-icon" (click)="displayEditMode()"></i>
            </div>
            <ng-template #editMode>
                <div class="col-lg-5 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(FirstName)">First Name</label><i [ngClass]="childHasInvalid(FirstName)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <input OnlyLetters required type="text" name="FirstName" Validate [(ngModel)]="vm.FirstName" #FirstName="ngModel" />
                </div>
                <div class="col-lg-2 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label>MI</label>
                    </div>
                    <input OnlyLetters type="text" Validate name="MiddleName" [(ngModel)]="vm.MiddleName" #MiddleName="ngModel" />
                </div>
                <div class="col-lg-5 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(LastName)">Last Name</label><i [ngClass]="childHasInvalid(LastName)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <input OnlyLetters type="text" Validate required name="LastName" [(ngModel)]="vm.LastName" #LastName="ngModel" />
                </div>
            </ng-template>

            <div class="col-lg-12 col-xs-12 contactAddress" *ngIf="isNotInEditMode else editMode2">
                <span class="contactAddressDisplay gbmask">{{vm.Address.Address1}} {{vm.Address.City}} {{vm.Address.State}} {{vm.Address.Zip}}</span>
            </div>
            <ng-template #editMode2>
                <div class="col-lg-12 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="addressSetIsInvalid(Address, City, State, Zip)">Address</label>
                        <i [ngClass]="addressSetIsInvalid(Address, City, State, Zip)" class="fas fa-asterisk" title="*Required field"></i>
                    </div>

                    <input [ngClass]="childHasInvalid(Address)" type="text" Validate required name="Address" [(ngModel)]="vm.Address.Address1" #Address="ngModel" />
                </div>

                <div class="col-lg-6 col-xs-12">
                    <input [ngClass]="childHasInvalid(City)" OnlyLetters type="text" Validate required name="City" [(ngModel)]="vm.Address.City" #City="ngModel" />
                </div>
                <div class="col-lg-2 col-xs-12">
                    <select Validate required [(ngModel)]="vm.Address.State" #State="ngModel" name="State" class="state gbmask">
                        <option [ngValue]="null">State</option>
                        <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                    </select>
                </div>
                <div class="col-lg-4 col-xs-12">
                    <input [ngClass]="childHasInvalid(Zip)" OnlyNumbers type="text" Validate required name="Zip" [(ngModel)]="vm.Address.Zip" #Zip="ngModel" />
                </div>
            </ng-template>
            <ng-container *ngFor="let contactPhone of this.vm.ContactInfo.Phones; let i = index">
                <div class="col-lg-5 col-xs-12" *ngIf="contactPhone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Phone)">Phone</label>
                    </div>
                    <input type="tel" name="ContactPhone{{i}}" mask="(000) 000-0000" [(ngModel)]="contactPhone.PhoneNumber" #Phone="ngModel" /><a class="normalLink" *ngIf="canDisplayRemoveButton(vm.ContactInfo)" (click)="removePhone(contactPhone)"><u>Remove</u></a>
                </div>
                <div class="col-lg-6 col-xs-12" *ngIf="contactPhone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(PhoneType)">Phone Type</label>
                    </div>
                    <select name="phoneType{{i}}" [(ngModel)]="contactPhone.PhoneType" #PhoneType="ngModel">
                        <option [ngValue]="null">Select Phone Type</option>
                        <option *ngFor="let pt of availablePhoneTypes(contactPhone, vm.ContactInfo, vm.PhoneTypes)" [ngValue]="pt.DisplayCode">{{ pt.DisplayValue }}</option>
                    </select>
                </div>
            </ng-container>
            <div *ngIf="canAddPhone(vm.ContactInfo)">
                <button class="phone addNew" (click)="addPhone(vm.ContactInfo)">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add Phone</span>
                </button>
            </div>
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Email)">Email</label>
                </div>
                <input type="email" Validate name="Email" email [(ngModel)]="email" #Email="ngModel" />
            </div>
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(DateOfBirth)">Date of Birth</label>
                </div>
                <input type="text" Validate mask="M0/d0/0000" [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" name="DateOfBirth" #DateOfBirth="ngModel" [(ngModel)]="dateOfBirth" />
            </div>
            <div class="col-lg-6 col-xs-12 d-flex flex-column justify-content-lg-end">
                <label>Gender</label>
                <div class="container-wrapper d-flex">
                    <label class="container d-flex">
                        <input type="radio" [(ngModel)]="vm.Gender" value="F" name="gender">
                        <span class="checkmark"></span>
                        <span>Female</span>
                    </label>
                    <label class="container d-flex">
                        <input type="radio" [(ngModel)]="vm.Gender" value="M" name="gender">
                        <span class="checkmark"></span>
                        <span>Male</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(lossParticipantForm)">Continue</button>
    </div>
</div>