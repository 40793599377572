




import { BaseModel } from './BaseModel';

import { ValueDetailModel } from './ValueDetailModel';

export class AdditionalPartyInfoModel extends BaseModel {
    
    public States: ValueDetailModel[] = [];
}