




import { BaseModel } from './BaseModel';


export class PhoneModel {
    
    public PhoneType: string = null;
    public PhoneNumber: string = null;
    public Extension: string = null;
    public Note: string = null;
    public IsActive: boolean = false;
}