<div ngForm #passengersForm="ngForm" class="main-inner-wrapper">
    <p class="header">Please enter <em class="emphasis-bold">all passengers</em> in the vehicle at the time of the incident.</p>

    <div class="alert alert-danger alert-dismissible fade show" *ngIf="noPassengersSelected"  [type]="danger">
        <strong>Error!</strong> Please select a Passenger.
    </div>

    <div class="input-wrapper">
        <div class="row">
            <div *ngFor="let participant of vm.Passengers">
                <button class="person" [class.selected]="participant.IsSelected" (click)="selectPerson(participant)" required>
                    <div *ngIf="!isDeviceMobile">
                        <div *ngIf="participant.Gender === 'M'">
                            <maleGenderIcon></maleGenderIcon>
                        </div>
                        <div *ngIf="participant.Gender === 'F'">
                            <femaleGenderIcon></femaleGenderIcon>
                        </div>
                    </div>
                    <span class="gbmask">{{participant.Name}}</span>
                    <i *ngIf="participant.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>
            <div>
                <button class="person addNew" (click)="addNew()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add a Passenger</span>
                </button>
            </div>
            <!--<div class="col-lg-12 col-xs-12">
                <button class="person addNew" (click)="addUnknown()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add Unknown Passenger</span>
                </button>
            </div>-->
            <!--<div class="col-lg-12 col-xs-12">
                <button class="person">
                    <i class="fas fa-plus-circle"></i>
                    <span>All passengers have been added</span>
                    <i class="fas fa-check-circle"></i>
                </button>
            </div>-->
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(passengersForm)">Continue</button>
    </div>
</div>