import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ErrorHandlerService {

    private errors: string[];
    private subject = new Subject<boolean>();

    constructor() {
        this.errors = [];
    }

    public setErrors(errors: string[]) {
        this.errors = errors;
    }

    public addError(error: string) {
        this.errors.push(error);
    }

    public getErrors(): string[] {
        return this.errors;
    }

    public clearErrors(): void {
        this.errors = [];
    }

    public setShowErrors(show: boolean) {
        if (this.errors != null && this.errors.length > 0) {
            this.subject.next(show);
        }
    }

    public getShowErrors(): Observable<boolean> {
        return this.subject.asObservable();
    }
}