




import { BaseModel } from './BaseModel';

import { BasePhoneModel } from './BasePhoneModel';
import { BaseEmailModel } from './BaseEmailModel';

export class BaseContactInfoModel {
    
    public Phones: BasePhoneModel[] = [];
    public Emails: BaseEmailModel[] = [];
}