import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { LayoutComponent } from './ClaimsWeb.Areas.Common/app/components/layout.component';
import { AddressValidationBottomSheet } from './ClaimsWeb.Areas.Common/app/components/modals/addressValidation.component';
import { ReviewSubmissionBottomSheet } from './ClaimsWeb.Areas.Common/app/components/modals/reviewSubmission.component'
import { UnsubmittedClaimBottomSheet } from './ClaimsWeb.Areas.Common/app/components/modals/unsubmittedClaim.component'

import { CommonModule } from './ClaimsWeb.Areas.Common/app/common.module';
import { FNOLAutoModule } from './ClaimsWeb.Areas.FNOL.Auto/app/fnolAuto.module';
import { FNOLHomeModule } from './ClaimsWeb.Areas.FNOL.Home/app/fnolHome.module';
import { MyClaims360Module } from './ClaimsWeb.Areas.MyClaims360/app/myclaims360.module';

import { SessionInterceptorService, ErrorLoggerService, LayoutService } from './ClaimsWeb.Areas.Common/app/services';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ServiceLocator } from './locator.service';
import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
 
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule } from '@angular/material/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        LayoutComponent,
        AddressValidationBottomSheet,
        ReviewSubmissionBottomSheet,
        UnsubmittedClaimBottomSheet
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        CommonModule,
        FNOLAutoModule,
        FNOLHomeModule,
        MyClaims360Module,
        RouterModule.forRoot([
            { path: 'fnolThirdPartyAuto', loadChildren: () => import('./ClaimsWeb.Areas.FNOL.ThirdParty.Auto/app/fnolThirdPartyAuto.module').then(m => m.FNOLThirdPartyAutoModule) }
        ]),
        BrowserAnimationsModule,
        MatSliderModule,
        MatProgressSpinnerModule,
        MatBottomSheetModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatIconModule,
 
        MatNativeDateModule,
        MatButtonModule,
        NgbModule,
    ],
    exports: [
        MatSliderModule,
        MatProgressSpinnerModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptorService, multi: true },
        { provide: ErrorHandler, useClass: ErrorLoggerService },
        LayoutService
    ],
    bootstrap: [LayoutComponent]
})
export class AppModule {
    constructor(private injector: Injector) {    // Create global Service Injector.
        ServiceLocator.injector = this.injector;
    }
}