import { Component, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { VehicleApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { VehicleInfoModel, RVVehicleClaimInfoModel, VehicleClaimInfoModel, AddressModel, ClaimTypeCategoryEnum, RVVehicleInfoModel, ClaimReporterModel } from '@ClaimsModels/index';
import { ConstantsService, SessionService } from '@Common/services'

@Component({
    selector: 'vehicleDetail',
    templateUrl: '../../views/vehicle/vehicleDetail.component.html',
    styleUrls: ['../../styles/vehicleInfo.component.css'],
    providers: [FNOLRoutingService]
})
export class VehicleDetailComponent extends BaseVehicleComponent {

    constructor(private api: VehicleApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, public fnolAutoService: FNOLAutoService,
        private constantsService: ConstantsService,
        private sessionService: SessionService) {
        super();

        this.vm = new VehicleInfoModel();
        this.vm.LossParticipants = [];
        this.vm.VehicleClaimInfo = new VehicleClaimInfoModel();
        this.vm.VehicleLocationAddress = new AddressModel();
    }

    public vm: VehicleInfoModel = new VehicleInfoModel();
    public id: number;
    public isVehicleDamageScreen: boolean = false;
    public isRVVehicleDamageScreen: boolean = false;
    public hasRecoveredVehicleFromTheft: boolean = false;
    @Output() useTheftComponent = new EventEmitter<boolean>();
    public accidentLocationsWithBusiness: string[] = [this.constantsService.BodyShopLocationValue, this.constantsService.ImpoundYardLocationValue,
                                                      this.constantsService.TowYardLocationValue, this.constantsService.WorkLocationValue];
// if new field is blank don't set to true or false let it default to null otherwise set true and false as usual'
    public IsInteriorDamage: boolean = null;
    public IsEngineRunning: boolean = null;
    public IsWindshieldOnlyDamages: boolean = null;
    public IsWindshieldTwoPieces: boolean = null;
    public IsDamagesOtherThanWindshield: boolean = null;
    public GlassDamageAreaDescription: string;
    public ClaimTypeCode: string;
    public ClaimTypeCategory: ClaimTypeCategoryEnum;
    public IsSingleVehicle: boolean = null;
    public IsTowing: boolean = null;
    public workflowType = this.sessionService.getWorkflowType();

    ngOnInit(): void {
        this.spinner.show();

        this.ClaimTypeCategory = this.fnolAutoService.getClaimTypeCategory();
        this.ClaimTypeCode = this.fnolAutoService.getClaimTypeCode();

        let id = this.fnolRouting.getRoute().snapshot.params.id;
        this.id = id;

        if (this.fnolAutoService.getHasRecoveredVehicleFromTheft() == this.constantsService.TrueStringValue) {
            this.hasRecoveredVehicleFromTheft = true;
        }
        else {
            this.hasRecoveredVehicleFromTheft = false;
        }

        this.api.getVehicleInfo(id).then(response => {
            this.spinner.hide();
            console.log(response);
            this.vm = response;
            this.ClaimTypeCategory= this.fnolAutoService.getClaimTypeCategory();

            if (this.workflowType == this.constantsService.FNOLRV) {
                this.setRVVehicleDamage(response, this.ClaimTypeCategory, this.ClaimTypeCode, this.IsTowing);
            } else if (this.workflowType == this.constantsService.FNOLAuto) {
                this.setVehicleDamages(response, this.ClaimTypeCategory, this.ClaimTypeCode, this.IsTowing);
            }

            if (this.ClaimTypeCategory == ClaimTypeCategoryEnum.Theft) {
                let vehicleIDsThatHaveAnsweredTheftQuestions: string[] = this.fnolAutoService.getVehicleIDsThatHaveAnsweredTheftQuestions();
                if (vehicleIDsThatHaveAnsweredTheftQuestions != null && vehicleIDsThatHaveAnsweredTheftQuestions.includes(this.id.toString())) {
                    let displayTheft: string = this.fnolRouting.getRoute().snapshot.params.displayTheftComponent;
                    if (displayTheft == this.constantsService.TrueStringValue) {
                        this.useTheftComponent.emit(true);
                    }                   
                }
            }
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public goBack() {
        this.scrollToTopOfPage();
        this.isVehicleDamageScreen = false;
        this.isRVVehicleDamageScreen = false;
    }

    submit(form: NgForm): void {
        if (!this.validate(form)) return;
        this.spinner.show();
        this.vm.VehicleClaimInfo.IsOnPolicy = true;
        this.vm.VehicleClaimInfo.IsActive = true;
        let claimTypeCode = this.fnolAutoService.getClaimTypeCode();
        let claimTypeCategory = this.fnolAutoService.getClaimTypeCategory();
        if (this.constantsService.ClaimTypeSingleVehicle.indexOf(claimTypeCode) > -1) {
            this.IsSingleVehicle = true;
        }
        if (this.constantsService.ClaimTypeMechanicalBreakdown.indexOf(claimTypeCode) > -1) {
            this.IsTowing = true;
        }
        if (this.workflowType == this.constantsService.FNOLRV) {
            this.vm.VehicleType = this.constantsService.RecreationVehicle;
        }
        if (claimTypeCategory == ClaimTypeCategoryEnum.Glass) {
            this.vm.VehicleClaimInfo.IsWindshieldOnlyDamages = (sessionStorage.getItem('isWindshieldOnlyDamages') === 'true' ? true : false);
            if (sessionStorage.getItem('isWindshieldTwoPieces') === "true") {
                this.vm.VehicleClaimInfo.IsWindshieldTwoPieces = true;
            }
            else if (sessionStorage.getItem('isWindshieldTwoPieces') === "false") {
                this.vm.VehicleClaimInfo.IsWindshieldTwoPieces = false;
            }
            else {
                this.vm.VehicleClaimInfo.IsWindshieldTwoPieces = null;
            }
            this.vm.VehicleClaimInfo.IsDamagesOtherThanWindshield = (sessionStorage.getItem('isDamagesOtherThanWindshield') === 'true' ? true : false);
            this.vm.VehicleClaimInfo.GlassDamageAreaDescription = sessionStorage.getItem('GlassDamageAreaDescription');
        }

        if (this.workflowType == this.constantsService.FNOLRV) {
            this.setRVVehicleDamage(this.vm, this.ClaimTypeCategory, this.ClaimTypeCode, this.IsTowing);
        } else if (this.workflowType == this.constantsService.FNOLAuto) { 
            this.setVehicleDamages(this.vm, this.ClaimTypeCategory, this.ClaimTypeCode, this.IsTowing);  
        }

        switch (claimTypeCategory) {
            case ClaimTypeCategoryEnum.MechanicalBreakdown: 
                this.api.saveVehicleInfo(this.vm).then(response => {
                    this.spinner.hide();
                    if (response.Success) {
                        this.fnolRouting.next({ vehicleId: this.id.toString() }, { replaceUrl: true });
                    }
                });
                return;
            case ClaimTypeCategoryEnum.Fire:
                if (!this.isVehicleDamageScreen && !this.isRVVehicleDamageScreen) {
                    this.scrollToTopOfPage();
                    if (this.workflowType == this.constantsService.FNOLRV) {
                        this.isRVVehicleDamageScreen = true;
                    } else {
                        this.isVehicleDamageScreen = true;
                    }
                    this.spinner.hide();
                    return;
                } else {
                    this.api.saveVehicleInfo(this.vm).then(response => {
                        this.spinner.hide();
                        if (response.Success) {
                            this.fnolRouting.next({ vehicleId: this.id.toString() }, { replaceUrl: true });
                        }
                    });
                    return;
                }
            case ClaimTypeCategoryEnum.Theft:
                this.vm.VehicleClaimInfo.IsActive = false; //Activate when submitted on theft component
                if (!this.isVehicleDamageScreen && !this.isRVVehicleDamageScreen && this.hasRecoveredVehicleFromTheft) {
                    if (this.workflowType == this.constantsService.FNOLRV) {
                        this.isRVVehicleDamageScreen = true;
                    } else {
                        this.isVehicleDamageScreen = true;
                    }
                    this.spinner.hide();
                    return;
                }
                else if (this.isRVVehicleDamageScreen) {
                    if (this.validate(form)) {
                        this.api.saveVehicleInfo(this.vm).then(response => {
                            this.spinner.hide();
                            if (response.Success) {
                                this.useTheftComponent.emit(true);
                            }
                        });
                        this.spinner.hide();
                        return;
                    }
                }
                else {
                    this.useTheftComponent.emit(true);
                    this.spinner.hide();
                    return;
                }

            case ClaimTypeCategoryEnum.Glass:
            case ClaimTypeCategoryEnum.Awning:
            case ClaimTypeCategoryEnum.MiscRV:
            case ClaimTypeCategoryEnum.MechanicalBreakdown:
            case ClaimTypeCategoryEnum.LockOut:
            case ClaimTypeCategoryEnum.JumpStart:
            case ClaimTypeCategoryEnum.FlatTire:
            case ClaimTypeCategoryEnum.GasDelivery:
            case ClaimTypeCategoryEnum.Winch:
            case ClaimTypeCategoryEnum.IllegallyParkedTow:
            case ClaimTypeCategoryEnum.Impounded:
                if (this.validate(form)) {
                    this.api.saveVehicleInfo(this.vm).then(response => {
                        if (response.Success) {
                            this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.id}/selectOwner`, { replaceUrl: true });
                        }
                    });
                    this.spinner.hide();
                    return;
                }
            default:
                if (!this.isVehicleDamageScreen && !this.isRVVehicleDamageScreen) {
                    this.scrollToTopOfPage();
                    if (this.workflowType == this.constantsService.FNOLRV) {
                        this.isRVVehicleDamageScreen = true;
                    } else {
                        this.isVehicleDamageScreen = true;
                    }
                    this.spinner.hide();
                    return;
                }
        }

        this.api.saveVehicleInfo(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                        this.fnolRouting.next({ vehicleId: this.id.toString() }, { replaceUrl: true });                  
            }
        });
        
    }

    public handleDamageSlider(event: any) {
        this.damageLevel = event.value;
        this.vm.VehicleClaimInfo.DamageSeverityLevel = `Level ${event.value}`;
    }
    public isVehicleLocationTypeRequired(): boolean {
        let claimTypeCode: string = this.fnolAutoService.getClaimTypeCode();
        let claimTypeCodeLower: string = claimTypeCode.toLowerCase();
        if (this.constantsService.ClaimTypeMechanicalBreakdown.indexOf(claimTypeCode) > -1 ||
            this.constantsService.ClaimTypePedestrian.indexOf(claimTypeCode) > -1 ||
            this.constantsService.ClaimTypeSingleVehicle.indexOf(claimTypeCode) > -1 ||
            this.constantsService.ClaimTypeMultiVehicle.indexOf(claimTypeCode) > -1 ||
            this.constantsService.ClaimTypeWeather.indexOf(claimTypeCode) > -1 ||
            this.constantsService.ClaimTypeCodeVandalism.indexOf(claimTypeCode) > -1 ||
            this.constantsService.ClaimTypeRVMisc.indexOf(claimTypeCode) > -1 ||
            claimTypeCodeLower === this.constantsService.Fire ||
            claimTypeCodeLower === this.constantsService.Awning ||
            claimTypeCodeLower === this.constantsService.Glass) {
            return true;
        }

        return false;
    }

    public displayLocationQuestions(): boolean {
        switch (this.fnolAutoService.getClaimTypeCategory()) {
            case ClaimTypeCategoryEnum.Theft:
                if (this.hasRecoveredVehicleFromTheft) {
                    return true;
                }
                else {
                    return false;
                }
            default:
                return true;
        }
    }

    public displayBusinessNameQuestion(): boolean {
        if (this.accidentLocationsWithBusiness.includes(this.vm.VehicleClaimInfo.VehicleLocationType)) {
            return true;
        }
        return false;
    }

    public setLocationAddress(): void {
        let selectedLocation = this.vm.VehicleClaimInfo.VehicleLocationType;

        this.vm.VehicleLocationAddress = new AddressModel();

        if (selectedLocation == this.constantsService.ResidenceLocationDisplayCode) {
            this.vm.VehicleLocationAddress = this.vm.PolicyPrimaryAddress;
        }
    }

    public displayInteriorDamageQuestion(): boolean {
        
        let claimTypeCode: string = this.fnolAutoService.getClaimTypeCode().toLowerCase();
        if (this.workflowType.toString() === this.constantsService.FNOLAuto) {
            if (this.constantsService.ClaimTypeCodeFire.toLowerCase().indexOf(claimTypeCode) > -1) {
                return true;
            }
        }
        if (this.workflowType.toString() === this.constantsService.FNOLRV) {
            return true;
        }
        return false;
    }
}