import { Directive, HostListener } from '@angular/core';
//import { NgModel } from '@angular/forms'

@Directive({
    // This seems complex but it selects text inputs (or text area) that use [(ngModel)].
    // the ngModel selector was taken from the angular github.
    selector: '[DisallowWhiteSpace]'
    //selector: '[ngModel]:not([formControlName]):not([formControl])input:not([type="radio"]):not([type="checkbox"]),[ngModel]:not([formControlName]):not([formControl])textarea'
})

//export class DisallowWhiteSpaceDirective {
//    constructor(private ngModel: NgModel) { }

//    @HostListener('blur')
//    onBlur() {

//        let value: any = this.ngModel.value;

//        if (value) {
//            value = value.trim();
//        }

//        if (value == "") {
//            value = null;
//        }

//        this.ngModel.control.setValue(value);
//        this.ngModel.valueAccessor.writeValue(value);
//        this.ngModel.viewToModelUpdate(value);
//        this.ngModel.control.updateValueAndValidity();
//    }
//}
export class DisallowWhiteSpaceDirective {
    private whiteSpace: string = ' ';
    private regex: RegExp = new RegExp(/^[A-Za-z0-9]*/g)


    constructor() {}

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        // Allow special characters
        if (e.key !== undefined) {
            if (e.key !== this.whiteSpace) {
                return;
            }
            else {
                e.preventDefault();
            }
        }
    }
}