<div class="main-inner-wrapper">
    <div class="input-wrapper">
        <div class="row">
            <div class="col-xs-12">
                <form #BridgeSessionForm action="/Common/Bridge/BridgeSession" method="post">
                    <input hidden name="ApplicationName" readonly />
                    <input hidden name="Token" readonly />
                    <label>Policy Number:</label><br />

                    <select name="PolicyNumber">
                        <option value="1HO0477810">1HO0477810 - XM</option>
                        <option value="PHO9845086">PHO9845086 - XM</option>
                        <option value="2005232377">2005232377 - NPS</option>
                        <option value="2005955888">2005955888 - NPS</option>
                        <option value="2002360589">2002360589 - NPS - DOL 06/05/2019</option>
                        <option value="2002223453">2002223453 - NPS - DOL 06/05/2016</option>
                    </select>

                    <label>Policy not in the drop down? Enter it below.</label>
                    <input placeholder="(i.e. 1PA9833132)" type="text" name="PolicyInput" />

                    <label *ngIf="showBrand">Select a brand to utilize going forward</label>
                    <select name="FNOLBranding" *ngIf="showBrand">
                        <option value="https://m.directauto.com/UnifiedMyAccount#!/login">Direct Auto</option>
                        <option value="https://claims.nationalgeneral.com/">National General Insurance</option>
                        <option value="https://goodsamrvinsurance.com/">Good Sam RV Insurance</option>
                        <option value="https://farmersunioninsurance.com/">Farmers Union Insurance</option>
                        <option value="https://aie-ny.com">Adirondack Insurance Exchange</option>
                        <option value="https://mvic-aie.com">Mountain Valley Indemnity Company</option>
                        <option value="https://njsi.com">New Jersey Skylands Insurance</option>
                        <option value="http://localhost:49566/fnolDashboard">No Branding</option>
                    </select>

                    <div class="button-wrapper">
                        <button id="continueBtn" (click)="submitForm(BridgeSessionForm)" class="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>