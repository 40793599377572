import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LossApi, HomeRoutingService, HomeService } from '@FNOLHome/services';
import { HomeLossDescriptionModel, AddressModel, LossDetailsModel, BaseLossPropertyModel } from '@ClaimsModels/index';

@Component({
    selector: 'lossDescription',
    templateUrl: '../../views/loss/lossDescription.component.html',
    providers: [HomeRoutingService]
})
export class LossDescriptionComponent extends BaseFormComponent {

    public incidentOccuredAtInsuredProperty: boolean;

    constructor(private api: LossApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: HomeRoutingService, private homeService: HomeService) {
        super();

        this.vm = new HomeLossDescriptionModel();
        this.vm.Address = new AddressModel();
        this.vm.LossDetails = new LossDetailsModel();
        this.vm.LossProperty = new BaseLossPropertyModel();
    }

    public vm: HomeLossDescriptionModel = new HomeLossDescriptionModel();

    ngOnInit(): void {

        this.incidentOccuredAtInsuredProperty = this.homeService.getIncidentOccuredAtInsuredProperty();
        let damageToTheHome: boolean = this.homeService.getDamageToTheHome();

        // Set the field to Yes if there was damage to the home
        if (damageToTheHome != null && damageToTheHome) {
            this.incidentOccuredAtInsuredProperty = true;
        }

        this.spinner.show();
        this.api.getLossDescriptionModel().then(response => {
            this.spinner.hide();
            this.vm = response;

            if (damageToTheHome) {
                this.SetPolicyProperty(true);
            }

            // set the property Id based on what is returned from the get handler
            this.homeService.setPropertyId(this.vm.LossProperty.PropertyId);
        });
    }

    public SetPolicyProperty(isPolicyProperty: boolean) {

        if (isPolicyProperty) {
            this.vm.Address = this.vm.PolicyPropertyAddress;
        } else {
            this.vm.Address = new AddressModel();
        }

    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.api.saveLossDescriptionModel(this.vm).then(response => {
                this.spinner.hide();

                if (this.incidentOccuredAtInsuredProperty != null) {
                    this.homeService.setIncidentOccuredAtInsuredProperty(this.incidentOccuredAtInsuredProperty);
                }
                
                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
        }
    }
}