import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { VehicleDamageModel, RVVehicleDamageModel } from '@ClaimsModels/index';
import { MyClaimsApi } from '../services';
import { BaseFormComponent } from '../../../ClaimsWeb.Areas.Common/app/base/baseForm.component';
import { SessionService } from '../../../ClaimsWeb.Areas.Common/app/services';

@Component({
    selector: 'vehicleDamage',
    templateUrl: '../views/vehicleDamage.component.html',
    styleUrls: ['../styles/vehicleDamage.component.css']
})
export class VehicleDamageComponent extends BaseFormComponent {
    @Input() id: number;
    public vm: VehicleDamageModel = new VehicleDamageModel();
    constructor(private api: MyClaimsApi, private sessionService: SessionService) {
        super();

    }
    ngOnInit(): void {
        this.api.getVehicleDamage(this.id).then(response => {
            this.vm = response;
            this.setVehicleDamages(response);
            this.setRVVehicleDamage(null);
        });
    }
    public goingBackWithLocation(): void {

    }
    protected submit(form: NgForm): void {

    }

    public getDamagedZones(): string[] {
        let damagedZones: string[] = [];
        this.vehicleComponents.forEach(component => {
            if (component.isClicked && damagedZones.indexOf(component.title) < 0) {
                damagedZones.push(component.title);
            }
        });
        return damagedZones;
    }

    public setRVVehicleDamage(vm: RVVehicleDamageModel): void {
        let info: RVVehicleDamageModel = vm;

        if (info.DmgWindshield) {
            this.rvVehicleComponents.filter(x => x.title == DamageLocation.FrontWindShield).forEach(x => x.isClicked = true);
        }
    }

    public setVehicleDamages(vm: VehicleDamageModel): void {
        let info: VehicleDamageModel = vm;

        // Wind Damage
        if (info.Dmg1Front) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.FrontWindShield).forEach(x => x.isClicked = true);
        }

        if (info.Dmg1Rear) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.RearWindShield).forEach(x => x.isClicked = true);
        }

        // Hood
        if (info.Dmg1Hood) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.Hood).forEach(x => x.isClicked = true);
        }

        // Roof
        if (info.Dmg1Top) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.Roof).forEach(x => x.isClicked = true);
        }

        // Trunk
        if (info.Dmg2Trunk) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.Trunk).forEach(x => x.isClicked = true);
        }

        // Left
        if (info.Dmg1LeftFront) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.LeftFront).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1LeftRear) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.LeftRear).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1LeftSide) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.LeftSide).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1LeftFender) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.LeftFender).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1LeftQuarterPiece) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.LeftQuarterPiece).forEach(x => x.isClicked = true)
        }

        // Right
        if (info.Dmg1RightFront) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.RightFront).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1RightRear) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.RightRear).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1RightSide) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.RightSide).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1RightFender) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.RightFender).forEach(x => x.isClicked = true);
        }
        if (info.Dmg1RightQuarterPiece) {
            this.vehicleComponents.filter(x => x.title == DamageLocation.RightQuarterPiece).forEach(x => x.isClicked = true);
        }
    }

    public rvVehicleComponents: IVehicleDamageComponent[] = [
        {
            id: 1,
            name: 'frontWindshield',
            title: DamageLocation.FrontWindShield,
            dimensions: 'm 32.290541,111.83108 c 30.83108,0 31.013513,-0.36486 31.013513,-0.36486 l 1.094595,10.21621 H 31.013513 Z',
            isClicked: false
        }

    ];

    public vehicleComponents: IVehicleDamageComponent[] = [
        {
            id: 1,
            name: 'hoodTopView',
            title: DamageLocation.Hood,
            dimensions: 'M287.5,17.9c-27.8,0-48.3,7.7-52.6,16.6c-3.9,18.6-3.1,63.2-3.1,70.9c7.6-5.3,26.2-13.2,56.6-13.2c30.3,0,48,5.5,55.7,13.2c0.8-5.5-0.8-57.7-2.5-72.5C333.2,22.9,315.1,17.9,287.5,17.9z',
            isClicked: false
        },
        {
            id: 2,
            name: 'hoodFrontView',
            title: DamageLocation.Hood,
            dimensions: 'M104,58.5c35,0,70.3,2.6,70.6,5.4c0.3,2.8-5.9,15-5.5,16.6c-10.5,0.5-19.2-0.4-30.6,6.3c2.2-5.2-1.4-6.2-3.1-6.2c-1.7,0-60.6,0-62.5,0s-2.6,2.5-2.6,4.3C64.3,81.1,51.6,78,39.1,78c-1.3-3-6.3-16.3-6.3-16.3C57.8,55.9,77,58.5,104,58.5z',
            isClicked: false
        },
        {
            id: 3,
            name: 'trunkTopView',
            title: DamageLocation.Trunk,
            dimensions: 'M245.7,333c-1.5-11.5-1.6-17.1-1.4-33.4c12.6,4.4,30.2,6,46.6,6s35.5-1.4,43.4-6.5c-0.7,19.7-0.9,28-1.5,33.7   c0,0-12.9,3.3-42.9,3.3C269.6,336,251.4,334.2,245.7,333z',
            isClicked: false
        },
        {
            id: 4,
            name: 'trunkRearView',
            title: DamageLocation.Trunk,
            dimensions: 'm 46.5,245.8 -1.2,4.7 c 0,0 -7.31276,-0.2682 -15.1,20 l -0.316321,16.2339 150.912581,0.14942 c 0,0 3.90374,-19.98332 -5.89626,-28.78332 -9.8,-8.8 -9.5,-8.7 -9.5,-8.7 V 246 c 0,0 -57.45816,-5.57233 -118.9,-0.2 z',
            isClicked: false
        },
        {
            id: 5,
            name: 'leftFrontTopView',
            title: DamageLocation.LeftFront,
            dimensions: 'M288.1,5.1c-43.7,0-56.8,13.9-59.8,17.6s-5.2,12.9-3.8,14c1.4,1.1,3.3,2.6,7.4,3.9c3-13.1,16.9-22.9,56.2-22.9   c0.1,0,0.3,0,0.4,0V5.1C288.4,5.1,288.3,5.1,288.1,5.1z',
            isClicked: false
        },
        {
            id: 6,
            name: 'leftFrontView',
            title: DamageLocation.LeftFront,
            dimensions: 'M164.1,131.3l4.7,3.5l0.1,16.8H189v-42.8l2.4-27.4l-3.3-4.9c0,0-4.4,4.1-8.7,7.5c-3-3-7.2-4.7-10-4.9c-2.8-0.2-19.2,0.4-30.8,7.6c1.2-2.3,1-6.8-2.7-6.8c-1.8,0-16.5,0-31.2,0v51.1L164.1,131.3z',
            isClicked: false
        },
        {
            id: 7,
            name: 'rightFrontTopView',
            title: DamageLocation.RightFront,
            dimensions: 'M287.5,5.8v12.5c39,0.1,50.2,7.4,57.5,21.7c0,0,7.8-0.8,7.5-3.2C349.9,16.6,335.3,6.7,287.5,5.8z',
            isClicked: false
        },
        {
            id: 8,
            name: 'rightFrontView',
            title: DamageLocation.RightFront,
            dimensions: 'M20.4,78.3l0.1,72.2l1.3,1.9h17.1l1.4-1.5v-17l1.9-2.4H105V80.1c-15.2,0-30.4,0-31.4,0c-1.7,0-4.5,1.2-4.5,4c-2.3,0-13.6-7.1-31-7.1c-5.3,0-8.2,3.9-8.2,3.9s-5-0.1-7.8-6.7C19.9,74.4,20.4,78.3,20.4,78.3z',
            isClicked: false
        },
        {
            id: 9,
            name: 'leftRearView',
            title: DamageLocation.LeftRear,
            dimensions: 'm 18.830644,286.28564 0.448214,54.03922 1.792855,2.43556 h 17.032124 l 2.390474,-1.82667 -0.149405,-23.44237 1.643451,-1.67445 28.685683,0.30445 v 4.11002 l 32.86901,0.45667 0.29881,-33.48909 -79.782055,-0.30445 -4.033924,1.9789 z',
            isClicked: false
        },
        {
            id: 10,
            name: 'leftRearTopView',
            title: DamageLocation.LeftRear,
            dimensions: 'M288.3,337.4c-17.4,0-35.3-1-43.1-3.5s-10.5-5.6-12.3-17.8c0,0-6.6-1.6-6.8-1.4c0,0.6,1.3,13.3,6.8,20.4   c4,5.1,17.4,8.2,55.4,8.2l0,0V337.4C288.4,337.4,288.4,337.4,288.3,337.4z',
            isClicked: false
        },
        {
            id: 11,
            name: 'rightRearView',
            title: DamageLocation.RightRear,
            dimensions: 'M 103.54305,320.68807 136.56842,320.8013 136.3,316 l 5,0.1 c 0,0 -0.39845,5.14955 6.47479,5.74723 0,0 7.68996,1.71192 8.92521,-4.74723 l 12.7,0 -0.25837,25.21755 21.81508,-0.29884 0.89651,-55.73306 -0.74709,2.68953 -4.18371,-1.94244 h -82.47896 z',
            isClicked: false
        },
        {
            id: 12,
            name: 'rightRearTopView',
            title: DamageLocation.RightRear,
            dimensions: 'M332.2,332.3c-7.6,1.7-22.1,3.6-43.9,3.6v6.1c25,0,48.8-1.8,56.1-6.9c5.3-5.8,7.4-21.4,7.4-21.4l-6.1,0.5   c0,0-0.8,6.8-3.8,11.5C339.6,329.6,335.6,331.5,332.2,332.3z',
            isClicked: false
        },
        {
            id: 13,
            name: 'leftSideTopView',
            title: DamageLocation.LeftSide,
            dimensions: 'M 221.76935,98.998512 221.6,127.8 c 0,0 -12.14702,3.87917 -9,8.3 l 9,-0.2 c 0,0 -0.99286,115.55833 0.90893,118.51667 l 5.43782,-0.31697 c 0,0 13.64373,-10.1366 16.25325,-17.7997 l 7.39678,-0.056 c 0,0 6.88001,-39.81126 -3.49678,-78.544 0,0 -1.9244,-0.48571 -4.0375,-5.34583 0,0 -14.4625,-34.95417 -12.7625,-41.65417 0,0 -1.96667,-1.38155 1.7,-6.7 l 0.49702,-5.107143 z',
            isClicked: false
        },
        {
            id: 14,
            name: 'leftSideFrontView',
            title: DamageLocation.LeftSide,
            dimensions: 'm 178.4,59.7 4.9,3.6 13.42917,-0.329762 c 0,0 5.07143,-6.127976 -1.05655,-11.410714 L 180.66964,50.925595 177.6,57 c 0,0 -14.83393,-36.595238 -24.9,-37.5 0,0 21.52798,36.858333 20.1,42.7 l 2.8,2.1 z',
            isClicked: false
        },
        {
            id: 15,
            name: 'leftSideRearView',
            title: DamageLocation.LeftSide,
            dimensions: 'M 25.251488,254.52232 H 11.727679 c 0,0 -5.9166671,-8.13541 4.22619,-12.67857 h 14.580357 c 0,0 2.097619,0.083 1.365774,6.85625 0,0 15.857068,-41.05319 25,-36.1 v 3.9 c 0,0 -9.123193,14.0085 -10.3,28.3 l -19.7,6.6 z',
            isClicked: false
        },
        {
            id: 16,
            name: 'rightSideTopView',
            title: DamageLocation.RightSide,
            dimensions: 'M 355.14502,98.998512 355.31437,127.8 c 0,0 12.14702,3.87917 9,8.3 l -9,-0.2 c 0,0 0.99286,115.55833 -0.90893,118.51667 l -5.43782,-0.31697 c 0,0 -13.64373,-10.1366 -16.25325,-17.7997 l -7.39678,-0.056 c 0,0 -6.88001,-39.81126 3.49678,-78.544 0,0 1.9244,-0.48571 4.0375,-5.34583 0,0 14.4625,-34.95417 12.7625,-41.65417 0,0 1.96667,-1.38155 -1.7,-6.7 l -0.49702,-5.107143 z',
            isClicked: false
        },
        {
            id: 17,
            name: 'rightSideFrontView',
            title: DamageLocation.RightSide,
            dimensions: 'M 32.423792,59.916777 27.2,63.5 12.889881,63.392857 c 0,0 -4.8601191,-6.761905 2.324405,-12.044643 H 28.738095 L 32.8,57.4 c 0,0 19.393452,-42.819643 27.423214,-39.016071 0,0 -23.244047,29.583333 -22.1875,41.839285 L 34.232143,62.125 Z',
            isClicked: false
        },
        {
            id: 18,
            name: 'rightSideRearView',
            title: DamageLocation.RightSide,
            dimensions: 'm 186.4,254.7 13.2875,-0.072 c 0,0 8.02976,-4.54316 -3.2753,-12.67857 L 180.03571,241.7381 179.9,247.3 c 0,0 -13.75268,-34.10387 -24.4,-35.1 l -0.6,5.3 c 0,0 8.22589,13.21577 10.5,28.5 l 15.5,4.4 3.5,1.4 z',
            isClicked: false
        },
        {
            id: 19,
            name: 'rearWindShield',
            title: DamageLocation.RearWindShield,
            dimensions: 'M57.4,217.8c0,0,22.8-1.2,48.7-1.2s49,1.4,49,1.4c3.5,5.1,9,18.6,10.3,28c-16.1-1.7-27-2.1-58.9-2.1   s-47.6,0.4-59.3,2.1C48.6,236.2,57.4,217.8,57.4,217.8z',
            isClicked: false
        },
        {
            id: 20,
            name: 'rearWindShieldTopView',
            title: DamageLocation.RearWindShield,
            dimensions: 'M287.6,249.6c20.1,0,38-3.4,40-2.4c2.3,1.2,12.7,42.7,9.5,49.2c-2.6,5.3-15,10.4-43.6,10.4   c-28.6,0-51.3-2.8-54.1-10.9c-2.8-8.1,7.2-47.5,9.3-49.5C250.7,244.4,255.5,249.6,287.6,249.6',
            isClicked: false
        },
        {
            id: 21,
            name: 'roof',
            title: DamageLocation.Roof,
            dimensions: 'M289.7,151.7c-22.7,0-39.6,6.6-41.8,5.6c2.9,9.2,5.2,22.6,5.2,41.4s-1,43.4-1.5,46.8   c9.3,1.7,21.7,3.3,37.2,3.3s34.3-2,38.4-2.8c-1.7-29.9-1.2-39.8-1.2-48.6s2.7-34.6,4.4-40.4C321.6,155.1,311.7,151.7,289.7,151.7z',
            isClicked: false
        },
        {
            id: 22,
            name: 'roofFrontView',
            title: DamageLocation.Roof,
            dimensions: 'M105.7,16c18.4,0,46.5,2.2,46.5,2.2l4.9,7.2h-51.4l-50-0.1l5.7-7.5C61.3,17.8,79.6,16,105.7,16z',
            isClicked: false
        },
        {
            id: 23,
            name: 'roofRearView',
            title: DamageLocation.Roof,
            dimensions: 'M57.2,212.2c14.6-0.5,31.4-1.4,47.6-1.4c19.4,0.1,37.8,0.7,50.7,1.4v5l-50.7-1.4l-47.6,1.4   C57.2,217.2,57.2,212.2,57.2,212.2z',
            isClicked: false
        },
        {
            id: 24,
            name: 'frontWindshieldView',
            title: DamageLocation.FrontWindShield,
            dimensions: 'M103,25c25.2,0,52.5,0.7,52.5,0.7c9.2,14.5,15.1,28.3,17.3,36.5c-31.8-3.3-43.6-4.4-69.8-4.4s-47.9-0.6-66.6,2.5c3.3-13.6,18-34.6,18-34.6C73.5,24.8,103,25,103,25z',
            isClicked: false
        },
        {
            id: 25,
            name: 'frontWindshieldTopView',
            title: DamageLocation.FrontWindShield,
            dimensions: 'M288.9,93.3c-42,0-56.2,12.6-57.5,17.1s12.6,45.1,14.9,48.5c2.5,3.8,15.3-4.2,40.5-4.2c22.9,0,43.8,6,44.9,4.7   c1.1-1.2,17.2-34.6,15.3-48C345,98,317.6,93.3,288.9,93.3z',
            isClicked: false
        },
        {
            id: 26,
            name: 'leftFenderFrontView',
            title: DamageLocation.LeftFender,
            dimensions: 'm 178.50894,59.599584 c 0,0 7.04532,4.219336 10.4212,18.824727 0,0 -4.40332,5.842158 -8.80665,6.978133 0,0 -2.34844,-4.868465 -9.83411,-4.381618 0,0 7.19211,-24.017757 8.21956,-21.421242 z',
            isClicked: false
        },
        {
            id: 27,
            name: 'leftFenderTopView',
            title: DamageLocation.LeftFender,
            dimensions: 'm 221.76935,98.998512 1.69047,-57.793155 0.63393,-5.282738 c 0,0 -0.21131,1.373512 7.60714,4.860119 0,0 5.07143,-10.354167 3.90923,-5.494047 -1.1622,4.860119 -5.49405,64.34375 -2.95833,63.815476 2.53571,-0.528274 -10.88244,-0.105655 -10.88244,-0.105655 z',
            isClicked: false
        },
        {
            id: 28,
            name: 'rightFenderFrontView',
            title: DamageLocation.RightFender,
            dimensions: 'm 32.423792,59.916777 c 0,0 -7.172083,3.884879 -10.608706,17.332534 0,0 4.482552,5.379063 8.965104,6.424992 0,0 2.390694,-4.482552 10.011032,-4.034297 0,0 -7.321501,-22.113924 -8.36743,-19.723229 z',
            isClicked: false
        },
        {
            id: 29,
            name: 'rightFenderTopView',
            title: DamageLocation.RightFender,
            dimensions: 'm 355.77252,98.998512 -1.69047,-57.793155 -0.63393,-5.282738 c 0,0 0.21131,1.373512 -7.60714,4.860119 0,0 -5.07143,-10.354167 -3.90923,-5.494047 1.1622,4.860119 5.49405,64.34375 2.95833,63.815476 -2.53571,-0.528274 10.88244,-0.105655 10.88244,-0.105655 z',
            isClicked: false
        },
        {
            id: 30,
            name: 'leftQPRearView',
            title: DamageLocation.LeftQuarterPiece,
            dimensions: 'm 26.9,251.4 c 0,0 -11.356362,20.93356 -7.1,36.8 l 3.50927,-1.31668 6.574409,-0.14942 L 29.2,270.4 c 0,0 8.207051,-20.12407 16.1,-19.9 l 0.3,-5.6 z',
            isClicked: false
        },
        {
            id: 31,
            name: 'leftQPTopView',
            title: DamageLocation.LeftQuarterPiece,
            dimensions: 'm 243.86074,299.4 1.5,32.9 c 0,0 -8.99345,-0.37083 -12.2,-16.2 l -6.5875,-1.35446 c 0,0 -3.06399,-14.89733 -3.48661,-34.02084 -0.42262,-19.12351 -0.31696,-26.51934 -0.31696,-26.51934 l 5.17708,-0.10566 4.21399,-3.7997 c 0,0 5.26396,-2.91867 12.3,-14 l 7.13604,-0.056',
            isClicked: false
        },
        {
            id: 32,
            name: 'rightQPRearView',
            title: DamageLocation.RightQuarterPiece,
            dimensions: 'm 165.8,246.2 0.4,3.6 c 0,0 11.52524,7.51548 9.4,9.3 0,0 9.23056,8.35893 5.19626,27.78332 h 6.27557 L 191.1,288.8 c 0,0 6.16768,-17.84444 -6.7,-37 0,0 -14.47071,-5.94036 -18.6,-5.6 z',
            isClicked: false
        },
        {
            id: 33,
            name: 'rightQPTopView',
            title: DamageLocation.RightQuarterPiece,
            dimensions: 'm 333.99794,299.4 -1.5,32.9 c 0,0 8.99345,-0.37083 12.2,-16.2 l 6.5875,-1.35446 c 0,0 3.06399,-14.89733 3.48661,-34.02084 0.42262,-19.12351 0.31696,-26.51934 0.31696,-26.51934 l -5.17708,-0.10566 -4.21399,-3.7997 c 0,0 -5.26396,-2.91867 -12.3,-14 l -7.13604,-0.056',
            isClicked: false
        },
    ];
}

interface IVehicleDamageComponent {
    id: number;
    name: string;
    title: DamageLocation;
    dimensions: string;
    isClicked: boolean;
}

enum DamageLocation {
    FrontWindShield = 'Front Windshield',
    RearWindShield = 'Rear Windshield',
    Roof = 'Roof',
    LeftSide = 'Left Side',
    RightSide = 'Right Side',
    LeftRear = 'Left Rear',
    RightRear = 'Right Rear',
    LeftFront = 'Left Front',
    RightFront = 'Right Front',
    Trunk = 'Trunk',
    Hood = 'Hood',
    LeftFender = 'Left Fender',
    RightFender = 'Right Fender',
    LeftQuarterPiece = "Left Quarter Piece",
    RightQuarterPiece = "Right Quarter Piece"
}