import { Component } from '@angular/core';

@Component({
    selector: 'femaleGenderIcon',
    template: `      <svg x="0px" y="0px" viewBox="0 0 15 30" class="people-icon">
                        <style type="text/css">
                            .st0 {
                                fill: none;
                                stroke: #707070;
                                stroke-width: 0.3771;
                                stroke-miterlimit: 10;
                            }
                        </style>
                        <g>
                            <circle class="st0" cx="7.5" cy="3.4" r="2.7" />
                            <path class="st0" d="M11.7,8.2l2.4,7.3c0.2,0.5-0.1,1.1-0.6,1.3l0,0c-0.5,0.2-1.1-0.1-1.3-0.6L10.6,11l0,0
                     c-0.2,0.2-0.3,0.5-0.2,0.7l2.2,7.7c0,0.2-0.1,0.3-0.3,0.3H10v8.3c0,0.7-0.5,1.2-1.2,1.2h0c-0.7,0-1.3-0.6-1.3-1.3v-8.2H7.1v8.3
                     c0,0.6-0.5,1.2-1.2,1.2h0c-0.6,0-1.2-0.5-1.2-1.2v-8.3H2.4c-0.2,0-0.3-0.2-0.3-0.4l2.6-7.6c0.1-0.3,0-0.6-0.3-0.8h0l-1.7,5.1
                     C2.6,16.7,2,17,1.5,16.8h0C1,16.6,0.7,16,0.9,15.5l2.5-7.3c0.3-1,1.3-1.7,2.4-1.7h3.6C10.4,6.5,11.4,7.2,11.7,8.2z" />
                        </g>
                    </svg>`
})
export class FemaleGenderIconComponent {

}