




import { BaseModel } from './BaseModel';


export class BaseEmailModel {
    
    public EmailType: string = null;
    public EmailAddress: string = null;
}