




import { BaseModel } from './BaseModel';

import { LossParticipantModel } from './LossParticipantModel';

export class FixedPropertyOwnerModel extends BaseModel {
    
    public LossParticipant: LossParticipantModel = null;
    public PropertyDescription: string = null;
}