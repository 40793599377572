<div ngForm #fixedPropertyForm="ngForm" class="main-inner-wrapper">
    <p class="header">Please provide a description of the property and the damages.</p>
    
    <div class="input-wrapper">
        <div class="row">
            <div class="col-lg-10 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Property Description</label>
                </div>
                <input type="text" Validate name="PropertyDescription" [(ngModel)]="vm.PropertyDescription" #PropertyDescription="ngModel"/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-10 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Damage Description</label>
                </div>
                <textarea Validate name="PropertyDamageDescription" rows="3" [(ngModel)]="vm.DamageDescription" #PropertyDamageDescription="ngModel"></textarea>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(fixedPropertyForm)">Continue</button>
    </div>
</div>