




import { BaseModel } from './BaseModel';


export class EmailModel {
    
    public EmailType: string = null;
    public EmailAddress: string = null;
}