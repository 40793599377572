import { Component } from "@angular/core";
import { HomeRoutingService, HomeService } from "@FNOLHome/services";
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { NgForm } from "@angular/forms";

@Component({
    selector: 'hasDwellingDamage',
    templateUrl: '../../views/property/hasDwellingDamage.component.html',
    providers: [HomeRoutingService]
})
export class HasDwellingDamageComponent extends BaseFormComponent {

    public hasDamage: boolean = null;
    public displayError: boolean = false;

    constructor(private fnolRouting: HomeRoutingService, private homeService: HomeService) {
        super();
    }

    ngOnInit(): void {
        this.hasDamage = this.homeService.getDamageToTheHome();
    }

    public selectYes() {
        this.hasDamage = true;
    }

    public selectNo() {
        this.hasDamage = false;
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {

        if (this.hasDamage == null) {
            this.displayError = true;
            return;
        }

        // store the choice in session for the next page
        this.homeService.setDamageToTheHome(this.hasDamage);
        this.fnolRouting.routeToSpecificPage('/fnolHome/claimType');
    }
}