import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { UnsubmittedClaimService } from '../services'

@Component({
    selector: 'closeTab',
    templateUrl: '../views/closeTab.component.html'
})
export class CloseTabComponent extends BaseComponent {

    constructor(private unsubmittedClaim: UnsubmittedClaimService) {
        super();
    }

    public ngOnInit(): void {

        this.unsubmittedClaim.setIgnoreAlert();
        window.close();

    }

}