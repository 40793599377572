




import { BaseModel } from './BaseModel';

import { LossDetailsModel } from './LossDetailsModel';
import { AddressModel } from './AddressModel';
import { ValueDetailModel } from './ValueDetailModel';

export class LossAddressModel extends BaseModel {
    
    public LossDetails: LossDetailsModel = null;
    public Address: AddressModel = null;
    public TimeZones: ValueDetailModel[] = [];
    public States: ValueDetailModel[] = [];
    public PolicyPrimaryAddress: AddressModel = null;
}