import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { FNOLRoutingService, ParticipantApi, VehicleApi, FNOLAutoService } from '@FNOLAuto/services';
import { VehicleInfoModel, VehiclePassengersModel, ParticipantModel, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { ConstantsService } from '@Common/services';


@Component({
    selector: 'passengers',
    templateUrl: '../../views/participant/passengers.component.html',
    providers: [FNOLRoutingService]
})
export class PassengersComponent extends BaseVehicleComponent {

    public vm: VehiclePassengersModel = new VehiclePassengersModel();
    public unknown: ParticipantModel = new ParticipantModel();
    public noPassengersSelected: boolean = false;
    private vehicleId: number;
    private passengerType: string = this.constantsService.EmptyString;

    public isThirdParty: boolean = false;
    

    constructor(private fnolRouting: FNOLRoutingService, private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private vehicleApi: VehicleApi,
                private fnolAutoService: FNOLAutoService, private constantsService: ConstantsService) {
        super();
    }

    public addNew(): void {
        this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment`, { queryParams: { assignmentType: this.passengerType} });
    }

    public addUnknown(): void {
        this.vm.IsUnknownPassengerAdd = true;

        this.api.saveVehiclePassengersModel(this.vm).then(response => {
            if (response.Success) {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment/${response.NewPassengerId}`,
                    { queryParams: { assignmentType: this.passengerType } });
            }
        });
    }

    public ngOnInit(): void {
        this.vm = new VehiclePassengersModel();
        this.vm.Passengers = [];
        this.vm.VehicleInfo = new VehicleInfoModel();
        this.vehicleId = this.fnolRouting.getRoute().snapshot.params.vehicleId;
        this.passengerType = this.constantsService.Passenger.toLowerCase();

        this.spinner.show();
        this.api.getVehiclePassengersModel(this.vehicleId).then(response => {
            this.vm = response;

            if (!this.vm.VehicleInfo.VehicleClaimInfo.IsUnlisted && !this.vm.VehicleInfo.VehicleClaimInfo.IsOnPolicy) {
                this.isThirdParty = true;
                this.passengerType = this.constantsService.ThirdPartyPassenger;
                this.vm.Passengers = this.vm.Passengers.filter(x => x.ContactRole === this.constantsService.Claimant);

            } else {
                this.vm.Passengers = this.vm.Passengers.filter(x => x.ContactRole !== this.constantsService.Claimant);

            }

            this.spinner.hide();
        });
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {
        let claimType = this.fnolAutoService.getClaimTypeCategory();
        let selectedPassengers = this.vm.Passengers.filter(x => x.IsSelected);

        if (selectedPassengers.length === 0) {
            this.noPassengersSelected = true;
            return;
        }

        if (this.validate(form)) {
            if (this.isThirdParty) {
                this.fnolRouting.routeToSpecificPage('fnolAuto/additionalClaimant');
            } else if (claimType !== ClaimTypeCategoryEnum.MultipleVehicleAccident) {
                this.fnolRouting.next();
            } else {
                this.fnolRouting.routeToSpecificPage('fnolAuto/otherPartyInfo');
            }
        }
    }

    public selectPerson(person: ParticipantModel) {
        person.IsSelected = !person.IsSelected;

        if (person.IsSelected) {
            if (person.IsOnPolicy) {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/assignment/${person.Id}`,
                    { queryParams: { assignmentType: this.passengerType } });
            } else {
                this.fnolRouting.routeToSpecificPage(
                    `fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment/${person.Id}`,
                    { queryParams: { assignmentType: this.passengerType } });
            }
        } else {
            // VS wont let me use findIndex...
            let deselectedPersonIndex: number = this.vm.VehicleInfo.SelectedPassengers.map(x => x.Id)
                .indexOf(person.Id);

            this.vm.VehicleInfo.SelectedPassengers.splice(deselectedPersonIndex, 1);

            this.spinner.show();
            this.vehicleApi.saveVehicleInfo(this.vm.VehicleInfo).then(response => {
                this.spinner.hide();
            });
        }
    }

}