




import { BaseModel } from './BaseModel';

import { LossDetailsModel } from './LossDetailsModel';

export class LossDescriptionModel extends BaseModel {
    
    public LossDetails: LossDetailsModel = null;
}