import { Component, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { FNOLRoutingService, ParticipantApi, FNOLAutoService } from '@FNOLAuto/services';
import { ParticipantModel, VehicleOwnerModel, VehicleInfoModel, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services';

@Component({
    selector: 'selectVehicleOwner',
    templateUrl: '../../views/participant/selectVehicleOwner.component.html',
    providers: [FNOLRoutingService]
})
export class SelectVehicleOwner extends BaseFormComponent {

    public vm: VehicleOwnerModel = new VehicleOwnerModel();
    public unknown: ParticipantModel = new ParticipantModel();
    public displayAlreadySelectedMessage: boolean = false;
    private hasAlreadySelected: boolean = false;
    private vehicleId: number;
    public isLoaded: boolean = false;

    public isThirdParty: boolean = false;
    public isPedestrian: boolean = false;

    constructor(private fnolRouting: FNOLRoutingService, private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private fnolAutoService: FNOLAutoService,
                private constantsService: ConstantsService) {
        super();
    }

    public addNew(): void {
        this.fnolAutoService.setUnknownOwner(this.constantsService.FalseStringValue);
        this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment`,
            { queryParams: { assignmentType: this.constantsService.Owner.toLowerCase() } });
    }

    public addUnknown(): void {

        this.unknown.IsSelected = !this.unknown.IsSelected;
        this.fnolAutoService.setUnknownOwner(this.unknown.IsSelected.toString());
    }

    public ngOnInit(): void {
        
        this.checkClaimType();
        this.fnolAutoService.isDeviceMobile.emit(this.isDeviceMobile);
        this.vm = new VehicleOwnerModel();
        this.vm.OwnerOptions = [];
        this.vm.VehicleInfo = new VehicleInfoModel();
        this.vehicleId = this.fnolRouting.getRoute().snapshot.params.vehicleId;

        this.checkUnknownOwner();

        this.spinner.show();
        this.api.getVehicleOwnerModel(this.vehicleId).then(response => {
            this.spinner.hide();
            this.vm = response;
            this.isLoaded = true;

            if (!this.vm.VehicleInfo.VehicleClaimInfo.IsUnlisted && !this.vm.VehicleInfo.VehicleClaimInfo.IsOnPolicy) {
                this.isThirdParty = true;
            }
            if (this.fnolAutoService.getClaimTypeCode() == this.constantsService.ClaimTypeCodePedNF) {
                this.isThirdParty = true;
            }

            if (this.vm.OwnerOptions.filter(x => x.IsSelected).length > 0) {
                this.hasAlreadySelected = true;
                this.fnolAutoService.setUnknownOwner(this.constantsService.FalseStringValue);
            }

            if (!this.isThirdParty) {
                this.vm.OwnerOptions = this.vm.OwnerOptions.filter(x => x.ContactRole !== this.constantsService.Claimant);
            }
        });
        
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {
        let ownersSelected: ParticipantModel[] = this.vm.OwnerOptions.filter(x => x.IsSelected);

        if (ownersSelected.length == 0 && this.hasAlreadySelected) {
            this.displayAlreadySelectedMessage = true;
            return;
        }

        if (ownersSelected.length == 0 && !this.unknown.IsSelected && !this.hasAlreadySelected) {
            this.addUnknown();
        }

        this.fnolRouting.next({
            vehicleId: this.vehicleId.toString()
        });

        this.fnolAutoService.emitIsDeviceMobile(this.isDeviceMobile);
        
    }

    public selectPerson(person: ParticipantModel) {
        person.IsSelected = !person.IsSelected;

        if (this.unknown.IsSelected) {
            this.addUnknown(); // call this function to set it back to false and update the session
        }
        if (person.IsSelected) {   
            if (person.IsOnPolicy) {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/assignment/${person.Id}`,
                    { queryParams: { assignmentType: this.constantsService.Owner.toLowerCase() } });
            } else {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/unlistedAssignment/${person.Id}`,
                    { queryParams: { assignmentType: this.constantsService.Owner.toLowerCase() } });
            }
        }
    }

    public checkUnknownOwner(): void {
        let unknownOwner: string = this.fnolAutoService.getUnknownOwner();

        if (unknownOwner == null || unknownOwner == undefined || unknownOwner != this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = false;
        }

        if (unknownOwner == this.constantsService.TrueStringValue) {
            this.unknown.IsSelected = true;
        }
    }

    public checkClaimType(): void {
        switch (this.fnolAutoService.getClaimTypeCategory()) {
            case ClaimTypeCategoryEnum.Pedestrian:
                this.isPedestrian = true;
                break;
                
            default:
                break;
        }
    }

}