import { BrowserModule } from '@angular/platform-browser';
import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, NgModel } from "@angular/forms";
import { LayoutService, ErrorHandlerService, SessionService } from '@Common/services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { MyClaimsApi } from '@MyClaims360/services';
import { DocumentModel, SessionModel } from '../../../ClaimsWeb.Areas.Models/models';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

@Component({
    selector: 'documentUpload',
    templateUrl: '../views/documentUpload.component.html',
    styleUrls: ['../styles/mc360styling.css']
})

export class DocumentUploadComponent extends BaseFormComponent {
    public vm: DocumentModel;
    public claimDocuments: DocumentModel[];
    public ngOnInit(): void {

        this.spinner.show();
        let lossParticipantId = +this.route.snapshot.paramMap.get("lossParticipantId");
        this.vm = new DocumentModel();
        this.vm.LossParticipantId = lossParticipantId;
        this.myClaimsApi.getLossParticipantDocuments(lossParticipantId).then(docResponse => {
            this.claimDocuments = docResponse.Documents;
            this.spinner.hide();
        });
        this.setMobileConfig();
        
    }
    public goingBackWithLocation(): void {
        this.location.back();
    }
    protected submit(form: NgForm): void {
        
    }

    public progressUpdated(event): void {
        if (event > 0 && event <= 100) {
            this.spinner.show();
        }
        else {
            this.spinner.hide();
            if (event < 0) {
                alert("Something went wrong.  Please contact your adjuster for further assistance.");
            }
        }
    }
    constructor(private location: Location, private route: ActivatedRoute, private layoutService: LayoutService, private myClaimsApi: MyClaimsApi,
        private router: Router, private sessionService: SessionService, private spinner: Ng4LoadingSpinnerService) {
        super();
    }
    public viewDocumentDetails(documentId: number): void {
        this.router.navigate(['myClaims360/documentDetails/' + documentId]);
    }
    public viewDocuments(): void {
        this.router.navigate(['myClaims360/documents/' + this.vm.LossParticipantId]);
    }

}