import { Component } from "@angular/core";
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseComponent } from "@Common/base/base.component";
import { StyleManagerService, CommonClaimApi, SessionService, UnsubmittedClaimService, ContactService } from '@Common/services';
import { CallInClaimModel, BrandEnum } from "@ClaimsModels/index";


@Component({
    selector: 'callInClaim',
    templateUrl: '../views/callInClaim.component.html'
})
export class CallInClaimComponent extends BaseComponent {

    public vm: CallInClaimModel = new CallInClaimModel();

    constructor(private spinner: Ng4LoadingSpinnerService, private styleManager: StyleManagerService, private api: CommonClaimApi, private session: SessionService,
                private unsubmittedClaim: UnsubmittedClaimService, private contactService: ContactService) {
        super();
    }

    public isEncompassBrand: boolean;

    public ngOnInit(): void {

        if (BrandEnum.EncompassInsurance === this.session.getBrand()) {
            this.isEncompassBrand = true;
        }

        this.spinner.show();
        this.api.getCallInClaimModel().then(response => {
            this.spinner.hide();
            this.vm.ReturnUrl = response.ReturnUrl;

            if (!response.Success || this.IsNullOrEmptyString(response.ReturnUrl)) {
                this.vm.ReturnUrl = this.getDefaultReturnUrl();
            }
        });
    }

    public goingBackWithLocation(): void {
        this.unsubmittedClaim.leaveMccApp(this.vm.ReturnUrl);
    }

    private getDefaultReturnUrl(): string {
        let returnUrl: string;

        this.styleManager.returnUrl.subscribe(url => {
            returnUrl = url;
        })

        return returnUrl;
    }

    public getPageHeader(): string {
        let header: string;
        let brand: string = this.session.getBrand();

        switch (brand) {
            case BrandEnum.Esurance:
                header = `Looks like your claim requires the human touch. Give
                          us a call and we'll help you out. Esurance claims
                          professionals are available 24/7 to assist you and get
                          you back on the road as quickly as possible.`;
                break;
            case BrandEnum.SafeAuto:
                header = `SafeAuto Claim Service is available 24/7 365 days a year. 
                          Please call to report your claim.`;
                break;
            default:
                header = `Encompass Claim Service is available 24/7 365 days a year. 
                          Choose from one of our options below to report a new claim.`;
        }

        return header;
    }

    public getClaimPhoneNumber(): string {
        return this.contactService.getClaimsPhone();
    }

}