import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BaseApi } from '@Common/base/base.api';
import { SessionService } from '@Common/services';
import { ErrorHandlerService } from '@Common/services';
import {
    CustomerValidationModel, DashboardModel, VehicleDamageModel,
    LossParticipantMessagesModel, ExposureCorrespondenceModel, ExposureModel, ClaimModel,
    ClaimDocumentsModel, DocumentModel, ClaimPaymentsModel
} from '../../../ClaimsWeb.Areas.Models/models';

@Injectable()
export class MyClaimsApi extends BaseApi {

    constructor(httpClient: HttpClient, error: ErrorHandlerService, session: SessionService, router: Router) {
        super(httpClient, error, session, router);
    }

    public getCustomerValidationInfo(externalId: string): Promise<CustomerValidationModel> {
        return this.httpGet("MyClaims360/Validation/GetCustomerValidation/" + externalId);
    }

    public sendCustomerValidationInfo(model: CustomerValidationModel): Promise<CustomerValidationModel> {
        return this.httpPost("MyClaims360/Validation/SendCustomerValidation", model);
    }

    public getDashboardModel(sessionId: string): Promise<DashboardModel> {
        return this.httpGet("Dashboard/Claim/GetDashboardModel/" + sessionId);
    }

    public initiateDashboard(model: ClaimModel): Promise<ClaimModel> {
        return this.httpPost("Dashboard/Claim/InitiateDashboard", model);
    }

    public getClaimDetails(sessionId: string): Promise<ClaimModel> {
        return this.httpGet("Dashboard/Claim/GetClaimDetails/" + sessionId);
    }

    public getVehicleDamage(id: number): Promise<VehicleDamageModel> {
        return this.httpGet("Dashboard/Claim/GetVehicleDamage/" + id);
    }

    public getMessages(lossParticipantId: number): Promise<LossParticipantMessagesModel> {
        return this.httpGet("Dashboard/Claim/GetParticipantMessages/" + lossParticipantId);
    }

    public getExposure(exposureID: number): Promise<ExposureModel> {
        return this.httpGet("Dashboard/Claim/GetExposure/" + exposureID);
    }

    public saveMessage(model: LossParticipantMessagesModel): Promise<LossParticipantMessagesModel> {
        return this.httpPost("Dashboard/Claim/SaveMessage", model);
    }

    public getLossParticipantPayments(lossParticipantId: number): Promise<ClaimPaymentsModel> {
        return this.httpGet("Dashboard/Claim/GetLossParticipantPayments/" + lossParticipantId);
    }

    public getLossParticipantDocuments(lossParticipantId: number): Promise<ClaimDocumentsModel> {
        return this.httpGet("Dashboard/Claim/GetLossParticipantDocuments/" + lossParticipantId);
    }

    public getDocumentDetails(documentId): Promise<DocumentModel> {
        return this.httpGet("Dashboard/Claim/GetDocumentDetails/" + documentId);
    }

    public postClaimDocument(document: DocumentModel): Promise<DocumentModel> {
        return this.httpPost("Dashboard/Claim/PostClaimDocument", document);
    }
}