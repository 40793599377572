




import { BaseModel } from './BaseModel';


export class ConfirmationInfoModel extends BaseModel {
    
    public ClaimNumber: string = null;
    public AdjusterFirstName: string = null;
    public AdjusterLastName: string = null;
    public AdjusterEmail: string = null;
    public AdjusterPhone: string = null;
    public IsClaimOpen: boolean = false;
    public ReporterEmail: string = null;
    public PolicySubType: string = null;
}