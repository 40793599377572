<div id="claimsHeader" class="card">
    <div class="card-body">
        <h5 class="card-title text-center">
            <i class="bi bi-circle-fill"></i>
            Claim # {{vm.ClaimNumber}}
        </h5>

        <div class="card-text">
            <ul class="progress-claim">
                <li class="complete">
                    Claim
                    <br />
                    Submitted
                </li>
                <li [ngClass]="{'active': claimStatus != (this.constantsService.ClaimStatusClosed), 'complete': claimStatus == (this.constantsService.ClaimStatusClosed)}">
                    Open
                </li>
                <li [ngClass]="{'complete': claimStatus == (this.constantsService.ClaimStatusClosed)}">
                    Closed
                </li>
            </ul>
            <hr class="hide-mobile-hr" />
            <div class="accordion" id="accordionClaimStatus">
                <div class="accordion-item">
                    <div class="accordion-header" id="headingClaimStatus">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseClaimStatus" aria-controls="collapseClaimStatus">
                            <ul class="claim-details">
                                <li *ngIf="!isHome" class="car-type">
                                    <i class="bi bi-car-front"></i>
                                    {{vm.PropertyDescription}}
                                </li>
                                <li *ngIf="isHome" class="car-type-nonmobile">
                                    <i class="bi bi-house"></i>
                                    {{vm.LossAddress.Address1}}
                                </li>
                                <li>
                                    <i class="bi bi-calendar3"></i>
                                    <div class="info-wrap">
                                        <div class="title">Incident Date</div>
                                        {{vm.LossDateTime | date: "MM/dd/YYYY"}}
                                    </div>
                                </li>
                            </ul>
                        </button>
                    </div>

                    <div id="collapseClaimStatus" class="accordion-collapse collapse" aria-labelledby="headingClaimStatus" data-bs-parent="#accordionClaimStatus">
                        <div class="accordion-body">
                            <ul class="claim-details">
                                <li *ngIf="!isHome" class="car-type-nonmobile hide-in-accordion">
                                    <i class="bi bi-car-front"></i>
                                    {{vm.PropertyDescription}}
                                </li>
                                <li *ngIf="isHome" class="house-nonmobile hide-in-accordion">
                                    <i class="bi bi-house"></i>
                                    {{vm.LossAddress.Address1}}
                                </li>
                                <li class="hide-in-accordion">
                                    <i class="bi bi-calendar3"></i>
                                    <div class="info-wrap">
                                        <div class="title">Incident Date</div>
                                        {{vm.LossDateTime | date: "MM/dd/YYYY"}}
                                    </div>
                                </li>
                                <li>
                                    <i class="bi bi-person-check"></i>
                                    <div class="info-wrap">
                                        <div class="title" *ngIf="vm.ParticipantLabel !=''">{{vm.ParticipantLabel}}</div>
                                        {{vm.ParticipantName}}
                                    </div>
                                </li>
                                <li *ngIf="!isHome">
                                    <i class="bi bi-geo-alt"></i>
                                    <div class="info-wrap">
                                        <div class="title">Accident Location</div>
                                        {{vm.LossAddress.Address1}}<br>
                                        {{vm.LossAddress.City}}, {{vm.LossAddress.State}}, {{vm.LossAddress.Zip}}
                                    </div>
                                </li>
                                <li *ngIf="isHome">
                                    <i class="bi bi-geo-alt"></i>
                                    <div class="info-wrap">
                                        <div class="title">Location</div>
                                        {{vm.LossAddress.Address1}}<br>
                                        {{vm.LossAddress.City}}, {{vm.LossAddress.State}}, {{vm.LossAddress.Zip}}
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
        </div>
    </div>
</div>
