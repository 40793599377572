import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { LayoutService, StyleManagerService, LoggerApi, UnsubmittedClaimService, ConstantsService, SessionService } from '../services';
import { DOCUMENT } from '@angular/common';
import { Renderer2, Inject } from '@angular/core';
import { RecordingSessionModel } from '../../../ClaimsWeb.Areas.Models/models/RecordingSessionModel';

@Component({
    selector: 'layout',
    templateUrl: '../views/layout.component.html'
})
export class LayoutComponent implements OnInit {
    public year: number;
    public username: string;
    public showUsername: boolean = false;
    private recordingScriptSettings: RecordingSessionModel;
    public selectedBrand: string = "";

    constructor(private service: LayoutService, private styleManager: StyleManagerService, private loggerApi: LoggerApi,
        private unsubmittedClaim: UnsubmittedClaimService, private sessionService: SessionService,
        private constantsService: ConstantsService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.recordingScriptSettings = this.sessionService.getRecordingSessionSettings();
        this.year = new Date().getFullYear();
        this.service.getShowUsername().subscribe((show) => {
            this.username = this.service.getUsername();
            this.showUsername = show && (this.username !== null && this.username !== undefined && this.username !== '');
        });

        this.service.getBrand().subscribe(brand => {
            this.styleManager.applyBrand(brand);
            this.selectedBrand = brand;
            this.changeDetector.detectChanges();

            if (this.selectedBrand == this.constantsService.MC360) {
                this.loadBootstrap5();
            }
        });

        if (this.service.getUsername() !== null &&
            this.service.getUsername() !== undefined &&
            this.service.getUsername() !== '') {
            this.username = this.service.getUsername();
            this.showUsername = true;
        } else {
            this.username = null;
            this.showUsername = false;
        }

        if (this.recordingScriptSettings?.RecordSession == true) {
            let script = this.renderer.createElement('script');
            script.type = 'text/javascript';
            script.src = this.recordingScriptSettings.ScriptSource;
            script.setAttribute(this.recordingScriptSettings.AttributeName, this.recordingScriptSettings.AttributeValue);
            this.renderer.appendChild(this.document.body, script);
        }

    }

    @HostListener("window:beforeunload", ["$event"]) OnPageUnload(event: Event) {
        return this.unsubmittedClaim.displayDefaultBrowserAlert()
    }


    private bootstrapLoaded = false;

    private loadBootstrap5() {
        if (!this.bootstrapLoaded) {
            this.removeAllOtherStyles();
            this.bootstrapLoaded = true;

            console.log('loadBootstrap5');


            this.loadRemoteStylesheet('https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.0/css/bootstrap.min.css', () => {
                var randomNum = this.getRandomInteger(0, 999999);

                this.loadRemoteStylesheet('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');
                this.loadRemoteStylesheet('assets/styles/mc360.css?R=' + randomNum);

                this.loadRemoteScript('https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.0/js/bootstrap.bundle.min.js', () => {
                    // ... (your Bootstrap initialization code, if needed)



                    this.bootstrapLoaded = true;
                });

            });
        }
    }

    private getRandomInteger(min, max) {
        min = Math.ceil(min)
        max = Math.floor(max)

        return Math.floor(Math.random() * (max - min)) + min
    }

    private removeAllOtherStyles() {
        const allowedStylesheets = [
            'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css',
            'https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.3.0/css/bootstrap.min.css',
            'assets/styles/mc360.css'
        ];

        // Cast to NodeListOf<HTMLLinkElement> to ensure type safety
        const stylesheetLinks = document.querySelectorAll('link[rel="stylesheet"]') as NodeListOf<HTMLLinkElement>;
        stylesheetLinks.forEach(link => {
            if (!allowedStylesheets.includes(link.href)) {
                link.remove();
            }
        });

        const allElements = document.querySelectorAll('*');
        allElements.forEach(el => {
            el.removeAttribute('style');
        });
    }

    private loadRemoteStylesheet(url: string, onLoadCallback?: () => void) {
        const link = document.createElement('link') as HTMLLinkElement;
        link.rel = 'stylesheet';
        link.href = url;
        //link.type = 'text/css';
        link.onerror = () => {
            console.error(`Error loading stylesheet: ${url}`);
        };

        document.head.appendChild(link);

        if (onLoadCallback) {
            link.onload = onLoadCallback;
        }
    }

    private loadRemoteScript(url: string, onLoadCallback?: () => void) {
        const script = document.createElement('script');
        script.src = url;

        script.onerror = () => {
            console.error(`Error loading script: ${url}`);
        };

        script.onload = () => {
            //console.log(`${url} loaded!`);
            if (onLoadCallback) {
                onLoadCallback();
            }
        };
        document.body.appendChild(script);
    }


}