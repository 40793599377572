




import { BaseModel } from './BaseModel';

import { PaymentModel } from './PaymentModel';

export class ClaimPaymentsModel extends BaseModel {
    
    public ClaimNumber: string = null;
    public Payments: PaymentModel[] = [];
}