import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SessionBridgeApi {

    // This API should be going away
    constructor(private http: HttpClient) {
    }

    public generateBridgePayload(policyNumber: string, appName: string, sourceParty: string): Promise<string> {

        return this.http.post("/Common/Bridge/GenerateBridgePayload", { PolicyNumber: policyNumber, ApplicationName: appName, SourceParty: sourceParty}, { responseType: 'text' })
            .toPromise()
            .then(response => {
                return response;
            });
    }

    public getSessionReturnUrl(id: string): Promise<string> {
        return this.http.get("/Common/Bridge/GetSessionReturnURL?id=" + id, { responseType: 'text' })
            .toPromise()
            .then(response => {
                return response;
            });
    }

    public getBrand(useEncompass: boolean): Promise<string> {
        return this.http.get("/Common/Bridge/GetBrand?isEncompass=" + useEncompass, { responseType: 'text' })
            .toPromise()
            .then(response => {
                return response
            });
    }
}