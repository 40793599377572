




import { BaseModel } from './BaseModel';


export class ClaimTypeModel extends BaseModel {
    
    public ClaimType: string = null;
    public PolicySubType: string = null;
    public PolicyMCOName: string = null;
}