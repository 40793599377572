import { Component, HostListener } from '@angular/core';

import { NgForm, NgModel } from "@angular/forms";
import { BaseComponent } from './base.component';
import { ReporterInformationModel, PhoneModel } from '@ClaimsModels/index';
import { DeviceTrackingModel, BrandEnum } from '@ClaimsModels/index';
import { ServiceLocator } from '../../../../app/locator.service';
import { ConstantsService } from '../services';

@Component({
    template: ''
})
export abstract class BaseFormComponent extends BaseComponent {
    public abstract ngOnInit(): void;
    public abstract goingBackWithLocation(): void;
    protected abstract submit(form: NgForm): void;
    public isDeviceMobile = false;
    //use manually injected service to avoid DI parameter in super()
    public injectorConstantsService: ConstantsService; 

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.setDeviceTrackingInfo();
        this.setMobileConfig();
         
    }
    constructor() {
        super();
        this.injectorConstantsService = ServiceLocator.injector.get(ConstantsService);
        this.onResize();
    }

    protected validate(form: NgForm): boolean {
        Object.keys(form.controls).forEach(key => {
            let value = form.controls[key].value;
            if (typeof value === 'string' && !this.IsNullOrEmptyString(value)) {
                if (value.trim().length === 0) {
                    form.controls[key].setValue("");
                    form.controls[key].markAsTouched();
                }
            }
        })
        if (form && form.valid) {
            return true;
        } else {
            Object.keys(form.controls).forEach(key => {
                form.controls[key].markAsDirty();
                form.controls[key].markAsTouched();
            });
            this.findFirstInvalidElement();
            return false;
        }
    }

    public determineIfInjured(injuryDescription: string): boolean {

        if (injuryDescription == null) { // user chose nothing
            return null;
        }

        if (injuryDescription.length == 0) { // user selected no
            return false;
        }

        return true; // user selected yes
    }

    public findFirstInvalidElement() {
        const invalidElement: HTMLElement = document.querySelector('div[ngForm] .ng-invalid');
        this.setInvalid(invalidElement);
    }

    // this is only for specific circumstances where the form
    // will be invalid but isn't yet.
    // Example: AddressValidation popup sets the Zip to blank and we exit out
    public setInvalid(invalidElement: HTMLElement): void {
        if (invalidElement) { // ensure we have an object
            invalidElement.scrollIntoView({ behavior: 'smooth' });
            invalidElement.focus();
        }
    }

    public childHasInvalid(element: NgModel) {
        return {
            // adds the invalid element if the statement is true...
            invalid: element.control.invalid && element.control.touched
        };
    }

    public addressSetIsInvalid(address: NgModel, city: NgModel, state: NgModel, zip: NgModel) {
        let controls: NgModel[] = [address, state];

        if (zip !== null && zip !== undefined) {
            controls.push(zip);
        }

        if (city !== null && city !== undefined) {
            controls.push(city);
        }

        let isInvalid: boolean = false;

        for (let control of controls) {
            if (control.control.invalid && control.control.touched) {
                isInvalid = true;
            }
        }

        return {
            invalid: isInvalid
        };
    }

    public convertToUTCDate(dateString: string): Date {
        let date: Date = new Date(dateString);
        return new Date(date.getFullYear()
            + '-' + this.leftPad(date.getMonth() + 1)
            + '-' + this.leftPad(date.getDate())
            + 'T' + this.leftPad(date.getHours()) // t
            + ':' + this.leftPad(date.getMinutes())
            + ':' + this.leftPad(date.getSeconds())
            + 'Z' // the z is what makes it a utc date
        )
    }

    public leftPad(value, length = 2, leftPadChar = '0'): string {
        return (String(leftPadChar).repeat(length) + String(value)).slice(String(value).length);
    }

    public getApplicationName(form: HTMLFormElement, isEnc: boolean): HTMLInputElement {

        let user: HTMLInputElement = <HTMLInputElement>form.children.namedItem("userType");

        let appName: HTMLInputElement = <HTMLInputElement>form.children.namedItem("ApplicationName");
        
        if (user.value == "Insured") {
            appName.value = "ClaimsWeb";

        } else if (user.value == "Agent") {
            appName.value = "NPS";
        }
        else {
            appName.value = user.value; //InsuredAndAgent
        }

        if (isEnc) {
            appName.value = "EncompassAgencyDashboard";
        }

        return appName;
    }

    public fillEmptyPhones(person: ReporterInformationModel): PhoneModel[] {
        let result: PhoneModel[] = person.ContactInfo.Phones;

        if (result === null || result.length === 0) {

            let newPhone = new PhoneModel();
            newPhone.IsActive = true;

            result.push(newPhone);
        }

        // fill in the empty phones with inactive phones
        while (result.length < 0) {
            let newPhone = new PhoneModel();
            newPhone.IsActive = false;
            result.push(newPhone);
        }

        return result;
    }

    public setDeviceTrackingInfo(): DeviceTrackingModel {
        let deviceTrackingModel = new DeviceTrackingModel();
        deviceTrackingModel.UserAgent = navigator.userAgent;
        deviceTrackingModel.ScreenSize = `${screen.availHeight} x ${screen.availWidth} (${screen.pixelDepth} bit)`;
        deviceTrackingModel.Properties = JSON.stringify({
            vendor: navigator.vendor,
            platform: navigator.platform,
            product: navigator.product
        });
        return deviceTrackingModel;
    }

    public setMobileConfig() {
        this.isDeviceMobile = screen.availWidth <  this.injectorConstantsService.MobileWidthThresholdMax ? true : false;
    }
}