import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { EmergencyResponseModel } from '@ClaimsModels/EmergencyResponseModel';
import { LossApi, FNOLRoutingService } from '@FNOLAuto/services';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ConstantsService } from '@Common/services';


@Component({
    selector: 'emergencyResponse',
    templateUrl: '../../views/loss/emergencyResponse.component.html',
    providers: [FNOLRoutingService]
})
export class EmergencyResponseComponent extends BaseFormComponent {

    constructor(private api: LossApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, private constantsService: ConstantsService) {
        super();
    }

    public vm: EmergencyResponseModel = new EmergencyResponseModel();
    public ems: EmergencyType = new EmergencyType();
    public police: EmergencyType = new EmergencyType();
    public firefighter: EmergencyType = new EmergencyType();
    public hasEmergencyService: boolean = null;
    public selectionMade: boolean = false;

    private allSelections: EmergencyType[] = [
        this.ems, this.police, this.firefighter
    ]

    ngOnInit(): void {
        if (sessionStorage.getItem('hasEmergencyService') === "true")
            this.setIsEmergencyResponse(true)
        if (sessionStorage.getItem('hasEmergencyService') === "false")
            this.setIsEmergencyResponse(false);
        if (sessionStorage.getItem('IsSelectionMade') === "true")
            this.setIsSelectionMade(true)
        if (sessionStorage.getItem('IsSelectionMade') === "false")
            this.setIsSelectionMade(false);

        this.spinner.show();
        this.initializeTypes();

        this.api.getEmergencyResponseDetailsModel().then(response => {
            this.spinner.hide();
            this.vm = response;

        });
    }

    public goingBackWithLocation() {
        if (this.selectionMade) {
            this.selectionMade = false;
            this.scrollToTopOfPage();
            return;
        }
        sessionStorage.removeItem('hasEmergencyService');
        sessionStorage.removeItem('IsSelectionMade');

        this.fnolRouting.back();
    }

    public selectTypeService(e, emergencyType: EmergencyType): void {
        if (emergencyType.name == this.constantsService.EMS) {
            this.vm.EMS = !emergencyType.isSelected;
        } else if (emergencyType.name == this.constantsService.Police) {
            this.vm.Police = !emergencyType.isSelected;
        } else if (emergencyType.name == this.constantsService.Firefighter) {
            this.vm.Fire = !emergencyType.isSelected;;
        }

        this.toggleSelection(emergencyType);
    }

    private toggleSelection(emergencyType: EmergencyType): void {
        let isSelected = !emergencyType.isSelected;
        let imgElement = document.querySelector(`#${emergencyType.name} img`);

        if (imgElement != null) {
            if (isSelected) {
                imgElement.setAttribute("src", "/content/images/" + `${emergencyType.name}` + ".svg");
            } else {
                imgElement.setAttribute("src", "/content/images/" + `${emergencyType.name}-outline` + ".svg");
            }
        }

        emergencyType.isSelected = isSelected;
    }

    private initializeTypes(): void {
        this.ems.name = this.constantsService.EMS;
        this.police.name = this.constantsService.Police;
        this.firefighter.name = this.constantsService.Firefighter;
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.api.saveEmergencyResponseDetailsModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
            sessionStorage.setItem('hasEmergencyService', this.hasEmergencyService.toString());
            sessionStorage.setItem('IsSelectionMade', this.hasEmergencyService.toString());

        }
    }

    public selectYes(): void {
        this.scrollToTopOfPage();
        this.hasEmergencyService = true;
        this.selectionMade = true;
        sessionStorage.setItem('hasEmergencyService', 'true');
        sessionStorage.setItem('IsSelectionMade', 'true');
    }

    public selectNo(): void {
        this.scrollToTopOfPage();
        this.hasEmergencyService = false;
        this.selectionMade = true;
        sessionStorage.setItem('hasEmergencyService', 'false');
        sessionStorage.setItem('IsSelectionMade', 'false');
        this.vm = new EmergencyResponseModel();
        this.spinner.show();
        this.api.saveEmergencyResponseDetailsModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                this.fnolRouting.next();
            }
        });

    }

    public setIsEmergencyResponse(value: boolean): void {
        this.hasEmergencyService = value;

    }
    public setIsSelectionMade(value: boolean): void {
        this.selectionMade = value;

    }
}

class EmergencyType {
    isSelected: boolean = false;
    name: string;
}