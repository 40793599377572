import { Injectable } from "@angular/core";
import { BaseApi } from "../../base/base.api";

import {
    BaseClaimReporterModel, BaseLossParticipantModel, ReporterDetailsModel
} from '@ClaimsModels/index';

@Injectable()
export class BaseParticipantApi extends BaseApi {

    public getClaimReportModel(): Promise<BaseClaimReporterModel> {
        return this.httpGet("Common/Participant/GetClaimReporterModel");
    }

    public saveClaimReportModel(model: BaseClaimReporterModel): Promise<BaseClaimReporterModel> {
        return this.httpPost("Common/Participant/SaveClaimReporterModel", model);
    }

    public getLossParticipantModel(id: number): Promise<BaseLossParticipantModel> {
        return this.httpGet("Common/Participant/GetLossParticipantModel/" + id);
    }

    public saveLossParticipantModel(model: BaseLossParticipantModel): Promise<BaseLossParticipantModel> {
        return this.httpPost("Common/Participant/SaveLossParticipantModel", model);
    }

    public getReporterDetailsModel(): Promise<ReporterDetailsModel> {
        return this.httpGet("Common/Participant/GetReporterDetailsModel");
    }
}