import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { BaseApi } from '@Common/base/base.api';
import { SessionService } from '@Common/services';
import { ErrorHandlerService } from '@Common/services';
import { SelectVehiclesModel, VehicleInfoModel, VehicleOwnerModel, VehicleDriverModel, VehiclePassengersModel } from '@ClaimsModels/index';

@Injectable()
export class VehicleApi extends BaseApi {

    constructor(httpClient: HttpClient, error: ErrorHandlerService, session: SessionService, router: Router) {
        super(httpClient, error, session, router);
    }

    public getSelectVehiclesModel(): Promise<SelectVehiclesModel> {
        return this.httpGet("FNOLAuto/Vehicle/GetSelectVehiclesModel");
    }

    public saveSelectVehiclesModel(model: SelectVehiclesModel): Promise<SelectVehiclesModel> {
        return this.httpPost("FNOLAuto/Vehicle/SaveSelectVehiclesModel", model);
    }

    public getVehicleInfo(id: number = null): Promise<VehicleInfoModel> {
        return this.httpGet("FNOLAuto/Vehicle/GetVehicleInfo/" + id);
    }

    public saveVehicleInfo(model: VehicleInfoModel): Promise<VehicleInfoModel> {
        return this.httpPost("FNOLAuto/Vehicle/SaveVehicleInfo", model);
    }

    public getVehicleOwnerModel(id: number = null): Promise<VehicleOwnerModel> {
        return this.httpGet("FNOLAuto/Vehicle/GetVehicleOwnerModel/" + id);
    }

    public saveVehicleOwnerModel(model: VehicleOwnerModel): Promise<VehicleOwnerModel> {
        return this.httpPost("FNOLAuto/Vehicle/SaveVehicleOwnerModel", model);
    }

    public getVehicleDriverModel(id: number = null): Promise<VehicleDriverModel> {
        return this.httpGet("FNOLAuto/Vehicle/GetVehicleDriverModel/" + id);
    }

    public saveVehicleDriverModel(model: VehicleDriverModel): Promise<VehicleDriverModel> {
        return this.httpPost("FNOLAuto/Vehicle/SaveVehicleDriverModel", model);
    }

    public getVehiclePassengersModel(id: number = null): Promise<VehiclePassengersModel> {
        return this.httpGet("FNOLAuto/Vehicle/GetVehiclePassengersModel/" + id);
    }

    public saveVehiclePassengersModel(model: VehiclePassengersModel): Promise<VehiclePassengersModel> {
        return this.httpPost("FNOLAuto/Vehicle/SaveVehiclePassengersModel", model);
    }
}