import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BaseComponent } from '../../base/base.component';

@Component({
    selector: 'addressValidation',
    templateUrl: '../../views/modals/addressValidation.component.html'
})
export class AddressValidationBottomSheet extends BaseComponent {
    constructor(public sheetRef: MatBottomSheetRef, @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
        super();
        this.sheetRef.backdropClick().subscribe(result => {
            document.querySelector(".cdk-overlay-container").animate([{ transform: 'translateY(0%)' }, { transform: 'translateY(-200%)' }], { duration: 500 });
        });
    }

    ngOnInit(): void {
        
    }

    public keepOriginal(): void {
        document.querySelector(".cdk-overlay-container").animate([{ transform: 'translateY(0%)' }, { transform: 'translateY(-200%)' }], { duration: 500 });
        this.sheetRef.dismiss({ event: 'Keep' });
    }

    public changeAddress(): void{
        document.querySelector(".cdk-overlay-container").animate([{ transform: 'translateY(0%)' }, { transform: 'translateY(-200%)' }], { duration: 500 });
        this.sheetRef.dismiss({ event: 'Change' });
    }
}