<div class="main-inner-wrapper no-mobile">
    <div class="container">
        <p class="textConfirmation">You currently have an open claim.  Would you like to continue and create a new claim, or return to your account?</p>
        <div class="row">
            <div class="col col-lg-12 col-xs-12 ">
                <div class="d-flex justify-content-center">
                    <section>
                        <div>
                            <label class="openClaimTypeLabel">
                                Claim Number:
                            </label>
                            <span>
                                <strong><em>{{vm.ClaimNumber}}</em></strong>
                            </span>
                        </div>
                        <div *ngIf="showAdjuster">
                            <label class="openClaimTypeLabel">
                                Adjuster Name:
                            </label>
                            <span>
                                <strong><em>{{vm.AdjusterFullName}}</em></strong>
                            </span>
                        </div>
                        <div>
                            <label class="openClaimTypeLabel">
                                Date Of Loss:
                            </label>
                            <span>
                                <strong><em>{{lossDate}}</em></strong>
                            </span>

                        </div>
                    </section>
                </div>
                <br />
                <div class="d-flex justify-content-center">
                    <span>Your adjusters contact information can be found below:</span>
                </div>
                <br />
                <div class="d-flex justify-content-center">
                    <section>
                        <div>
                            <label class="openClaimTypeLabel">Email: </label>
                            <span><strong><a (click)="sendEmail()" role="button" tabindex="0" class="cursor">{{claimsEmail}}</a></strong></span>
                        </div>
                        <div *ngIf="showAdjusterPhone">
                            <label class="openClaimTypeLabel">Phone: </label>
                            <span style="white-space: nowrap" *ngIf="vm.AdjusterWorkPhone.length == 10"><strong><a href="tel:+1{{ vm.AdjusterWorkPhone }}">{{ vm.AdjusterWorkPhone | mask: '(000) 000-0000'  }}</a></strong></span>
                            <span style="white-space: nowrap" *ngIf="vm.AdjusterWorkPhone.length > 10"><strong><em>{{ vm.AdjusterWorkPhone | mask: '(000) 000-0000 SSS 9999999' }}</em></strong></span> <br />
                        </div>
                        <div *ngIf="!showAdjusterPhone">
                            <label class="openClaimTypeLabel">Phone: </label>
                            <span style="white-space: nowrap"><strong>{{claimsPhone}}</strong></span>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Return</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">Continue</button>
    </div>
</div>