import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { ClaimTypeCategoryEnum, RouteCrumb, Breadcrumb } from "@ClaimsModels/index";
import { Router, ActivatedRoute } from "@angular/router";
import { FNOLAutoService } from "./fnolAuto.service";
import { BreadcrumbService, ConstantsService } from "@Common/services";
import { BaseRoutingService } from '@Common/base/baseRouting.service';

@Injectable()
export class FNOLRoutingService extends BaseRoutingService {
    constructor(location: Location, router: Router, public route: ActivatedRoute, public breadcrumbService: BreadcrumbService,
        private fnolAutoService: FNOLAutoService, private constantsService: ConstantsService) {

        super(location, router, route, breadcrumbService, fnolAutoService);
        this.checkOccupancy();
    }

    private checkOccupancy(): void {
        let hasOccupants: boolean = this.fnolAutoService.getHasOccupantInVehicle() == this.constantsService.TrueStringValue;
        if (!hasOccupants) {
            return;
        }
        this.reinitializeRoutes();
    }

   

    public initialize(claimType: ClaimTypeCategoryEnum, claimTypeCode: string): void {
        /*
         * Add a breadcrumb here and associate a route with it
         * You can group routes by using the same name
         */
        this.routes.length = 0; // clear any potential previous routes before we add in our routes.
        switch (claimType) {
            case ClaimTypeCategoryEnum.SingleVehicle:
                this.SingleVehicleRoutes();
                break;
            case ClaimTypeCategoryEnum.TireBlowout:
                this.SingleVehicleRoutesWithoutLossDescriptionPage();
                break;
            case ClaimTypeCategoryEnum.RoadHazard:
                this.SingleVehicleRoutesWithoutLossDescriptionPage();
                break;
            case ClaimTypeCategoryEnum.FallingObject:
                this.SingleVehicleRoutesWithoutLossDescriptionPage();
                break;
            case ClaimTypeCategoryEnum.AnimalCollision:
                this.SingleVehicleRoutesWithoutLossDescriptionPage();
                break;
            case ClaimTypeCategoryEnum.RearEndedAF:
                this.MultiVehicleRoutesWithoutLossDescriptionPage();
                break;
            case ClaimTypeCategoryEnum.RearEndedNAF:
                this.MultiVehicleRoutesWithoutLossDescriptionPage();
                break;
            case ClaimTypeCategoryEnum.MultipleVehicleAccident:
                this.MultiVehicleRoutes();
                break;
            case ClaimTypeCategoryEnum.FixedProperty:
                this.FixedPropertyRoutes();
                break;
            case ClaimTypeCategoryEnum.Theft:
                this.TheftNoOccupantsRoutes();
                break;
            case ClaimTypeCategoryEnum.Weather:
                this.WeatherNoOccupantsRoutes();
                break;
            case ClaimTypeCategoryEnum.Vandalism:
                this.VandalismNoOccupantsRoutes();
                break;
            case ClaimTypeCategoryEnum.Pedestrian:
                this.PedestrianRoutes(claimTypeCode);
                break;
            case ClaimTypeCategoryEnum.Fire:
                this.FireNoOccupantsRoutes();
                break;
            case ClaimTypeCategoryEnum.Glass:
                this.GlassOnlyNoOccupantsRoutes();
                break;
            case ClaimTypeCategoryEnum.Awning:
                this.AwningOnlyNoOccupantsRoutes();
                break;
            case ClaimTypeCategoryEnum.MiscRV:
                this.MiscRVNoOccupantsRoute();
                break;
            case ClaimTypeCategoryEnum.Mechanical:
            case ClaimTypeCategoryEnum.LockOut:
            case ClaimTypeCategoryEnum.JumpStart:
            case ClaimTypeCategoryEnum.FlatTire:
            case ClaimTypeCategoryEnum.GasDelivery:
            case ClaimTypeCategoryEnum.Winch:
            case ClaimTypeCategoryEnum.IllegallyParkedTow:
            case ClaimTypeCategoryEnum.MechanicalBreakdown:
                this.TowingRoutes();
                break;
            case ClaimTypeCategoryEnum.Impounded:
            case ClaimTypeCategoryEnum.Other:
                this.TowingRoutesWithLossDescription();
                break;
            default:
                this.useOriginalRoutes();
                break;
        }
    }

    private SingleVehicleRoutes(): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            ClaimReporter: new Breadcrumb('Claim Reporter', 3),
            LossAddress: new Breadcrumb('Loss Address', 4),
            Vehicles: new Breadcrumb('Vehicles', 5),
            Passengers: new Breadcrumb('Passengers', 6),
            Document: new Breadcrumb('Document', 7),
            Review: new Breadcrumb('Review', 8),
            Confirmation: new Breadcrumb('Confirmation', 9)
        };

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);
        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, uploadDocumentPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, uploadDocumentPage);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Vehicles, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, lossDescription);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleDetail,
            nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage, vehicleDriverPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, unlistedDriverWithIdPage, unlistedDriverPage,
            smsConsent
        );
    }

    private SingleVehicleRoutesWithoutLossDescriptionPage(): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            ClaimReporter: new Breadcrumb('Claim Reporter', 3),
            LossAddress: new Breadcrumb('Loss Address', 4),
            Vehicles: new Breadcrumb('Vehicles', 5),
            Passengers: new Breadcrumb('Passengers', 6),
            Document: new Breadcrumb('Document', 7),
            Review: new Breadcrumb('Review', 8),
            Confirmation: new Breadcrumb('Confirmation', 9)
        };

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);
        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, uploadDocumentPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, uploadDocumentPage);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Vehicles, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, emergencyResponse);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleDetail,
            nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage, vehicleDriverPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, unlistedDriverWithIdPage, unlistedDriverPage,
            smsConsent
        );
    }

    private MultiVehicleRoutes(): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            ClaimReporter: new Breadcrumb('Claim Reporter', 3),
            LossAddress: new Breadcrumb('Loss Address', 4),
            Vehicles: new Breadcrumb('Vehicles', 5),
            Passengers: new Breadcrumb('Passengers', 6),
            OtherPartyInfo: new Breadcrumb('Other Party Info', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)

        };

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        // third party pages
        let additionalClaimantPage = new RouteCrumb(`fnolAuto/additionalClaimant`, crumbs.OtherPartyInfo, uploadDocumentPage);
        let thirdPartyPassengersPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyPassengers', crumbs.OtherPartyInfo, additionalClaimantPage);
        let thirdPartyHasPassengersPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyHasPassengers', crumbs.OtherPartyInfo, thirdPartyPassengersPage);
        let thirdPartyDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyDriver', crumbs.OtherPartyInfo, thirdPartyHasPassengersPage);
        let thirdPartyOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyOwner', crumbs.OtherPartyInfo, thirdPartyDriverPage);
        let thirdPartyOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyOwner/:participantId?assignmentType=thirdPartyOwner', crumbs.OtherPartyInfo, thirdPartyDriverPage);
        let otherPartyInfoPage = new RouteCrumb('fnolAuto/otherPartyInfo', crumbs.OtherPartyInfo, null);

        // Claimant third party drivers
        let unlistedThirdPartyOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=thirdPartyOwner', crumbs.Vehicles, thirdPartyDriverPage);
        let unlistedThirdPartyDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=thirdPartyDriver', crumbs.OtherPartyInfo, thirdPartyHasPassengersPage);
        let unlistedThirdPartyDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=thirdPartyDriver', crumbs.Vehicles, thirdPartyHasPassengersPage);
        let unlistedThirdPartyOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=thirdPartyOwner', crumbs.Vehicles, thirdPartyDriverPage);

        // base routing
        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, otherPartyInfoPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, otherPartyInfoPage);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Vehicles, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, lossDescription);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        // insured party but unlisted vehicle pages
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleDetail,
            nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage, vehicleDriverPage, unlistedOwnerPage, unlistedOwnerWithIdPage, unlistedDriverWithIdPage,
            thirdPartyOwnerWithIdPage, thirdPartyOwnerPage, thirdPartyDriverPage, thirdPartyHasPassengersPage, thirdPartyPassengersPage,
            unlistedThirdPartyDriverWithIdPage, unlistedThirdPartyOwnerPage, unlistedThirdPartyOwnerWithIdPage, unlistedThirdPartyDriverPage, otherPartyInfoPage,
            unlistedDriverPage, smsConsent
        );
    }

    private MultiVehicleRoutesWithoutLossDescriptionPage(): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            ClaimReporter: new Breadcrumb('Claim Reporter', 3),
            LossAddress: new Breadcrumb('Loss Address', 4),
            Vehicles: new Breadcrumb('Vehicles', 5),
            Passengers: new Breadcrumb('Passengers', 6),
            OtherPartyInfo: new Breadcrumb('Other Party Info', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)
        };

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        // third party pages
        let additionalClaimantPage = new RouteCrumb(`fnolAuto/additionalClaimant`, crumbs.OtherPartyInfo, uploadDocumentPage);
        let thirdPartyPassengersPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyPassengers', crumbs.OtherPartyInfo, additionalClaimantPage);
        let thirdPartyHasPassengersPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyHasPassengers', crumbs.OtherPartyInfo, thirdPartyPassengersPage);
        let thirdPartyDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyDriver', crumbs.OtherPartyInfo, thirdPartyHasPassengersPage);
        let thirdPartyOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyOwner', crumbs.OtherPartyInfo, thirdPartyDriverPage);
        let thirdPartyOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyOwner/:participantId?assignmentType=thirdPartyOwner', crumbs.OtherPartyInfo, thirdPartyDriverPage);
        let otherPartyInfoPage = new RouteCrumb('fnolAuto/otherPartyInfo', crumbs.OtherPartyInfo, null);

        // Claimant third party drivers
        let unlistedThirdPartyOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=thirdPartyOwner', crumbs.Vehicles, thirdPartyDriverPage);
        let unlistedThirdPartyDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=thirdPartyDriver', crumbs.OtherPartyInfo, thirdPartyHasPassengersPage);
        let unlistedThirdPartyDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=thirdPartyDriver', crumbs.Vehicles, thirdPartyHasPassengersPage);
        let unlistedThirdPartyOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=thirdPartyOwner', crumbs.Vehicles, thirdPartyDriverPage);

        // base routing
        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, otherPartyInfoPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, otherPartyInfoPage);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Vehicles, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, emergencyResponse);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        // insured party but unlisted vehicle pages
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleDetail,
            nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage, vehicleDriverPage, unlistedOwnerPage, unlistedOwnerWithIdPage, unlistedDriverWithIdPage,
            thirdPartyOwnerWithIdPage, thirdPartyOwnerPage, thirdPartyDriverPage, thirdPartyHasPassengersPage, thirdPartyPassengersPage,
            unlistedThirdPartyDriverWithIdPage, unlistedThirdPartyOwnerPage, unlistedThirdPartyOwnerWithIdPage, unlistedThirdPartyDriverPage, otherPartyInfoPage,
            unlistedDriverPage, smsConsent
        );
    }

    private PedestrianRoutes(claimTypeCode): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            ClaimReporter: new Breadcrumb('Claim Reporter', 3),
            LossAddress: new Breadcrumb('Loss Address', 4),
            Pedestrian: new Breadcrumb('Pedestrian', 5),
            Vehicles: new Breadcrumb('Vehicles', 6),
            Passengers: new Breadcrumb('Passengers', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)
        };
        //base
        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);
        let additionalClaimantPage = new RouteCrumb('fnolAuto/additionalClaimant', crumbs.Passengers, uploadDocumentPage);

        //Claimant vehicle & insured pedestrian
        let thirdPartyPassengersPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyPassengers', crumbs.Passengers, additionalClaimantPage);
        let thirdPartyHasPassengersPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyHasPassengers', crumbs.Passengers, thirdPartyPassengersPage);
        let thirdPartyDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyDriver', crumbs.Vehicles, thirdPartyHasPassengersPage);
        let thirdPartyOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/thirdPartyOwner?assignmentType=thirdPartyOwner', crumbs.Vehicles, thirdPartyDriverPage);
        let thirdPartyVehiclePage = new RouteCrumb('fnolAuto/thirdPartyVehicle', crumbs.Vehicles, thirdPartyOwnerPage);
        // Claimant third party drivers
        let unlistedThirdPartyOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=thirdPartyOwner', crumbs.Vehicles, thirdPartyDriverPage);
        let unlistedThirdPartyDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=thirdPartyDriver', crumbs.Vehicles, thirdPartyHasPassengersPage);
        let unlistedThirdPartyDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=thirdPartyDriver', crumbs.Vehicles, thirdPartyHasPassengersPage);
        let unlistedThirdPartyOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=thirdPartyOwner', crumbs.Vehicles, thirdPartyDriverPage);


        //insured vehicle & claimant pedestrian
        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, additionalClaimantPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, additionalClaimantPage);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Vehicles, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);
        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        // insured party but unlisted vehicle pages
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        let claimantPedestrian = new RouteCrumb('fnolAuto/pedestrian', crumbs.Pedestrian, selectInsuredVehicle);
        let selectPedestrian = new RouteCrumb('fnolAuto/selectPedestrian', crumbs.Pedestrian, thirdPartyVehiclePage);

        //base
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectPedestrian);
        if (claimTypeCode =="PEDDRVR") {
            lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, claimantPedestrian);
        }

        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, lossDescription);

        this.routes.push(lossAddress, claimReporter, emergencyResponse, lossDescription, claimType, uploadDocumentPage, smsConsent, review, confirmation,
            claimantPedestrian, selectInsuredVehicle, selectOwner, selectDriver, hasPassengers, passengers, additionalClaimantPage, vehicleDetail, nonPolicyVehicle,
            unlistedDriverPage, unlistedDriverWithIdPage, unlistedOwnerPage, unlistedOwnerWithIdPage, vehicleDriverPage, vehicleOwnerPage, thirdPartyVehiclePage,
            thirdPartyOwnerPage,thirdPartyDriverPage, thirdPartyHasPassengersPage, thirdPartyPassengersPage,additionalClaimantPage,
            selectPedestrian, unlistedThirdPartyDriverPage, unlistedThirdPartyDriverWithIdPage, unlistedThirdPartyOwnerPage, unlistedThirdPartyOwnerWithIdPage, nonPolicyVehicleWithId);
    }

    private FixedPropertyRoutes(): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            ClaimReporter: new Breadcrumb('Claim Reporter', 3),
            LossAddress: new Breadcrumb('Loss Address', 4),
            Vehicles: new Breadcrumb('Vehicles', 5),
            Passengers: new Breadcrumb('Passengers', 6),
            Property: new Breadcrumb('Property', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)

        };

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let fixedPropertyOwnerWithId = new RouteCrumb('fnolAuto/property/:propertyId/propertyOwner/:id', crumbs.Property, uploadDocumentPage);
        let fixedPropertyOwner = new RouteCrumb('fnolAuto/property/:propertyId/propertyOwner', crumbs.Property, uploadDocumentPage);

        let fixedProperty = new RouteCrumb('fnolAuto/property', crumbs.Property, fixedPropertyOwner);

        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, fixedProperty);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, fixedProperty);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Vehicles, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, lossDescription);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleDetail,
            nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage, vehicleDriverPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, unlistedDriverWithIdPage,
            fixedProperty, fixedPropertyOwner, fixedPropertyOwnerWithId, unlistedDriverPage,
            smsConsent
        );
    }

    private TowingRoutes(): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            ClaimReporter: new Breadcrumb('Claim Reporter', 2),
            LossAddress: new Breadcrumb('Loss Address', 3),
            Vehicles: new Breadcrumb('Vehicles', 4),
            Passengers: new Breadcrumb('Passengers', 5),
            Receipt: new Breadcrumb('Receipt', 6),
            Review: new Breadcrumb('Review', 7),
            Confirmation: new Breadcrumb('Confirmation', 8)
        };

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadReceiptPage = new RouteCrumb('fnolAuto/uploadReceipt', crumbs.Receipt, smsConsent);

        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, uploadReceiptPage);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, emergencyResponse);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadReceiptPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadReceiptPage);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadReceiptPage);

        this.routes.push(claimType, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, review, confirmation, vehicleDetail, nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, uploadReceiptPage, smsConsent
        );
    }

    private TheftNoOccupantsRoutes(): void {
        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            RecoveryInformation: new Breadcrumb("Recovery Information", 2),
            LossInformation: new Breadcrumb('Loss Information', 3),
            ClaimReporter: new Breadcrumb('Claim Reporter', 4),
            LossAddress: new Breadcrumb('Loss Address', 5),
            Vehicles: new Breadcrumb('Vehicles', 6),
            Participants: new Breadcrumb('Participants', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)

        };

        // create the routes in an backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Participants, uploadDocumentPage);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let recoveryInformation = new RouteCrumb('fnolAuto/recoveryInformation', crumbs.RecoveryInformation, lossDescription);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.RecoveryInformation, recoveryInformation)
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let vehicleTheftDetails = new RouteCrumb('fnolAuto/vehicleTheftDetails/:id/:displayTheftComponent', crumbs.Vehicles, selectOwner);

        let nonPolicyVehicleTheft = new RouteCrumb('fnolAuto/nonPolicyVehicleTheft', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleTheftWithId = new RouteCrumb('fnolAuto/nonPolicyVehicleTheft/:vehicleId/:displayTheftComponent', crumbs.Vehicles, selectOwner);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);

        this.routes.push(claimType, occupantInformation, recoveryInformation, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, uploadDocumentPage, review, confirmation, vehicleTheftDetails, nonPolicyVehicleTheft, nonPolicyVehicleTheftWithId, vehicleOwnerPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, smsConsent
        );
    }

    private FireNoOccupantsRoutes(): void {
        const crumbs = {
            LossDate: new Breadcrumb("Loss Date", -1),
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantsInformation: new Breadcrumb("Occupants Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),          
            Document: new Breadcrumb('Document', 7),
            Review: new Breadcrumb("Review", 8),
            Confirmation: new Breadcrumb("Confirmation", 9)

        }

        // create the routes in an backwards order to link them correctly
        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, uploadDocumentPage);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner )
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantsInformation, lossDescription);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);
        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', crumbs.ClaimType, claimType);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', crumbs.LossDate, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', crumbs.LossDate, dateOfLoss);

        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, claimType, occupantInformation, lossDescription, emergencyResponse,
            claimReporter, lossAddress, selectInsuredVehicle, vehicleDetail, selectOwner, vehicleOwnerPage, unlistedOwnerPage, unlistedOwnerWithIdPage,
            uploadDocumentPage, smsConsent, review, confirmation);

    }
    

    private useOriginalRoutes(): void {
        const FnolAutoDefaultRoutes = {
            HideOnThisPage: new Breadcrumb("None", -1),
            LossDate: new Breadcrumb("Loss Date", 1),
            ClaimType: new Breadcrumb("Claim Type", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Participants: new Breadcrumb("Participants", 6),
            Vehicles: new Breadcrumb("Vehicles", 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb("Review", 9),
            Confirmation: new Breadcrumb("Confirmation", 10)

        }

        // create the routes in an backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', FnolAutoDefaultRoutes.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', FnolAutoDefaultRoutes.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', FnolAutoDefaultRoutes.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', FnolAutoDefaultRoutes.Document, smsConsent);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', FnolAutoDefaultRoutes.Vehicles, uploadDocumentPage);
        let selectVehicles = new RouteCrumb('fnolAuto/selectVehicles', FnolAutoDefaultRoutes.Vehicles, emergencyResponse);
        let selectLossParticipants = new RouteCrumb('fnolAuto/selectLossParticipants', FnolAutoDefaultRoutes.Participants, selectVehicles);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', FnolAutoDefaultRoutes.LossAddress, selectLossParticipants);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', FnolAutoDefaultRoutes.ClaimReporter, lossAddress);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', FnolAutoDefaultRoutes.LossInformation, claimReporter);
        let claimType = new RouteCrumb('fnolAuto/claimType', FnolAutoDefaultRoutes.ClaimType, lossDescription);
        let glassOnly = new RouteCrumb('fnolAuto/glassOnly', FnolAutoDefaultRoutes.ClaimType, claimType);
        let towingAndLabor = new RouteCrumb('fnolAuto/towingAndLabor', FnolAutoDefaultRoutes.ClaimType, glassOnly);
        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', FnolAutoDefaultRoutes.ClaimType, towingAndLabor);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', FnolAutoDefaultRoutes.LossDate, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', FnolAutoDefaultRoutes.HideOnThisPage, dateOfLoss);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, towingAndLabor, glassOnly, claimType, lossDescription, claimReporter,
            lossAddress, selectLossParticipants, selectVehicles, emergencyResponse, uploadDocumentPage, review, confirmation,
            smsConsent
        );
    }

    private TowingRoutesWithLossDescription(): void {

        const FnolAutoDefaultRoutes = {
            HideOnThisPage: new Breadcrumb("None", -1),
            LossDate: new Breadcrumb("Loss Date", 1),
            ClaimType: new Breadcrumb("Claim Type", 2),
            ClaimReporter: new Breadcrumb("Claim Reporter", 3),
            LossAddress: new Breadcrumb("Loss Address", 4),
            Participants: new Breadcrumb("Participants", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Receipt: new Breadcrumb('Receipt', 7),
            Review: new Breadcrumb("Review", 8),
            Confirmation: new Breadcrumb("Confirmation", 9)

        }

        // create the routes in an backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', FnolAutoDefaultRoutes.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', FnolAutoDefaultRoutes.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', FnolAutoDefaultRoutes.Review, review);
        let uploadReceiptPage = new RouteCrumb('fnolAuto/uploadReceipt', FnolAutoDefaultRoutes.Receipt, smsConsent);

        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', FnolAutoDefaultRoutes.Vehicles, uploadReceiptPage);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', FnolAutoDefaultRoutes.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', FnolAutoDefaultRoutes.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', FnolAutoDefaultRoutes.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', FnolAutoDefaultRoutes.Vehicles, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', FnolAutoDefaultRoutes.Vehicles, emergencyResponse);
        let claimType = new RouteCrumb('fnolAuto/claimType', FnolAutoDefaultRoutes.ClaimType, lossDescription);
        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', FnolAutoDefaultRoutes.ClaimType, claimType);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', FnolAutoDefaultRoutes.LossDate, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', FnolAutoDefaultRoutes.HideOnThisPage, dateOfLoss);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', FnolAutoDefaultRoutes.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', FnolAutoDefaultRoutes.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', FnolAutoDefaultRoutes.Vehicles, selectOwner);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', FnolAutoDefaultRoutes.Vehicles, uploadReceiptPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', FnolAutoDefaultRoutes.Vehicles, uploadReceiptPage);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', FnolAutoDefaultRoutes.Vehicles, uploadReceiptPage);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, claimType, emergencyResponse, claimReporter,
            lossAddress, selectInsuredVehicle, selectOwner, uploadReceiptPage, review, confirmation,
            smsConsent, unlistedOwnerWithIdPage, unlistedOwnerPage, vehicleOwnerPage, nonPolicyVehicleWithId, nonPolicyVehicle, vehicleDetail, lossDescription
        );
    }

    private WeatherNoOccupantsRoutes(): void {
        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb('Loss Information', 3),
            ClaimReporter: new Breadcrumb('Claim Reporter', 4),
            LossAddress: new Breadcrumb('Loss Address', 5),
            Vehicles: new Breadcrumb('Vehicles', 6),
            Participants: new Breadcrumb('Participants', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)
        }

        // create the routes in an backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Participants, uploadDocumentPage);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, emergencyResponse)
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);
        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);

        this.routes.push(claimType, occupantInformation, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, uploadDocumentPage, review, confirmation, vehicleDetail, nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, smsConsent
        );
    }

    private VandalismNoOccupantsRoutes(): void {
        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb('Loss Information', 3),
            ClaimReporter: new Breadcrumb('Claim Reporter', 4),
            LossAddress: new Breadcrumb('Loss Address', 5),
            Vehicles: new Breadcrumb('Vehicles', 6),
            Participants: new Breadcrumb('Participants', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)
        }

        // create the routes in an backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Participants, uploadDocumentPage);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription)
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);

        this.routes.push(claimType, occupantInformation, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner,uploadDocumentPage, review, confirmation, vehicleDetail, nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, smsConsent
        );
    }

    public reinitializeRoutes(): void {
        // clear breadcrumbs

        let claimType: ClaimTypeCategoryEnum = this.fnolAutoService.getClaimTypeCategory();
        switch (claimType) {
            case ClaimTypeCategoryEnum.Theft:
                this.routes.length = 0;
                this.TheftWithOccupantsRoutes()
                this.updateBreadcrumbs();
                break;
            case ClaimTypeCategoryEnum.Weather:
                this.routes.length = 0;
                this.WeatherWithOccupantsRoutes()
                this.updateBreadcrumbs();
                break;
            case ClaimTypeCategoryEnum.Vandalism:
                this.routes.length = 0;
                this.VandalismWithOccupantsRoutes()
                this.updateBreadcrumbs();
                break;
            case ClaimTypeCategoryEnum.Fire:
                this.routes.length = 0;
                this.FireWithOccupantsRoutes();
                this.updateBreadcrumbs();
                break;
            case ClaimTypeCategoryEnum.Awning:
                this.routes.length = 0;
                this.AwningOnlyWithOccupantsRoutes();
                this.updateBreadcrumbs();
                break;
            case ClaimTypeCategoryEnum.Glass:
                this.routes.length = 0;
                this.GlassOnlyWithOccupantsRoutes();
                this.updateBreadcrumbs();
                break;
            case ClaimTypeCategoryEnum.MiscRV:
                this.routes.length = 0;
                this.MiscRVRoutes();
                this.updateBreadcrumbs();
            default:
                break;
        }
    }

    public TheftWithOccupantsRoutes(): void {
        // add routes and breadcrumbs
        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            RecoveryInformation: new Breadcrumb("Recovery Information", 2),
            LossInformation: new Breadcrumb('Loss Information', 3),
            ClaimReporter: new Breadcrumb('Claim Reporter', 4),
            LossAddress: new Breadcrumb('Loss Address', 5),
            Vehicles: new Breadcrumb('Vehicles', 6),
            Participants: new Breadcrumb('Participants', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)

        };

        // create the routes in a backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);
        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Participants, uploadDocumentPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Participants, passengers);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Participants, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Participants, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.LossInformation, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let recoveryInformation = new RouteCrumb('fnolAuto/recoveryInformation', crumbs.RecoveryInformation, lossDescription);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.RecoveryInformation, recoveryInformation)
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let vehicleTheftDetails = new RouteCrumb('fnolAuto/vehicleTheftDetails/:id/:displayTheftComponent', crumbs.Vehicles, selectOwner);

        let nonPolicyVehicleTheft = new RouteCrumb('fnolAuto/nonPolicyVehicleTheft', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleTheftWithId = new RouteCrumb('fnolAuto/nonPolicyVehicleTheft/:vehicleId/:displayTheftComponent', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, occupantInformation, recoveryInformation, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleTheftDetails, nonPolicyVehicleTheft, nonPolicyVehicleTheftWithId, vehicleOwnerPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, smsConsent, vehicleDriverPage, unlistedDriverPage, unlistedDriverWithIdPage
        );
    }

    public WeatherWithOccupantsRoutes(): void {
        // add routes and breadcrumbs
        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb('Loss Information', 3),
            ClaimReporter: new Breadcrumb('Claim Reporter', 4),
            LossAddress: new Breadcrumb('Loss Address', 5),
            Vehicles: new Breadcrumb('Vehicles', 6),
            Participants: new Breadcrumb('Participants', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)
        }

        // create the routes in an backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Participants, uploadDocumentPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Participants, passengers);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Participants, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Participants, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, emergencyResponse)
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, occupantInformation, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleDetail, nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, smsConsent, vehicleDriverPage, unlistedDriverPage, unlistedDriverWithIdPage
        );
    }

    public VandalismWithOccupantsRoutes(): void {
        // add routes and breadcrumbs
        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb('Loss Information', 3),
            ClaimReporter: new Breadcrumb('Claim Reporter', 4),
            LossAddress: new Breadcrumb('Loss Address', 5),
            Vehicles: new Breadcrumb('Vehicles', 6),
            Participants: new Breadcrumb('Participants', 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb('Review', 9),
            Confirmation: new Breadcrumb('Confirmation', 10)
        }

        // create the routes in an backwards order to link them correctly

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Participants, uploadDocumentPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Participants, passengers);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Participants, hasPassengers);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Participants, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);
        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription)
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Vehicles, hasPassengers);

        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);

        this.routes.push(claimType, occupantInformation, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, selectDriver, hasPassengers, passengers, uploadDocumentPage, review, confirmation, vehicleDetail, nonPolicyVehicle, nonPolicyVehicleWithId, vehicleOwnerPage,
            unlistedOwnerPage, unlistedOwnerWithIdPage, smsConsent, vehicleDriverPage, unlistedDriverPage, unlistedDriverWithIdPage
        );
    }

    private FireWithOccupantsRoutes(): void {
        const crumbs = {
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Passengers: new Breadcrumb("Passengers", 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb("Review", 9),            
            Confirmation: new Breadcrumb("Confirmation", 10)
        }

        // create the routes in an backwards order to link them correctly
        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, uploadDocumentPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, passengers);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Passengers, hasPassengers);
        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Passengers, hasPassengers);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Passengers, hasPassengers); 
        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Passengers, selectDriver);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner)
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);

        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let glassOnly = new RouteCrumb('fnolAuto/glassOnly', crumbs.ClaimType, claimType);
        let towingAndLabor = new RouteCrumb('fnolAuto/towingAndLabor', crumbs.ClaimType, glassOnly);

        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', crumbs.ClaimType, towingAndLabor);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', crumbs.ClaimType, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', crumbs.ClaimType, dateOfLoss);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, towingAndLabor, glassOnly, claimType, occupantInformation, lossDescription, emergencyResponse,
            claimReporter, lossAddress, selectInsuredVehicle, selectOwner, selectDriver, vehicleOwnerPage, vehicleDriverPage, vehicleDetail,
            hasPassengers, passengers, uploadDocumentPage, smsConsent, review, confirmation, unlistedOwnerPage, unlistedDriverPage, unlistedDriverWithIdPage, unlistedOwnerWithIdPage);

    }

    private AwningOnlyWithOccupantsRoutes(): void {
        const crumbs = {
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Passengers: new Breadcrumb("Passengers", 7),
            Document: new Breadcrumb("Document", 8),
            Review: new Breadcrumb("Review", 9),
            Confirmation: new Breadcrumb("Confirmation", 10)
        }

        // create the routes in an backwards order to link them correctly
        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        //let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, uploadDocumentPage);
        //let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, passengers);

        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Passengers, uploadDocumentPage);
        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Passengers, uploadDocumentPage);
        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Passengers, uploadDocumentPage);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Passengers, uploadDocumentPage);

        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Passengers, selectDriver);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);

        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);

        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let glassOnly = new RouteCrumb('fnolAuto/glassOnly', crumbs.ClaimType, claimType);
        let towingAndLabor = new RouteCrumb('fnolAuto/towingAndLabor', crumbs.ClaimType, glassOnly);

        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', crumbs.ClaimType, towingAndLabor);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', crumbs.ClaimType, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', crumbs.ClaimType, dateOfLoss);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, towingAndLabor, glassOnly, claimType, occupantInformation, lossDescription, emergencyResponse,
            claimReporter, lossAddress, selectInsuredVehicle, selectOwner, selectDriver, vehicleOwnerPage, unlistedOwnerWithIdPage, vehicleDriverPage,
            uploadDocumentPage, smsConsent, review, confirmation,
            unlistedOwnerPage, unlistedDriverWithIdPage, unlistedDriverPage);

    }

    private AwningOnlyNoOccupantsRoutes(): void {
        const crumbs = {
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Document: new Breadcrumb('Document', 7),
            Review: new Breadcrumb("Review", 8),
            Confirmation: new Breadcrumb("Confirmation", 9)
        }

        // create the routes in an backwards order to link them correctly
        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, uploadDocumentPage);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);

        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        let glassOnly = new RouteCrumb('fnolAuto/glassOnly', crumbs.ClaimType, claimType);
        let towingAndLabor = new RouteCrumb('fnolAuto/towingAndLabor', crumbs.ClaimType, glassOnly);

        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', crumbs.ClaimType, towingAndLabor);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', crumbs.ClaimType, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', crumbs.ClaimType, dateOfLoss);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, towingAndLabor, glassOnly, claimType, occupantInformation, lossDescription, emergencyResponse,
            claimReporter, lossAddress, selectInsuredVehicle, selectOwner, unlistedOwnerWithIdPage, vehicleOwnerPage, 
            uploadDocumentPage, smsConsent, review, confirmation,
            unlistedOwnerPage);

    }
    private GlassOnlyWithOccupantsRoutes(): void {
        const crumbs = {
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Passengers: new Breadcrumb("Passengers", 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb("Review", 9),
            Confirmation: new Breadcrumb("Confirmation", 10)
        }

        // create the routes in an backwards order to link them correctly
        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        //let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, uploadDocumentPage);
        //let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, passengers);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, uploadDocumentPage);
        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Passengers, uploadDocumentPage);
        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Passengers, uploadDocumentPage);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Passengers, uploadDocumentPage);

        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Passengers, selectDriver);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);

        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription);

        let isDamagesOtherThanWindshield = new RouteCrumb('fnolAuto/isDamagesOtherThanWindshield', crumbs.ClaimType, occupantInformation);
        let isWindshieldOnlyDamages = new RouteCrumb('fnolAuto/isWindshieldOnlyDamages', crumbs.ClaimType, occupantInformation);
        let glassOnly = new RouteCrumb('fnolAuto/glassOnly', crumbs.ClaimType, isWindshieldOnlyDamages);
        let towingAndLabor = new RouteCrumb('fnolAuto/towingAndLabor', crumbs.ClaimType, glassOnly);

        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', crumbs.ClaimType, towingAndLabor);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', crumbs.ClaimType, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', crumbs.ClaimType, dateOfLoss);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, towingAndLabor, glassOnly, isWindshieldOnlyDamages, isDamagesOtherThanWindshield, occupantInformation, lossDescription, emergencyResponse,
            claimReporter, lossAddress, selectInsuredVehicle, nonPolicyVehicleWithId, nonPolicyVehicle, vehicleDetail, selectOwner, unlistedOwnerWithIdPage, selectDriver, unlistedDriverWithIdPage, vehicleOwnerPage, vehicleDriverPage,
            uploadDocumentPage, smsConsent, review, confirmation, unlistedOwnerPage, unlistedDriverPage);

    }

    private GlassOnlyNoOccupantsRoutes(): void {
        const crumbs = {
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Document: new Breadcrumb('Document', 7),
            Review: new Breadcrumb("Review", 8),
            Confirmation: new Breadcrumb("Confirmation", 9)
        }

        // create the routes in an backwards order to link them correctly
        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, uploadDocumentPage);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicle = new RouteCrumb('fnolAuto/nonPolicyVehicle', crumbs.Vehicles, selectOwner);
        let nonPolicyVehicleWithId = new RouteCrumb('fnolAuto/nonPolicyVehicle/:vehicleId', crumbs.Vehicles, selectOwner);

        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);

        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription);

        let isDamagesOtherThanWindshield = new RouteCrumb('fnolAuto/isDamagesOtherThanWindshield', crumbs.ClaimType, occupantInformation);
        let isWindshieldOnlyDamages = new RouteCrumb('fnolAuto/isWindshieldOnlyDamages', crumbs.ClaimType, occupantInformation);
        let glassOnly = new RouteCrumb('fnolAuto/glassOnly', crumbs.ClaimType, isWindshieldOnlyDamages);
        let towingAndLabor = new RouteCrumb('fnolAuto/towingAndLabor', crumbs.ClaimType, glassOnly);

        let hasOpenClaim = new RouteCrumb('fnolAuto/hasOpenClaim', crumbs.ClaimType, towingAndLabor);
        let dateOfLoss = new RouteCrumb('fnolAuto/dateOfLoss', crumbs.ClaimType, hasOpenClaim);
        let newClaim = new RouteCrumb('fnolAuto/newClaim', crumbs.ClaimType, dateOfLoss);

        this.routes.push(newClaim, dateOfLoss, hasOpenClaim, towingAndLabor, glassOnly, isWindshieldOnlyDamages, isDamagesOtherThanWindshield, occupantInformation, lossDescription, emergencyResponse,
            claimReporter, lossAddress, selectInsuredVehicle, nonPolicyVehicleWithId, nonPolicyVehicle, vehicleDetail, selectOwner, vehicleOwnerPage, unlistedOwnerWithIdPage, uploadDocumentPage, smsConsent, review, confirmation, unlistedOwnerPage);

    }
    private MiscRVRoutes(): void {
        let crumbs = {
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Passengers: new Breadcrumb("Passengers", 7),
            Document: new Breadcrumb('Document', 8),
            Review: new Breadcrumb("Review", 9),
            Confirmation: new Breadcrumb("Confirmation", 10)
        }

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let passengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/passengers', crumbs.Passengers, uploadDocumentPage);
        let hasPassengers = new RouteCrumb('fnolAuto/vehicle/:vehicleId/hasPassengers', crumbs.Passengers, passengers);
        let unlistedDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=driver', crumbs.Vehicles, hasPassengers);
        let unlistedDriverWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=driver', crumbs.Passengers, hasPassengers);
        let vehicleDriverPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=driver', crumbs.Passengers, hasPassengers);
        let selectDriver = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectDriver', crumbs.Passengers, hasPassengers);


        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Passengers, selectDriver);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, selectDriver);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, selectDriver);

        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);

        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        this.routes.push(occupantInformation, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, unlistedOwnerPage, vehicleOwnerPage, unlistedOwnerWithIdPage, selectDriver, vehicleDriverPage, unlistedDriverWithIdPage, unlistedDriverPage,
            hasPassengers, passengers, uploadDocumentPage, smsConsent, review, confirmation, claimType, vehicleDetail)
    }

    private MiscRVNoOccupantsRoute(): void {
        let crumbs = {
            ClaimType: new Breadcrumb("Claim Type", 1),
            OccupantInformation: new Breadcrumb("Occupant Information", 2),
            LossInformation: new Breadcrumb("Loss Information", 3),
            ClaimReporter: new Breadcrumb("Claim Reporter", 4),
            LossAddress: new Breadcrumb("Loss Address", 5),
            Vehicles: new Breadcrumb("Vehicles", 6),
            Document: new Breadcrumb('Document', 7),
            Review: new Breadcrumb("Review", 8),
            Confirmation: new Breadcrumb("Confirmation", 9)
        }

        let confirmation = new RouteCrumb('fnolAuto/confirmation', crumbs.Confirmation, null);
        let review = new RouteCrumb('fnolAuto/review', crumbs.Review, confirmation);
        let smsConsent = new RouteCrumb('fnolAuto/smsConsent', crumbs.Review, review);
        let uploadDocumentPage = new RouteCrumb('fnolAuto/uploadDocument', crumbs.Document, smsConsent);

        let unlistedOwnerWithIdPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let vehicleOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/assignment/:participantId?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let unlistedOwnerPage = new RouteCrumb('fnolAuto/vehicle/:vehicleId/unlistedAssignment?assignmentType=owner', crumbs.Vehicles, uploadDocumentPage);
        let selectOwner = new RouteCrumb('fnolAuto/vehicle/:vehicleId/selectOwner', crumbs.Vehicles, uploadDocumentPage);
        let vehicleDetail = new RouteCrumb('fnolAuto/vehicleDetail/:id', crumbs.Vehicles, selectOwner);
        let selectInsuredVehicle = new RouteCrumb('fnolAuto/selectInsuredVehicle', crumbs.Vehicles, selectOwner);
        let lossAddress = new RouteCrumb('fnolAuto/lossAddress', crumbs.LossAddress, selectInsuredVehicle);
        let claimReporter = new RouteCrumb('fnolAuto/claimReporter', crumbs.ClaimReporter, lossAddress);
        let emergencyResponse = new RouteCrumb('fnolAuto/emergencyResponse', crumbs.ClaimReporter, claimReporter);

        let lossDescription = new RouteCrumb('fnolAuto/lossDescription', crumbs.LossInformation, emergencyResponse);
        let occupantInformation = new RouteCrumb('fnolAuto/occupantInformation', crumbs.OccupantInformation, lossDescription);
        let claimType = new RouteCrumb('fnolAuto/claimType', crumbs.ClaimType, occupantInformation);

        this.routes.push(occupantInformation, lossDescription, emergencyResponse, claimReporter, lossAddress, selectInsuredVehicle,
            selectOwner, unlistedOwnerPage, vehicleOwnerPage, unlistedOwnerWithIdPage, uploadDocumentPage, smsConsent, review, confirmation, claimType, vehicleDetail)
    }
}