




import { BaseModel } from './BaseModel';


export class PaymentModel extends BaseModel {
    
    public TransactionStatus: string = null;
    public SequenceNumber: number = 0;
    public CheckNumber: string = null;
    public PaymentType: string = null;
    public TransactionNumber: string = null;
    public PaymentStatus: string = null;
    public ActualPaidDate: Date = null;
    public IssueDate: Date = null;
    public PaidTo: string = null;
    public Amount: number = null;
}