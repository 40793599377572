<div ngForm #uploadDocumentForm="ngForm" class="main-inner-wrapper">
    <p class="header">Did you take photos of the vehicle damages at the scene of the accident?  <span>If so, please upload them now.  You may also upload other photos or documents you would like to submit as part of your claim.</span></p>
    <div class="row w-150">
        <div class="col-12 remove-padding-mobile">
            <div  class="highlight-wrapper">
                <div class="fieldLabel-wrapper highlight-text-bold-ui">
                    <label>
                        If you are unable to upload your documents at this time, you may submit to
                        <span><strong><a href="mailto:{{claimsEmail}}" role="button" class="cursor">{{claimsEmail}}</a></strong></span>
                        with your claim number as the subject line.
                    </label>
                </div>
                <div class="text-center highlight-text-ui">Documents and photos are <em class="emphasis-bold">not</em> required for claim submission.</div>
            </div>
             <file-upload height="25vh" [isDeviceMobile]=isDeviceMobile></file-upload>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(uploadDocumentForm)">Continue</button>
    </div>
</div>