




import { BaseModel } from './BaseModel';

import { VehicleModel } from './VehicleModel';

export class SelectVehiclesModel extends BaseModel {
    
    public Vehicles: VehicleModel[] = [];
}