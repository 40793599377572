import { Component } from '@angular/core';
import { MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { BaseComponent } from '../../base/base.component';
import { SessionService } from '../../services';

@Component({
    selector: 'reviewSubmission',
    templateUrl: '../../views/modals/reviewSubmission.component.html'
})

export class ReviewSubmissionBottomSheet extends BaseComponent {
    constructor(public sheetRef: MatBottomSheetRef, private session: SessionService) {
        super();
        this.sheetRef.backdropClick().subscribe(result => {
            document.querySelector(".cdk-overlay-container").animate([{ transform: 'translateY(0%)' }, { transform: 'translateY(-200%)' }], { duration: 500 });
        });
    }

    ngOnInit(): void {

    }

    public submit(): void {
        document.querySelector(".cdk-overlay-container").animate([{ transform: 'translateY(0%)' }, { transform: 'translateY(-200%)' }], { duration: 500 });
        this.checkIfSessionIsExpiredBeforeSubmit();
    }

    public continueReview(): void {
        document.querySelector(".cdk-overlay-container").animate([{ transform: 'translateY(0%)' }, { transform: 'translateY(-200%)' }], { duration: 500 });
        this.sheetRef.dismiss({ event: 'Review' });
    }

    private checkIfSessionIsExpiredBeforeSubmit(): void {
        let session = this.session.getSession();

        if (session.IsExpired) {
            this.sheetRef.dismiss({ event: 'SessionExpired' });
            this.session.navigateToSessionExpirationPage();
        } else {
            this.sheetRef.dismiss({ event: 'Submit' });
        }
    }
}