




import { BaseModel } from './BaseModel';


export class AdditionalPropertyNoteModel extends BaseModel {
    
    public HasAdditionalProperty: boolean = false;
}