import { Component } from '@angular/core';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LossApi, FNOLRoutingService } from '@FNOLAuto/services/';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { MyClaimsPicModel, SMSConsentModel } from '@ClaimsModels/index';
import { BreadcrumbService } from '@Common/services';

@Component({
    selector: 'myClaimsPic',
    templateUrl: '../../views/loss/myClaimsPic.component.html',
    providers: [FNOLRoutingService]
})
export class MyClaimsPicComponent extends BaseFormComponent {
    constructor(private api: LossApi,
        private spinner: Ng4LoadingSpinnerService,
        private fnolRouting: FNOLRoutingService,
        private breadCrumService: BreadcrumbService    ) {
        super();
        this.vm.SMSConsent = new SMSConsentModel();
    }
    public vm: MyClaimsPicModel = new MyClaimsPicModel();
    public displayPage: boolean;
    public hasError: boolean;

    public ngOnInit(): void {
        this.spinner.show();

        // update breadCrums incase page is refreshed,
        // since this page is not part of the fnolRouting service.
        this.breadCrumService.update('fnolAuto/review');

        this.api.getMyClaimsPicModel().then(response => {
            this.spinner.hide();
            this.vm = response;
            if (response.Success) {
                if (response.SMSConsent.SMSConsent && response.SMSConsent.IsEligibleForMCP) {
                    this.displayPage = true;
                } else {
                    // Using replaceUrl so we don't preserve in history when page is bypassed.
                    this.fnolRouting.routeToSpecificPage('fnolAuto/review', { replaceUrl: true });
                }
            }
        });
    };

    public submit(): void {

        if (this.vm.SendLink === null || this.vm.SendLink === undefined) {
            this.hasError = true;
            return;
        }

        this.spinner.show();
        this.api.saveMyClaimsPicModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                this.fnolRouting.routeToSpecificPage('fnolAuto/review');
            }
        });
    };

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    };
}