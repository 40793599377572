import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BaseModel, ValidationResponse } from '@ClaimsModels/index';
import { ErrorHandlerService } from '../services/error/errorHandler.service';
import { SessionService } from '../services/session/session.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseApi {

    constructor(private http: HttpClient, private errorHandler: ErrorHandlerService, private session: SessionService, private router: Router) { }

    protected httpGet<T extends BaseModel>(method: string): Promise<T> {
        this.errorHandler.clearErrors();

        const headers = new HttpHeaders().set("Cache-Control", "no-cache").set("Pragma", "no-cache");

        return this.http.get<ValidationResponse<T>>(method, { headers })
            .toPromise()
            .then(response => {
                this.handleResponse(response);
                return response.Result;
            });
    }

    protected httpPost<T extends BaseModel>(method: string, model: BaseModel): Promise<T> {
        this.errorHandler.clearErrors();

        return this.http.post<ValidationResponse<T>>(method, model)
            .toPromise()
            .then(response => {
                this.handleResponse(response);
                return response.Result;
            });
    }

    private handleResponse<T extends BaseModel>(response: ValidationResponse<T>): void {
        // This should only ever return false if there is an issue connecting to the server
        if (!this.validateResponse(response)) {
            this.errorHandler.addError("");
            this.errorHandler.setShowErrors(true);

            return;
        }

        let session = this.session.getSession();

        if (response.Validation.HasSessionTimeout || (session.IsInitialized && session.IsExpired)) {
            this.router.navigate(['/sessionTimeout']);
            return;
        }
        else if (response.Validation.HasFatalError) {
            var pageName: string = this.router.url.split('/')[2];
            if (pageName == "customerValidation") {
                this.router.navigate(['myClaims360/customerValidationError']);
            }
            else {
                this.router.navigate(['/error']);
            }
        } else if (response.Validation.HasBusinessRuleErrors) {
            this.errorHandler.setErrors(response.Validation.BusinessRuleErrors);
            this.errorHandler.setShowErrors(true);
        }

        this.session.refreshSession();
    }

    private validateResponse<T extends BaseModel>(response: ValidationResponse<T>): boolean {
        if (response === null ||
            response === undefined ||
            response.Validation === null ||
            response.Validation === undefined) {
            return false;
        }

        return true;
    }
}