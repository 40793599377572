import { Component } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { StyleManagerService } from '../services';

@Component({
    selector: 'userCallIn',
    templateUrl: '../views/userCallIn.component.html'
})
export class UserCallInComponent extends BaseComponent {

    public contactNumber: string;

    constructor(private styleManager: StyleManagerService) {
        super();
    }

    public ngOnInit(): void {
        this.styleManager.contactPhoneNumber.subscribe(contactPhoneNumber => {
            this.contactNumber = contactPhoneNumber;
        })
    }
}