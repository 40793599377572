<div ngForm #vehicleDetailForm="ngForm" class="content-wrapper">
    <p class="header">Please provide the following information for your vehicle.</p>
    <div class="carInfo">
        <p>{{vm.Year}} {{vm.Make}} {{vm.Model}}</p>
    </div>

    <div class="container">
        <div class="row justify-content-center" *ngIf="!isVehicleDamageScreen && !isRVVehicleDamageScreen">
            <div class="input-wrapper">
                <ng-container *ngIf="displayLocationQuestions()">
                    <div class="row">
                        <div class="col-lg-12 col-xs-12">
                            <div class="fieldLabel-wrapper">
                                <label [ngClass]="childHasInvalid(VehicleLocation)">Where is the vehicle now?<i [ngClass]="childHasInvalid(VehicleLocation)" *ngIf="isVehicleLocationTypeRequired()" class="fas fa-asterisk" title="*Required Field"></i></label>
                            </div>

                            <select Validate required="{{ isVehicleLocationTypeRequired() }}" name="VehicleLocation" [(ngModel)]="vm.VehicleClaimInfo.VehicleLocationType" #VehicleLocation="ngModel" (ngModelChange)="setLocationAddress()">
                                <option [ngValue]="null" hidden disabled selected></option>
                                <option *ngFor="let vehicleLocationType of vm.VehicleLocationTypes" [ngValue]="vehicleLocationType.DisplayCode"> {{ vehicleLocationType.DisplayValue }} </option>
                            </select>
                        </div>
                    </div>
                    <div class="row" *ngIf="displayBusinessNameQuestion()">
                            <div class="col-lg-12 col-xs-12">
                                <div class="fieldLabel-wrapper">
                                    <label>Business Name</label>
                                </div>
                                <input Validate type="text" name="BusinessName" [(ngModel)]="vm.VehicleClaimInfo.VehicleLocationDescription" #VehicleBusiness="ngModel" />
                            </div>
                    </div>
                    <div class="row" *ngIf="!this.isUnrecoveredTheft">
                        <div class="col-lg-12 col-xs-12">
                            <div class="fieldLabel-wrapper">
                                <label>What is the Address where the Vehicle is located?</label>
                            </div>
                            <input type="text" name="Address" [(ngModel)]="vm.VehicleLocationAddress.Address1" #VehicleLocationAddress="ngModel" Validate />
                        </div>
                        <div class="col-lg-6 col-xs-12">
                            <input type="text" name="City" [(ngModel)]="vm.VehicleLocationAddress.City" #VehicleLocationCity="ngModel" OnlyLetters Validate />
                        </div>
                        <div class="col-lg-2 col-xs-12">
                            <select [(ngModel)]="vm.VehicleLocationAddress.State" #VehicleLocationState="ngModel" name="vehicleLocationState" class="state gbmask">
                                <option [ngValue]="null">State</option>
                                <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-xs-12">
                            <input type="tel" name="Zip" maxlength="5" [(ngModel)]="vm.VehicleLocationAddress.Zip" #VehicleLocationZip="ngModel" OnlyNumbers Validate />
                        </div>
                    </div>
                </ng-container>
                <div class="row">
                    <div class="col-lg-6 col-xs-12">
                        <div class="fieldLabel-wrapper">
                            <label>License Plate Number</label>
                        </div>
                        <input Validate type="text" class="plateNumber" name="PlateNumber" [(ngModel)]="vm.PlateNumber" #PlateNumber="ngModel" />
                    </div>
                    <div class="col-lg-6 col-xs-12">
                        <div class="fieldLabel-wrapper">
                            <label>State</label>
                        </div>
                        <select [(ngModel)]="vm.PlateStateCode" #State="ngModel" name="State" class="state gbmask">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-xs-12">
                        <label>Do you use your vehicle for a ride sharing service? (i.e. Uber, Lyft, etc...) </label>
                        <div class="container-wrapper">
                            <div class="row">
                                <div class="col-lg-6 d-flex">
                                    <label class="container">
                                        <input type="radio" name="ride-sharing" [(ngModel)]="vm.VehicleClaimInfo.RideShareUse" [value]="true" />
                                        <span class="checkmark"></span>
                                        Yes
                                    </label>
                                    <label class="container">
                                        <input type="radio" name="ride-sharing" [(ngModel)]="vm.VehicleClaimInfo.RideShareUse" [value]="false" />
                                        <span class="checkmark"></span>
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-xs-12">
                        <label>Do you use your vehicle for delivery services (i.e. Amazon, Papa Johns, etc...)</label>
                        <div class="container-wrapper">
                            <div class="row">
                                <div class="col-lg-6 d-flex">
                                    <label class="container">
                                        <input type="radio" name="delivery-service" [(ngModel)]="vm.VehicleClaimInfo.DeliveryServiceUse" [value]="true" />
                                        <span class="checkmark"></span>
                                        Yes
                                    </label>
                                    <label class="container">
                                        <input type="radio" name="delivery-service" [(ngModel)]="vm.VehicleClaimInfo.DeliveryServiceUse" [value]="false" />
                                        <span class="checkmark"></span>
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-lg-12" *ngIf="isRVVehicleDamageScreen">
            <div class="car-detail-wrapper">
                <p>Select the areas that were damaged during the incident.</p>
                <p class="vehicleDiagram-front-label">Front</p>
                <svg id="rvVehicleDamageImage">
                    <path *ngFor="let component of rvVehicleComponents"
                          class="image-area clickable"
                          [attr.id]="component.name"
                          [attr.d]="component.dimensions"
                          (click)="rvAreaClicked(component, vm)"
                          [class.area-background]="component.isClicked" />
                </svg>
                <p class="vehicleDiagram-back-label">Back</p>
            </div>
        </div>
        <div class="col-xs-12 col-lg-12" *ngIf="isVehicleDamageScreen">
            <div class="car-detail-wrapper">
                <p>Select the areas that were damaged during the incident.</p>
                <p class="vehicleDiagram-front-label">Front</p>
                <svg id="vehicleDamageImage">
                    <path *ngFor="let rvcomponent of vehicleComponents"
                          class="image-area clickable"
                          [attr.id]="rvcomponent.name"
                          [attr.d]="rvcomponent.dimensions"
                          (click)="areaClicked(rvcomponent, vm)"
                          [class.area-background]="rvcomponent.isClicked" />
                </svg>
                <p class="vehicleDiagram-back-label">Back</p>
            </div>
        </div>
        <div class="col-xs-12 col-lg-12" *ngIf="isVehicleDamageScreen || isRVVehicleDamageScreen">
            
            <div *ngIf="(displayInteriorDamageQuestion())" class="row justify-content-center ">
                <div class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Interior)">Vehicle interior damage?</label>
                        <i [ngClass]="childHasInvalid(Interior)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="interior" [value]="true" required
                                           [(ngModel)]="vm.VehicleClaimInfo.IsInteriorDamage" (change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)"
                                           #Interior="ngModel">
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="interior" [value]="false" required
                                           [(ngModel)]="vm.VehicleClaimInfo.IsInteriorDamage" (change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)">
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xs-12"></div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Drivable)">Is your vehicle drivable?</label>
                        <i [ngClass]="childHasInvalid(Drivable)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="driveable" [value]="true" required
                                           [(ngModel)]="vm.VehicleClaimInfo.IsDriveable" (change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)"
                                           #Drivable="ngModel">
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="driveable" [value]="false" required
                                           [(ngModel)]="vm.VehicleClaimInfo.IsDriveable"(change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)">
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="ClaimTypeCategory != 7" class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(AirBags)">Did your airbags deploy?</label><i [ngClass]="childHasInvalid(AirBags)" class="fas fa-asterisk"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="airbag-deploy" [(ngModel)]="vm.VehicleClaimInfo.IsAirbagDeployed" #AirBags="ngModel"
                                           [value]="true"(change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)" #Airbags="ngModel" required>
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="airbag-deploy" [(ngModel)]="vm.VehicleClaimInfo.IsAirbagDeployed"
                                           [value]="false" (change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)" required>
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="ClaimTypeCategory == 7" class="col-lg-4 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Engine)">Was the engine running when the fire started?</label><i [ngClass]="childHasInvalid(Engine)" class="fas fa-asterisk"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-12 d-flex">
                                <label class="container">
                                    <input type="radio" name="engine" [(ngModel)]="vm.VehicleClaimInfo.IsEngineRunning" #AirBags="ngModel"
                                           [value]="true" (change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)" #Engine="ngModel" required>
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="engine" [(ngModel)]="vm.VehicleClaimInfo.IsEngineRunning"
                                           [value]="false" (change)="isVehicleDamageScreen && calculateVehicleDamage(vm) || isRVVehicleDamageScreen && calculateRVVehicleDamage(vm)" required>
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-8 col-xs-12">
                    <div class="fieldLabel-wrapper">
                        <label for="description" [ngClass]="childHasInvalid(DamageDescription)">Description of damages</label>
                        <i [ngClass]="childHasInvalid(DamageDescription)" class="fas fa-asterisk"></i>
                    </div>
                    <textarea Validate name="DamageDescription" required rows="3" [(ngModel)]="vm.VehicleClaimInfo.DamageDescription" #DamageDescription="ngModel"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button *ngIf="!isVehicleDamageScreen && !isRVVehicleDamageScreen" id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button *ngIf="isVehicleDamageScreen || isRVVehicleDamageScreen" id="backBtn" class="btn btn-primary" (click)="goBack()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(vehicleDetailForm)">Continue</button>
    </div>
</div>