<div class="container review-container-wrapper">
    <p class="reviewPageHeading">Please review and submit your claim.</p>
    <!--todo-->
    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="hasError" (close)="hasError = false" [type]="danger">
        We are currently unable to process your Claim. Please try again later or call <a href="tel:+1{{ contactNumber }}">{{ contactNumber | mask: '(000) 000-0000' }}</a> to report your Claim.
    </ngb-alert>

    <div class="row add-padding-ui">
        <div class="col-lg-4 col-md-6 col-xs-12">
            <header class="reviewSectionHeader h5-header-ui">
                <h5>Incident Report</h5>
            </header>
            <div class="review-input-wrapper" [class.selected]="isEditingLossInfo">
                <header>
                    <span class="reportLabel">Incident Details</span>
                    <div *ngIf="vm.DisplayLossDescriptionBox">
                        <button *ngIf="!isEditingLossInfo" class="editBtn" (click)="editLossInfo()">Edit</button>

                        <button *ngIf="isEditingLossInfo" class="editBtn selected" (click)="updateLossInfo(LossDescription)">Update</button>
                    </div>
                </header>
                <div class="cardFields-wrapper d-flex flex-wrap selected">
                    <div class="labelInputWrapper col-lg-6 col-xs-12 half-wrap">
                        <label>Date</label>
                        <input type="text" [(ngModel)]="lossDate" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12 half-wrap">
                        <label>Time</label>
                        <input type="text" [(ngModel)]="lossTime" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Location</label>
                        <input type="text" [(ngModel)]="vm.LossAddress.Address1" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>City/State/Zip</label>
                        <input type="text" [(ngModel)]="lossCityStateZip" disabled="disabled" />
                    </div>
                    <div *ngIf="vm.DisplayLossDescriptionBox" class="labelInputWrapper col-lg-12 col-xs-12">
                        <label>Incident Description</label>
                        <textarea Validate required type="text" name="LossDescription" #LossDescription="ngModel" [(ngModel)]="vm.LossDetails.LossDescription" [disabled]="!isEditingLossInfo"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12">
            <header class="reviewSectionHeader">
                <h5>Parties Involved</h5>
                <button *ngIf="!showCloseAll(vm.LossParticipants)" class="ExpandAll" (click)="expandAll(vm.LossParticipants)">Expand All</button>
                <button *ngIf="showCloseAll(vm.LossParticipants)" class="ExpandAll" (click)="closeAll(vm.LossParticipants)">Close All</button>
            </header>
            <div *ngFor="let participant of vm.LossParticipants" class="review-input-wrapper"
                 [class.selected]="participant.DisplayMode == 'Edit'">

                <header class="cursor" (click)="toggle(participant);">
                    <div class="media">
                        <div *ngIf="!isDeviceMobile" style="margin-right:10px;">
                            <maleGenderIcon *ngIf="participant.Gender === 'M'" class="mr-3 align-self-start" style="width: 17px !important;"></maleGenderIcon>
                            <femaleGenderIcon *ngIf="participant.Gender === 'F'" class="mr-3 align-self-start" style="width: 17px !important;"></femaleGenderIcon>
                         </div> 
                         <h5 class="mt-0 gbmask">{{participant.FirstName}} {{participant.LastName}}</h5>
                        </div>
                        <span class="policyMember" *ngIf="participant.VehicleRole !== null">{{ participant.VehicleRole }}</span>
                        <span class="policyMember">{{ (participant.ParticipantClaimDetails !== null && participant.ParticipantClaimDetails.IsOnPolicy) ? "Insured" : "Other" }}</span>
                        <button class="editBtn" *ngIf="participant.DisplayMode != 'Edit'" (click)="$event.stopPropagation(); editMode(participant);">
                            Edit
                        </button>
                        <button class="editBtn selected" *ngIf="participant.DisplayMode == 'Edit'" (click)="$event.stopPropagation(); updateLossParticipant(participant, FirstName, LastName, MiddleName, Address, Email, Phone, InjuryDescription)">
                            Update
                        </button>
</header>
                <div class="cardFields-wrapper d-flex flex-wrap" [class.selected]="participant.DisplayMode != 'ReadOnly'">
                    <div *ngIf="participant.IsOnPolicy" class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Policy Number</label>
                        <input type="text" disabled="disabled" [value]="vm.Policy.PolicyNumber" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>First Name</label>
                        <input type="text" name="FirstName" OnlyLetters Validate required [(ngModel)]="participant.FirstName" #FirstName="ngModel" disabled="disabled">
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Last Name</label>
                        <input type="text" name="LastName"  OnlyLetters Validate required [(ngModel)]="participant.LastName" #LastName="ngModel" disabled="disabled"/>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Middle Name</label>
                        <input type="text" name="MiddleName" OnlyLetters Validate [(ngModel)]="participant.MiddleName" #MiddleName="ngModel" disabled="disabled"/>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Address</label>
                        <input type="text" Validate name="Address"  [(ngModel)]="participant.Address.Address1" #Address="ngModel" [disabled]="participant.DisplayMode != 'Edit'" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>City/State/Zip</label>
                        <input type="text" [value]="getParticipantDisplayAddress(participant)" disabled="disabled" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Email Address:</label>
                        <input type="email" Validate name="Email" [(ngModel)]="participant.ContactInfo.Emails[0].EmailAddress" #Email="ngModel" [disabled]="participant.DisplayMode != 'Edit'" />
                    </div>
                    <ng-container *ngFor="let participantPhone of participant.ContactInfo.Phones; let i = index">
                        <div class="labelInputWrapper col-lg-6 col-xs-12" *ngIf="participantPhone != null && participantPhone.IsActive">
                            <label [ngClass]="childHasInvalid(Phone)">Primary Phone/Ext</label>
                            <input required name="participantPhoneNumber{{participant.LossParticipantId}}" type="tel" mask="(000) 000-0000" [(ngModel)]="participantPhone.PhoneNumber" #Phone="ngModel" [disabled]="participant.DisplayMode != 'Edit'" />
                        </div>
                        <div class="labelInputWrapper col-lg-3 col-xs-12" *ngIf="participant.DisplayMode != 'Edit' && participantPhone.IsActive">
                            <label>Phone Type</label>
                            <input type="text" [(ngModel)]="participantPhone.PhoneType" disabled />
                        </div>       
                        <div class="labelInputWrapper col-lg-3 col-xs-12" *ngIf="participant.DisplayMode == 'Edit' && participantPhone.IsActive">
                            <label [ngClass]="childHasInvalid(PhoneType)">Phone Type</label>
                            <select required  [(ngModel)]="participantPhone.PhoneType" name="participantPhoneType{{participant.LossParticipantId}}" #PhoneType="ngModel">
                                <option [ngValue]="null">Select Phone Type</option>
                                <option *ngFor="let pt of availablePhoneTypes(participant, participantPhone)" [ngValue]="pt.DisplayCode">{{ pt.DisplayValue }}</option>
                            </select>
                        </div>
                        <div style="margin-top:20px;" class="col-lg-3"*ngIf="participant.DisplayMode == 'Edit' && canDisplayRemoveButton(participant) && participantPhone.IsActive">
                            <a class="normalLink" (click)="removePhone(participantPhone)">Remove</a>
                        </div>
                    </ng-container>
                    <div class="col-lg-12 col-xs-12" *ngIf="canAddPhone(participant) && participant.DisplayMode == 'Edit';">
                        <button class="btn" (click)="addPhone(participant);">Add Phone</button>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Injured</label>
                        <input type="text" value="{{ participant.ParticipantClaimDetails.InjuryDescription != null && participant.ParticipantClaimDetails.InjuryDescription.length > 0 ? 'Yes' : 'No' }}" disabled="disabled" />
                    </div>
                    <div *ngIf="participant.ParticipantClaimDetails.InjuryDescription != null && participant.ParticipantClaimDetails.InjuryDescription != ''" class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Injury Description</label>
                        <input type="text" [(ngModel)]="participant.ParticipantClaimDetails.InjuryDescription" #InjuryDescription="ngModel" disabled="disabled" />
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-xs-12 ">
            <header class="reviewSectionHeader">
                <h5>Damaged Properties</h5>
                <button *ngIf="!showCloseAll(vm.Vehicles)" class="ExpandAll" (click)="expandAll(vm.Vehicles)">Expand All</button>
                <button *ngIf="showCloseAll(vm.Vehicles)" class="ExpandAll" (click)="closeAll(vm.Vehicles)">Close All</button>
            </header>
            <div *ngFor="let vehicle of vm.Vehicles" class="review-input-wrapper cursor"
                 [class.selected]="vehicle.DisplayMode == 'Edit'">

                <header class="cursor" (click)="toggle(vehicle);">
                    <div *ngIf="isVehicleInfoKnown(vehicle)">
                        <span class="year gbmask">{{vehicle.Year}}</span>
                        <span class="make gbmask">{{vehicle.Make}}</span>
                        <span class="model gbmask">{{vehicle.Model}}</span>
                    </div>
                    <div *ngIf="!isVehicleInfoKnown(vehicle)">
                        <span>Unknown</span>
                    </div>
                    <span class="policyMember">{{(vehicle.VehicleClaimInfo !== null && vehicle.VehicleClaimInfo.IsOnPolicy) ? "Insured" : "Other" }}</span>

                    <button class="editBtn" *ngIf="vehicle.DisplayMode != 'Edit'" (click)="$event.stopPropagation(); editMode(vehicle);">
                        Edit
                    </button>
                    <button class="editBtn selected" *ngIf="vehicle.DisplayMode == 'Edit'" (click)="$event.stopPropagation(); updateVehicle(vehicle, Year, Make, Model, PlateNumber, State, DamageDescription)">
                        Update
                    </button>
                </header>
                <div class="cardFields-wrapper d-flex flex-wrap" [class.selected]="vehicle.DisplayMode != 'ReadOnly'">
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Year</label>
                        <input type="text" Validate name="Year" required OnlyNumbers [(ngModel)]="vehicle.Year" #Year="ngModel" disabled="disabled"/>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Make</label>
                        <input type="text" Validate name="Make" required OnlyLetters [(ngModel)]="vehicle.Make" #Make="ngModel" disabled="disabled"/>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Model</label>
                        <input type="text" Validate name="Model" required OnlyLetters [(ngModel)]="vehicle.Model" #Model="ngModel" disabled="disabled"/>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>License Plate</label>
                        <input type="text" Validate name="PlateNumber" [(ngModel)]="vehicle.PlateNumber" #PlateNumber="ngModel" [disabled]="vehicle.DisplayMode != 'Edit' || vehicle.IsOnPolicy" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>License Plate State</label>
                        <input *ngIf="vehicle.DisplayMode != 'Edit'" type="text" [(ngModel)]="vehicle.PlateStateCode" disabled />
                        <select *ngIf="vehicle.DisplayMode == 'Edit'" [(ngModel)]="vehicle.PlateStateCode" #State="ngModel" name="State" [disabled]="vehicle.DisplayMode != 'Edit' || vehicle.IsOnPolicy">
                            <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                        </select>
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Damage Description</label>
                        <textarea Validate name="DamageDescription"  [(ngModel)]="vehicle.VehicleClaimInfo.DamageDescription" #DamageDescription="ngModel" [disabled]="vehicle.DisplayMode != 'Edit'"></textarea>
                    </div>
                    <div *ngIf="vehicle.IsOnPolicy" class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Airbags Deployed?</label>
                        <input type="text" value="{{ vehicle.VehicleClaimInfo.IsAirbagDeployed ? 'Yes' : 'No' }} " disabled="disabled" />
                    </div>
                    <div *ngIf="vehicle.IsOnPolicy" class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>Current Vehicle Location</label>
                        <input type="text" value="{{ transposeVehicleLocationCode(vehicle) }}" [disabled]="vehicle.DisplayMode != 'Edit' || vehicle.IsOnPolicy" />
                    </div>

                    <span class="col-lg-6 col-xs-12 align-top gbmask">{{ getVehiceOccupantPositionByVehicle(vehicle, 'Owner') }}</span>
                    <span class="col-lg-6 col-xs-12 align-top gbmask">{{ getVehiceOccupantPositionByVehicle(vehicle, 'Driver') }}</span>
                    <span class="col-lg-6 col-xs-12 align-top gbmask">{{ getVehiceOccupantPositionByVehicle(vehicle, 'Passenger') }}</span>
                </div>
            </div>
            <div *ngFor="let property of vm.PropertyInfoModels" class="review-input-wrapper cursor"
                 [class.selected]="property.DisplayMode == 'Edit'">

                <header class="cursor" (click)="toggle(property);">
                    <div *ngIf="isPropertyInfoKnown(property)">
                        <span class="PropertyDescription">{{property.PropertyDescription}}</span>
                    </div>
                    <div *ngIf="!isPropertyInfoKnown(property)">
                        <span>Unknown</span>
                    </div>

                    <span class="policyMember">Other</span>

                    <button class="editBtn" *ngIf="property.DisplayMode != 'Edit'" (click)="$event.stopPropagation(); editMode(property);">
                        Edit
                    </button>
                    <button class="editBtn selected" *ngIf="property.DisplayMode == 'Edit'" (click)="$event.stopPropagation(); updateProperty(property, PropertyDescription, DamageDescription)">
                        Update
                    </button>
                </header>

                <div class="cardFields-wrapper d-flex flex-wrap" [class.selected]="property.DisplayMode != 'ReadOnly'">
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>PropertyDescription</label>
                        <input type="text" required Validate name="PropertyDescription" [(ngModel)]="property.PropertyDescription" #PropertyDescription="ngModel" [disabled]="property.DisplayMode != 'Edit'" />
                    </div>
                    <div class="labelInputWrapper col-lg-6 col-xs-12">
                        <label>DamageDescription</label>
                        <textarea required Validate name="PropertyDamageDescription" [(ngModel)]="property.DamageDescription" #PropertyDamageDescription="ngModel" [disabled]="property.DisplayMode != 'Edit'"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">Submit</button>
    </div>
</div>