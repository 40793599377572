




import { BaseModel } from './BaseModel';

import { AddressModel } from './AddressModel';
import { ContactInfoModel } from './ContactInfoModel';
import { ValueDetailModel } from './ValueDetailModel';

export class PrimaryContactModel extends BaseModel {
    
    public PrimaryContactId: number = 0;
    public PrimaryContactName: string = null;
    public Address: AddressModel = null;
    public ContactInfo: ContactInfoModel = null;
    public States: ValueDetailModel[] = [];
}