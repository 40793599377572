import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LayoutService, SessionService, SessionBridgeApi, LoggerApi } from '@Common/services';
import { DashboardSettings } from '@ClaimsModels/index';

@Component({
    selector: 'fnolHome',
    templateUrl: '../../views/home/fnolHome.component.html'
})
export class FNOLHomeDashboardComponent extends BaseFormComponent {

    public showBrand: boolean = true;
    private isEncompassAgencyDashboard: boolean = false;

    constructor(private router: Router, private bridgeApi: SessionBridgeApi,
        private layoutService: LayoutService, private session: SessionService, private loggerApi: LoggerApi) {
        super();
    }

    ngOnInit(): void {
        this.loggerApi.getDashboardSettings().then((settings: DashboardSettings[]) => {

            if (settings.find(x => x.Key === "HomeDashboardEnabled").Value === "false") {
                this.router.navigate(['404']);
            }

            if (settings.find(x => x.Key === "BrandEnabled").Value === "false") {
                this.showBrand = false;
            }
        });

        this.layoutService.clearUsername();
        this.layoutService.setShowUsername(false);
        this.session.clearSession();
    }

    public goingBackWithLocation(): void {

    }

    protected submit(form: NgForm): void { }

    public submitForm(form: HTMLFormElement): void {
        //let policyNumber: HTMLInputElement = <HTMLInputElement>form.children.namedItem("PolicyInput");

        //if (policyNumber.value == "") {
        //    policyNumber = <HTMLInputElement>form.children.namedItem("PolicyNumber");
        //}

        //let appName = this.getApplicationName(form);

        //this.bridgeApi.generateBridgePayload(policyNumber.value, appName.value)
        //    .then(response => {
        //        let authToken: HTMLInputElement = <HTMLInputElement>form.children.namedItem("Token");
        //        authToken.value = response;

        //        // I am blanking this out to show that in the ReceiveJWT call, we require the token to provide this value.
        //        // The PolicyNumber is required for token.
        //        policyNumber.value = "";

        //        form.submit();
        //    });
    }
}