import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SessionWarningBottomSheet } from '@Common/components/modals/sessionWarning.component';
import { UserIdleService } from 'angular-user-idle';
import { fromEvent, merge, Subscription } from 'rxjs';
import { SessionService } from './session.service';
import { SessionSettingsModel } from '@ClaimsModels/index';
import { ConstantsService } from '../constants.service';

@Injectable()
export class SessionIdleService {

    private matBottomSheetRef: MatBottomSheetRef;
    private timerSubscriptions: Subscription[] = [];

    constructor(private userIdle: UserIdleService, private bottomSheet: MatBottomSheet, private session: SessionService, private constants: ConstantsService) {

        this.userIdle.setCustomActivityEvents(merge(
            // For our purposes, we don't need to watch for events.
            // Left blank intentionally.
        ));
    }

    public startWatchingSession() {

        if (!this.hasSessionSettings()) {
            return;
        }

        if (!this.isValidFnolPage()) {
            return;
        }

        this.setSessionIdleTimer();

        this.userIdle.startWatching();
        this.timerSubscriptions.push(this.userIdle.onTimerStart().subscribe(count => {

            if (count === 1) {
                this.matBottomSheetRef = this.bottomSheet.open(SessionWarningBottomSheet, { disableClose: true })
                this.matBottomSheetRef.afterDismissed().subscribe(result => {

                    if (result.event == 'Continue') {
                        this.session.refreshSession();
                        this.restartTimer();
                    }

                    if (result.event == 'End') {
                        this.stopWatchingSession();
                        this.session.navigateToSessionExpirationPage();
                    }
                });
            }
        }));

        this.timerSubscriptions.push(this.userIdle.onTimeout().subscribe(event => {
            this.bottomSheet.dismiss();
            this.stopWatchingSession();
            this.session.navigateToSessionExpirationPage();
        }));
    }

    private setSessionIdleTimer(): void {
        this.userIdle.stopWatching();

        let settings: SessionSettingsModel = this.session.getSessionSettings();

        // covert minutes to seconds
        let idleTimeSeconds: number = settings.SessionIdleMinutes * 60;
        let timeOutSeconds: number = settings.WarningTimeOutMinutes * 60;

        this.userIdle.setConfigValues({ idle: idleTimeSeconds, timeout: timeOutSeconds, ping: 0, idleSensitivity: 0 });
    }

    public stopWatchingSession(): void {
        this.timerSubscriptions.forEach(x => x.unsubscribe());
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
    }

    public restartTimer(): void {
        this.stopWatchingSession();
        this.session.refreshSession();
        this.startWatchingSession();
    }

    private isValidFnolPage(): boolean {
        let watchIsNeeded: boolean = true;
        let url: string = window.location.pathname;

        switch (url) {
            case '/':
            case '/404':
            case '/error':
            case '/sessionTimeout':
            case '/mc360Dashboard':
            case '/homeDashboard':
            case '/fnolDashboard':
                watchIsNeeded = false;
                break;
            default:
                watchIsNeeded = true;
                break;
        }

        return watchIsNeeded;
    }

    private hasSessionSettings(): boolean {
        let settings: SessionSettingsModel = this.session.getSessionSettings();

        if (settings === null || settings === undefined) {
            return false;
        }

        if (settings.SessionIdleMinutes === 0) {
            return false;
        }

        return true;
    }
}