export class Breadcrumb {
    public DisplayName: string;
    public DisplayOrder: number;
    public IsCompleted: boolean;
    public PercentDone: number;
    public Counter: number;

    constructor(displayName: string, displayOrder: number) {
        this.DisplayName = displayName;
        this.DisplayOrder = displayOrder;
        this.IsCompleted = false;
        this.PercentDone = 0;
        this.Counter = 0;
    }
}