import { Component } from '@angular/core';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { ConstantsService, BreadcrumbService } from '@Common/services';
import { FNOLRoutingService, FNOLAutoService, LossApi } from '../../services';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { ClaimTypeCategoryEnum, BaseLossDescriptionModel, LossDetailsModel, ClaimTypeModel } from '@ClaimsModels/index';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'occupantInformation',
    templateUrl: '../../views/loss/occupantInformation.component.html',
    providers: [FNOLRoutingService]
})

export class OccupantInformationComponent extends BaseFormComponent {
    
    public hasOccupantsInVehicle: boolean | null;
    public hasError: boolean = false;
    public claimTypeCategory: string;
    constructor(private api: LossApi, private fnolRouting: FNOLRoutingService, private fnolAutoService: FNOLAutoService, private constantsService: ConstantsService, private breadcrumbService: BreadcrumbService,
        private spinner: Ng4LoadingSpinnerService) {
        super();
        this.vm = new BaseLossDescriptionModel();
        this.vm.LossDetails = new LossDetailsModel();
    }
    public vm: BaseLossDescriptionModel = new BaseLossDescriptionModel();

    public ngOnInit() {

        if (sessionStorage.getItem('HasOccupantsInVehicle') === "true")
            this.setHasOccupantsInVehicle(true)
        if (sessionStorage.getItem('HasOccupantsInVehicle') === "false")
            this.setHasOccupantsInVehicle(false);

        this.setPageHeader();
        this.spinner.hide();
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {
        if (this.hasOccupantsInVehicle == null) {
            this.hasError = true;
            this.spinner.hide();
            return;
        }
        
        
        if (this.validate(form)) {
            if (this.hasOccupantsInVehicle == true) {
                sessionStorage.setItem(this.constantsService.HasOccupantsInVehicleKey, "true");
                this.fnolAutoService.setHasOccupantInVehicle("true");
            }
            else {
                sessionStorage.setItem(this.constantsService.HasOccupantsInVehicleKey, "false");
                this.fnolAutoService.setHasOccupantInVehicle("false");
            }
            
            this.spinner.show();
            this.api.saveHasOccupantsInVehicle(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    let model: ClaimTypeModel = new ClaimTypeModel();
                    model.ClaimType = sessionStorage.getItem("ClaimTypeCode");
                    this.api.saveClaimType(model).then(response => {
                        this.spinner.hide();
                        if (response.Success) {
                            this.fnolRouting.next();
                        }
                    });
                  
                }
            });
        }
    }

    public participantInfoStringToBoolean(recoveryInfoString: string): boolean | null {
        if (recoveryInfoString != null && recoveryInfoString != undefined) {
            if (recoveryInfoString == this.constantsService.TrueStringValue) {
                return true;
            }

            if (recoveryInfoString == this.constantsService.FalseStringValue) {
                return false;
            }
        }

        return null;
    }

    public setHasOccupantsInVehicle(value: boolean): void {
        this.vm.LossDetails.HasOccupantsInVehicle = value;
        this.hasOccupantsInVehicle = value;
    }

    private setPageHeader(): void {
        let claimTypeEnum: ClaimTypeCategoryEnum = this.fnolAutoService.getClaimTypeCategory();

        switch (claimTypeEnum) {
            case ClaimTypeCategoryEnum.Theft:
                this.claimTypeCategory = "theft"; 
                break;
            case ClaimTypeCategoryEnum.Weather:
                this.claimTypeCategory = "weather";  
                break;
            default:
                this.claimTypeCategory = "other";  
                break;
        }
    }
}