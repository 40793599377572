<div ngForm #selectVehicleDriver="ngForm" class="main-inner-wrapper">
    <p class="header" *ngIf="vm.VehicleInfo.Year != null && vm.VehicleInfo.Make != null && vm.VehicleInfo.Model != null">
        Please <span class="bold" *ngIf="!isThirdParty">select or</span> add the <em class="emphasis-bold">driver</em> of the <em class="emphasis-bold">{{ vm.VehicleInfo.Year }} {{ vm.VehicleInfo.Make }} {{ vm.VehicleInfo.Model }}</em>.
        <span class="bold" *ngIf="!hasAlreadySelected">&nbsp;If there was no <em class="emphasis-bold">driver</em>, select &quot;no driver.&quot; If the driver is <em class="emphasis-bold">unknown</em>, click continue.</span>
    </p>

    <p class="header" *ngIf="vm.VehicleInfo.Year == null || vm.VehicleInfo.Make == null || vm.VehicleInfo.Model == null">
        Please <span class="bold" *ngIf="!isThirdParty">select or </span>add the <em class="emphasis-bold">driver</em> of the <span class="bold" *ngIf="isThirdParty">other</span> vehicle at the time of the incident.
        <span class="bold" *ngIf="!hasAlreadySelected"> If there was no <em class="emphasis-bold">driver</em>, select "no driver." If the driver is <em class="emphasis-bold">unknown</em>, click continue.</span>
    </p>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="displayAlreadySelectedMessage" (close)="displayAlreadySelectedMessage = false" [type]="danger">
        <strong>Error!</strong> Please select a Driver.
    </ngb-alert>

    <div class="input-wrapper">
        <div class="row">
            <div *ngFor="let participant of vm.DriverOptions">
                <button class="person" [class.selected]="participant.IsSelected" (click)="selectPerson(participant)" required>
                    <div *ngIf="!isDeviceMobile">
                        <div *ngIf="participant.Gender === 'M'">
                            <maleGenderIcon></maleGenderIcon>
                        </div>
                        <div *ngIf="participant.Gender === 'F'">
                            <femaleGenderIcon></femaleGenderIcon>
                        </div>
                    </div>
                    <span class="gbmask">{{participant.Name}}</span>
                    <i *ngIf="participant.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>

            <div *ngIf="shouldDisplayNoDriver()">
                <button class="person noDriver" [class.selected]="noDriverSelected" [class.addNew]="!noDriverSelected" (click)="noDriver()">
                    <i class="fas fa-ban"></i>
                    <span>No Driver</span>
                    <i *ngIf="noDriverSelected" class="fas fa-check-circle"></i>
                </button>
            </div>

            <div>
                <button class="person addNew" (click)="addNew()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Driver Not Listed</span>
                </button>
            </div>

        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(selectVehicleDriver)">Continue</button>
    </div>
</div>