




import { BaseModel } from './BaseModel';

import { ParticipantModel } from './ParticipantModel';

export class SelectParticipantsModel extends BaseModel {
    
    public LossParticipants: ParticipantModel[] = [];
}