<div class="desktop"  *ngIf="breadcrumbs.length > 0">
    <ng-container *ngFor="let breadcrumb of breadcrumbs;let i = index;">
        <div class="outer-wrapper">
            <div class="circle" [class.completed]="breadcrumb.IsCompleted || breadcrumb.Counter > 0">
                <span *ngIf="!breadcrumb.IsCompleted && i !== breadcrumbs.length - 1">{{ i+1 }}</span>
                <span *ngIf="i === breadcrumbs.length - 1"><i class="fas fa-flag"></i></span>
                <span *ngIf="breadcrumb.IsCompleted"><i class="fas fa-check"></i></span>
            </div>
            <div class="inner-wrapper">
                <div class="line">
                    <mat-progress-bar mode="determinate" value="{{ breadcrumb.PercentDone }}"></mat-progress-bar>
                </div>
            </div>
            <p>{{ breadcrumb.DisplayName }}</p>
        </div>
    </ng-container>
</div>

<div class="mobile" *ngIf="mobileBreadcrumb.Counter > 0">
    <div class="outer-wrapper">
        <div class="circle completed">
            <span><i class="fas fa-flag"></i></span>
        </div>
        <div class="inner-wrapper">
            <div class="line">
                <mat-progress-bar mode="determinate" value="{{ mobileBreadcrumb.PercentDone }}"></mat-progress-bar>
            </div>
        </div>
        <div class="circle">
            <span><i class="fas fa-flag-checkered"></i></span>
        </div>
    </div>
</div>