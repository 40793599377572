import { BaseModel } from './BaseModel';

import { PedestrianInformationModel } from './PedestrianInformationModel';
import { ValueDetailModel } from './ValueDetailModel';

export class PedestrianModel extends BaseModel {
    
    public Persons: PedestrianInformationModel[] = [];
    public Pedestrian: PedestrianInformationModel = null;
    public States: ValueDetailModel[] = [];
    public PhoneTypes: ValueDetailModel[] = [];
}