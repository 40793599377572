import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutService {
    private username: string = null;
    private subject = new Subject<boolean>();
    private brand = new BehaviorSubject<string>('');
    private brandKey: string = "claimsWebBrand";
    private usernameKey: string = "claimsWebUsername";

    public setUsername(username: string) {
        this.username = username;

        sessionStorage.setItem(this.usernameKey, username);
    }

    public clearUsername(): void {
        this.username = null;
        sessionStorage.removeItem(this.usernameKey);
        this.setShowUsername(false);
    }

    public getUsername(): string {
        if (this.username !== null && this.username !== undefined && this.username !== '') {
            return this.username;
        }

        return sessionStorage.getItem(this.usernameKey);
    }

    public setShowUsername(show: boolean) {
        this.subject.next(show);
    }

    public getShowUsername(): Observable<boolean> {
        if (this.getUsername() !== null && this.getUsername() !== undefined && this.getUsername() !== '') {
            this.subject.next(false);
        }

        return this.subject.asObservable();
    }

    public setBrand(brand: string) {
        sessionStorage.setItem(this.brandKey, brand);
        this.brand.next(brand);
    }

    public getBrand(): Observable<string> {
        let brand: string = sessionStorage.getItem(this.brandKey);

        if (brand != null && brand != undefined && brand != '') {
            this.brand.next(brand);
        }

        return this.brand.asObservable();
    }
}