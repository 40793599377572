import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';

import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LayoutService, SessionService, SessionBridgeApi, LoggerApi } from '@Common/services';
import { DashboardSettings } from '@ClaimsModels/index';

@Component({
    selector: 'mc360Dashboard',
    templateUrl: '../../views/mc360/mc360Dashboard.component.html'
})
export class MC360DashboardComponent extends BaseFormComponent {
    public showBrand: boolean = true;

    protected submit(form: NgForm): void { }

    constructor(private router: Router, private bridgeApi: SessionBridgeApi, private layoutService: LayoutService, private session: SessionService, private loggerApi: LoggerApi) {
        super();

        this.loggerApi.getDashboardSettings().then((settings: DashboardSettings[]) => {

            if (settings.find(x => x.Key == "MC360DashboardEnabled").Value == "false") {
                router.navigate(['404']);
            }

            if (settings.find(x => x.Key == "BrandEnabled").Value == "false") {
                this.showBrand = false;
            }
        })
    }
    public ngOnInit(): void {
        this.layoutService.clearUsername();
        this.layoutService.setShowUsername(false);
        this.session.clearSession();
    }
    public goingBackWithLocation(): void {
        
    }

    public submitForm(form: HTMLFormElement): void {
        let sessionID: HTMLInputElement = <HTMLInputElement>form.children.namedItem("SessionID");
        let authToken: HTMLInputElement = <HTMLInputElement>form.children.namedItem("Token");

        authToken.value = sessionID.value;
        let appName: HTMLInputElement = <HTMLInputElement>form.children.namedItem("ApplicationName");
        appName.value = "ClaimsWeb";

        form.submit();
    }

}