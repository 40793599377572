<div class="details-header" style="width:30%;float:left;">
    <div class="claim-box">
        <div class="claim-card">
            <h3>Previous Documents</h3><button class="button-arrow" (click)="viewDocuments()">View All <img src="/content/images/arrow.svg" width="20" height="25" /></button>
            <div *ngFor="let document of claimDocuments" style="border: 1px solid lightgrey;border-radius:10px;padding:10px;margin-left:15px;">
                <span>{{document.DocumentName}}</span><br />
                <span style="font-size:smaller;">Uploaded {{document.DateUploaded | date}}</span>&nbsp;
                <button (click)="viewDocumentDetails(document.DocumentId)">View</button>
            </div>
        </div>
    </div>
</div>
<div class="content-wrapper" style="width:65%;float:right;margin:10px;">
    <div class="claim-box">
        <span>{{vm.DocumentName}}</span><br />
        <span>{{vm.DocumentSize/1024 | number : '1.0-2'}} KB</span><br />
        <span>Uploaded {{vm.DateUploaded | date}}</span><br />
        <div id="docPreview">

        </div>
        <button (click)="viewDocument()">View</button>
        <button (click)="goingBackWithLocation()">Back</button>
    </div>
</div>