import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'ng2-file-upload';

import {
    FileUploadComponent, MaleGenderIconComponent, FemaleGenderIconComponent, VehicleIconComponent
} from './components'


@NgModule({
    declarations: [
        FileUploadComponent, MaleGenderIconComponent, FemaleGenderIconComponent, VehicleIconComponent
    ],
    imports: [
        CommonModule,
        FileUploadModule
    ],
    exports: [
        FileUploadComponent, MaleGenderIconComponent, FemaleGenderIconComponent, VehicleIconComponent
    ]
})
export class CommonComponentsModule { }