<div ngForm #insuredPedestrianForm="ngForm" class="main-inner-wrapper">

    <div *ngIf="isScreenOne">
        <div *ngIf="!isClaimant && !isPedestrian">
            <p class="header">
                <em class="emphasis-bold" >National General Insured</em>
                <br /><br />
            </p>
        </div>
        <div *ngIf="!isClaimant">
            <p class="header">
                Please select or add the <em class="emphasis-bold">pedestrian</em>.
                <br />
                If the pedestrian is <em class="emphasis-bold">unknown</em>, click continue.
            </p>
        </div>       
    </div>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="noPedestrianSelected" [type]="danger">
        <strong>Error!</strong> Please select a Pedestrian.
    </ngb-alert>

    <div class="input-wrapper" *ngIf="isScreenOne">
        <div class="row">
            <div *ngFor="let participant of vm.Persons, let i = index">
                <button class="person" [class.selected]="participant.IsSelected" (click)="selectPerson(participant,i)" required>
                    <span class="gbmask">{{participant.FirstName}} {{participant.LastName}}</span>
                    <i *ngIf="participant.IsSelected" class="fas fa-check-circle"></i>
                </button>
            </div>

            <div *ngIf="canAddNew">
                <button class="person addNew" (click)="addNew()">
                    <i class="fas fa-plus-circle"></i>
                    <span>Pedestrian Not Listed</span>
                </button>
            </div>

        </div>
    </div>

    <div *ngIf="!isScreenOne">
            <div *ngIf="!isClaimant && !isPedestrian">
                <p>
                    <em class="emphasis-bold" >National General Insured</em>
                    <br /><br />
                </p>
            </div>
            <p class="header">
                Please provide the pedestrian's information.
            </p>                
    </div>

    <div class="input-wrapper" *ngIf="!isScreenOne">
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(FirstName)">First Name</label><i [ngClass]="childHasInvalid(FirstName)" class="fas fa-asterisk" title="*Required Field"></i>
                </div>
                <input type="text" Validate OnlyLetters required name="FirstName" ngModel="FirstName" #FirstName="ngModel" [(ngModel)]="vm.Pedestrian.FirstName" />
            </div>

            <div class="col-lg-2 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>MI</label>
                </div>
                <input type="text" OnlyLetters Validate name="MiddleName" [(ngModel)]="vm.Pedestrian.MiddleName" #MiddleName="ngModel" ngModel="MiddleName" />
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(LastName)">Last Name</label><i [ngClass]="childHasInvalid(LastName)" class="fas fa-asterisk" title="*Required Field"></i>
                </div>
                <input type="text" name="LastName" OnlyLetters Validate required ngModel="LastName" #LastName="ngModel" [(ngModel)]="vm.Pedestrian.LastName" />
            </div>

            <div class="col-lg-12 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Address</label>
                </div>
                <input type="text" name="Address" Validate [(ngModel)]="vm.Pedestrian.Address.Address1" />
            </div>
            <div class="col-lg-6 col-xs-12">
                <input OnlyLetters type="text" Validate name="City" #City="ngModel" [(ngModel)]="vm.Pedestrian.Address.City" />
            </div>
            <div class="col-lg-2 col-xs-12">
                <select [(ngModel)]="vm.Pedestrian.Address.State" #State="ngModel" name="State" class="state">
                    <option [ngValue]="null">State</option>
                    <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                </select>
            </div>
            <div class="col-lg-4 col-xs-12">
                <input OnlyNumbers type="tel" Validate name="Zip" #Zip="ngModel" [(ngModel)]="vm.Pedestrian.Address.Zip" />
            </div>
            <ng-container *ngFor="let contactPhone of this.vm.Pedestrian.ContactInfo.Phones; trackBy: track; let i = index">
                <div class="col-lg-5 col-xs-12" *ngIf="contactPhone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(Phone)">Phone</label><i [ngClass]="childHasInvalid(Phone)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <input type="tel" required name="ContactPhone{{i}}" mask="(000) 000-0000" [(ngModel)]="contactPhone.PhoneNumber" #Phone="ngModel" /><a class="normalLink" (click)="removePhone(contactPhone)">Remove</a>
                </div>
                <div class="col-lg-6 col-xs-12" *ngIf="contactPhone.IsActive">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(PhoneType)">Phone Type</label><i [ngClass]="childHasInvalid(PhoneType)" class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <select name="phoneType{{i}}" required [(ngModel)]="contactPhone.PhoneType" #PhoneType="ngModel">
                        <option [ngValue]="null">Select Phone Type</option>
                        <option *ngFor="let pt of availablePhoneTypes(contactPhone); trackBy: track;" [ngValue]="pt.DisplayCode">{{ pt.DisplayValue }}</option>
                    </select>
                </div>
            </ng-container>
            <div *ngIf="canAddPhone();">
                <button class="phone addNew" (click)="addPhone();">
                    <i class="fas fa-plus-circle"></i>
                    <span>Add Phone</span>
                </button>
            </div>
            <div class="col-lg-6 col-xs-12">
                <label>Email</label>
                <input type="email" Validate email name="Email" [(ngModel)]="email" #Email="ngModel" />
            </div>
            <div class="col-lg-6 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label>Date of Birth</label>
                </div>
                <input type="text" Validate mask="M0/d0/0000" [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" name="DateOfBirth" #DateOfBirth="ngModel" [(ngModel)]="dateOfBirth" />
            </div>
            <div class="col-lg-6 col-xs-12 container">
                <div class="fieldLabel-wrapper">
                    <label>Gender</label>
                </div>
                <div class="container-wrapper">
                    <label class="container">
                        <input type="radio" name="gender" [(ngModel)]="vm.Pedestrian.Gender" value="F">
                        <span class="checkmark"></span>
                        <span>Female</span>
                    </label>
                    <label class="container">
                        <input type="radio" name="gender" [(ngModel)]="vm.Pedestrian.Gender" value="M">
                        <span class="checkmark"></span>
                        <span>Male</span>
                    </label>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 container">
                <!--<label >Injured</label>-->
                <div>
                    <div class="fieldLabel-wrapper" >
                        <label [ngClass]="childHasInvalid(injury)">Injured</label>
                        <i class="fas fa-asterisk" title="*Required Field"></i>
                    </div>
                    <div class="container-wrapper">
                        <label class="container">
                            <input type="radio" name="Injured" [value]="true" #injury="ngModel" [(ngModel)]="injured" required>
                            <span class="checkmark"></span>
                            <span>Yes</span>
                        </label>
                        <label class="container">
                            <input type="radio" name="Injured" [value]="false" [(ngModel)]="injured">
                            <span class="checkmark"></span>
                            <span>No</span>
                        </label>
                    </div>

                </div>

            </div>
            <div *ngIf="injured" class="col-lg-12 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(InjuryDescription)">Injury Description</label>
                    <i [ngClass]="childHasInvalid(InjuryDescription)" class="fas fa-asterisk" title="*Required Field"></i>
                </div>
                <textarea name="InjuryDescription" Validate rows="3" [(ngModel)]="vm.Pedestrian.InjuryDescription" required #InjuryDescription="ngModel"></textarea>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(insuredPedestrianForm)">Continue</button>
    </div>
</div>