import { Component } from '@angular/core';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ParticipantApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { AddressValidationService, ConstantsService } from '@Common/services/';
import { BaseSelectContactComponent } from'./baseSelectContact.component';

@Component({
    selector: 'selectPolicyContact',
    templateUrl: '../../views/participant/selectPolicyContact.component.html',
    providers: [FNOLRoutingService]
})
export class SelectPolicyContactComponent extends BaseSelectContactComponent {

    constructor(api: ParticipantApi, spinner: Ng4LoadingSpinnerService, addressValidationService: AddressValidationService,
        fnolRouting: FNOLRoutingService, fnolAutoService: FNOLAutoService, constantsService: ConstantsService) {
        super(api, spinner, addressValidationService, fnolRouting, fnolAutoService, constantsService);

        super.setIsOnPolicy(true);
    }
}