import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@Common/common.module';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonDirectivesModule } from '../../ClaimsWeb.Areas.Common/app/commonDirectives.module';
import { CommonComponentsModule } from '../../ClaimsWeb.Areas.Common/app/commonComponents.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule  } from '@angular/material/slider';
import { MatSelectModule  } from '@angular/material/select';

import { AuthGuardService } from '@Common/services/';
import {
    DateOfLossComponent, ClaimReporterComponent, LossDescriptionComponent, ClaimTypeComponent, ConfirmationComponent, HasOpenClaimComponent,
    ReviewComponent, UploadDocumentComponent, ContactInformationComponent, SelectPropertyOwnerComponent, HasInjuryComponent, HasDwellingDamageComponent, CallInEmergency
} from './components';

import {
    LossApi, ParticipantApi, HomeService
} from './services';


const fnolHomeRoutes: Routes = [
    { path: 'fnolHome/dateOfLoss', component: DateOfLossComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/dwelling', component: HasDwellingDamageComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/injury', component: HasInjuryComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/callIn', component: CallInEmergency, canActivate: [AuthGuardService] },
    { path: 'fnolHome/hasOpenClaim', component: HasOpenClaimComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/claimReporter', component: ClaimReporterComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/lossDescription', component: LossDescriptionComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/claimType', component: ClaimTypeComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/selectPropertyOwner', component: SelectPropertyOwnerComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/contactInformation', component: ContactInformationComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/contactInformation/:participantId', component: ContactInformationComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/uploadDocument', component: UploadDocumentComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/review', component: ReviewComponent, canActivate: [AuthGuardService] },
    { path: 'fnolHome/confirmation', component: ConfirmationComponent, canActivate: [AuthGuardService] }
];

@NgModule({
    declarations: [
        DateOfLossComponent, ClaimReporterComponent, LossDescriptionComponent, ClaimTypeComponent, ConfirmationComponent, HasOpenClaimComponent,
        ReviewComponent, UploadDocumentComponent, ContactInformationComponent, SelectPropertyOwnerComponent, HasInjuryComponent, HasDwellingDamageComponent,
        CallInEmergency
    ],
    imports: [
        RouterModule.forChild(fnolHomeRoutes),
        BrowserModule,
        FormsModule,
        CommonModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        NgbModule,
        CommonDirectivesModule,
        CommonComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatSliderModule
    ],
    exports: [
        MatSliderModule
    ],
    providers: [
        LossApi, ParticipantApi, HomeService
    ]
})
export class FNOLHomeModule { }