<div class="content-wrapper">
    <div class="claims-home">
        <div id="claimHeader" class="claim-box">
            <div class="box-header">
                <h2 class="col-10">Open Claims</h2>
                <button class="button newClaim">New Claim</button>
            </div>
            <div *ngFor="let claim of vm.OpenClaims">
                <div class="openClaim">
                    <div class="box-header">
                        <h4 class="col-9">Claim # {{claim.ClaimNumber}}</h4>
                        <span class="claim-status">Status: {{claim.ClaimStatus}}</span>
                    </div>
                    <div class="claim-info">
                        <div class="avatar-lg">
                            <img src="/content/images/vehicle-icon-filled.svg" width="45" height="45" />
                        </div>
                        <div class="claim-description">
                            <h4 class="property">{{claim.ClaimPropertyDescription}}</h4>
                            <span class="incident-date">Incident Date {{claim.LossDateTime | date}}</span>
                        </div>
                    </div>
                    <div class="button-wrapper">
                        <button (click)="showClaimDetails(claim.LossParticipantID)" class=" button claimDetails">Claim Details</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="claim-box" *ngIf="vm.PreviousClaims.length > 0">
            <div class="box-header">
                <h2 class="col-10">Previous Claims</h2>
            </div>
            <div *ngFor="let claim of vm.PreviousClaims">
                <h4>Claim # {{claim.ClaimNumber}}</h4>
                <h4>{{claim.ClaimPropertyDescription}}</h4>
                <span>Closed Date {{claim.CloseDateTime | date}}</span>
            </div>
        </div>
    </div>
    <div class="contactBox">
        <h3 class="contact-header">Contact Us</h3>
        <div class="contact-info">
            <img src="/content/images/email-icon.svg" width="25" height="25" />
            <a href="mailto:customerservice@ngic.com">customerservice@ngic.com</a>
        </div>
        <div class="contact-info">
            <img src="/content/images/blue-phone.svg" width="25" height="25" />
            <a href="tel:18885556442">1-888-555-NGIC (6442)</a>
        </div>
        <div class="contact-info">
            <img src="/content/images/chat-icon.svg" width="25" height="25" />
            <a href="">Chat</a>
        </div>
    </div>
</div>