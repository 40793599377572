<div ngForm #lossAddressForm="ngForm" class="main-inner-wrapper">
    <p class="header">{{ getHeaderTextByClaimType() }}</p>
    <div class="input-wrapper">
        <div class="row">
            <div class="col-12 d-flex justify-content-center mb-3" *ngIf="isTheftClaim">
                <div class="fieldLabel-wrapper reverse-checkbox">
                    <input type="checkbox" id="primaryAddressCheckbox" [checked]="vehicleStolenFromResidence" (click)="fillLossAddressWithPrimaryAddress($event)">
                    <label for="primaryAddressCheckbox" class="mr-2">The vehicle was stolen from my residence</label>
                </div>
            </div>
            <div class="col-lg-12 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="addressSetIsInvalid(Address, null, State, null)">Street Name/Address</label>
                    <i [ngClass]="addressSetIsInvalid(Address, null, State, null)" class="fas fa-asterisk" title="*Required Field"></i>
                </div>
                <input type="text" name="Address" Validate required [(ngModel)]="vm.Address.Address1" #Address="ngModel" />
            </div>
            <div class="col-lg-6 col-xs-12">
                <input type="text" OnlyLetters Validate name="City" [(ngModel)]="vm.Address.City" #City="ngModel" />
            </div>
            <div class="col-lg-2 col-xs-12">
                <select Validate required [(ngModel)]="vm.Address.State" #State="ngModel" name="State" class="state gbmask">
                    <option [ngValue]="null">State</option>
                    <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                </select>
            </div>
            <div class="col-lg-4 col-xs-12">
                <input OnlyNumbers type="tel" Validate maxlength="5" minlength="5" name="Zip" [(ngModel)]="vm.Address.Zip" #Zip="ngModel" />
            </div>

        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(lossAddressForm)">Continue</button>
    </div>
</div>