import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { VehicleApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { VehicleInfoModel, VehicleClaimInfoModel } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services'
import { NonPolicyVehicleComponent } from './nonPolicyVehicle.component';

@Component({
    selector: 'nonPolicyVehicleTheft',
    templateUrl: '../../views/vehicle/nonPolicyVehicleTheft.component.html',
    styleUrls: ['../../styles/vehicleInfo.component.css'],
    providers: [FNOLRoutingService]
})
export class NonPolicyVehicleTheftComponent extends BaseVehicleComponent {

    constructor(private api: VehicleApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, public fnolAutoService: FNOLAutoService,
        private constantsService: ConstantsService) {
        super();

        this.vm = new VehicleInfoModel();
        this.vm.VehicleClaimInfo = new VehicleClaimInfoModel();
    }

    @ViewChild(NonPolicyVehicleComponent) childNonPolicyVehicleComponent: NonPolicyVehicleComponent;
    public vm: VehicleInfoModel = new VehicleInfoModel();
    public displayTheftComponent: boolean = false;
    public hasRecoveredVehicleFromTheft: boolean = false;


    public hasKeysSelection: string = "";
    public rolledUpWindowsSelection: string = "";
    public lockedVehicleSelection: string = "";
    public priorDamageSelection: string = "";

    ngOnInit(): void {
    }

    public goingBackWithLocation() {
        this.mapRadioButtonSelectionsToViewModel();
        this.childNonPolicyVehicleComponent.vm = this.vm;
        this.displayTheftComponent = false;
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            if (this.fnolAutoService.getHasRecoveredVehicleFromTheft() == this.constantsService.TrueStringValue) {
                this.hasRecoveredVehicleFromTheft = true;
                let recoveredDate: string = this.fnolAutoService.getDateOfTheftRecovery();
                if (recoveredDate != null) {
                    this.vm.VehicleClaimInfo.RecoveryDate = this.convertToUTCDate(recoveredDate);
                }
            }
            else {
                this.hasRecoveredVehicleFromTheft = false;
            }
            this.vm.VehicleClaimInfo.IsRecoveredFromTheft = this.hasRecoveredVehicleFromTheft;

            this.mapRadioButtonSelectionsToViewModel();

            this.vm.VehicleClaimInfo.IsActive = true;

            this.api.saveVehicleInfo(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    let vehicleIDsThatHaveAnsweredTheftQuestions: string[] = this.fnolAutoService.getVehicleIDsThatHaveAnsweredTheftQuestions();
                    if (vehicleIDsThatHaveAnsweredTheftQuestions == null) {
                        vehicleIDsThatHaveAnsweredTheftQuestions = [response.PropertyId.toString()];
                        this.fnolAutoService.setVehicleIDsThatHaveAnsweredTheftQuestions(vehicleIDsThatHaveAnsweredTheftQuestions);
                    }
                    else if (!vehicleIDsThatHaveAnsweredTheftQuestions.includes(response.PropertyId.toString())) {
                        vehicleIDsThatHaveAnsweredTheftQuestions.push(response.PropertyId.toString());
                        this.fnolAutoService.setVehicleIDsThatHaveAnsweredTheftQuestions(vehicleIDsThatHaveAnsweredTheftQuestions);
                    }

                    let newUrl = `fnolAuto/nonPolicyVehicleTheft/${response.PropertyId.toString()}/${true}`;
                    this.fnolRouting.replaceCurrentState(newUrl);
                    this.fnolRouting.next({ vehicleId: response.PropertyId.toString() });
                }
            });
        }
    }

    public mapRadioButtonSelectionsToViewModel(): void {
        //HasKeys
        if (this.hasKeysSelection != this.constantsService.UnknownStringValue) {
            this.vm.VehicleClaimInfo.HasKeys = this.hasKeysSelection == this.constantsService.YesStringValue ? true : false;
        }
        else {
            this.vm.VehicleClaimInfo.HasKeys = null;
        }

        //WindowUpDuringTheft
        if (this.rolledUpWindowsSelection != this.constantsService.UnknownStringValue) {
            this.vm.VehicleClaimInfo.WindowUpDuringTheft = this.rolledUpWindowsSelection == this.constantsService.YesStringValue ? true : false;
        }
        else {
            this.vm.VehicleClaimInfo.WindowUpDuringTheft = null;
        }

        //LockedDuringTheft
        if (this.lockedVehicleSelection != this.constantsService.UnknownStringValue) {
            this.vm.VehicleClaimInfo.LockedDuringTheft = this.lockedVehicleSelection == this.constantsService.YesStringValue ? true : false;
        }
        else {
            this.vm.VehicleClaimInfo.LockedDuringTheft = null;
        }

        //DamagedPriorToTheft
        if (this.priorDamageSelection != this.constantsService.UnknownStringValue) {
            this.vm.VehicleClaimInfo.DamagedPriorToTheft = this.priorDamageSelection == this.constantsService.YesStringValue ? true : false;
        }
        else {
            this.vm.VehicleClaimInfo.DamagedPriorToTheft = null;
        }
    }

    public mapViewModelToRadioButtonSelections(): void {
        //hasKeys
        if (this.vm.VehicleClaimInfo.HasKeys != null) {
            this.hasKeysSelection = this.vm.VehicleClaimInfo.HasKeys == true ? this.constantsService.YesStringValue : this.constantsService.NoStringValue
        }
        else {
            this.hasKeysSelection = this.constantsService.UnknownStringValue;
        }

        //rolledUpWindowSelection
        if (this.vm.VehicleClaimInfo.WindowUpDuringTheft != null) {
            this.rolledUpWindowsSelection = this.vm.VehicleClaimInfo.WindowUpDuringTheft == true ? this.constantsService.YesStringValue : this.constantsService.NoStringValue
        }
        else {
            this.rolledUpWindowsSelection = this.constantsService.UnknownStringValue;
        }

        //lockedVehicleSelection
        if (this.vm.VehicleClaimInfo.LockedDuringTheft != null) {
            this.lockedVehicleSelection = this.vm.VehicleClaimInfo.LockedDuringTheft == true ? this.constantsService.YesStringValue : this.constantsService.NoStringValue
        }
        else {
            this.lockedVehicleSelection = this.constantsService.UnknownStringValue;
        }

        //priorDamageSelection
        if (this.vm.VehicleClaimInfo.DamagedPriorToTheft != null) {
            this.priorDamageSelection = this.vm.VehicleClaimInfo.DamagedPriorToTheft == true ? this.constantsService.YesStringValue : this.constantsService.NoStringValue
        }
        else {
            this.priorDamageSelection = this.constantsService.UnknownStringValue;
        }
    }

    public displayPriorDamageDescription(): boolean {
        return this.priorDamageSelection == this.constantsService.YesStringValue;
    }

    setupAndShowTheftComponent(value: boolean) {
        if (value == true) {
            this.vm = this.childNonPolicyVehicleComponent.vm;

            let vehicleIDsThatHaveAnsweredTheftQuestions: string[] = this.fnolAutoService.getVehicleIDsThatHaveAnsweredTheftQuestions();
            if (this.vm.PropertyId != null && vehicleIDsThatHaveAnsweredTheftQuestions != null && vehicleIDsThatHaveAnsweredTheftQuestions.includes(this.vm.PropertyId.toString())) {
                this.mapViewModelToRadioButtonSelections();
            }
        }
        this.displayTheftComponent = value;
    }
}