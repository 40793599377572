<div ngForm #lossInformationForm="ngForm" class="main-inner-wrapper no-mobile">
    <p class="header">Please provide loss information to include location and description.</p>

    <div class="input-wrapper">
        <div class="row">
            <div class="column-1 col-lg-12">
                <section class="section-group-1 col-lg-6">
                    <label>Did the incident occur at the Insured Property?</label>
                    <div class="container-wrapper" style="margin-bottom: 50px;">
                        <div class="col-lg-6 d-flex">
                            <label class="container">
                                <input type="radio" name="insured" [(ngModel)]="incidentOccuredAtInsuredProperty" [value]="true" (click)="SetPolicyProperty(true)" />
                                <span class="checkmark"></span>
                                Yes
                            </label>
                            <label class="container">
                                <input type="radio" name="insured" [(ngModel)]="incidentOccuredAtInsuredProperty" [value]="false" (click)="SetPolicyProperty(false)" />
                                <span class="checkmark"></span>
                                No
                            </label>
                        </div>
                    </div>
                    <label>Location</label>
                    <div class="row">
                        <div class="col-lg-12 col-xs-12">
                            <input Validate type="text" required placeholder="Address" name="Address" [(ngModel)]="vm.Address.Address1" #Address="ngModel" />
                        </div>
                        <div class="col-lg-12 col-xs-12">
                            <input Validate OnlyLetters type="text" placeholder="City" name="City" [(ngModel)]="vm.Address.City" #City="ngModel" />
                        </div>
                        <div class="col col-lg-4 col-xs-12">
                            <select [(ngModel)]="vm.Address.State" required Validate #State="ngModel" name="State" class="state gbmask">
                                <option [ngValue]="null">State</option>
                                <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                            </select>
                        </div>
                        <div class="col col-lg-8 col-xs-12">
                            <input OnlyNumbers type="text" name="Zip" placeholder="Zip Code" Validate [(ngModel)]="vm.Address.Zip" #Zip="ngModel" />
                        </div>
                    </div>
                </section>
                <section class="section-group-2 col-lg-6">
                    <div class="col-lg-12 col-xs-12" style="margin-bottom: 24px;">
                        <label>How did the Loss happen?</label>
                        <div>
                            <textarea Validate required DisallowSpecialCharacters allowedCharacters="." name="LossHappenedBecause" cols="40" rows="4" [(ngModel)]="vm.LossDetails.LossDescription" #LossDescription="ngModel"></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xs-12">
                        <label>What specific damage did this cause?</label>
                        <div>
                            <textarea Validate required DisallowSpecialCharacters allowedCharacters="." name="SpecificDamageCaused" cols="40" rows="4" [(ngModel)]="vm.LossProperty.DamageDescription" #LossDescription="ngModel"></textarea>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(lossInformationForm)">Continue</button>
    </div>
</div>
