import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { FNOLRoutingService } from '../../services';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { LossApi } from '@FNOLAuto/services';
import { PropertyInfoModel } from '@ClaimsModels/index';

@Component({
    selector: 'fixedProperty',
    templateUrl: '../../views/loss/fixedProperty.component.html',
    providers: [FNOLRoutingService]
})
export class FixedPropertyComponent extends BaseFormComponent {
    constructor(private fnolRouting: FNOLRoutingService, private api: LossApi, private spinner: Ng4LoadingSpinnerService) {
        super();
    }

    public vm: PropertyInfoModel = new PropertyInfoModel();

    public ngOnInit(): void {

        this.spinner.show();
        this.api.getPropertyInfo().then(response => {
            if (response.Success) {
                this.vm = response;
                this.spinner.hide();
            }
        });
    }

    public submit(form: NgForm): void {

        this.vm.IsActive = true;
        this.spinner.show();
        this.api.savePropertyInfo(this.vm).then(response => {
            if (response.Success) {
                this.spinner.hide();

                if (response.OwnerContactId > 0) {

                    this.fnolRouting.routeToSpecificPage(`fnolAuto/property/${response.PropertyId}/propertyOwner/${response.OwnerContactId}`);

                } else {

                    this.fnolRouting.next({ propertyId: response.PropertyId.toString() });
                }
            }
        });
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
        
    }
}