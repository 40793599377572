<div class="container">

    <div class="jumbotron">
        <h1 class="display-2 text-center">404 - Page not found</h1>
        <hr class="my-4">
        <div class="text-center">
            <h2>Uh oh...</h2>
            <p class="lead">
                The page you were trying to reach was not found.
            </p>
        </div>
        <div class="button-wrapper">
            <button id="continueBtn" class="btn btn-primary" (click)="return()">Back</button>
        </div>
    </div>

</div>