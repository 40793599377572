import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // remove later
import { RouterModule, Routes } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FileUploadModule } from 'ng2-file-upload';
import { UserIdleModule } from 'angular-user-idle';
import { CommonDirectivesModule } from '../../ClaimsWeb.Areas.Common/app/commonDirectives.module';
import { CommonComponentsModule } from '../../ClaimsWeb.Areas.Common/app/commonComponents.module';
import { NgModel } from '@angular/forms';

import {
    ErrorHandlerService, SessionService, AuthGuardService, LoggerApi, BreadcrumbService, StyleManagerService, ConstantsService, ContactService,
    ClaimTypeService, CommonClaimApi, BaseLossApi, BaseParticipantApi, UserLoginApi, SessionIdleService, AddressValidationApi, UnsubmittedClaimService,
    DeviceDetectionService
} from './services';

import {
    DashboardComponent, ErrorDisplayComponent, UserCallInComponent, FatalErrorComponent, BreadcrumbComponent, CloseTabComponent,
    FNOLDashboardComponent, SessionTimeoutComponent, PageNotFoundComponent,
    MC360DashboardComponent, FNOLHomeDashboardComponent, ReporterDetailsComponent, NewClaimComponent, NewClaimThirdPartyComponent, CallInClaimComponent, AgentLoginComponent, PolicyCallInClaimComponent
} from './components';

import { Ng4LoadingSpinnerService, Ng4LoadingSpinnerComponent } from './external/spinner';

import { rvMessageComponent } from './components/rvMessage.component';
;

const commonRoutes: Routes = [
    { path: 'fnolDashboard', component: FNOLDashboardComponent },
    { path: 'agentLogin', component: AgentLoginComponent },
    { path: 'mc360Dashboard', component: MC360DashboardComponent },
    { path: 'callInClaim', component: UserCallInComponent, canActivate: [AuthGuardService] },
    { path: 'policyCallInClaim', component: PolicyCallInClaimComponent, canActivate: [AuthGuardService] },
    { path: 'error', component: FatalErrorComponent },
    { path: 'newClaim/:id', component: NewClaimComponent },
    { path: 'newClaimThirdParty/:id', component: NewClaimThirdPartyComponent },
    { path: 'reporterDetails', component: ReporterDetailsComponent, canActivate: [AuthGuardService] },
    { path: 'rvMessage', component: rvMessageComponent },
    { path: 'sessionTimeout', component: SessionTimeoutComponent },
    { path: '', redirectTo: '404', pathMatch: 'full' },
    { path: '404', component: PageNotFoundComponent },
    //{ path: 'fileUploadTest', component: FileUploadComponent },
    { path: 'callIn', component: CallInClaimComponent },
    { path: 'closeTab', component: CloseTabComponent }
];

@NgModule({
    declarations: [DashboardComponent, ErrorDisplayComponent, FNOLDashboardComponent, MC360DashboardComponent, UserCallInComponent, FatalErrorComponent, BreadcrumbComponent,
        SessionTimeoutComponent, PageNotFoundComponent, CloseTabComponent,
        Ng4LoadingSpinnerComponent, FNOLHomeDashboardComponent, ReporterDetailsComponent, NewClaimComponent, NewClaimThirdPartyComponent, CallInClaimComponent, AgentLoginComponent, PolicyCallInClaimComponent,
        rvMessageComponent],
    imports: [
        RouterModule.forChild(commonRoutes),
        BrowserModule,
        NgxMaskModule.forRoot(),
        MatProgressBarModule,
        FileUploadModule,
        FormsModule, // remove later
        ReactiveFormsModule,
        NgbModule,
        UserIdleModule,
        CommonDirectivesModule,
        CommonComponentsModule,
    ],
    exports: [
        ErrorDisplayComponent, BreadcrumbComponent, Ng4LoadingSpinnerComponent,
    ],
    providers: [
        NgModel,
        ErrorHandlerService, SessionService, AuthGuardService, LoggerApi, BreadcrumbService, StyleManagerService, Ng4LoadingSpinnerService, ContactService,
        ConstantsService, ClaimTypeService, CommonClaimApi, BaseLossApi, BaseParticipantApi, UserLoginApi, SessionIdleService, AddressValidationApi,
        UnsubmittedClaimService, DeviceDetectionService
    ]
})
export class CommonModule { }