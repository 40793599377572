<div ngForm #customerValidationForm="ngForm" class="main-inner-wrapper" style="padding:25px;">
    <div class="form_wrap">
        <!--Landing screen-->
        <div *ngIf="!this.hasExceededAttempts && !this.displayCancelScreen && !this.displayCancelValidation && !this.isAlreadySigned && !this.isExpired" class="main-inner-wrapper">
            <h5>Please do not close this window!</h5>
            <h5>In order to confirm you are the intended recipient, </h5>
            <h5 *ngIf="this.vm.RecipientInfo.AddressIsEmail">we sent you a separate email with a PIN Code. </h5>
            <h5 *ngIf="!this.vm.RecipientInfo.AddressIsEmail">we sent you a seperate text with a PIN Code.</h5>
            <h5 style="margin-bottom:30px;">Please enter the PIN code below to proceed with signing your documents.</h5>

            <div class="align-items-center">
                <div class="input-group">
                    <input name="pin" [(ngModel)]="this.vm.Pin" class="pin-entry" OnlyNumbers required Validate type="{{this.hidePin ? 'password' : 'text'}}" inputmode="tel"  [validation]="false" placeholder="PIN Code" #pinCode="ngModel" />
                    <div class="input-group-append">
                        <span>
                            <img style="height:42px;" class="pin-entry-icon" *ngIf="this.hidePin" (click)="this.hidePin = !this.hidePin" src="/content/brand/mc360/visibility_off-white-24dp.svg" />
                            <img style="height:42px;" class="pin-entry-icon" *ngIf="!this.hidePin" (click)="this.hidePin = !this.hidePin" src="/content/brand/mc360/visibility-white-24dp.svg" />
                        </span>
                    </div>
                </div>
            </div>
            <span *ngIf="displayPinValidation(pinCode)" class="red" style="margin-top:15px;">A PIN is required.</span>
            <span class="red" style="margin-top:15px;" *ngIf="!this.validPin && !pinCode.invalid && !this.vm.HasBusinessRuleError">Wrong pin, please try again.</span>
            <span class="red" style="margin-top:15px;" *ngFor="let message of this.vm.BusinessRuleMessages">{{message}}</span>
            <div style="margin-bottom: 30px; min-width: 200px; position: inherit; background: none; box-shadow: none;" class="button-wrapper">
                <button (click)="cancel()" id="continueBtn" class="btn btn-primary">Cancel</button>
                <button (click)="submit(customerValidationForm)" [disabled]="this.vm.Pin == null || (this.vm.Pin != null && this.vm.Pin.length < 1)" class="btn btn-primary" id="continueBtn">Submit</button>
            </div>

            <h6>Didn't receive PIN? Click <a (click)="requestPin()"> here</a> to resend to {{this.vm.RecipientInfo.ObfuscatedDeliveryAddress}}.</h6>
            <h6 style="color:green" *ngIf="this.hasRequestedNewPin">A new pin is being sent. Please wait to receive your pin.</h6>
        </div>

        <!--Exceeded attempts screen-->
        <div *ngIf="this.hasExceededAttempts" class="main-inner-wrapper">
            <h4>Whoops!</h4>
            <h5>It looks like you are having trouble.</h5>
            <h5 style="margin-bottom:30px;">This link has been disabled.</h5>

            <h5 style="margin-bottom:30px;">Please contact your adjuster for assistance.</h5>

            <h5>You may now close this window.</h5>
        </div>

        <!--Cancel Screen-->
        <div *ngIf="this.displayCancelScreen" class="main-inner-wrapper">
            <h4 style="margin-bottom:30px;">You are about to cancel this transaction.</h4>

            <h5>You will not be able to sign your documents electronically.</h5>
            <h5 style="margin-bottom:30px;">Paper copies of the documents will be mailed to the address on file.</h5>

            <h5>Click CANCEL to return to the previous screen.</h5>
            <h5>Click CONTINUE to receive paper copies by mail.</h5>
            <div class="button-wrapper" style="min-width: 200px; position: inherit; background: none; box-shadow: none;">
                <button (click)="goingBackWithLocation()" id="continueBtn" class="btn btn-primary">Cancel</button>
                <button (click)="cancelValidation()" id="continueBtn" class="btn btn-primary">Continue</button>
            </div>
        </div>

        <!--Cancel Validation-->
        <div *ngIf="this.displayCancelValidation" class="main-inner-wrapper">
            <h5>Your transaction has been canceled.</h5>
            <h5>Paper documents will be mailed to you for your review and signature.</h5>
            <h5 style="margin-bottom:30px;">You may now close this window.</h5>

            <h4>Thank you!</h4>
        </div>

        <!--Is Already Signed-->
        <div *ngIf="this.isAlreadySigned" class="main-inner-wrapper">
            <h5>This document has already been signed.</h5>
            <h5>Please contact your adjuster if you have questions on this document.</h5>
            <h5 style="margin-bottom:30px;">You may now close this window.</h5>

            <h4>Thank you!</h4>
        </div>

        <!--Is Expired-->
        <div *ngIf="this.isExpired" class="main-inner-wrapper">
            <h5>This document is expired.</h5>
            <h5>Please contact your adjuster if you have questions on this document.</h5>
            <h5 style="margin-bottom:30px;">You may now close this window.</h5>

            <h4>Thank you!</h4>
        </div>
    </div>
</div>