import { Injectable } from "@angular/core";
import { Router, Event as NavigationEvent, NavigationStart } from '@angular/router';
import { of, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Breadcrumb, RouteCrumb } from '@ClaimsModels/index';


@Injectable()
export class BreadcrumbService {

    private routes: RouteCrumb[] = [];
    private breadcrumbs: Breadcrumb[] = [];
    private categoryCounter = {};
    private mobileBreadcrumb: Breadcrumb = new Breadcrumb("Mobile Breadcrumb", -1);
    private action: NavigationAction = NavigationAction.Forward; // assume forward for now
    private lastBreadcrumbIndex: number = -1;
    private componentIterationCounter: number = 0;

    constructor(private router: Router) {
        this.findNavigationType();
    }

    public addBreadcrumb(routecrumb: RouteCrumb): void {

        // First check and see if the route previously exists. If so, exit.
        let existingBreadcrumb: Breadcrumb = this.breadcrumbs.find(x => x.DisplayName == routecrumb.Crumb.DisplayName);
        let existingRoute: RouteCrumb = this.findRouteByPath(routecrumb.Route);

        if (existingRoute) {
            return;
        }

        this.routes.push(routecrumb);
        this.categoryCounter[routecrumb.Crumb.DisplayName] = this.categoryCounter[routecrumb.Crumb.DisplayName] + 1 || 1;

        if (existingBreadcrumb) {
            return;
        }

        if (routecrumb.Crumb.DisplayOrder > 0) {
            this.breadcrumbs.push(routecrumb.Crumb);
        }
    }

    public update(routeUrl: string): void {
        this.breadcrumbs = this.breadcrumbs.sort(this.sortAscending);
        let breadcrumb: Breadcrumb = this.findBreadcrumbByRoute(routeUrl);

        if (breadcrumb) {
            this.lastBreadcrumbIndex = breadcrumb.DisplayOrder;
            this.updateBreadcrumbValues(breadcrumb);
        }

        if (!breadcrumb && this.lastBreadcrumbIndex > -1) {
            let previousCrumb: Breadcrumb = this.breadcrumbs.find(x => x.DisplayOrder == this.lastBreadcrumbIndex);
            previousCrumb.Counter++; // page is likely an offshoot, increment the counter ?
        }

        // if we're going to hide on the page, this should be the last thing we do
        if (breadcrumb && breadcrumb.DisplayOrder < 1) {
            this.clearBreadcrumbs();
        }
    }

    public getBreadcrumbs(): Observable<Breadcrumb[]> {
        return of(this.breadcrumbs);
    }

    public getMobileBreadcrumb(): Observable<Breadcrumb> {
        return of(this.mobileBreadcrumb);
    }

    public clearBreadcrumbs(): void {
        this.routes.length = 0;
        this.breadcrumbs.length = 0;
        this.mobileBreadcrumb.Counter = 0;
        this.categoryCounter = {};
        this.lastBreadcrumbIndex = -1;
    }

    private sortAscending(a: Breadcrumb, b: Breadcrumb) {
        return a.DisplayOrder - b.DisplayOrder;
    }

    private updateBreadcrumbValues(breadcrumb: Breadcrumb): void {
        //the component triggers twice on page load, duplicating intended progress bar values
        if (this.componentIterationCounter < 1) {
            let categoriesCompleted: number = 0;
            this.breadcrumbs.forEach(x => {

                // don't think about the next breakcrumbs...
                if (x.DisplayOrder > breadcrumb.DisplayOrder) {
                    x.IsCompleted = false;
                    x.PercentDone = 0;
                    x.Counter = 0;
                    return;
                }

                // for previous breadcrumbs, mark them as completed
                if (x.DisplayOrder < breadcrumb.DisplayOrder) {
                    x.IsCompleted = true;
                    x.PercentDone = 100;
                    categoriesCompleted += 1;
                } else {
                    x.IsCompleted = false;
                }

                if (breadcrumb.Counter < 0) {
                    breadcrumb.Counter = 0;
                }

                // if we are in the middle
                if (x.DisplayName == breadcrumb.DisplayName) {
                    // increment through the current category
                    if (this.action == NavigationAction.Forward) {
                        breadcrumb.Counter++;
                    }

                    if (this.action == NavigationAction.Back) {
                        breadcrumb.Counter--;
                        //the Counter must have a positive value otherwise it won't register as active in the progress bar
                        if (breadcrumb.Counter === 0 || breadcrumb.Counter < 0) {
                            breadcrumb.Counter = 1;
                        }
                    }


                    breadcrumb.PercentDone = (breadcrumb.Counter / this.categoryCounter[breadcrumb.DisplayName]) * 100;
                }


                if (breadcrumb.Counter > this.categoryCounter[breadcrumb.DisplayName]) {
                    breadcrumb.Counter = this.categoryCounter[breadcrumb.DisplayName];
                }

                if (breadcrumb.PercentDone > 100) {
                    breadcrumb.PercentDone = 100;
                }
            });

            if (categoriesCompleted == 0) {
                this.mobileBreadcrumb.Counter = 1; // show initial progress
            } else {
                this.mobileBreadcrumb.Counter = categoriesCompleted;
            }
            let mobileTotalRoutes: number = this.breadcrumbs.length;
            this.mobileBreadcrumb.PercentDone = (this.mobileBreadcrumb.Counter / mobileTotalRoutes) * 100;
        }
        this.componentIterationCounter++;
    }

    private findNavigationType() {
        this.router.events
            .pipe(
                filter(
                    (event: NavigationEvent) => { return (event instanceof NavigationStart); }
                )
        ).subscribe(
            (event: NavigationStart) => {
                this.componentIterationCounter = 0;
                if (event.navigationTrigger == "imperative") {
                    this.action = NavigationAction.Forward;
                }

                if (event.navigationTrigger == "popstate") {
                    this.action = NavigationAction.Back;
                }
            }
        )
    }

    private findRouteByPath(path: string): RouteCrumb {
        return this.routes.find(x => x.Route == path);
    }

    private findBreadcrumbByRoute(path: string): Breadcrumb {
        let routecrumb: RouteCrumb = this.findRouteByPath(path);

        if (routecrumb) {
            return this.breadcrumbs.find(x => x.DisplayName == routecrumb.Crumb.DisplayName);
        }

        return undefined;
    }
}

enum NavigationAction {
    Forward, Back
}