




import { BaseModel } from './BaseModel';
import { BaseVehiclePartyInfoModel } from './BaseVehiclePartyInfoModel'
import { ParticipantModel } from './ParticipantModel';

export class VehicleOwnerModel extends BaseVehiclePartyInfoModel {
    
    public OwnerOptions: ParticipantModel[] = [];
}