




import { BaseModel } from './BaseModel';

import { BaseReporterInformationModel } from './BaseReporterInformationModel';
import { ValueDetailModel } from './ValueDetailModel';

export class BaseClaimReporterModel extends BaseModel {
    
    public Persons: BaseReporterInformationModel[] = [];
    public ClaimReporter: BaseReporterInformationModel = null;
    public States: ValueDetailModel[] = [];
    public PhoneTypes: ValueDetailModel[] = [];
    public IsAgent: boolean = false;
}