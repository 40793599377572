
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { BaseComponent } from "@Common/base/base.component";
import { ClaimModel } from '@ClaimsModels/index';
import { SessionService, ConstantsService } from '@Common/services'


@Component({
    selector: 'incidentNotAvailable',
    templateUrl: '../views/incidentNotAvailable.component.html',
    styleUrls: ['../styles/mc360styling.css']
})

export class IncidentNotAvailableComponent extends BaseComponent {

    @Input() claimModelData;
    phoneNumber: string = "";

    constructor( private sessionService: SessionService, private constants: ConstantsService) {
        super();
    }


    public vm: ClaimModel;

    public ngOnInit(): void {

        this.vm = this.claimModelData;

        this.PhoneAssignment();
    }

    public PhoneAssignment()
    {
        switch (this.claimModelData.ConsumerBrand) {
            case this.constants.Encompas: {
                this.phoneNumber = this.constants.ClaimsPhone.Encompass;
                break;
            }
            case this.constants.Esurance: {
                this.phoneNumber = this.constants.ClaimsPhone.Esurance;
                break;
            }
            case this.constants.SafeAuto: {
                this.phoneNumber = this.constants.ClaimsPhone.Default;
                break;
            }
            default: {
                this.phoneNumber = this.constants.ClaimsPhone.Default;
            }
        }
    }
}
