import { Component } from "@angular/core";
import * as moment from 'moment';
import { NgForm } from "@angular/forms";
import { HomeRoutingService, ParticipantApi, HomeService } from "@FNOLHome/services";
import { Ng4LoadingSpinnerService } from "@Common/external/spinner";
import { BaseContactFormComponent } from '@Common/base/baseContactForm.component';
import { ConstantsService } from "@Common/services";
import {
    InsuranceInformationModel, AddressModel, BaseParticipantClaimDetailsModel, BaseContactInfoModel, HomeLossParticipantModel, BaseEmailModel
} from '@ClaimsModels/index';

@Component({
    selector: 'contactInformation',
    templateUrl: '../../views/participant/contactInformation.component.html',
    providers: [HomeRoutingService]
})
export class ContactInformationComponent extends BaseContactFormComponent {

    public vm: HomeLossParticipantModel;
    public email: string = this.constantsService.EmptyString;
    public dateOfBirth: string = this.constantsService.EmptyString;
    public participantId: number = 0;
    public isNotInEditMode: boolean = true;

    constructor(private spinner: Ng4LoadingSpinnerService, private fnolRouting: HomeRoutingService, private api: ParticipantApi, private homeService: HomeService,
        private constantsService: ConstantsService) {
        super();

        // set the participant id from the route or set it to 0 if we do not have one in the route
        this.participantId = this.fnolRouting.getRoute().snapshot.params.participantId || 0;

        this.vm = new HomeLossParticipantModel();
        this.vm.Address = new AddressModel();
        this.vm.ContactInfo = new BaseContactInfoModel();
        this.vm.ParticipantClaimDetails = new BaseParticipantClaimDetailsModel();
        this.vm.InsuranceInformation = new InsuranceInformationModel();
    }

    ngOnInit(): void {
        this.spinner.show();
        this.api.getLossParticipantModel(this.participantId).then(response => {
            this.spinner.hide();
            this.vm = response;
            this.vm.PropertyId = this.homeService.getPropertyId();

            // if we have no name, assume its a new person
            if (this.participantId == 0) {
                this.isNotInEditMode = false;
            }

            if (this.vm.DateOfBirth != null) {
                this.dateOfBirth = moment(this.vm.DateOfBirth).format("MM/DD/YYYY");
            }

            if (this.vm.ContactInfo != null &&
                this.vm.ContactInfo.Emails != null &&
                this.vm.ContactInfo.Emails.length > 0) {
                this.email = this.vm.ContactInfo.Emails[0].EmailAddress;
            }
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {

        if (this.validate(form)) {

            this.spinner.show();
            this.mapDateOfBirth();
            this.mapEmail();

            this.api.saveLossParticipantModel(this.vm).then(response => {
                this.spinner.hide();

                // update the route history with the new contact Id for back button functionality
                if (this.participantId == 0) {
                    let newUrl = `fnolHome/contactInformation/${response.LossParticipantId}`;
                    this.fnolRouting.replaceCurrentState(newUrl);
                }

                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
        }
    }

    public displayEditMode(): void {
        this.isNotInEditMode = !this.isNotInEditMode;
    }

    private mapDateOfBirth(): void {

        if (this.dateOfBirth !== null && this.dateOfBirth !== this.constantsService.EmptyString) {
            this.vm.DateOfBirth = this.convertToUTCDate(this.dateOfBirth);
        } else {
            this.vm.DateOfBirth = null;
        }
    }

    private mapEmail(): void {
        let emailType: string = null;

        if (this.vm.ContactInfo.Emails != null && this.vm.ContactInfo.Emails.length > 0) {
            emailType = this.vm.ContactInfo.Emails[0].EmailType;
        }

        this.vm.ContactInfo.Emails = [];

        if (this.email !== null && this.email !== this.constantsService.EmptyString) {
            let emailModel = new BaseEmailModel();
            emailModel.EmailAddress = this.email;
            emailModel.EmailType = emailType != null ? emailType : this.constantsService.EmailTypePER;
            this.vm.ContactInfo.Emails.push(emailModel);
        }
    }
}