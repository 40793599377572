




import { BaseModel } from './BaseModel';


export class BaseClaimTypeModel extends BaseModel {
    
    public ClaimType: string = null;
}