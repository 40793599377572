import { Component } from '@angular/core';

@Component({
    selector: 'vehicleIcon',
    template: `     <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 30 30" style="enable-background:new 0 0 30 30;" xml:space="preserve">
                    <style type="text/css">
                        .st0 {
                            fill: none;
                            stroke: #707070;
                            stroke-width: 0.25;
                            stroke-miterlimit: 10;
                        }
                    </style>
                    <g id="Layer_1">
                  </g>
                    <g id="Layer_2">
                    <path class="st0" d="M26.4,11.2L26.4,11.2l-3.1-6.3C22.9,3.7,21.8,3,20.6,3H9.4C8.2,3,7.1,3.7,6.7,4.8l-3.1,6.3h0c-1.7,0-3,1.4-3,3
                      v7.3h2.2v3.7c0,0.7,0.6,1.3,1.3,1.3h2.9c0.7,0,1.3-0.6,1.3-1.3v-3.7h14v4.3c0,0.7,0.6,1.3,1.3,1.3h2.9c0.7,0,1.3-0.6,1.3-1.3v-4.3
                      h1.9v-7.3C29.5,12.5,28.1,11.2,26.4,11.2z M4.1,16.8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2s2,0.9,2,2C6.1,16,5.2,16.8,4.1,16.8z
                        M5.6,11.2l2.2-4.9c0.3-0.9,1.1-1.5,1.9-1.5h10.6c0.9,0,1.6,0.6,1.9,1.5l2.2,4.9H5.6z M26,16.8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2
                      s2,0.9,2,2C28,16,27.1,16.8,26,16.8z" />



                  </g>
                  </svg>`
})
export class VehicleIconComponent {

}