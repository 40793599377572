




import { BaseModel } from './BaseModel';


export class DocumentModel extends BaseModel {
    
    public DocumentName: string = null;
    public DocumentType: string = null;
    public DateUploaded: Date = new Date(0);
    public ClaimId: number = 0;
    public ViewURL: string = null;
    public DocumentContent: number[] = [];
    public DocumentSize: number = 0;
    public LossParticipantId: number = 0;
    public DocumentId: number = 0;
}