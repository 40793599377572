




import { BaseModel } from './BaseModel';


export class AddressModel {
    
    public AddressType: string = null;
    public Address1: string = null;
    public Address2: string = null;
    public City: string = null;
    public State: string = null;
    public Zip: string = null;
    public County: string = null;
    public Country: string = null;
}