import { Injectable } from "@angular/core";
import { BaseApi } from "../../base/base.api";

import {
    BaseDateOfLossModel, BaseClaimTypeModel, BaseReviewModel, BaseOpenClaimDetailsModel, BaseLossDetailsModel, BasePropertyInfoModel
} from '@ClaimsModels/index';

@Injectable()
export class BaseLossApi extends BaseApi {

    public getDateOfLossModel(): Promise<BaseDateOfLossModel> {
        return this.httpGet("Common/Loss/GetDateOfLoss");
    }

    public saveDateOfLoss(model: BaseDateOfLossModel): Promise<BaseDateOfLossModel> {
        return this.httpPost("Common/Loss/SaveDateOfLoss", model);
    }

    public saveClaimType(model: BaseClaimTypeModel): Promise<BaseClaimTypeModel> {
        return this.httpPost("Common/Loss/SaveClaimTypeModel", model);
    }

    public getClaimTypeModel(): Promise<BaseClaimTypeModel> {
        return this.httpGet("Common/Loss/GetClaimTypeModel");
    }

    public getReviewModel(): Promise<BaseReviewModel> {
        return this.httpGet("Common/Loss/GetReviewModel");
    }

    public submitReviewModel(model: BaseReviewModel): Promise<BaseReviewModel> {
        return this.httpPost("Common/Loss/SubmitReviewModel", model);
    }

    public getOpenClaimDetailsModel(): Promise<BaseOpenClaimDetailsModel> {
        return this.httpGet("Common/Loss/GetOpenClaimDetails");
    }

    // Review page
    public saveLossDetailsModel(model: BaseLossDetailsModel): Promise<BaseLossDetailsModel> {
        return this.httpPost("Common/Loss/SaveLossDetails", model);
    }

    public savePropertyInfo(model: BasePropertyInfoModel): Promise<BasePropertyInfoModel> {
        return this.httpPost("Common/Loss/SavePropertyInfoModel", model);
    }
}