




import { BaseModel } from './BaseModel';


export class InsuranceInformationModel extends BaseModel {
    
    public InsuranceProvider: string = null;
    public PolicyNumber: string = null;
    public ClaimNumber: string = null;
}