




import { BaseModel } from './BaseModel';


export class BaseParticipantModel {
    
    public Name: string = null;
    public Gender: string = null;
    public Id: number = 0;
    public IsSelected: boolean = false;
    public IsPrimaryInsured: boolean = false;
    public IsOnPolicy: boolean = false;
    public IsUnknown: boolean = false;
    public ContactRole: string = null;
}