import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { VehicleApi, FNOLAutoService, FNOLRoutingService } from '@FNOLAuto/services';
import { SelectVehiclesModel, VehicleModel, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services';

@Component({
    selector: 'policyVehicles',
    templateUrl: '../../views/vehicle/selectVehicles.component.html',
    providers: [FNOLRoutingService]
})
export class SelectVehiclesComponent extends BaseFormComponent {

    constructor(private api: VehicleApi, private spinner: Ng4LoadingSpinnerService, private fnolAuto: FNOLAutoService, private fnolRouting: FNOLRoutingService,
                private constantsService: ConstantsService) {
        super();

        this.vm = new SelectVehiclesModel();
        this.vm.Vehicles = [];
    }

    public vm: SelectVehiclesModel = new SelectVehiclesModel();
    public isOtherVehicle: boolean = false;
    public hasMultiplePolicyVehiclesSelected: boolean = false;
    public displayMultiVehicleRequirements: boolean = false;

    ngOnInit(): void {
        this.spinner.show();
        this.api.getSelectVehiclesModel().then(response => {
            this.spinner.hide();
            this.vm = response;
            this.checkForMultipleVehicles(true);
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {

        if (this.fnolAuto.getClaimTypeCategory() == ClaimTypeCategoryEnum.MultipleVehicleAccident && this.vm.Vehicles.filter(x => x.IsSelected).length < 2) {
            this.displayMultiVehicleRequirements = true;
            return;
        }

        this.spinner.show();
        this.api.saveSelectVehiclesModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                this.fnolRouting.next();
            }
        });
    }

    private isVehicleInfoKnown(vehicle: VehicleModel): boolean {
        return vehicle.VehicleInfo != undefined && vehicle.VehicleInfo.trim() != this.constantsService.EmptyString;
    }

    public selectVehicle(vehicle: VehicleModel): void {
        vehicle.IsSelected = !vehicle.IsSelected;

        // prevent the user from selecting multiple poliy vehicles.
        let policySelections: VehicleModel[] = this.vm.Vehicles.filter(x => x.IsOnPolicy && x.IsSelected);
        if (vehicle.IsOnPolicy && policySelections.length > 1) {
            vehicle.IsSelected = false;
            this.hasMultiplePolicyVehiclesSelected = true;
            return;
        }

        if (vehicle.IsSelected) {
            if (vehicle.IsOnPolicy) {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicleInfo/${vehicle.VehicleId}`);
            } else {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/additionalVehicle/${vehicle.VehicleId}`);
            }
        }
        else {
            this.spinner.show();
            this.api.saveSelectVehiclesModel(this.vm).then(response => {
                this.spinner.hide();
            });
        }
    }

    public addNew(): void {
        this.fnolRouting.routeToSpecificPage('fnolAuto/additionalVehicle/');
    }


    public numberSelected(): number {
        if (this.vm == null || this.vm.Vehicles == null) {
            return 0;
        }

        return this.vm.Vehicles.filter(x => x.IsSelected).length;
    }

    public checkForMultipleVehicles(updateSelected: boolean): void {
        let policySelections: VehicleModel[] = this.vm.Vehicles.filter(x => x.IsOnPolicy && x.IsSelected);

        if (policySelections.length >= 2) {
            this.hasMultiplePolicyVehiclesSelected = true;
            // deselect their other policy selections
            if (updateSelected) {
                policySelections // deselect all policy vehicles for now until we can establish which was the "last" one selected
                    .forEach((value) => {
                        value.IsSelected = false;
                    });
                this.spinner.show();
                this.api.saveSelectVehiclesModel(this.vm).then(response => {
                    this.spinner.hide();
                });
            }
        }
    }
}