<div class="main-inner-wrapper">
    <div class="input-wrapper">
        <div class="row" *ngIf="!isAuthenticated">
            <p class="header">Please sign in</p>
            <div class="col-xs-12">

                <div class="invalidMessage" *ngIf="hasError">
                    <i class="fas fa-exclamation-circle"></i>
                    Incorrect username or password.
                </div>
                <div class="invalidMessage" *ngIf="policyNumberError">
                    <i class="fas fa-exclamation-circle"></i>
                    Invalid Policy Number.
                </div>
                <form [formGroup]="BridgeSessionForm" (ngSubmit)="submit" method="post">

                    <input hidden formControlName="token" name="Token" readonly />
                    <input hidden formControlName="applicationName" name="applicationName" readonly />
                    <div class="form-group">
                        <label for="username">Username</label>
                        <input type="text"
                               id="username"
                               formControlName="username"
                               name="username"
                               placeholder="Username"
                               Validate
                               required />
                    </div>
                    <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password"
                               id="password"
                               formControlName="password"
                               name="password"
                               placeholder="Password"
                               Validate autocomplete="off"
                               required />
                    </div>

                    <label>Policy Number</label>
                    <input type="text" name="policyNumber" formControlName="policyNumber" placeholder="Policy Number" Validate required />
           
                    <label>Select Source Party</label>
                    <select #sourceParty name="sourceParty" formControlName="sourceParty" (change)="onSelected(sourceParty.value)">
                        <option value="INSUREDANDAGENT">Insured and Agents</option>
                        <option value="ADVERSECARRIERFNOLREP">Adverse Carrier</option>
                    </select>

                    <div class="button-wrapper">
                        <button id="signInBtn" (click)="submit(BridgeSessionForm)" class="btn btn-primary">Sign in</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>