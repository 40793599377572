<div ngForm #vehicleInfoForm="ngForm" class="content-wrapper">
    <p class="header">Please provide the following information for your vehicle.</p>
    <div class="carInfo">
        <p>{{vm.Year}} {{vm.Make}} {{vm.Model}}</p>
    </div>

    <div class="row">
        <div class="column-1 col-lg-8 col-xs-12">
            <section class="section-group-1 col-lg-6">
                <div class="row">
                    <div class="col-lg-12 col-xs-12">
                        <div class="fieldLabel-wrapper">
                            <label [ngClass]="childHasInvalid(VehicleLocation)">Where is the car now?</label><i [ngClass]="childHasInvalid(VehicleLocation)" class="fas fa-asterisk" title="*Required Field"></i>
                        </div>
                        <select Validate required name="VehicleLocation" [(ngModel)]="vm.VehicleClaimInfo.VehicleLocationType" #VehicleLocation="ngModel">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let vehicleLocationType of vm.VehicleLocationTypes" [ngValue]="vehicleLocationType.DisplayCode"> {{ vehicleLocationType.DisplayValue }} </option>
                        </select>
                    </div>
                </div>
                <div class="col-xs-12 col-lg-12 container">
                    <div class="fieldLabel-wrapper">
                        <div class="fieldLabel-wrapper">
                            <label for="injured" [ngClass]="childHasInvalid(Drivable)">Is your vehicle drivable?</label>
                            <i [ngClass]="childHasInvalid(Drivable)" class="fas fa-asterisk" title="*Required Field"></i>
                        </div>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-6 d-flex">
                                <label class="container">
                                    <input type="radio" name="driveable" [value]="true" required
                                           [(ngModel)]="vm.VehicleClaimInfo.IsDriveable" (change)="calculateVehicleDamage(vm)"
                                           #Drivable="ngModel">
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="driveable" [value]="false" required
                                           [(ngModel)]="vm.VehicleClaimInfo.IsDriveable" (change)="calculateVehicleDamage(vm)">
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-xs-12">
                        <div class="fieldLabel-wrapper">
                            <label>License Plate</label>
                        </div>
                        <input Validate type="text" name="PlateNumber" [(ngModel)]="vm.PlateNumber" #PlateNumber="ngModel" />
                    </div>
                    <div class="col-lg-6 col-xs-12">
                        <div class="fieldLabel-wrapper">
                            <label>State</label>
                        </div>
                        <select [(ngModel)]="vm.PlateStateCode" #State="ngModel" name="State" class="state gbmask">
                            <option [ngValue]="null"></option>
                            <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-xs-12">
                        <div class="fieldLabel-wrapper">
                            <label>Driver</label><i [ngClass]="childHasInvalid(Driver)"></i>
                            <a (click)="addPerson()" role="button" tabindex="0" class="unlistedVehicleParticipant">My driver isn't listed</a>
                        </div>
                        <select [(ngModel)]="vm.VehicleClaimInfo.DriverContactId" #Driver="ngModel" name="Driver" class="gbmask">
                            <option [ngValue]="null">Select a driver for this vehicle</option>
                            <option *ngFor="let participant of vm.Passengers" [ngValue]="participant.Id">{{participant.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-xs-12">
                        <div class="fieldLabel-wrapper">
                            <label [ngClass]="childHasInvalid(Owner)">Owner</label><i [ngClass]="childHasInvalid(Owner)" class="fas fa-asterisk"></i>
                            <a (click)="addPerson()" role="button" tabindex="0" class="unlistedVehicleParticipant">My owner isn't listed</a>
                        </div>
                        <select Validate required [(ngModel)]="vm.VehicleClaimInfo.OwnerContactId" #Owner="ngModel" name="Owner" class="gbmask">
                            <option [ngValue]="null">Select an owner for this vehicle</option>
                            <option *ngFor="let participant of vm.Passengers" [ngValue]="participant.Id">{{participant.Name}}</option>
                        </select>
                    </div>
                    <div class="col-lg-12 col-xs-12">
                        <div class="fieldLabel-wrapper passengerLabel">
                            <label>Passengers</label>
                            &nbsp;<a (click)="addPerson()" role="button" tabindex="0" class="unlistedVehicleParticipant">My passenger isn't listed</a>
                        </div>
                        <mat-select multiple name="Passenger" class="multiselectDropdown" [(ngModel)]="vm.SelectedPassengers" [compareWith]="compareParticipants" class="gbmask">
                            <mat-option *ngFor="let passenger of vm.Passengers" [value]="passenger">{{passenger.Name}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </section>
            <section class="section-group-2 col-lg-6">
                <div class="col-lg-12 col-xs-12 container">
                    <div class="fieldLabel-wrapper">
                        <label [ngClass]="childHasInvalid(AirBags)">Did your airbags deploy?</label><i [ngClass]="childHasInvalid(AirBags)" class="fas fa-asterisk"></i>
                    </div>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-6 d-flex">
                                <label class="container">
                                    <input type="radio" name="airbag-deploy" [(ngModel)]="vm.VehicleClaimInfo.IsAirbagDeployed" #AirBags="ngModel"
                                           [value]="true" (change)="calculateVehicleDamage(vm)" #Airbags="ngModel" required>
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="airbag-deploy" [(ngModel)]="vm.VehicleClaimInfo.IsAirbagDeployed"
                                           [value]="false" (change)="calculateVehicleDamage(vm)" required>
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xs-12 container">
                    <label>Do you use your vehicle for a ride sharing service? (i.e. Uber, Lyft, etc...) </label>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-6 d-flex">
                                <label class="container">
                                    <input type="radio" name="ride-sharing" [(ngModel)]="vm.VehicleClaimInfo.RideShareUse" [value]="true" />
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="ride-sharing" [(ngModel)]="vm.VehicleClaimInfo.RideShareUse" [value]="false" />
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xs-12 container">
                    <label>Do you use your vehicle for delivery services (i.e. Amazon, Papa Johns, etc...)</label>
                    <div class="container-wrapper">
                        <div class="row">
                            <div class="col-lg-6 d-flex">
                                <label class="container">
                                    <input type="radio" name="delivery-service" [(ngModel)]="vm.VehicleClaimInfo.DeliveryServiceUse" [value]="true" />
                                    <span class="checkmark"></span>
                                    Yes
                                </label>
                                <label class="container">
                                    <input type="radio" name="delivery-service" [(ngModel)]="vm.VehicleClaimInfo.DeliveryServiceUse" [value]="false" />
                                    <span class="checkmark"></span>
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xs-12 description-wrapper">
                    <div class="fieldLabel-wrapper">
                        <label for="description" [ngClass]="childHasInvalid(DamageDescription)">Description of damages</label>
                        <i [ngClass]="childHasInvalid(DamageDescription)" class="fas fa-asterisk"></i>
                    </div>
                    <textarea Validate name="DamageDescription" required rows="3" [(ngModel)]="vm.VehicleClaimInfo.DamageDescription" #DamageDescription="ngModel"></textarea>
                </div>
            </section>
        </div>
        <div class="column-2 col-lg-4 col-xs-12">
            <div class="car-detail-wrapper">

                <p>Select the areas that were damaged during the incident.</p>
                <p class="vehicleDiagram-front-label">Front</p>
                <svg id="vehicleDamageImage">
                    <path *ngFor="let component of vehicleComponents"
                          class="image-area clickable"
                          [attr.id]="component.name"
                          [attr.d]="component.dimensions"
                          (click)="areaClicked(component, vm)"
                          [class.area-background]="component.isClicked" />
                </svg>
                <p class="vehicleDiagram-back-label">Back</p>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(vehicleInfoForm)">Continue</button>
    </div>
</div>