import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@Common/common.module';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonDirectivesModule } from '../../ClaimsWeb.Areas.Common/app/commonDirectives.module';
import { CommonComponentsModule } from '../../ClaimsWeb.Areas.Common/app/commonComponents.module';

import {
    DateOfLossComponent, PrimaryContactInfoComponent, ClaimTypeComponent,
    LossAddressComponent, ContactInformationComponent, LossParticipantComponent,
    SelectVehiclesComponent, VehicleInfoComponent, LossDescriptionComponent,
    EmergencyResponseComponent, ReviewComponent, AdditionalVehicleComponent, ConfirmationComponent,
    HasOpenClaimComponent, ClaimReporterComponent, SelectLossParticipantsComponent, GlassOnlyComponent,
    VehicleDetailComponent, SelectInsuredVehicle, HasPassengersComponent, PassengersComponent,
    OtherPartyInfoComponent, SelectVehicleDriver, SelectVehicleOwner, NonPolicyVehicleComponent, SelectPolicyContactComponent,
    SelectOtherContactComponent, ThirdPartyVehicleComponent, AdditionalThirdPartyComponent, UploadReceiptComponent, UploadDocumentComponent,
    FixedPropertyComponent, FixedPropertyOwnerComponent, OccupantInformationComponent, RecoveryInformationComponent, VehicleTheftDetails,
    NonPolicyVehicleTheftComponent, TowingAndLaborComponent, SMSConsentComponent, MyClaimsPicComponent, SelectPedestrianComponent,
    AwningOnlyComponent, IsWindshieldOnlyDamagesComponent, IsDamagesOtherThanWindshieldComponent
} from './components';

import { LossApi, ParticipantApi, VehicleApi, FNOLAutoService, FNOLRoutingService } from './services';

import { AuthGuardService, AddressValidationService } from '@Common/services/';

import {Component} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatNativeDateModule} from '@angular/material/core';
import {MatIcon, MatIconModule} from '@angular/material/icon';
 

const fnolAutoRoutes: Routes = [
    { path: 'fnolAuto/dateOfLoss', component: DateOfLossComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/lossAddress', component: LossAddressComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/primaryContactInfo', component: PrimaryContactInfoComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/lossParticipant/:id', component: LossParticipantComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/lossParticipant', component: LossParticipantComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/selectVehicles', component: SelectVehiclesComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/contactInformation/:id', component: ContactInformationComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicleInfo/:id', component: VehicleInfoComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/lossDescription', component: LossDescriptionComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/emergencyResponse', component: EmergencyResponseComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/review', component: ReviewComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/additionalVehicle', component: AdditionalVehicleComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/additionalVehicle/:id', component: AdditionalVehicleComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/confirmation', component: ConfirmationComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/claimType', component: ClaimTypeComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/hasOpenClaim', component: HasOpenClaimComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/claimReporter', component: ClaimReporterComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/selectLossParticipants', component: SelectLossParticipantsComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/glassOnly', component: GlassOnlyComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/awningOnly', component: AwningOnlyComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicleDetail/:id', component: VehicleDetailComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/selectInsuredVehicle', component: SelectInsuredVehicle, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/hasPassengers', component: HasPassengersComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/selectOwner', component: SelectVehicleOwner, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/selectDriver', component: SelectVehicleDriver, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/nonPolicyVehicle', component: NonPolicyVehicleComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/nonPolicyVehicle/:vehicleId', component: NonPolicyVehicleComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/passengers', component: PassengersComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/otherPartyInfo', component: OtherPartyInfoComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/assignment/:participantId', component: SelectPolicyContactComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/unlistedAssignment', component: SelectOtherContactComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/unlistedAssignment/:participantId', component: SelectOtherContactComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/thirdPartyOwner/:participantId', component: SelectOtherContactComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/thirdPartyOwner', component: SelectVehicleOwner, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/thirdPartyVehicle', component: ThirdPartyVehicleComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/thirdPartyVehicle/:id', component: ThirdPartyVehicleComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/thirdPartyDriver', component: SelectVehicleDriver, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/additionalClaimant', component: AdditionalThirdPartyComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/additionalClaimant/:additionalClaimantProperty', component: AdditionalThirdPartyComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/thirdPartyHasPassengers', component: HasPassengersComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicle/:vehicleId/thirdPartyPassengers', component: PassengersComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/uploadReceipt', component: UploadReceiptComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/uploadDocument', component: UploadDocumentComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/property', component: FixedPropertyComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/property/:propertyId/propertyOwner', component: FixedPropertyOwnerComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/property/:propertyId/propertyOwner/:id', component: FixedPropertyOwnerComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/occupantInformation', component: OccupantInformationComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/recoveryInformation', component: RecoveryInformationComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/vehicleTheftDetails/:id/:displayTheftComponent', component: VehicleTheftDetails, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/nonPolicyVehicleTheft', component: NonPolicyVehicleTheftComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/nonPolicyVehicleTheft/:vehicleId/:displayTheftComponent', component: NonPolicyVehicleTheftComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/towingAndLabor', component: TowingAndLaborComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/smsConsent', component: SMSConsentComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/mcpLink', component: MyClaimsPicComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/pedestrian', component: SelectPedestrianComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/selectPedestrian', component: SelectPedestrianComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/isWindshieldOnlyDamages', component: IsWindshieldOnlyDamagesComponent, canActivate: [AuthGuardService] },
    { path: 'fnolAuto/isDamagesOtherThanWindshield', component: IsDamagesOtherThanWindshieldComponent, canActivate: [AuthGuardService] }

];

@NgModule({
    declarations: [DateOfLossComponent, PrimaryContactInfoComponent, LossAddressComponent, ContactInformationComponent, LossParticipantComponent,
        SelectVehiclesComponent, VehicleInfoComponent, LossDescriptionComponent, EmergencyResponseComponent, ReviewComponent,
        AdditionalVehicleComponent, ConfirmationComponent, HasOpenClaimComponent, ClaimTypeComponent, ClaimReporterComponent,
        SelectLossParticipantsComponent, GlassOnlyComponent, VehicleDetailComponent, SelectInsuredVehicle,
        HasPassengersComponent, PassengersComponent, SelectVehicleOwner, SelectVehicleDriver, NonPolicyVehicleComponent, OtherPartyInfoComponent,
        SelectPolicyContactComponent, SelectOtherContactComponent, ThirdPartyVehicleComponent, AdditionalThirdPartyComponent, UploadReceiptComponent,
        UploadDocumentComponent, FixedPropertyComponent, FixedPropertyOwnerComponent, OccupantInformationComponent, RecoveryInformationComponent,
        VehicleTheftDetails, NonPolicyVehicleTheftComponent, TowingAndLaborComponent, SMSConsentComponent, MyClaimsPicComponent,
        SelectPedestrianComponent, AwningOnlyComponent, IsWindshieldOnlyDamagesComponent, IsDamagesOtherThanWindshieldComponent
    ],

    imports: [
        RouterModule.forChild(fnolAutoRoutes),
        BrowserModule,
        FormsModule,
        CommonModule,
        NgxMaskModule.forRoot(),
        BrowserAnimationsModule,
        MatSliderModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        NgbModule,
        CommonDirectivesModule,
        CommonComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonModule,
        MatIconModule
    ],
    exports: [
        MatSliderModule
    ],
    providers: [
        LossApi, ParticipantApi, VehicleApi, FNOLAutoService, AddressValidationService, FNOLRoutingService
    ]
})
export class FNOLAutoModule { }