




import { BaseModel } from './BaseModel';

import { BaseClaimTypeModel } from './BaseClaimTypeModel';
import { BaseLossDetailsModel } from './BaseLossDetailsModel';
import { AddressModel } from './AddressModel';
import { BaseLossParticipantModel } from './BaseLossParticipantModel';
import { PolicyModel } from './PolicyModel';
import { ValueDetailModel } from './ValueDetailModel';
import { BasePropertyInfoModel } from './BasePropertyInfoModel';

export class BaseReviewModel extends BaseModel {
    
    public ClaimReporterId: number = 0;
    public ClaimType: BaseClaimTypeModel = null;
    public LossDetails: BaseLossDetailsModel = null;
    public LossAddress: AddressModel = null;
    public LossParticipants: BaseLossParticipantModel[] = [];
    public Policy: PolicyModel = null;
    public States: ValueDetailModel[] = [];
    public PhoneTypes: ValueDetailModel[] = [];
    public PropertyInfoModels: BasePropertyInfoModel[] = [];
    public IsSuccessfulSubmit: boolean = false;
    public HasClaimantInfo: boolean = false;
}