




import { BaseModel } from './BaseModel';
import { BaseLossDescriptionModel } from './BaseLossDescriptionModel'
import { BasePropertyInfoModel } from './BasePropertyInfoModel';
import { AddressModel } from './AddressModel';

export class HomeLossDescriptionModel extends BaseLossDescriptionModel {
    
    public LossProperty: BasePropertyInfoModel = null;
    public PolicyPropertyAddress: AddressModel = null;
}