import { Injectable } from '@angular/core';
import { BaseParticipantApi } from '@Common/services/api/baseParticipant.api';
import {
    PropertyOwnerModel, HomeLossParticipantModel
} from '@ClaimsModels/index';

@Injectable()
export class ParticipantApi extends BaseParticipantApi {

    public getPropertyOwnerModel(propertyId: number): Promise<PropertyOwnerModel> {
        return this.httpGet("FNOLHome/Participant/GetPropertyOwnerModel/" + propertyId);
    }

    public savePropertyOwnerModel(model: PropertyOwnerModel): Promise<PropertyOwnerModel> {
        return this.httpPost("FNOLHome/Participant/SavePropertyOwnerModel", model);
    }

    public getLossParticipantModel(propertyId: number): Promise<HomeLossParticipantModel> {
        return this.httpGet("FNOLHome/Participant/GetLossParticipantModel/" + propertyId);
    }

    public saveLossParticipantModel(model: HomeLossParticipantModel): Promise<HomeLossParticipantModel> {
        return this.httpPost("FNOLHome/Participant/SaveLossParticipantModel", model);
    }
}