import { Component } from "@angular/core";
import { BaseComponent } from "@Common/base/base.component";
import { MC360TabOptions } from '@ClaimsModels/index';
import { ClaimModel, AddressModel, SessionModel } from '@ClaimsModels/index';
import { ConstantsService, SessionService } from '@Common/services';
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
//import { SMSTextOptionComponent } from '@MyClaims360/components';
import { LossApi } from '@FNOLAuto/services/';

@Component({
    selector: 'dashboard',
    templateUrl: '../views/dashboard.component.html',
    styleUrls: ['../styles/mc360styling.css']
})

export class DashboardComponent extends BaseComponent {
 
    public homeOptInStatus: boolean;
 
    public claimModelOutput: Object;

    constructor(private constantsService: ConstantsService,
        private sessionService: SessionService,
        private spinner: Ng4LoadingSpinnerService,
        private myClaimsApi: MyClaimsApi,
        private dashboardService: DashboardService,
        private api: LossApi
    )
    {
        super();
    }

    public readonly MEDIA = '(max-width: 991.98px)';
    public TabOptions = MC360TabOptions;
    public selectedTab: string = "HOME";
   
    public showOptIn: boolean = false;
    public hideOptIn: boolean = true;
    public fullAccess: boolean = false;
    public dataLoaded: boolean = false;

    private session: SessionModel;

    public vm: ClaimModel;

    public claimStatus = this.constantsService.ClaimStatusClosed;

    public driverName: string;

    public modalRef: NgbModalRef;

    public isMediaMobileWidth: boolean = false;

    public setCSS: string = "mobile-desktop-sms";

    public ngOnInit(): void {

        this.session = this.sessionService.getSession();

        let dashboardSessionGuid = this.dashboardService.getDashboardSessionGuid();

        this.session.IsExpired = false;
        this.session.IsInitialized = false;
        this.sessionService.setSession(this.session);

        this.initializeModel();

        this.spinner.show();
        this.myClaimsApi.getClaimDetails(dashboardSessionGuid).then((response) => {
           
            this.vm = response;
            this.fullAccess = response.FullAccess;
            
            this.claimModelOutput = this.vm;
            this.spinner.hide();
       
            this.dataLoaded = true;
        });

        this.setSMSOptionConsent();

        this.checkMediaQuery();


        this.loadRemoteScript('assets/scripts/mc360.js', () => {
        });

    }

    private loadRemoteScript(url: string, onLoadCallback?: () => void) {
        const script = document.createElement('script');
        script.src = url;

        script.onerror = () => {
            console.error(`Error loading script: ${url}`);
        };

        script.onload = () => {
            //console.log(`${url} loaded!`);
            if (onLoadCallback) {
                onLoadCallback();
            }
        };
        document.body.appendChild(script);
    }


    checkMediaQuery() {
        const mediaQuery = window.matchMedia(this.MEDIA);

        if (mediaQuery.matches) {
            this.isMediaMobileWidth = true;
        }

        mediaQuery.addEventListener('change', (event) => {
            if (event.matches) {
                this.isMediaMobileWidth = true;
            } else {
                this.isMediaMobileWidth = false;
            }
        });
    }

    private initializeModel(): void {
        this.vm = new ClaimModel();
        this.vm.LossAddress = new AddressModel();
        this.vm.Exposures = [];
        this.vm.LossParticipants = [];
        this.vm.RecentDocuments = [];
    }

    public changeTab(newTab: string) {
        this.selectedTab = newTab;
    }

    public openModal(isOptIn: boolean): void {
        //if (this.smsModalOptionComponent.modalIsOpen == true) {
        //    this.smsModalOptionComponent.updateSMSOptionConsent(isOptIn);
        //} else {
        //    this.smsModalOptionComponent.openModal(isOptIn);
        //}
    }

    public showOptInScreen(isOptIn: boolean): void {
        this.showOptIn = !isOptIn;
        this.homeOptInStatus = !isOptIn;
    }
     
    public setSMSOptionConsent() {
         
        //this.api.getSMSConsentModel().then(response => {

        //    if (response.Success) {

        //        this.homeOptInStatus = !response.SMSConsent;
        //        this.hideOptIn = false;
        //    }

        //});
    }
}