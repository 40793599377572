<div ngForm #recoveryInformationForm="ngForm" class="main-inner-wrapper">
    <p class="header">Has your vehicle been recovered?</p>
    <span *ngIf="hasError" class="red">Please select one of the following options to continue.</span>



    <!--Recovery Questions-->
    <div class="input-wrapper">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="setHasRecoveredVehicle(true)" [ngClass]="{'selected': hasRecoveredVehicle != null && hasRecoveredVehicle}">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="setHasRecoveredVehicle(false)" [ngClass]="{'selected': hasRecoveredVehicle != null && !hasRecoveredVehicle}">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
        <div class="row" style="align-self: center; width: 15em" *ngIf="hasRecoveredVehicle">
            <div class="col-lg-12 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(DateOfRecovery)">Recovery Date</label><i [ngClass]="childHasInvalid(DateOfRecovery)" class="fas" title="*Required Field"></i>
                </div>
                <input id="incidentDatePicker" mask="00/00/0000" [specialCharacters]="[ '/' ]" [dropSpecialCharacters]="false" Validate type="text" name="DateOfRecovery" [(ngModel)]="this.recoveredDate" pattern="(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20|21)\d\d" #DateOfRecovery="ngModel" daterangepicker [options]="recoveredDateOptions" (selected)="selectedDate($event, recoveredDateRange)" />
                <div class="invalidMessage" [class.d-none]="DateOfRecovery.valid || DateOfRecovery.untouched"><i class="fas fa-exclamation-circle"></i>Please enter a valid date</div>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary"  (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(recoveryInformationForm)">Continue</button>
    </div>
</div>