




import { BaseModel } from './BaseModel';

import { VehicleInfoModel } from './VehicleInfoModel';

export class BaseVehiclePartyInfoModel extends BaseModel {
    
    public VehicleInfo: VehicleInfoModel = null;
}