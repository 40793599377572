







export class BaseModel {
    
    public Success: boolean = false;
}