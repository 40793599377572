<div ngForm #newClaimForm="ngForm" class="main-inner-wrapper" *ngIf="isThirdParty">
    <p class="header">Connecting...</p>

</div>

<div ngForm #newClaimForm="ngForm" class="main-inner-wrapper" *ngIf="!isThirdParty">
    <p class="header">What type of claim are you reporting today?</p>

    <div class=" emergencyHeader ">
        <h4 class="font-weight-bold " style="margin-right:1rem; display:inline;">Need Immediate Roadside Assistance?</h4>
        <span class="h5">
            <span *ngIf="!isEsurance">Please </span><span *ngIf="isEsurance">Select Roadside from the esurance mobile app home screen or </span>
            call <a class="text-nowrap" href="tel:+1 {{ roadsidePhoneNumber }}">1{{ roadsidePhoneNumber | mask: '(000) 000-0000' }}</a> to speak with a representative regarding your policy.
        </span>
    </div>

    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="noClaimSelected" (close)="noClaimSelected = false" [type]="danger">
        <strong>Error!</strong> Please select a type of Claim.
    </ngb-alert>

    <!--see styles/claimType.scss for div order of claim types-->
    <div id="newClaimFlex" class="d-flex flex-wrap justify-content-center newClaim-wrapper">
        <div id="personalAutoSection" class="button-wrapper" (click)="selectTypeService($event, personalAuto)">
            <div id="personalAuto" class="icon cursor" [ngClass]="{'selectedService': personalAuto.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Personal Auto</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/vehicle-mobile.png" title="Auto" alt="Auto" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/personalAuto.svg" title="Auto" alt="Auto" width="100" height="100" />
            </div>
        </div>
        <div id="homeSection" class="button-wrapper" (click)="selectTypeService($event, home)">
            <div id="home" class="icon cursor" [ngClass]="{'selectedService': home.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Home / Dwelling</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/home-mobile.png" title="Home" alt="Home" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/home.svg" title="Home" alt="Home" width="100" height="100" />
            </div>
        </div>
        <div id="watercraftSection" class="button-wrapper" (click)="selectTypeService($event, watercraft)">
            <div id="watercraft" class="icon cursor" [ngClass]="{'selectedService': watercraft.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Watercraft</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/blank-mobile.png" title="Watercraft" alt="Watercraft" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/watercraft.svg" title="Watercraft" alt="Watercraft" width="100" height="100" />
            </div>
        </div>
        <div id="motorcraftSection" class="button-wrapper" (click)="selectTypeService($event, motorhome)">
            <div id="motorhome" class="icon cursor" [ngClass]="{'selectedService': motorhome.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong style="margin-left:10px">RV, Motorhome, or Fifth Wheel</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/rv-mobile.png" title="RV" alt="RV" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/motorhome.svg" title="RV" alt="RV" width="100" height="100" />
            </div>
        </div>
        <div id="motorcycleSection" class="button-wrapper" (click)="selectTypeService($event, motorcycle)">
            <div id="motorcycle" class="icon cursor" [ngClass]="{'selectedService': motorcycle.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Motorcycle</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/motorcycle-mobile.png" title="Motorcycle" alt="Motorcycle" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/motorcycle.svg" title="Motorcycle" alt="Motorcycle" width="100" height="100" />
            </div>
        </div>
        <div id="commercialAutoSection" class="button-wrapper" (click)="selectTypeService($event, commercialAuto)">
            <div id="commercialAuto" class="icon cursor" [ngClass]="{'selectedService': commercialAuto.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Commercial Auto</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/vehicle-com-mobile.png" title="CommercialAuto" alt="CommercialAuto" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/commercialAuto.svg" title="CommercialAuto" alt="CommercialAuto" width="100" height="100" />
            </div>
        </div>
        <div id="farmSection" class="button-wrapper" (click)="selectTypeService($event, farm)">
            <div id="farm" class="icon cursor" [ngClass]="{'selectedService': farm.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Farm</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/blank-mobile.png" title="Farm" alt="Farm" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/farm.svg" title="Farm" alt="Farm" width="100" height="100" />
            </div>
        </div>
        <div id="lifeSection" class="button-wrapper" (click)="selectTypeService($event, life)">
            <div id="life" class="icon cursor" [ngClass]="{'selectedService': life.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div class="claims-innertext"><strong>Life</strong></div>
                </div>
                <img *ngIf="isDeviceMobile" src="/content/images/blank-mobile.png" title="Life" alt="Life" width="59" height="26" />
                <img *ngIf="!isDeviceMobile" src="/content/images/life.svg" title="Life" alt="Life" width="100" height="100" />
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(newClaimForm)">Continue</button>
    </div>
</div>
