




import { BaseModel } from './BaseModel';


export class ValueDetailModel {
    
    public DisplayValue: string = null;
    public DisplayCode: string = null;
}