import { Component, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { FNOLRoutingService, FNOLAutoService} from "@FNOLAuto/services";
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ContactService } from "@Common/services";

@Component({
    selector: 'uploadReceipt',
    templateUrl: '../../views/loss/uploadReceipt.component.html',
    providers: [FNOLRoutingService]
})
export class UploadReceiptComponent extends BaseFormComponent {

    public claimsEmail: string;
    isMobileDeviceSubscription: any;

    constructor(private fnolRouting: FNOLRoutingService, private spinner: Ng4LoadingSpinnerService, private contactService: ContactService,
                private fnolAutoService: FNOLAutoService) {
        super();
    }

    public ngOnInit(): void {
        this.isMobileDeviceSubscription = this.fnolAutoService.isDeviceMobile
            .subscribe(respMobile => {
                this.isDeviceMobile = respMobile;
            });
        this.claimsEmail = this.contactService.getClaimsEmail();
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {

        // todo - do we need to spin to simulate some type of "uploading document(s)"
        // for the user or do we just add in a loading bar on the actual file upload tool itself.
        // tbd.

        if (this.validate(form)) {
            this.spinner.show();
            this.spinner.hide();            
            this.fnolRouting.next();            
        }
    }

}