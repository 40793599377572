import { NgForm, AbstractControl } from "@angular/forms";
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { ContactInfoModel, PhoneModel, ValueDetailModel, AddressModel } from "@ClaimsModels/index";


export abstract class BaseContactFormComponent extends BaseFormComponent {

    public canAddPhone(contactInfo: ContactInfoModel): boolean {
        if (!contactInfo || !contactInfo.Phones) {
            return false;
        }

        let activePhones = contactInfo.Phones.filter(x => x.IsActive);

        return activePhones.length < 3;
    }

    public addPhone(contactInfo: ContactInfoModel): void {

        if (contactInfo && contactInfo.Phones) {
            // fill in the empty phones with inactive phones
            while (contactInfo.Phones.length < 3) {
                let newPhone = new PhoneModel();
                newPhone.IsActive = false;
                contactInfo.Phones.push(newPhone);
            }
        }

        for (let i = 0; i < contactInfo.Phones.length; i++) {
            if (!contactInfo.Phones[i].IsActive) {
                contactInfo.Phones[i].IsActive = true;
                break;
            }
        }
    }

    public removePhone(phoneIndex: number): void {
        //
    }

    // remove the other from the base Form
    public determineIfInjured(injuryDescription: string): boolean {

        if (injuryDescription == null) { // user chose nothing
            return null;
        }

        if (injuryDescription.length == 0) { // user selected no
            return false;
        }

        return true; // user selected yes
    }

    public canDisplayRemoveButton(contactInfo: ContactInfoModel): boolean {
        return contactInfo.Phones.filter(phone => {
            return phone.IsActive;
        }).length > 1
    }


    // there may be a better way to do this
    public availablePhoneTypes(phone: PhoneModel, contactInfo: ContactInfoModel, phoneTypes: ValueDetailModel[]): ValueDetailModel[] {
        let activePhones = contactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        phoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public isValidAddress(form: NgForm, address: AddressModel): boolean {

        // if a field is required and we have no data for that field, exit out
        // note the !! for casting values over to boolean (true if present, false otherwise)
        let addressRequired = !!form.controls["Address"].validator && !!form.controls["Address"].validator({} as AbstractControl).required;
        let cityRequired = !!form.controls["City"].validator && !!form.controls["City"].validator({} as AbstractControl).required;
        let stateRequired = !!form.controls["State"].validator && !!form.controls["State"].validator({} as AbstractControl).required;
        let zipRequired = !!form.controls["Zip"].validator && !!form.controls["Zip"].validator({} as AbstractControl).required;

        // check for data, exit if required and no data
        if (addressRequired && !this.haveDataFor(address.Address1)) {
            this.setInvalid(document.querySelector('div[ngForm] input[name=Address]'));
            return false;
        }

        if (cityRequired && !this.haveDataFor(address.City)) {
            this.setInvalid(document.querySelector('div[ngForm] input[name=City]'));
            return false;
        }

        if (stateRequired && !this.haveDataFor(address.State)) {
            this.setInvalid(document.querySelector('div[ngForm] input[name=State]'));
            return false;
        }

        if (zipRequired && !this.haveDataFor(address.Zip)) {
            this.setInvalid(document.querySelector('div[ngForm] input[name=Zip]'));
            return false;
        }

        // if all fields are required AND have data, its valid.
        return true;
    }
}