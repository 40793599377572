




import { BaseModel } from './BaseModel';


export class ExposureCorrespondenceModel extends BaseModel {
    
    public SeqNumber: number = 0;
    public Message: string = null;
    public DateTimeSent: Date = new Date(0);
}