<div ngForm #primaryContactInfoForm="ngForm" class="main-inner-wrapper">
    <p class="header">Could you please verify your contact info, and any missing content?</p>
    <div class="input-wrapper">
        <div class="row">
            <div class="fieldLabel-wrapper">
                <label [ngClass]="addressSetIsInvalid(Address, City, State, Zip)">Address</label>
                <i [ngClass]="addressSetIsInvalid(Address, City, State, Zip)" class="fas fa-asterisk" title="*Required field"></i>
            </div>
            <div class="col-lg-12 col-xs-12">
                <input Validate type="text" required placeholder="Address" name="Address" [(ngModel)]="vm.Address.Address1" #Address="ngModel"/>
            </div>
            <div class="col-lg-6 col-xs-12">
                <input Validate OnlyLetters type="text" required name="City" [(ngModel)]="vm.Address.City" #City="ngModel"/>
            </div>
            <div class="col-lg-2 col-xs-12">
                <select [(ngModel)]="vm.Address.State" required Validate #State="ngModel" name="State">
                    <option [ngValue]="null">State</option>
                    <option *ngFor="let state of vm.States" [ngValue]="state.DisplayCode"> {{ state.DisplayCode }} </option>
                </select>
            </div>
            <div class="col-lg-4 col-xs-12">
                <input OnlyNumbers type="text" name="Zip" required Validate [(ngModel)]="vm.Address.Zip" #Zip="ngModel"/>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-7 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Email)">Email</label><i [ngClass]="childHasInvalid(Email)" class="fas fa-asterisk" title="*Required field"></i>
                </div>
                <input type="email" email Validate placeholder="example@example.com" name="Email" required [(ngModel)]="email" #Email="ngModel"/>
            </div>
            <div class="col-lg-5 col-xs-12">
                <div class="fieldLabel-wrapper">
                    <label [ngClass]="childHasInvalid(Phone)">Phone Number</label><i [ngClass]="childHasInvalid(Phone)" class="fas fa-asterisk" title="*Required field"></i>
                </div>
                <input type="tel" mask="(000) 000-0000" required Validate name="Phone" [(ngModel)]="phone" #Phone="ngModel"/>
            </div>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="continueBtn" class="btn btn-primary" (click)="submit(primaryContactInfoForm)">Continue</button>
    </div>
</div>