import { Component, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { Location } from '@angular/common';

import { VehicleApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { VehicleInfoModel, VehicleClaimInfoModel } from '@ClaimsModels/index';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { ConstantsService } from '@Common/services';


@Component({
    selector: 'additionalPartyVehicle',
    templateUrl: '../../views/vehicle/additionalVehicle.component.html',
    styleUrls: ['../../styles/vehicleInfo.component.css'],
    providers: [FNOLRoutingService]
})
export class AdditionalVehicleComponent extends BaseVehicleComponent {

    constructor(private api: VehicleApi, private router: Router, private location: Location, private route: ActivatedRoute, private spinner: Ng4LoadingSpinnerService,
        private fnolRouting: FNOLRoutingService, private constantsService: ConstantsService, private fnolAuto: FNOLAutoService,
    ) {
        super();

        this.vm = new VehicleInfoModel();
        this.vm.VehicleClaimInfo = new VehicleClaimInfoModel();
    }

    public vm: VehicleInfoModel = new VehicleInfoModel();
    private routeId: number = 0;

    @Input() public isThirdParty: boolean;
    public isRVWorkflow: boolean;


    ngOnInit(): void {
        this.spinner.show();

        // use the id if we have one or default to zero
        this.routeId = this.route.snapshot.params.id || 0;

        this.api.getVehicleInfo(this.routeId).then(response => {
            this.spinner.hide();
            this.vm.States = response.States;
            if (this.routeId != 0 || this.fnolAuto.getClaimTypeCode() == this.constantsService.ClaimTypeCodePedNF ) {
                this.vm = response;
            }
        });

        
        if (sessionStorage.getItem("workflowType") == this.constantsService.FNOLRV)
            this.isRVWorkflow = true;
        else this.isRVWorkflow = false;
    }

    public goingBackWithLocation() {
        this.location.back();
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.vm.VehicleClaimInfo.IsOnPolicy = false;
            this.vm.VehicleClaimInfo.IsActive = true;

            if (this.isRVWorkflow) {
                this.vm.VehicleType = this.constantsService.RecreationVehicle;
            }

            this.api.saveVehicleInfo(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    if (this.isThirdParty && response.VehicleClaimInfo.OwnerContactId === null) {

                        let newUrl = `fnolAuto/thirdPartyVehicle/${response.PropertyId}`;
                        this.fnolRouting.replaceCurrentState(newUrl);

                        this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${response.PropertyId}/thirdPartyOwner`,
                            { queryParams: { assignmentType: this.constantsService.ThirdPartyOwner } });

                    } else if (this.isThirdParty && response.VehicleClaimInfo.OwnerContactId > 0) {
                        this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${response.PropertyId}/thirdPartyOwner/${response.VehicleClaimInfo.OwnerContactId}`,
                            { queryParams: { assignmentType: this.constantsService.ThirdPartyOwner } });

                    } else {
                        this.location.back();
                    }
                }
            });
        }
    }

    public addPerson(): void {
        this.spinner.show();
        this.api.saveVehicleInfo(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {

                if (this.routeId == 0) {
                    let newUrl = `${this.router.url}/${response.PropertyId.toString()}`;
                    this.location.replaceState(newUrl);
                }

                this.router.navigate(['/fnolAuto/lossParticipant']);
            }
        });
    }
}