




import { BaseModel } from './BaseModel';

import { SessionModel } from './SessionModel';
import { PolicyModel } from './PolicyModel';
import { ValueDetailModel } from './ValueDetailModel';

export class BaseDateOfLossModel extends BaseModel {
    
    public DateOfLoss: Date = new Date(0);
    public Session: SessionModel = null;
    public Policy: PolicyModel = null;
    public TimeZone: string = null;
    public IncidentAMPM: string = null;
    public TimeZones: ValueDetailModel[] = [];
    public RedirectUrl: string = null;
    public RedirectQuery: string = null;
}