import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { HomeRoutingService } from '@FNOLHome/services';
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { ConstantsService, SessionService } from "@Common/services";
import { BrandEnum } from "@ClaimsModels/index";

@Component({
    selector: 'uploadDocument',
    templateUrl: '../../views/loss/uploadDocument.component.html',
    providers: [HomeRoutingService]
})
export class UploadDocumentComponent extends BaseFormComponent {

    constructor(private fnolRouting: HomeRoutingService, private spinner: Ng4LoadingSpinnerService, private constantsService: ConstantsService, private session: SessionService) {
        super();
    }

    public claimsEmail: string;

    public ngOnInit(): void {
        this.claimsEmail = this.getClaimsEmail();
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {

        // todo - do we need to spin to simulate some type of "uploading document(s)"
        // for the user or do we just add in a loading bar on the actual file upload tool itself.
        // tbd.

        if (this.validate(form)) {
            this.spinner.show();
            this.spinner.hide();
            this.fnolRouting.next();
        }
    }

    private getClaimsEmail(): string {
        let claimsEmail: string = this.constantsService.ClaimsEmail.Default;
        let brand: string = this.session.get(this.constantsService.ClaimsWebBrandSessionKey);

        switch (brand) {
            case BrandEnum.EncompassInsurance:
                claimsEmail = this.constantsService.ClaimsEmail.Encompass;
                break;
            default:
                claimsEmail = this.constantsService.ClaimsEmail.Default;
                break;
        }

        return claimsEmail;
    }
}