<div class="details-header" style="width:30%;float:left;">
    <div class="claim-box">
        <div class="claim-card">
            <h3>Previous Documents</h3><button class="button-arrow" (click)="viewDocuments()">View All <img src="/content/images/arrow.svg" width="20" height="25" /></button>
            <div *ngFor="let document of claimDocuments" style="border: 1px solid lightgrey;border-radius:10px;padding:10px;margin-left:15px;">
                <span>{{document.DocumentName}}</span><br />
                <span style="font-size:smaller;">Uploaded {{document.DateUploaded | date}}</span>&nbsp;
                <button (click)="viewDocumentDetails(document.DocumentId)">View</button>
            </div>
        </div>
    </div>
</div>
<div class="content-wrapper" style="width:65%;float:right;margin:10px;">
    <div class="overview-box">
        <div class="box-header">
            <h2>Claim Document Upload</h2><br />
            <file-upload height="25vh" (progress) ="progressUpdated($event)" uploadUrl="/Dashboard/Document/UploadFiles/{{vm.LossParticipantId}}" [isDeviceMobile]="isDeviceMobile"></file-upload>
        </div>
        <div class="button-wrapper">
            <button class="button button-primary" (click)="goingBackWithLocation()">Back</button>
        </div>
    </div>
</div>