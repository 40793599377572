import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { DashboardSettings, UIErrorModel, PageTrackingModel } from '@ClaimsModels/index';

@Injectable()
export class LoggerApi {

    constructor(private http: HttpClient) { }

    // Intentionally not subscribing to fire and forget the call, just want it to fail silently if there is an error here

    public trackPageNavigation(model: PageTrackingModel): void {
        this.http.post("/Common/Utility/LogPageTracking", model).toPromise().catch();
    }

    public logError(error: UIErrorModel): void {
        this.http.post("/Common/Utility/LogUIError", error).toPromise().catch();
    }

    public getServerStatus(): Promise<string> {
        return this.http.get("/Common/Utility/GetServerStatus",
                { responseType: 'text' }).toPromise()
            .then(response => {
                return response.toString();
            });
    }

    public getDashboardSettings(): Promise<DashboardSettings[]> {
        return this.http.get("/Common/Utility/GetDashboardSettings").toPromise().then(
            (response: DashboardSettings[]) => {
                return response;
            });
    }

    public isSessionCamEnabled(): Promise<boolean> {
        return this.http.get("/Common/Utility/IsSessionCamEnabled").toPromise().then(   
            (response: boolean) => {
                return response;
            }
        );
    }

    public getExternalUrlSettings(): Promise<DashboardSettings[]> {
        return this.http.get("/Common/Utility/GetExternalUrls").toPromise().then(
            (response: DashboardSettings[]) => {
                return response;
            });
    }
}