




import { BaseModel } from './BaseModel';

import { RecipientInfoModel } from './RecipientInfoModel';

export class CustomerValidationModel extends BaseModel {
    
    public PageValidationEnabled: boolean = false;
    public RecipientInfo: RecipientInfoModel = null;
    public SubmissionType: number = 0;
    public Pin: string = null;
    public HasBusinessRuleError: boolean = false;
    public BusinessRuleMessages: string[] = [];
    public RedirectUrl: string = null;
}