




import { BaseModel } from './BaseModel';

import { SMSConsentModel } from './SMSConsentModel';

export class MyClaimsPicModel extends BaseModel {
    
    public SendLink: boolean = null;
    public SMSConsent: SMSConsentModel = null;
}