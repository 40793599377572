import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { FNOLRoutingService, ParticipantApi, FNOLAutoService } from '../../services';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { VehiclePassengersModel, ClaimTypeCategoryEnum } from '../../../../ClaimsWeb.Areas.Models/models';
import { ConstantsService } from '@Common/services'


@Component({
    selector: 'hasPassengers',
    templateUrl: '../../views/participant/hasPassengers.component.html',
    providers: [FNOLRoutingService]
})
export class HasPassengersComponent extends BaseVehicleComponent {

    public numberOfPassengers: number = 1;
    public vehicleId: number = 0;
    private vehiclePassengers: VehiclePassengersModel;

    public isThirdParty: boolean = false;

    constructor(private fnolRouting: FNOLRoutingService, private api: ParticipantApi, private fnolAutoService: FNOLAutoService,
                private spinner: Ng4LoadingSpinnerService, private constantsService: ConstantsService) {
        super();
    }

    public ngOnInit(): void {
        this.vehicleId = this.fnolRouting.getRoute().snapshot.params.vehicleId;
        this.spinner.show();
        this.api.getVehiclePassengersModel(this.vehicleId).then(response => {
            if (response.Success) {
                this.vehiclePassengers = response;

                if (!response.VehicleInfo.VehicleClaimInfo.IsUnlisted && !response.VehicleInfo.VehicleClaimInfo.IsOnPolicy) {
                    this.isThirdParty = true;
                }
            }

            this.spinner.hide();
        });
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {
    }

    public passengers(hasPassengers: boolean): void {
        let claimType = this.fnolAutoService.getClaimTypeCategory();

        if (!hasPassengers && 
            this.constantsService.ClaimTypePedestrian.indexOf(this.fnolAutoService.getClaimTypeCode()) > -1) {
            this.fnolRouting.routeToSpecificPage('fnolAuto/additionalClaimant');
            return;
        }
        if (!hasPassengers && !this.isThirdParty) {
            if (claimType === ClaimTypeCategoryEnum.MultipleVehicleAccident || claimType === ClaimTypeCategoryEnum.RearEndedAF || claimType === ClaimTypeCategoryEnum.RearEndedNAF) {
                this.fnolRouting.routeToSpecificPage('fnolAuto/otherPartyInfo')
                return;
            }
            else if (claimType === ClaimTypeCategoryEnum.FixedProperty) {
                this.fnolRouting.routeToSpecificPage('fnolAuto/property')
                return;
            }
            else {
                this.fnolRouting.routeToSpecificPage('fnolAuto/uploadDocument')
                return;
            }
        }
        if (hasPassengers && !this.isThirdParty) {
            this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/passengers`);
            return;
        } else if (hasPassengers && this.isThirdParty) {
            this.fnolRouting.routeToSpecificPage(`fnolAuto/vehicle/${this.vehicleId}/thirdPartyPassengers`);
            return;
        }

        if (this.vehiclePassengers.Passengers.filter(x => x.IsSelected).length > 0) {

            this.vehiclePassengers.Passengers.forEach(x => x.IsSelected = false);

            this.api.saveVehiclePassengersModel(this.vehiclePassengers).then(response => {
                if (response.Success) {
                    if (this.isThirdParty) {
                        this.fnolRouting.routeToSpecificPage('fnolAuto/additionalClaimant');
                    } else {
                        this.fnolRouting.next();
                    }
                }
            });
            return;
        } else if (this.isThirdParty) {
            this.fnolRouting.routeToSpecificPage('fnolAuto/additionalClaimant');
        } else {
            this.fnolRouting.next();
               }
    }
}