<div id="dashboard">
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
        <div class="container-fluid">
            <a class="navbar-brand" href="#">MyClaimCenter</a>
            <button [hidden]="!fullAccess" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div  class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" [hidden]="!fullAccess" [routerLinkActive]="['active']" aria-current="page" routerLink="./home" (onClick)="changeTab('HOME')">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [hidden]="!fullAccess" [routerLinkActive]="['active']" routerLink="./payments" (onClick)="changeTab('PAYMENTS')">Payments</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>


    <div class="container-fluid content-wrapper mt-3">
        <div class="row">
            <div class="col-lg-3 col-md-12 mb-3 fixed-sidebar">

                <claimOverview [claimModelData]="claimModelOutput"></claimOverview>

            </div>

            <div class="col mb-3 main-content">
                <div [hidden]="!fullAccess">
                    <router-outlet></router-outlet>
                </div>
                <div>
                    <incidentNotAvailable *ngIf="!fullAccess && dataLoaded" [claimModelData]="claimModelOutput"></incidentNotAvailable>
                </div>
            </div>

            <div class="col-lg-3 col-md-12">

                <contacts [hidden]="!fullAccess" [claimModelData]="claimModelOutput"></contacts>

            </div>
        </div>
    </div>

</div>
 