import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseContactFormComponent } from '@Common/base/baseContactForm.component';
import { ParticipantApi, FNOLRoutingService } from '@FNOLAuto/services';
import { AddressValidationService, ConstantsService } from '@Common/services/';
import {
    LossParticipantModel, AddressModel,
    ContactInfoModel, PhoneModel, EmailModel, ParticipantClaimDetailsModel, AddressValidationModel, ValueDetailModel
} from '@ClaimsModels/index';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';


@Component({
    selector: 'contactInfo',
    templateUrl: '../../views/participant/contactInformation.component.html',
    providers: [FNOLRoutingService]
})
export class ContactInformationComponent extends BaseContactFormComponent {

    isNotInEditMode: boolean = true;

    constructor(private api: ParticipantApi, private router: Router, private route: ActivatedRoute, private spinner: Ng4LoadingSpinnerService, private addressValidationService: AddressValidationService,
                private bottomSheet: MatBottomSheet, private fnolRouting: FNOLRoutingService, private constantsService: ConstantsService) {
        super();

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.vm = new LossParticipantModel();
        this.vm.Address = new AddressModel();
        this.vm.ContactInfo = new ContactInfoModel();
        this.vm.ParticipantClaimDetails = new ParticipantClaimDetailsModel();

        this.addressValidationService.initialize(this.continuePastAddressValidation.bind(this), this.updateAddress.bind(this), this.isValidAddress.bind(this));
    }

    public vm: LossParticipantModel = new LossParticipantModel();
    public phone: string = this.constantsService.EmptyString;
    public email: string = this.constantsService.EmptyString;
    public dateOfBirth: string = this.constantsService.EmptyString;
    public injured: boolean = null;
    public injuryLevel: number;
    public isSameAsPrimary: boolean = false;

    ngOnInit(): void {
        this.spinner.show();
        let id = this.route.snapshot.params.id;

        this.api.getLossParticipantModel(id).then(response => {
            this.spinner.hide();

            this.vm = response;

            this.dateOfBirth = moment(this.vm.DateOfBirth).format("MM/DD/YYYY");

            if (this.vm.ContactInfo != null &&
                this.vm.ContactInfo.Emails != null &&
                this.vm.ContactInfo.Emails.length > 0) {
                this.email = this.vm.ContactInfo.Emails[0].EmailAddress;
            }

            this.injured = this.determineIfInjured(this.vm.ParticipantClaimDetails.InjuryDescription);

            if (!this.checkPrefilledData()) {
                this.isNotInEditMode = false;
            }
        });
    }
    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public canAddPhone(): boolean {
        if (this.vm.ContactInfo.Phones.length >= 3) {
            return false;
        }
        return true;
    }

    public addPhone(): void {
        let newPhone = new PhoneModel();
        newPhone.PhoneNumber = null;
        newPhone.PhoneType = null;
        newPhone.IsActive = true;
        this.vm.ContactInfo.Phones.push(newPhone);
    }

    public availablePhoneTypes(phone: PhoneModel): ValueDetailModel[] {
        let activePhones = this.vm.ContactInfo.Phones.filter(x => x.IsActive);

        let result = [];

        this.vm.PhoneTypes.forEach(x => {
            if (!activePhones.find(y => y.PhoneType === x.DisplayCode) || phone.PhoneType === x.DisplayCode) {
                result.push(x);
            }
        });

        return result;
    }

    public removePhone(index: number): void {
        this.vm.ContactInfo.Phones.splice(index, 1);
        //
    }

    public track(index: number) {
        return index;
    }

    public canDisplayRemoveButton(): boolean {
        return this.vm.ContactInfo.Phones.filter(phone => {
            return phone.IsActive
        }).length > 1
    }

    public updateAddress(address: AddressModel): void {
        this.vm.Address = address;
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.vm.ParticipantClaimDetails.IsOnPolicy = true;
            this.mapPhoneAndEmail();
            this.vm.DateOfBirth = this.convertToUTCDate(this.dateOfBirth);

            if (this.injured != null && !this.injured) {
                this.vm.ParticipantClaimDetails.InjuryLevel = this.constantsService.EmptyString;
                this.vm.ParticipantClaimDetails.InjuryDescription = this.constantsService.EmptyString;
            }

            if (!this.isNotInEditMode) {
                var validationAddress: AddressValidationModel = new AddressValidationModel();
                validationAddress.AddressToValidate = this.vm.Address;
                this.spinner.show();

                this.addressValidationService.checkAddress(validationAddress).then(addressValidationResponse => {
                    this.spinner.hide();

                    let addresses = {
                        originalAddress: this.vm.Address,
                        suggestedAddress: addressValidationResponse.AddressToValidate
                    };

                    this.addressValidationService.validateAddress(addressValidationResponse, addresses, form);
                });
            }
            else {
                this.continuePastAddressValidation();
            }
        }
    }

    public sameAsPrimary(): void {

        this.vm.Address.Address1 = this.constantsService.EmptyString;
        this.vm.Address.City = this.constantsService.EmptyString;
        this.vm.Address.State = null;
        this.vm.Address.Zip = this.constantsService.EmptyString;

        // due to model binding happening after the click event, we have to invert our logic here
        if (!this.isSameAsPrimary) {
            this.vm.Address.Address1 = this.vm.PrimaryContactAddress.Address1;
            this.vm.Address.City = this.vm.PrimaryContactAddress.City;
            this.vm.Address.State = this.vm.PrimaryContactAddress.State;
            this.vm.Address.Zip = this.vm.PrimaryContactAddress.Zip;
        }

    }

    public handleInjurySlider(event: any) {
        this.injuryLevel = event.value;
        this.vm.ParticipantClaimDetails.InjuryLevel = `Level ${event.value}`;
    }

    public continuePastAddressValidation(): void {
        this.spinner.show();
        this.api.saveLossParticipantModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                this.router.navigate(['/fnolAuto/selectLossParticipants']);
            }
        });
    }

    // checks to see if we need to expand the Address/Name form based on the prefilled data
    private checkPrefilledData(): boolean {
        if (this.IsNullOrEmptyString(this.vm.FirstName) ||
            this.IsNullOrEmptyString(this.vm.LastName) ||
            this.IsNullOrEmptyString(this.vm.Address.Address1) ||
            this.IsNullOrEmptyString(this.vm.Address.City) ||
            this.IsNullOrEmptyString(this.vm.Address.State) ||
            this.IsNullOrEmptyString(this.vm.Address.Zip)) {
            return false;
        }

        return true;
    }

    // todo: we need a way to handle phones and emails
    private mapPhoneAndEmail() {
        let emailType: string = null;

        if (this.vm.ContactInfo.Emails != null && this.vm.ContactInfo.Emails.length > 0) {
            emailType = this.vm.ContactInfo.Emails[0].EmailType;
        }

        this.vm.ContactInfo.Emails = [];

        let emailModel = new EmailModel();
        emailModel.EmailAddress = this.email;
        emailModel.EmailType = emailType != null ? emailType : this.constantsService.EmailTypePER;
        this.vm.ContactInfo.Emails.push(emailModel);
    }

    displayEditMode(): void {
        this.isNotInEditMode = !this.isNotInEditMode;
    }
}