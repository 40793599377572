import { Component } from '@angular/core';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { NgForm } from "@angular/forms";
import { FNOLRoutingService, ParticipantApi, VehicleApi, FNOLAutoService } from '@FNOLAuto/services';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { SelectVehiclesModel, VehicleClaimInfoModel } from '@ClaimsModels/index';
import { ConstantsService } from '@Common/services';

@Component({
    selector: 'otherPartyInfo',
    templateUrl: '../../views/participant/otherPartyInfo.component.html',
    providers: [FNOLRoutingService]
})
export class OtherPartyInfoComponent extends BaseVehicleComponent {
    constructor(private fnolRouting: FNOLRoutingService, private spinner: Ng4LoadingSpinnerService, private api: VehicleApi, private fnolAutoService: FNOLAutoService,
        private constantsService: ConstantsService) {
        super();
    }

    public vm: SelectVehiclesModel = new SelectVehiclesModel();
    public vehicleId: number = 0;

    public ngOnInit(): void {
        this.spinner.show();
        this.api.getSelectVehiclesModel().then(response => {
            this.spinner.hide();
            this.vm = response
            if (this.vm.Success) {
                let claimantVehicle = this.vm.Vehicles.filter(x => !x.IsOnPolicy && !x.IsUnlisted);

                if (claimantVehicle.length > 0) {
                    this.vehicleId = claimantVehicle[0].VehicleId;
                }
            }
        });

    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {

    }

    public hasClaimantInfo(hasInfo: boolean): void {

        this.setSessionHasClaimantInfo(hasInfo);

        if (hasInfo && this.vehicleId === 0) {
            this.fnolRouting.routeToSpecificPage('fnolAuto/thirdPartyVehicle');

        } else if (hasInfo && this.vehicleId > 0) {
            this.fnolRouting.routeToSpecificPage(`fnolAuto/thirdPartyVehicle/${this.vehicleId}`);

        } else {
            this.removeClaimantVehicle();
        }
    }

    private removeClaimantVehicle(): void {
        let claimantVehicle: SelectVehiclesModel = new SelectVehiclesModel();
        claimantVehicle.Vehicles = this.vm.Vehicles.filter(x => !x.IsOnPolicy && !x.IsUnlisted);

        if (claimantVehicle.Vehicles.length > 0) {
            claimantVehicle.Vehicles.forEach(x => x.IsSelected = false);

            this.spinner.show()
            this.api.saveSelectVehiclesModel(claimantVehicle).then(response => {
                if (response.Success) {
                    this.spinner.hide();
                    this.fnolRouting.routeToSpecificPage('fnolAuto/uploadDocument');
                }
            });
        } else {
            this.fnolRouting.routeToSpecificPage('fnolAuto/uploadDocument');
        }
    }

    private setSessionHasClaimantInfo(hasInfo: boolean): void {
        if (hasInfo) {
            this.fnolAutoService.setHasClaimantInfo(this.constantsService.TrueStringValue);
        } else {
            this.fnolAutoService.setHasClaimantInfo(this.constantsService.FalseStringValue);
        }
    }
}