




import { BaseModel } from './BaseModel';

import { BasePropertyInfoModel } from './BasePropertyInfoModel';
import { BaseParticipantModel } from './BaseParticipantModel';

export class PropertyOwnerModel extends BaseModel {
    
    public LossProperty: BasePropertyInfoModel = null;
    public LossParticipants: BaseParticipantModel[] = [];
}