<input id="fileUploader" type="file" multiple class="hideInput" ng2FileSelect [uploader]="uploader" (onFileSelected)="processFiles($event)">

<div class="h-auto">
    <div class="fileUploadArea row" ng2FileDrop [ngClass]="{'has-file-over': hasFileOver}"
         (fileOver)="fileOverDrop($event)"
         [uploader]="uploader"
         (onFileDrop)="processFiles($event)"
         (click)="openFileBrowser($event)">

        <div class="col-12" *ngIf="uploader?.queue?.length > 0">
            <div class="row p-4 mx-auto mb-3 bg-white" *ngFor="let item of uploader.queue">
                <div class="col-3 col-sm-1 text-center align-self-center">
                    <i class="fas fa-file-alt text-info fa-3x"></i>
                </div>
                <div class="col-9 col-sm-11 pl-4">
                    <div class="w-100">
                        <span class="h5 text-break gbmask">{{ item.file.name }}</span>
                    </div>
                    <div class="w-100">
                        <span class="h6">({{ item.file.size/1024 | number:'.2-2' }} KB)</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="hasSizeError" class="col-12 text-center my-auto warning-ui" style="color:red">
            <span>This file exceeds the 30MB limit and will not be uploaded</span>
        </div>
        <div *ngIf="hasGenericError" class="col-12 text-center my-auto warning-ui" style="color:red">
            <span>Unable to upload document. Please try again or email your document to Claims@ngic.com</span>
        </div>
        <div *ngIf="hasTypeError" class="col-12 text-center my-auto warning-ui" style="color:red">
            <span>File names should contain only alphabetic or numeric characters.</span>
        </div>
        <div class="col-12 text-center my-auto file-type-text-ui">
            <span *ngIf="!isDeviceMobile">Drag a file here or click to upload a file.</span>
            <span *ngIf="isDeviceMobile">Click to upload a file.</span>
            <br />
            <span>Accepted file types are .png, .jpg, .jpeg, .msg, .mov, .pdf, and .heic</span>
        </div>
    </div>
</div>

<style>
    .has-file-over {
        border: 2px dashed #999 !important;
        background-color: #eee;
    }

    .fileUploadArea {
        border: 1px dashed #aaa;
        border-radius: 4px;        
        margin: 12px 15px 12px 12px;
        padding: 5px;
    }

    .fileUploadArea:hover {
        border: 2px dashed #aaa;
        cursor: pointer;
    }
    .hideInput {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
</style>