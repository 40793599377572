import { Component, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { VehicleApi, FNOLRoutingService, FNOLAutoService } from '../../services';
import { VehicleInfoModel, VehicleClaimInfoModel, ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { ConstantsService, SessionService } from '@Common/services'


@Component({
    selector: 'nonPolicyVehicle',
    templateUrl: '../../views/vehicle/nonPolicyVehicle.component.html',
    styleUrls: ['../../styles/vehicleInfo.component.css'],
    providers: [FNOLRoutingService]
})
export class NonPolicyVehicleComponent extends BaseVehicleComponent {

    constructor(private api: VehicleApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService, private fnolAutoService: FNOLAutoService,
        private constantsService: ConstantsService, private fnolAuto: FNOLAutoService, private sessionService: SessionService) {
        super();

        this.vm = new VehicleInfoModel();
        this.vm.VehicleClaimInfo = new VehicleClaimInfoModel();
    }

    public vm: VehicleInfoModel = new VehicleInfoModel();
    public hasRecoveredFromTheft: boolean = false;
    private routeId: number = 0;
    @Output() useTheftComponent = new EventEmitter<boolean>();

    ngOnInit(): void {
        this.spinner.show();

        // use the id if we have one or default to zero
        this.routeId = this.fnolRouting.getRoute().snapshot.params.vehicleId || 0;

        let hasRecovered: string = this.fnolAutoService.getHasRecoveredVehicleFromTheft();
        if (hasRecovered == this.constantsService.TrueStringValue) {
            this.hasRecoveredFromTheft = true;
        }

        this.api.getVehicleInfo(this.routeId).then(response => {
            this.spinner.hide();
            this.vm.States = response.States;
            if (this.routeId != 0) {
                this.vm = response;
            }

            if (this.fnolAutoService.getClaimTypeCategory() == ClaimTypeCategoryEnum.Theft) {
                let vehicleIDsThatHaveAnsweredTheftQuestions: string[] = this.fnolAutoService.getVehicleIDsThatHaveAnsweredTheftQuestions();
                if (vehicleIDsThatHaveAnsweredTheftQuestions != null && vehicleIDsThatHaveAnsweredTheftQuestions.includes(response.PropertyId.toString())) {
                    let displayTheft: string = this.fnolRouting.getRoute().snapshot.params.displayTheftComponent;
                    if (displayTheft == this.constantsService.TrueStringValue) {
                        this.useTheftComponent.emit(true);
                    }
                }
            }
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {
        if (this.validate(form)) {
            this.spinner.show();
            this.vm.VehicleClaimInfo.IsOnPolicy = false;
            this.vm.VehicleClaimInfo.IsUnlisted = true;
            this.vm.VehicleClaimInfo.IsActive = true;

            let workflowType = this.sessionService.getWorkflowType();

            if (workflowType == this.constantsService.FNOLRV) {
                this.vm.VehicleType = this.constantsService.RecreationVehicle;
            }

            if (ClaimTypeCategoryEnum.Glass) {
                this.vm.VehicleClaimInfo.IsWindshieldOnlyDamages = (sessionStorage.getItem('isWindshieldOnlyDamages') === 'true' ? true : false);
                if (sessionStorage.getItem('isWindshieldTwoPieces') === "true") {
                    this.vm.VehicleClaimInfo.IsWindshieldTwoPieces = true;
                }
                else if (sessionStorage.getItem('isWindshieldTwoPieces') === "false") {
                    this.vm.VehicleClaimInfo.IsWindshieldTwoPieces = false;
                }
                else {
                    this.vm.VehicleClaimInfo.IsWindshieldTwoPieces = null;
                }

                this.vm.VehicleClaimInfo.IsDamagesOtherThanWindshield = (sessionStorage.getItem('isDamagesOtherThanWindshield') === 'true' ? true : false);
                this.vm.VehicleClaimInfo.GlassDamageAreaDescription = sessionStorage.getItem('GlassDamageAreaDescription');
            }
            
            if (this.fnolAutoService.getClaimTypeCategory() == ClaimTypeCategoryEnum.Theft) {          
                this.vm.VehicleClaimInfo.IsActive = false; //Activate when submitted on theft component
                this.useTheftComponent.emit(true);
                this.spinner.hide();
                return;
            }

            this.api.saveVehicleInfo(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                            this.fnolRouting.routeToSpecificPage(`/fnolAuto/vehicle/${response.PropertyId}/selectOwner`, { replaceUrl: true });
                    
                }
            });
        }
    }

    public displayDescriptionQuestion(): boolean {
        switch (this.fnolAutoService.getClaimTypeCategory()) {
            case ClaimTypeCategoryEnum.Theft:
                if (this.hasRecoveredFromTheft) {
                    return true;
                }
                else {
                    return false;
                }
            default:
                return true;
        }
    }
}