import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";

import { SessionService } from './session/session.service';
import { LoggerApi } from './utility/logger.api';
import { SessionModel } from '@ClaimsModels/index';
import { PageTrackingModel } from '@ClaimsModels/PageTrackingModel';
import { Observable } from "rxjs";
import { SessionIdleService } from './session/sessionIdle.service';

@Injectable()
export class AuthGuardService  {

    constructor(private sessionService: SessionService, private logger: LoggerApi, private sessionIdle: SessionIdleService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> |
                                                                            Promise<boolean | UrlTree> |
                                                                            boolean |
                                                                            UrlTree {
        let session: SessionModel = this.sessionService.getSession();

        if (session.IsExpired && session.IsInitialized) {
            this.sessionService.navigateToSessionExpirationPage();
            return false;
        }

        if ((session.IsSubmitted && session.IsInitialized) && route.routeConfig.path !== 'fnolAuto/confirmation' && route.routeConfig.path !== 'fnolHome/confirmation') {
            return false;
        }

        this.sessionService.refreshSession();
        this.sessionIdle.restartTimer();


        let pageLog: PageTrackingModel = new PageTrackingModel();
        pageLog.PageName = this.getConfiguredUrl(route);
        pageLog.SessionId = session.SessionId;

        if (pageLog.SessionId !== null && pageLog.SessionId !== undefined) {
            this.logger.trackPageNavigation(pageLog);
        }

        return true;
    }

    getConfiguredUrl(route: ActivatedRouteSnapshot): string {
        return route.pathFromRoot
            .filter(v => v.routeConfig)
            .map(v => v.routeConfig!.path)
            .join('/');
    }


}