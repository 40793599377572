<div class="main-inner-wrapper no-mobile">
    <p class="header">
        If this is an Emergency – due to
        Fire, Severe Water Damage, or an Uninhabitable Home:
    </p>

    <div class="input-wrapper">
        <div class="row">
            <p class="contactNumber">Call <a href="tel:+1{{ contactNumber }}">1 {{ contactNumber | mask: "(000) 000-0000" }}</a> to speak with someone immediately.</p>
        </div>
    </div>
    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Return</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">File Claim</button>
    </div>
</div>