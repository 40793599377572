




import { BaseModel } from './BaseModel';


export class RecipientInfoModel extends BaseModel {
    
    public RecipientId: string = null;
    public FirstName: string = null;
    public LastName: string = null;
    public ObfuscatedDeliveryAddress: string = null;
    public AddressIsEmail: boolean = false;
    public ResendsLeft: number = 0;
    public RetriesLeft: number = 0;
    public HasAlreadySigned: boolean = false;
    public HasCanceled: boolean = false;
    public SendPin: boolean = false;
}