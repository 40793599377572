import { BaseContactInfoModel } from './BaseContactInfoModel';
import { AddressModel } from './AddressModel';
import { InsuranceInformationModel } from './InsuranceInformationModel';

export class BasePedestrianInformationModel {
    
    public PolicyPersonId: number = 0;
    public FirstName: string = null;
    public MiddleName: string = null;
    public LastName: string = null;
    public DateOfBirth: Date = null;
    public Gender: string = null;
    public IsOnPolicy: boolean = false;
    public IsSelected: boolean = false;
    public IsInjured: boolean = false;
    public InjuryDescription: string = "";
    public ContactInfo: BaseContactInfoModel = null;
    public Address: AddressModel = null;
    public InsuranceInformation: InsuranceInformationModel = null;
}