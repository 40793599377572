




import { BaseModel } from './BaseModel';

import { BaseContactInfoModel } from './BaseContactInfoModel';
import { AddressModel } from './AddressModel';
import { InsuranceInformationModel } from './InsuranceInformationModel';

export class BaseReporterInformationModel {
    
    public PolicyPersonId: number = 0;
    public FirstName: string = null;
    public MiddleName: string = null;
    public LastName: string = null;
    public IsOnPolicy: boolean = false;
    public IsSelected: boolean = false;
    public ContactInfo: BaseContactInfoModel = null;
    public Address: AddressModel = null;
    public InsuranceInformation: InsuranceInformationModel = null;
}