import { Component } from '@angular/core';
import { NgForm, UntypedFormGroup, UntypedFormControl} from "@angular/forms";
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

import { BaseFormComponent } from '@Common/base/baseForm.component';

import { LayoutService, SessionService, SessionBridgeApi, LoggerApi, UserLoginApi } from '@Common/services';
import { DashboardSettings, AgentLoginModel, UserLoginModel } from '@ClaimsModels/index';
import { OnInit } from '@angular/core';

 @Component({
    selector: 'agentLogin',
    templateUrl: '../../views/fnol/agentLogin.component.html'
})
export class AgentLoginComponent extends BaseFormComponent implements OnInit{
    public showBrand: boolean = true;

    public isAuthenticated: boolean = false;
    public vm: AgentLoginModel = new AgentLoginModel();
    public hasError: boolean = null;
    public policyNumberError: boolean = null;
    private sourceParty: string = "";
    private applicationName: string = "";
    private isEncompassAgencyDashboard: boolean = false;
    private readonly BridgeSessionURL: string = "/Common/Bridge/BridgeSession";
    private readonly InsuredAndAgentSourceParty: string = "INSUREDANDAGENT";
    BridgeSessionForm: UntypedFormGroup;

    constructor(private router: Router, private bridgeApi: SessionBridgeApi,
        private layoutService: LayoutService, private session: SessionService, private loggerApi: LoggerApi,
        private loginApi: UserLoginApi,
        private spinner: Ng4LoadingSpinnerService) {
        super();

        this.vm = new AgentLoginModel();
        this.vm.Login = new UserLoginModel();
    }

    ngOnInit(): void {
       this.spinner.show();

        this.BridgeSessionForm = new UntypedFormGroup({
            username: new UntypedFormControl(''),
            password: new UntypedFormControl(''),
            sourceParty: new UntypedFormControl(this.InsuredAndAgentSourceParty),
            policyNumber: new UntypedFormControl(''),
            source: new UntypedFormControl(''),
            applicationName: new UntypedFormControl(''),
            token: new UntypedFormControl('')
        });

        this.vm = new AgentLoginModel();
        this.vm.Login = new UserLoginModel();

        let useEncompass: boolean = false;
        this.sourceParty = this.InsuredAndAgentSourceParty;
      
         
        let url = window.location.host.split('.');

        if (url.length > 1) {
            if (url[1] === 'myclaim360') {
                this.isEncompassAgencyDashboard = true;
                useEncompass = true;
            }
        }

        this.loggerApi.getDashboardSettings().then((settings: DashboardSettings[]) => {
            if (settings.find(x => x.Key === "AgentDashboardEnabled").Value === "false") {
                this.spinner.hide();
                this.router.navigate(['404']);
                return;
            }

            if (settings.find(x => x.Key === "BrandEnabled").Value === "false") {
                this.showBrand = false;
            }

            this.layoutService.clearUsername();
            this.layoutService.setShowUsername(false);
            this.session.clearSession();

            this.bridgeApi.getBrand(useEncompass).then(response => {
                this.layoutService.setBrand(response);
                this.vm.Brand = response;
                this.spinner.hide();
            });
        });
    }

    public submit(form: NgForm): void {

        if (!this.validate(form)) {
            if (this.BridgeSessionForm.controls.username.errors || this.BridgeSessionForm.controls.password.errors) {
                this.hasError = true;
            } else { this.hasError = false; }
            
            if (this.BridgeSessionForm.controls.policyNumber.errors) {
                this.policyNumberError = true;
            } else { this.policyNumberError = false; }
            return;
        }

        this.vm.Login.Username = this.BridgeSessionForm.controls["username"].value;
        this.vm.Login.Password = btoa(this.BridgeSessionForm.controls["password"].value);
        this.vm.PolicyNumber = this.BridgeSessionForm.controls["policyNumber"].value;
        this.vm.Login.Password = btoa(this.BridgeSessionForm.controls["password"].value);

        this.spinner.show();

        this.applicationName = this.getApplicationNameAgentLogin();

        this.loginApi.validateUserLoginModel(this.vm.Login).then(response => {
            this.isAuthenticated = response.IsAuthenticated;
            this.hasError = !response.IsAuthenticated;
            this.policyNumberError = false;
            this.spinner.hide();

            if (this.isAuthenticated) {
                this.bridgeApi.generateBridgePayload(this.vm.PolicyNumber, this.applicationName, this.sourceParty)
                    .then(response => {
                   
                        this.BridgeSessionForm.patchValue({
                            'Token': response
                        })

                        this.vm.PolicyNumber = "";
                        let form = this.getForm(response);
                        form.submit();
                    });
            }
        });
    }


    getForm(response: string): HTMLFormElement {

        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", this.BridgeSessionURL);
        form.setAttribute("method", "POST");
        
        var token = document.createElement("input");
        token.setAttribute("type", "text");
        token.setAttribute("name", "Token");
        token.setAttribute("value", response);
        form.appendChild(token);

        var app = document.createElement("input");
        app.setAttribute("type", "text");
        app.setAttribute("name", "ApplicationName");
        app.setAttribute("value", this.applicationName);
        form.appendChild(app);

        document.body.appendChild(form);
        return form;
    }

    getApplicationNameAgentLogin() : string {
        if (this.sourceParty == this.InsuredAndAgentSourceParty) {
            return "NPSFNOL";
        } else {
            return "NPS";
        }
    }

    onSelected(value: string): void {
        this.sourceParty = value;
    }

    public goingBackWithLocation(): void {

    }
}