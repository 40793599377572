import { Component, Input, EventEmitter, SimpleChange } from '@angular/core'
import { BaseComponent } from '@Common/base/base.component';
import { ClaimModel, AddressModel, SessionModel, LossAddressModel, FNOLClaimType } from '@ClaimsModels/index';
import { ConstantsService, ClaimTypeService } from '@Common/services';
import { ClaimTypeEnum } from '@ClaimsModels/index';
import { MyClaimsApi, DashboardService } from '@MyClaims360/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';

@Component({
    selector: 'claimOverview',
    templateUrl: '../views/claimOverview.component.html',
   styleUrls: ['../styles/mc360styling.css']
})

export class ClaimOverviewComponent extends BaseComponent {

    @Input() claimModelData;

    constructor(public constantsService: ConstantsService, public claimTypeService: ClaimTypeService) {
        super();
    }

    public vm: ClaimModel = new ClaimModel();

    public isHome: boolean = false;

    public claimStatus = this.constantsService.ClaimStatusClosed;

    public driverName: string;

    public ngOnInit(): void {

        this.vm.LossAddress = new AddressModel();

    }

    ngOnChanges(changes: SimpleChange) {
        let change: SimpleChange = changes['claimModelData'];
       // console.log(change);
        if (change.currentValue != null) {
            this.vm = change.currentValue;
            this.claimStatus = this.vm.ClaimStatus.toUpperCase()

            this.checkClaimTypes(this.vm.ClaimType);
        }
        
    }

    checkClaimTypes(claimType: string) {
        let homeClaimTypes = this.claimTypeService.getClaimTypes(ClaimTypeEnum.ClaimType.Home);
        this.isHome = this.searchRecordByPropertyValue(homeClaimTypes, "ClaimTypeCode", claimType) != null;
    }

    searchRecordByPropertyValue<T>(records: Record<string, T>, property: keyof T, value: any): T | undefined {
        for (const key in records) {
            if (records[key][property] === value) {
                return records[key];
            }
        }
        return undefined;
    }
}