




import { BaseModel } from './BaseModel';

import { UserLoginModel } from './UserLoginModel';

export class AgentLoginModel extends BaseModel {
    
    public Login: UserLoginModel = null;
    public PolicyNumber: string = null;
    public Brand: string = null;
    public SessionGuid: string = null;
}