




import { BaseModel } from './BaseModel';


export class UserLoginModel extends BaseModel {
    
    public Username: string = null;
    public Password: string = null;
    public IsAuthenticated: boolean = null;
}