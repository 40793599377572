<div ngForm #awningOnlyForm="ngForm" class="main-inner-wrapper">
    <p class="header">Are you reporting an <em class="emphasis-bold">Awning Only</em> claim?</p>
    <span class="text-info mb-3">
        <i class="fas fa-info-circle"></i>
        <b> If your vehicle was involved in an accident and has damages more than just the awning, please select NO from below options.</b>
    </span>
    <span *ngIf="hasError" class="red">Please select one of the following options to continue.</span>

    <!--<ngb-alert class="alert alert-info" style="width:100%" *ngIf="isAwning" [type]="info" [dismissible]="false">
        <h4 class="alert-heading">Claim is reimbursement for towing and/or roadside services only.</h4>
        <hr />
        <ng-container *ngIf="!isEsurance">
            <span class="h5">
                If immediate roadside assistance is needed,
                please call <a href="tel:+1{{ roadsidePhoneNumber }}">1{{ roadsidePhoneNumber | mask: "(000) 000-0000" }}</a>.
                If your vehicle is disabled because of an accident, please select a different loss category.
            </span>
            <br />
            <span class="h5">
                For faster handling of your claim, please have your service invoice ready to upload at the end of the claim submission.
            </span>
        </ng-container>
        <ng-container *ngIf="isEsurance">
            <span class="h5">
                If immediate roadside assistance is needed, please select Roadside from the esurance mobile
                app home screen or call <a href="tel:+1{{ roadsidePhoneNumber }}">1{{ roadsidePhoneNumber | mask: "(000) 000-0000" }}</a>.
                If your vehicle is disabled because of an accident, please select a different loss category.
            </span>
        </ng-container>
    </ngb-alert>-->

    <div class="input-wrapper">
        <div class="row">
            <div>
                <button class="vehicle addNew button-reversed" (click)="setAwning(true);" [ngClass]="{'selected': isAwning != null && isAwning}">
                    <i class="fas fa-check"></i>
                    <span>Yes</span>
                </button>
            </div>
            <div>
                <button class="vehicle addNew button-reversed" (click)="setAwning(false)" [ngClass]="{'selected': isAwning != null && !isAwning}">
                    <i class="fas fa-times"></i>
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit()">Continue</button>
    </div>
</div>

<!--<div class="main-inner-wrapper">
    <p class="header">For Reimbursement of towing and labor services, please submit your receipt/invoice to
        <span style="word-break: break-word;">
            <strong>
                <a (click)="sendEmail()" role="button" tabindex="0"
                    class="cursor">roadside.reimbursement@allstate.com</a>
            </strong>
        </span>
    </p>

    <div class="button-wrapper">
        <button id="continueBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Return</button>
    </div>
    <div class="d-flex">
        <span class="text-muted">Roadside services provided by Allstate Roadside Service</span>
    </div>
</div>-->
