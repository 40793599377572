import { Injectable } from "@angular/core";
import { Location } from '@angular/common';
import { ClaimTypeCategoryEnum, Breadcrumb, RouteCrumb } from "@ClaimsModels/index";
import { Router, ActivatedRoute } from "@angular/router";
import { BreadcrumbService } from "@Common/services";
import { BaseRoutingService } from '@Common/base/baseRouting.service';
import { HomeService } from "./home.service";

@Injectable()
export class HomeRoutingService extends BaseRoutingService {

    constructor(location: Location, router: Router, route: ActivatedRoute, breadcrumbService: BreadcrumbService, home: HomeService) {
        super(location, router, route, breadcrumbService, home);
    }

    public initialize(claimType: ClaimTypeCategoryEnum): void {

        this.routes.length = 0; // clear any potential previous routes before we add in our routes.
        // todo - setup home claim types
        switch (claimType) {
            case ClaimTypeCategoryEnum.SingleVehicle:
            default:
                this.SetupDefaultRoutes();
                break;
        }
    }

    private SetupDefaultRoutes(): void {

        let crumbs = {
            ClaimType: new Breadcrumb('Claim Type', 1),
            LossInformation: new Breadcrumb('Loss Information', 2),
            LossDate: new Breadcrumb('Loss Date', 3),
            Damages: new Breadcrumb('Damages', 4),
            OtherInformation: new Breadcrumb('Other Information', 5),
            Document: new Breadcrumb('Document', 6),
            Review: new Breadcrumb('Review', 7),
            Confirmation: new Breadcrumb('Confirmation', 8)
        };

        let confirmationPage = new RouteCrumb('fnolHome/confirmation', crumbs.Confirmation, null);
        let reviewPage = new RouteCrumb('fnolHome/review', crumbs.Review, confirmationPage);
        let uploadDocumentPage = new RouteCrumb('fnolHome/uploadDocument', crumbs.Document, reviewPage);
        let contactInformationNoParticipantIdPage = new RouteCrumb('fnolHome/contactInformation', crumbs.LossInformation, uploadDocumentPage)
        let contactInformationWithParticipantIdPage = new RouteCrumb('fnolHome/contactInformation/:participantId', crumbs.LossInformation, uploadDocumentPage)
        let newlossParticipantPage = new RouteCrumb('fnolHome/lossParticipant', crumbs.LossInformation, uploadDocumentPage)
        let selectPropertyOwnerPage = new RouteCrumb('fnolHome/selectPropertyOwner', crumbs.LossInformation, uploadDocumentPage)
        let lossDescriptionPage = new RouteCrumb('fnolHome/lossDescription', crumbs.LossInformation, selectPropertyOwnerPage);
        let claimReporterPage = new RouteCrumb('fnolHome/claimReporter', crumbs.LossInformation, lossDescriptionPage);
        let claimTypePage = new RouteCrumb('fnolHome/claimType', crumbs.ClaimType, claimReporterPage);

        this.routes.push(
            confirmationPage, reviewPage, uploadDocumentPage, claimTypePage, lossDescriptionPage, newlossParticipantPage,
            claimReporterPage, selectPropertyOwnerPage, contactInformationWithParticipantIdPage, contactInformationNoParticipantIdPage
        );
    }    
}