import { Component } from '@angular/core';
import { BreadcrumbService, SessionService, LoggerApi } from '../services';
import { BaseComponent } from '../base/base.component';
import { PageTrackingModel } from '@ClaimsModels/index'

@Component({
    selector: 'sessionTimeout',
    templateUrl: '../views/sessionTimeout.component.html'
})
export class SessionTimeoutComponent extends BaseComponent {

    constructor(private breadcrumbService: BreadcrumbService, private sessionService: SessionService, private loggerApi: LoggerApi) {
        super();
        this.logExternalPageTracking('/sessionTimeout');
    }

    public ngOnInit(): void {
        this.breadcrumbService.clearBreadcrumbs();

        this.sessionService.clearSession();
    }

    private logExternalPageTracking(externalUrl: string): void {
        let page: PageTrackingModel = new PageTrackingModel();
        page.PageName = externalUrl;
        page.SessionId = this.sessionService.getSession().SessionId;

        this.loggerApi.trackPageNavigation(page);
    }
}