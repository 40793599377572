import { Injectable } from '@angular/core';
import { SessionService } from '@Common/services/';
import { BaseService } from '@Common/base/base.service';

@Injectable()
export class FNOLAutoService extends BaseService {

    private _noDriverSelectedKey: string = "NoDriverSelected";
    private _noClaimantDriverSelectedKey: string = "NoClaimantDriverSelected";
    private _unknownDriverKey: string = "UnknownDriver";
    private _unknownOwnerKey: string = "UnknownOwner";
    private _unknownClaimantDriverKey: string = "UnknownClaimantDriver";
    private _unknownClaimantOwnerKey: string = "UnknownClaimantOwner";
    private _unknownPedestrianKey: string = "UnknownPedestrian";
    private _hasClaimantInfoKey: string = "HasClaimantInfo";
    private _unknownFixedPropertyOwnerKey: string = "UnknownFixedPropertyOwner";

    private _hasOccupantsInVehicle: string = "HasOccupantsInVehicle";
    private _hasRecoveredVehicleFromTheft: string = "HasRecoveredVehicleFromTheft";
    private _dateOfTheftRecovery: string = "DateOfTheftRecovery";
    private _vehicleIDsThatHaveAnsweredTheftQuestions: string = "VehiclesThatHaveAnsweredTheftQuestions";
    private _vehicleStolenFromResidenceKey: string = "vehicleStolenFromResidence";



    constructor(sessionService: SessionService) {
        super(sessionService);
    }

    public getNoDriverSelected(): string {
        return this.sessionService.get(this._noDriverSelectedKey);
    }

    public setNoDriverSelected(value: string): void {
        this.sessionService.set(this._noDriverSelectedKey, value);
    }

    public setUnknownPedestrian(value: string): void {
        this.sessionService.set(this._unknownPedestrianKey, value);
    }

    public getUnknownPedestrian(): string {
        return this.sessionService.get(this._unknownPedestrianKey);
    }

    public getUnknownDriver(): string {
        return this.sessionService.get(this._unknownDriverKey);
    }

    public setUnknownDriver(value: string): void {
        this.sessionService.set(this._unknownDriverKey, value);
    }

    public getUnknownOwner(): string {
        return this.sessionService.get(this._unknownOwnerKey);
    }

    public setUnknownOwner(value: string): void {
        this.sessionService.set(this._unknownOwnerKey, value);
    }

    public getUnknownClaimantOwner(): string {
        return this.sessionService.get(this._unknownClaimantOwnerKey);
    }

    public getUnknownClaimantDriver(): string {
        return this.sessionService.get(this._unknownClaimantDriverKey);
    }

    public setUnknownClaimantOwner(value: string): void {
        this.sessionService.set(this._unknownClaimantOwnerKey, value);
    }

    public setUnknownClaimantDriver(value: string): void {
        this.sessionService.set(this._unknownClaimantDriverKey, value);
    }

    public getNoClaimantDriverSelected(): string {
        return this.sessionService.get(this._noClaimantDriverSelectedKey);
    }

    public setNoClaimantDriverSelected(value: string): void {
        this.sessionService.set(this._noClaimantDriverSelectedKey, value);
    }

    public setHasClaimantInfo(value: string): void {
        this.sessionService.set(this._hasClaimantInfoKey, value);
    }

    public getHasClaimantInfo(): string {
        return this.sessionService.get(this._hasClaimantInfoKey);
    }

    public setUnknownFixedPropertyOwner(value: string): void {
        this.sessionService.set(this._unknownFixedPropertyOwnerKey, value);
    }

    public getUnknownFixedPropertyOwner(): string {
        return this.sessionService.get(this._unknownFixedPropertyOwnerKey);
    }

    public getHasOccupantInVehicle(): string {
        return this.sessionService.get(this._hasOccupantsInVehicle);
    }

    public setHasOccupantInVehicle(value: string): void {
        this.sessionService.set(this._hasOccupantsInVehicle, value);
    }

    public getHasRecoveredVehicleFromTheft(): string {
        return this.sessionService.get(this._hasRecoveredVehicleFromTheft);
    }

    public setHasRecoveredVehicleFromTheft(value: string): void {
        this.sessionService.set(this._hasRecoveredVehicleFromTheft, value);
    }

    public getDateOfTheftRecovery(): string {
        return this.sessionService.get(this._dateOfTheftRecovery);
    }

    public setDateOfTheftRecovery(value: string) {
        this.sessionService.set(this._dateOfTheftRecovery, value);
    }

    public getVehicleIDsThatHaveAnsweredTheftQuestions(): string[] {
        return JSON.parse(this.sessionService.get(this._vehicleIDsThatHaveAnsweredTheftQuestions));
    }

    public setVehicleIDsThatHaveAnsweredTheftQuestions(value: string[]) {
        this.sessionService.set(this._vehicleIDsThatHaveAnsweredTheftQuestions, JSON.stringify(value));
    }

    public getVehicleStolenFromResidence(): string {
        return this.sessionService.get(this._vehicleStolenFromResidenceKey);
    }

    public setVehicleStolenFromResidence(value: string): void {
        this.sessionService.set(this._vehicleStolenFromResidenceKey, value);
    }
}