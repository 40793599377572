




import { BaseModel } from './BaseModel';
import { BaseVehiclePartyInfoModel } from './BaseVehiclePartyInfoModel'
import { ParticipantModel } from './ParticipantModel';

export class VehicleDriverModel extends BaseVehiclePartyInfoModel {
    
    public DriverOptions: ParticipantModel[] = [];
}