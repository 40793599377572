import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { FNOLRoutingService, ParticipantApi } from '@FNOLAuto/services';
import { BaseVehicleComponent } from '@Common/base/baseVehicle.component';
import { AdditionalPropertyNoteModel } from '../../../../ClaimsWeb.Areas.Models/models';

@Component({
    selector: 'additionalThirdParty',
    templateUrl: '../../views/participant/additionalThirdParty.component.html',
    providers: [FNOLRoutingService]
})
export class AdditionalThirdPartyComponent extends BaseVehicleComponent {
    constructor(private fnolRouting: FNOLRoutingService, private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService) {
        super();
    }

    public vm: AdditionalPropertyNoteModel = new AdditionalPropertyNoteModel();

    public ngOnInit(): void {
        let additionalClaimantProperty = this.fnolRouting.getRoute().snapshot.params.additionalClaimantProperty;
        if (additionalClaimantProperty !== undefined) {
            this.vm.HasAdditionalProperty = this.parseBoolean(additionalClaimantProperty);
        }
        else {
            this.vm.HasAdditionalProperty = false;
        }
    }

    public goingBackWithLocation(): void {
        if (this.vm.HasAdditionalProperty) {
            this.vm.HasAdditionalProperty = false;
        }
        else      
            this.fnolRouting.back();
    }

    public submit(form: NgForm): void {
        this.spinner.show
        this.api.saveHasAdditionalPropertyModel(this.vm).then(response => {
            this.spinner.hide
            this.fnolRouting.routeToSpecificPage('fnolAuto/uploadDocument', { replaceUrl: true });
        });
    }

    public additionalClaimants(value: boolean): void {
        this.scrollToTopOfPage();
        this.vm.HasAdditionalProperty = value;
        sessionStorage.setItem('additionalClaimantProperty', this.vm.HasAdditionalProperty.toString())

        if (!this.vm.HasAdditionalProperty) {
            this.spinner.show();
            this.api.saveHasAdditionalPropertyModel(this.vm).then(response => {
                this.spinner.hide();
                this.fnolRouting.routeToSpecificPage('fnolAuto/uploadDocument', { replaceUrl: true });
            });
        }
    }
}