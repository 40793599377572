<div ngForm #newClaimThirdPartyForm="ngForm" class="main-inner-wrapper" *ngIf="isThirdParty">
    <p class="header">Connecting...</p>

</div>

<div ngForm #newClaimThirdPartyForm="ngForm" class="main-inner-wrapper" *ngIf="!isThirdParty">
    <p class="header">What type of claim are you reporting today?</p>
    <div>
        <span class="text-info mb-3" *ngIf="!isEsurance">
            <!--<i class="fas fa-info-circle"></i>-->
            Please select National General Insured's vehicle type for claims other than Home/Dwelling.
        </span>
    </div>
    <ngb-alert class="alert alert-danger alert-dismissible fade show" *ngIf="noClaimSelected" (close)="noClaimSelected = false" [type]="danger">
        <strong>Error!</strong> Please select a type of Claim.
    </ngb-alert>

    <!--see styles/claimType.scss for div order of claim types-->
    <div id="newClaimAdverseCarrierFlex" class="d-flex flex-wrap justify-content-center newClaimThirdParty-wrapper">
        <div id="personalAutoSection" class="button-wrapper" (click)="selectTypeService($event, personalThirdPartyAuto)">
            <div id="personalThirdPartyAuto" class="icon cursor" [ngClass]="{'selectedService': personalThirdPartyAuto.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div *ngIf="!personalThirdPartyAuto.isSelected" class="claims-innertext"><strong>Personal Auto</strong></div>
                    <div class="claims-innertext" *ngIf="personalThirdPartyAuto.isSelected">
                        <p>{{ personalThirdPartyAuto.helpText }}</p>
                    </div>
                </div>
                <div *ngIf="!personalThirdPartyAuto.isSelected">
                    <img *ngIf="isDeviceMobile" src="/content/images/vehicle-mobile.png" title="Auto" alt="Auto" width="59" height="26" />
                    <img *ngIf="!isDeviceMobile" src="/content/images/personalAuto.svg" title="Auto" alt="Auto" width="100" height="100" />
                </div>
            </div>
        </div>
        <div id="homeSection" class="button-wrapper" (click)="selectTypeService($event, home)">
            <div id="home" class="icon cursor" [ngClass]="{'selectedService': home.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div *ngIf="!home.isSelected" class="claims-innertext"><strong>Home / Dwelling</strong></div>
                    <div class="claims-innertext" *ngIf="home.isSelected">
                        <p>{{ home.helpText }}</p>
                    </div>
                </div>
                <div *ngIf="!home.isSelected">
                    <img *ngIf="isDeviceMobile" src="/content/images/home-mobile.png" title="Home" alt="Home" width="59" height="26" />
                    <img *ngIf="!isDeviceMobile" src="/content/images/home.svg" title="Home" alt="Home" width="100" height="100" />
                </div>
            </div>
        </div>
        <div id="motorcraftSection" class="button-wrapper" (click)="selectTypeService($event, motorhome)">
            <div id="motorhome" class="icon cursor" [ngClass]="{'selectedService': motorhome.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div *ngIf="!motorhome.isSelected" class="claims-innertext"><strong style="margin-left:10px">RV, Motorhome, or Fifth Wheel</strong></div>
                    <div class="claims-innertext" *ngIf="motorhome.isSelected">
                        <p>{{ motorhome.helpText }}</p>
                    </div>
                </div>
                <div *ngIf="!motorhome.isSelected">
                    <img *ngIf="isDeviceMobile" src="/content/images/rv-mobile.png" title="RV" alt="RV" width="59" height="26" />
                    <img *ngIf="!isDeviceMobile" src="/content/images/motorhome.svg" title="RV" alt="RV" width="100" height="100" />
                </div>
            </div>
            </div>
        <div id="motorcycleSection" class="button-wrapper" (click)="selectTypeService($event, motorcycle)">
            <div id="motorcycle" class="icon cursor" [ngClass]="{'selectedService': motorcycle.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div *ngIf="!motorcycle.isSelected" class="claims-innertext"><strong>Motorcycle</strong></div>
                    <div class="claims-innertext" *ngIf="motorcycle.isSelected">
                        <p>{{ motorcycle.helpText }}</p>
                    </div>
                </div>
                <div *ngIf="!motorcycle.isSelected">
                    <img *ngIf="isDeviceMobile" src="/content/images/motorcycle-mobile.png" title="Motorcycle" alt="Motorcycle" width="59" height="26" />
                    <img *ngIf="!isDeviceMobile" src="/content/images/motorcycle.svg" title="Motorcycle" alt="Motorcycle" width="100" height="100" />
                </div>
            </div>
            </div>
        <div id="commercialAutoSection" class="button-wrapper" (click)="selectTypeService($event, commercialAuto)">
            <div id="commercialAuto" class="icon cursor" [ngClass]="{'selectedService': commercialAuto.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div *ngIf="!commercialAuto.isSelected" class="claims-innertext"><strong>Commercial Auto</strong></div>
                    <div class="claims-innertext" *ngIf="commercialAuto.isSelected">
                        <p>{{ commercialAuto.helpText }}</p>
                    </div>
                </div>
                <div *ngIf="!commercialAuto.isSelected">
                    <img *ngIf="isDeviceMobile" src="/content/images/vehicle-com-mobile.png" title="CommercialAuto" alt="CommercialAuto" width="59" height="26" />
                    <img *ngIf="!isDeviceMobile" src="/content/images/commercialAuto.svg" title="CommercialAuto" alt="CommercialAuto" width="100" height="100" />
                </div>
            </div>
            </div>
        <div id="otherSection" class="button-wrapper" (click)="selectTypeService($event, other)">
            <div id="other" class="icon cursor" [ngClass]="{'selectedService': other.isSelected}">
                <div class="d-flex align-items-center flex-column claims-outertext-wrapper">
                    <div *ngIf="!other.isSelected" class="claims-innertext"><strong>Other</strong></div>
                    <div class="claims-innertext" *ngIf="other.isSelected">
                        <p>{{ other.helpText }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="button-wrapper">
        <button id="backBtn" class="btn btn-primary" (click)="goingBackWithLocation()">Back</button>
        <button id="continueBtn" class="btn btn-primary" (click)="submit(newClaimThirdPartyForm)">Continue</button>
    </div>
</div>