




import { BaseModel } from './BaseModel';


export class GlassOnlyModel extends BaseModel {
    
    public ReturnUrl: string = null;
    public SafeliteUrl: string = null;
}