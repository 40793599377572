<div class="col mb-3 main-content main-page">
    <div class="card-body">
        <div class="accordion" id="accordionPaymentStatus">
            <div class="accordion-item">
                <div class="accordion-header" id="headingPaymentStatus">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePaymentStatus" aria-controls="collapsePaymentStatus">Payments</button>
                </div>
                <div [ngClass]="ShowAllPayments == true ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'"
                     id="collapsePaymentStatus" aria-labelledby="headingClaimStatus" data-bs-parent="#accordionClaimStatus">
                    <div class="accordion-body">
                        <div>
                            <h5 class="card-title section-header desktopView">
                                <div class="title-wrap">
                                    Payments
                                    <sub>Transactions</sub>
                                </div>
                                <!--<a type="button" href="" class="btn btn-light">View All Payments</a>-->
                                <div *ngIf="!ShowAllPayments" id="desktopPaymentsButton">
                                    <button class="btn btn-light" [routerLink]="['/myClaims360/dashboard/payments']">View All Payments</button>
                                </div>
                            </h5>

                            <div class="card-text">
                                <table class="table new-table" mat-table [dataSource]="dataSource" matSort>
                                    <ng-container matColumnDef="PaidTo">
                                        <th mat-header-cell *matHeaderCellDef> Paid To </th>
                                        <td mat-cell *matCellDef="let payment"> {{payment.PaidTo}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Date">
                                        <th mat-header-cell *matHeaderCellDef> Date </th>
                                        <td mat-cell *matCellDef="let payment">
                                            <span>{{payment.ApprovedDate | date: 'mediumDate' }}</span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="Amount">
                                        <th mat-header-cell *matHeaderCellDef> Amount </th>
                                        <td mat-cell *matCellDef="let payment"> {{payment.Amount | currency}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <mat-paginator (page)="handlePageChange($event)"
                                               [length]="length"
                                               [hidden]="!ShowAllPayments || length < 10"
                                               [pageSize]="10"
                                               [showFirstLastButtons]="showFirstLastButtons"
                                               [pageIndex]="pageIndex"
                                               aria-label="Select page">
                                </mat-paginator>
                            </div>

                            <a type="button" *ngIf="!ShowAllPayments" class="btn btn-100 btn-primary d-lg-none" [routerLink]="['/myClaims360/dashboard/payments']">View All Payments</a>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
