import { Component } from "@angular/core";
import { HomeRoutingService, ParticipantApi, HomeService } from "@FNOLHome/services";
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { Ng4LoadingSpinnerService } from "@Common/external/spinner";
import { NgForm } from "@angular/forms";
import { PropertyOwnerModel, ParticipantModel, BaseParticipantModel } from "@ClaimsModels/index";

@Component({
    selector: 'selectPropertyOwner',
    templateUrl: '../../views/participant/selectPropertyOwner.component.html',
    providers: [HomeRoutingService]
})
export class SelectPropertyOwnerComponent extends BaseFormComponent {

    public vm: PropertyOwnerModel = new PropertyOwnerModel();
    public noOwnerSelected: boolean = false;

    constructor(private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: HomeRoutingService, private homeService: HomeService) {
        super();
    }

    ngOnInit(): void {
        this.spinner.show();
        this.api.getPropertyOwnerModel(this.homeService.getPropertyId()).then(response => {
            this.spinner.hide();
            this.vm = response;
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    submit(form: NgForm): void {
        let propertyOwnerSelected: BaseParticipantModel[] = this.vm.LossParticipants.filter(x => x.IsSelected);

        if (propertyOwnerSelected.length == 0) {
            this.noOwnerSelected = true;
            return;
        }

        this.spinner.show();
        if (this.validate(form)) {
            this.api.savePropertyOwnerModel(this.vm).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
        }
    }

    public selectPerson(person: ParticipantModel) {
        person.IsSelected = !person.IsSelected;

        if (person.IsSelected) {
            this.fnolRouting.routeToSpecificPage(`fnolHome/contactInformation/${person.Id}`);
        }
    }

    public addNew(): void {
        this.fnolRouting.routeToSpecificPage('fnolHome/contactInformation');
    }
}