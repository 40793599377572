import { Component } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from '@angular/router';
import { HomeRoutingService } from "@FNOLHome/services";
import { BaseFormComponent } from "@Common/base/baseForm.component";
import { LoggerApi, SessionService } from "@Common/services";

@Component({
    selector: 'hasInjury',
    templateUrl: '../../views/property/hasInjury.component.html',
    providers: [HomeRoutingService]
})
export class HasInjuryComponent extends BaseFormComponent {

    public hasInjury: boolean = null;
    public displayError: boolean = false;

    constructor(private fnolRouting: HomeRoutingService, private loggerApi: LoggerApi, private sessionService: SessionService,
        private router: Router) {
        super();
    }

    ngOnInit(): void {
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public selectYes() {
        this.hasInjury = true;
    }

    public selectNo() {
        this.hasInjury = false;
    }

    submit(form: NgForm): void {
        if (this.hasInjury == null) {
            this.displayError = true;
            return;
        }

        if (this.hasInjury) {
            this.router.navigateByUrl('/reporterDetails?claimType=home');
        }

        if (!this.hasInjury) {
            this.fnolRouting.routeToSpecificPage('/fnolHome/dateOfLoss');
        }
    }
}