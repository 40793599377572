import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BaseComponent } from '../../base/base.component';

@Component({
    selector: 'sessionWarning',
    templateUrl: '../../views/modals/sessionWarning.component.html'
})

export class SessionWarningBottomSheet extends BaseComponent {

    constructor(public sheetRef: MatBottomSheetRef) {
        super();
    }

    public ngOnInit(): void {
    }

    public extendSessionTime(): void {
        this.sheetRef.dismiss({ event: 'Continue'});
    }

    public endSession(): void {
        this.sheetRef.dismiss({ event: 'End'});
    }
}