import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { VehicleClaimInfoModel } from '@ClaimsModels/index';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { StyleManagerService, ConstantsService, UnsubmittedClaimService, ContactService } from '@Common/services';
import { ClaimTypeCategoryEnum } from '@ClaimsModels/index';
import { FNOLRoutingService, LossApi } from '../../services';

@Component({
    selector: 'isDamagesOtherThanWindshield',
    templateUrl: '../../views/loss/isDamagesOtherThanWindshield.component.html',
    providers: [FNOLRoutingService]
})
export class IsDamagesOtherThanWindshieldComponent extends BaseFormComponent {

    public vm: VehicleClaimInfoModel = new VehicleClaimInfoModel();

    constructor(private api: LossApi, private router: Router, private constantsService: ConstantsService, private unsubmittedClaim: UnsubmittedClaimService,
        private contactService: ContactService, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService) {
        super();
        
        this.vm = new VehicleClaimInfoModel();
    }

    public IsDamagesOtherThanWindshield: boolean | null;
    public hasError: boolean;
    public displayPage: boolean;

    public ngOnInit(): void {
        if (sessionStorage.getItem('isDamagesOtherThanWindshield') != null) {
            this.IsDamagesOtherThanWindshield = (sessionStorage.getItem('isDamagesOtherThanWindshield') === 'true' ? true : false);
            if (this.IsDamagesOtherThanWindshield) {
                this.vm.GlassDamageAreaDescription = sessionStorage.getItem('GlassDamageAreaDescription')
            }
            else {
                this.vm.GlassDamageAreaDescription = '';
                sessionStorage.removeItem('GlassDamageAreaDescription');
            }
        }
        else{
            this.vm.GlassDamageAreaDescription = '';
            sessionStorage.removeItem('GlassDamageAreaDescription');
        }
        this.displayPage = true
        this.spinner.hide();
    }

    public goingBackWithLocation(): void {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {

        if (this.validate(form) && this.IsDamagesOtherThanWindshield != null) {
            sessionStorage.setItem('isDamagesOtherThanWindshield', this.IsDamagesOtherThanWindshield.toString());
            if (this.IsDamagesOtherThanWindshield) {
                sessionStorage.setItem('GlassDamageAreaDescription', this.vm.GlassDamageAreaDescription)
            }
            this.fnolRouting.next();

        }
        else {
            this.hasError = true;
            this.spinner.hide();
        }


    }

    public setIsDamagesOtherThanWindshield(value: boolean): void {
        this.hasError = false;
        this.IsDamagesOtherThanWindshield = value;
        this.vm.GlassDamageAreaDescription = sessionStorage.getItem('GlassDamageAreaDescription') 
    }
    public resetGlassDamageAreaDescription(): void
    {
        this.IsDamagesOtherThanWindshield = false;
        this.vm.GlassDamageAreaDescription = null;
        sessionStorage.removeItem('GlassDamageAreaDescription');
    }
}