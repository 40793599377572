import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { ParticipantApi, FNOLRoutingService } from '@FNOLAuto/services';
import { SelectParticipantsModel, ParticipantModel } from '@ClaimsModels/index';

@Component({
    selector: 'selectLossParticipants',
    templateUrl: '../../views/participant/selectLossParticipants.component.html',
    providers: [FNOLRoutingService]
})
export class SelectLossParticipantsComponent extends BaseFormComponent {

    constructor(private api: ParticipantApi, private spinner: Ng4LoadingSpinnerService, private fnolRouting: FNOLRoutingService) {
        super();
    }
    public isOther: boolean = false;
    public vm: SelectParticipantsModel = new SelectParticipantsModel();

    public ngOnInit(): void {
        this.spinner.show();
        this.api.getSelectParticipantsModel().then(response => {
            this.spinner.hide();
            this.vm = response;
        });
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public submit(form: NgForm): void {
        this.spinner.show();

        this.api.saveSelectParticipantsModel(this.vm).then(response => {
            this.spinner.hide();
            if (response.Success) {
                if (response.Success) {
                    this.spinner.hide();
                    this.fnolRouting.next();
                }
            }
        });
    }

    public selectPerson(person: ParticipantModel) {
        person.IsSelected = !person.IsSelected;

        if (person.IsSelected) {
            if (person.IsOnPolicy) {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/contactInformation/${person.Id}`)
            } else {
                this.fnolRouting.routeToSpecificPage(`fnolAuto/lossParticipant/${person.Id}`);
            }
        }
    }

    public addNew(): void {
        this.fnolRouting.routeToSpecificPage('fnolAuto/lossParticipant');
    }
}