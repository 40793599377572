import { EventEmitter } from '@angular/core';

export class SMSModalService {

  closeEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  emit(data) {
    this.closeEvent.emit(data);
   }

  getCloseEvent() {
    return this.closeEvent;
  }
}