




import { BaseModel } from './BaseModel';


export class DeviceTrackingModel {
    
    public SessionId: string = "00000000-0000-0000-0000-000000000000";
    public UserAgent: string = null;
    public ScreenSize: string = null;
    public Properties: string = null;
}