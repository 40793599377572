




import { BaseModel } from './BaseModel';


export class BaseLossDetailsModel extends BaseModel {
    
    public LossDate: Date = new Date(0);
    public LossDescription: string = null;
    public LossDescriptionCode: string = null;
    public SummaryCode: string = null;
    public LossTimeZone: string = null;
    public HasOccupantsInVehicle: boolean = null;
}