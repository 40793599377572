import { Component } from '@angular/core';

@Component({
    selector: 'maleGenderIcon',
    template: `<svg x="0px" y="0px" viewBox="0 0 15 30" class="people-icon">
                        <style type="text/css">
                            .st0 {
                                fill: none;
                                stroke: #707070;
                                stroke-width: 0.3615;
                                stroke-miterlimit: 10;
                            }
                        </style>
                        <g>
                            <path class="st0" d="M12.6,8.3c0-0.8-0.6-1.4-1.4-1.4H3.8C3,6.9,2.4,7.6,2.4,8.3l0,7.5c0,0.4,0.3,0.8,0.6,0.9
                          c0.6,0.2,1.1-0.3,1.1-0.9v-4.6c0-0.1,0.1-0.3,0.3-0.3h0c0.1,0,0.3,0.1,0.3,0.3v16.3c0,0.7,0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3
                          v-8.2h0.4v8.2c0,0.7,0.6,1.3,1.3,1.3h0c0.7,0,1.3-0.6,1.3-1.3V11.3c0-0.1,0.1-0.3,0.3-0.3h0c0.1,0,0.3,0.1,0.3,0.3l0,4.6
                          c0,0.4,0.3,0.8,0.6,0.9c0.6,0.2,1.1-0.3,1.1-0.9L12.6,8.3z" />
                            <circle class="st0" cx="7.5" cy="3.8" r="2.6" />
                        </g>
                    </svg>`
})
export class MaleGenderIconComponent {

}