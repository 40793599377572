import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ClaimTypeModel, ClaimTypeCategoryEnum, BrandEnum } from '@ClaimsModels/index';
import { GlassOnlyModel } from '@ClaimsModels/index';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { ConstantsService, StyleManagerService, UnsubmittedClaimService, SessionService, ContactService } from '@Common/services';
import { LossApi, FNOLRoutingService, FNOLAutoService } from '@FNOLAuto/services';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';


@Component({
    selector: 'towing-and-Labor',
    templateUrl: '../../views/loss/towingAndLabor.component.html'
})
export class TowingAndLaborComponent extends BaseFormComponent {
    constructor(private api: LossApi, private router: Router, private fnolAutoService: FNOLAutoService,
        private styleManager: StyleManagerService, private constantsService: ConstantsService,
        private unsubmittedClaim: UnsubmittedClaimService, private session: SessionService, private contactService: ContactService, private spinner: Ng4LoadingSpinnerService) {
        super();
    }

    public isTowingAndLaborReimbursement: boolean | null;
    public hasError: boolean = false;
    public claimTypeCategory: string;

    public isEsurance: boolean;
    public roadsidePhoneNumber: string;
    public workflowType: string = this.session.getWorkflowType();


    // For now, using the Glass only model to get returnURL.
    public vm: GlassOnlyModel = new GlassOnlyModel();

    public ngOnInit(): void {
        this.hasError = false;
 
        if (sessionStorage.getItem('isTowingAndLaborSelected') === "true")
            this.setTowingAndLaborReimbursement(true)
        if (sessionStorage.getItem('isTowingAndLaborSelected') === "false")
            this.setTowingAndLaborReimbursement(false);
        
        if (BrandEnum.Esurance === this.session.getBrand()) {
            this.isEsurance = true;
        }
        this.roadsidePhoneNumber = this.getRoadsidePhoneNumber();
        this.api.getGlassOnlyModel().then(response => {
            this.vm.ReturnUrl = response.ReturnUrl;

            if (this.vm.ReturnUrl == this.constantsService.EmptyString) {
                this.styleManager.returnUrl.subscribe(url => {
                    this.vm.ReturnUrl = url;
                })
            }
        });
        this.spinner.hide();
    }

    public goingBackWithLocation(): void {
        this.router.navigateByUrl('/fnolAuto/glassOnly');
    }

    public submit() {
        this.spinner.show();
        if (this.isTowingAndLaborReimbursement == null) {
            this.hasError = true;
            this.spinner.hide();

            return;
        }

        sessionStorage.setItem('isTowingAndLaborSelected', this.isTowingAndLaborReimbursement.toString());

        if (this.isTowingAndLaborReimbursement) {
            this.fnolAutoService.setClaimTypeCategory(ClaimTypeCategoryEnum.Mechanical);
            this.spinner.hide();
            this.router.navigate(['/fnolAuto/claimType']);
        } else {
            if (this.workflowType === this.constantsService.FNOLRV) {
                this.router.navigate(['/fnolAuto/awningOnly']);
            } else {
                this.router.navigate(['/fnolAuto/claimType']);
            }
        }
    }

    private getRoadsidePhoneNumber(): string {
        let phoneNumber: string = this.contactService.GetRoadSidePhoneNumber();

        let brand: string = this.session.getBrand();

        //switch (brand) {
        //    case BrandEnum.NationalGeneral:
        //        phoneNumber = this.getRoadSidePhoneNumberByPolicySubtype();
        //        break;
        //}

        return phoneNumber;
    }

    //#region "getRoadSidePhoneNumberByPolicySubtype"

    //private getRoadSidePhoneNumberByPolicySubtype(): string {
    //    let phoneNumber: string;

    //    switch (this.policySubType) {
    //        case this.constantsService.Preferred:
    //        case this.constantsService.Premier:
    //            phoneNumber = this.constantsService.RoadsidePhoneNumbers.Premier; // Preferred and Premier have the same number.
    //            break;
    //        case this.constantsService.PEIC:
    //            phoneNumber = this.getRoadsidePhoneNumberByMCOName();
    //            break;
    //        default:
    //            phoneNumber = this.constantsService.RoadsidePhoneNumbers.Default;
    //            break;
    //    }
    //    return phoneNumber;
    //}

    //#endregion "getRoadSidePhoneNumberByPolicySubtype""

    public setTowingAndLaborReimbursement(value: boolean): void {
        this.isTowingAndLaborReimbursement = value;
        this.hasError = false;
    }
}