<!-- OLD CLAIM STATUS STRUCTURE-->
<!--<div style="display:flex;">
    <ng-container *ngFor="let statusBarItem of this.statusBarItems; let i = index;">
        <div class="claim-status-bar-wrapper">
            <div class="claim-status-bar-circle">
                <span *ngIf="statusBarItem.PercentDone == 100"><i class="fas fa-check"></i></span>
                <span *ngIf="statusBarItem.PercentDone != 100 && statusBarItem.PercentDone != 0" class="claim-status-partially-complete"></span>
                <span *ngIf="statusBarItem.PercentDone == 0" class="claim-status-incomplete"></span>
            </div>
            <div *ngIf="i < this.statusBarItems.length - 1" class="claim-status-bar-inner-wrapper">
                <div class="claim-status-bar-line">
                    <mat-progress-bar mode="determinate" class="blue-progress" value="{{ statusBarItem.PercentDone }}"></mat-progress-bar>
                </div>
            </div>
            <p [style.padding-right]="i < this.statusBarItems.length - 1 ? null : '30px'" class="claim-status-bar-text">{{ statusBarItem.ClaimStatusDisplay }}</p>
        </div>
    </ng-container>
</div>-->

<div>
    <ul class="progress-claim">
        <li class="complete">
            Claim
            <br />
            Submitted
        </li>
        <li class="active">
            Open
        </li>
        <li>
            Closed
        </li>

    </ul>
</div>