import { Component } from '@angular/core';
import { Ng4LoadingSpinnerService } from '@Common/external/spinner';
import { Router } from '@angular/router';

import { ClaimTypeModel, ClaimTypeCategoryEnum, BrandEnum } from '@ClaimsModels/index';
import { BaseFormComponent } from '@Common/base/baseForm.component';
import { FNOLAutoService, FNOLRoutingService, LossApi } from '@FNOLAuto/services/';
import { BreadcrumbService, ConstantsService, SessionService, ContactService } from '@Common/services/';


@Component({
    selector: 'claimType',
    templateUrl: '../../views/loss/claimType.component.html',
    providers: [FNOLRoutingService]
})
export class ClaimTypeComponent extends BaseFormComponent {

    constructor(private api: LossApi, private spinner: Ng4LoadingSpinnerService, private router: Router, private fnolAutoService: FNOLAutoService, private fnolRouting: FNOLRoutingService,
                private breadcrumbService: BreadcrumbService, private constantsService: ConstantsService, private session: SessionService, private contactService: ContactService) {
        super();
    }

    public roadsidePhoneNumber: string;
    public policySubType: string;
    public policyMCOName: string;
    public isEsurance: boolean;
    public isMechanical: boolean;
    public isAwning: boolean;
    public isAwningSelected: boolean;
    public workflowType: string;
    public isRVWorkflow: boolean;

    public ngOnInit(): void {
        this.spinner.show();
        this.initializeClaimTypes();
        this.isMechanical = this.setIsMechanical();
        this.isAwning = this.setIsAwning();
        this.workflowType = this.session.getWorkflowType();
        this.isRVWorkflow = this.workflowType === this.constantsService.FNOLRV ? true : false;
        this.firstSelectionMade = false;
        this.selectedSubCategoryName = "";
        this.hasError = false;
        if (BrandEnum.Esurance === this.session.getBrand()) {
            this.isEsurance = true;
        }
        this.api.getClaimTypeModel().then(response => {
            this.spinner.hide();
            this.policySubType = response.PolicySubType;
            this.policyMCOName = response.PolicyMCOName;
            this.roadsidePhoneNumber = this.getRoadsidePhoneNumber();
            if (this.haveDataFor(response.ClaimType) && !this.isMechanical) {
                let selected = this.allSelections.find(x => x.claimTypeCode === response.ClaimType);

                if (this.weatherSelections.find(x => x.claimTypeCode === response.ClaimType)) {
                    this.isWeatherEvent = true;
                    this.firstSelectionMade = true;
                } else if (this.singleCarSelections.find(x => x.claimTypeCode === response.ClaimType)) {
                    this.isSingleCar = true;
                    this.firstSelectionMade = true;
                } else if (this.multiCarSelections.find(x => x.claimTypeCode === response.ClaimType)) {
                    this.isMultipleVehicle = true;
                    this.firstSelectionMade = true;
                    this.selectedSubCategoryName = selected.subCategoryName;
                    this.isMultipleVehicleCategorySelected = true;
                } else if (this.pedestrianSelections.find(x => x.claimTypeCode === response.ClaimType)) {
                    this.isPedestrian = true;
                    this.firstSelectionMade = true;
                } else if (this.towSelections.find(x => x.claimTypeCode === response.ClaimType)) {
                    if (this.isMechanical) {
                        this.isTowing = true;
                        this.firstSelectionMade = true;
                    }
                } else if (response.ClaimType === this.theft.claimTypeCode ||
                    response.ClaimType === this.vandalism.claimTypeCode) {
                    this.firstSelectionMade = true;
                    this.isTheftVandalism = true;
                } else if (this.isAwning) {
                    if (sessionStorage.getItem(this.constantsService.AwningSelectedKey) === "true") {
                        // BACK FROM OCCUPANT INFORMATION PAGE
                        this.isAwningSelected = false;
                        this.goBack();
                    }
                    // USER WENT BACK FROM OCCUPANT INFORMATION AND IS NOW DECIDED TO GO AWNING AGAIN.
                    sessionStorage.setItem(this.constantsService.AwningSelectedKey, "true");
                    this.firstSelectionMade = true;
                    this.awning.isSelected = true;
                    this.submit();

                } else if (this.miscRVSelections.find(x => x.claimTypeCode === response.ClaimType)) {
                    this.isMiscDamage = true;
                    this.firstSelectionMade = true;
                }

                this.toggleSelection(selected);

            } else if (this.isMechanical) {
                this.isTowing = true;
                this.firstSelectionMade = true;
                this.mechanical.isSelected = true;
                let selected = this.towSelections.find(x => x.claimTypeCode === response.ClaimType);
                if (selected !== undefined) {
                    this.toggleSelection(selected);
                }
            } else if (this.isAwning) { // FIRST TIME SELECTING AWNING
                this.firstSelectionMade = true;
                this.awning.isSelected = true;
                sessionStorage.setItem(this.constantsService.AwningSelectedKey, "true");
                let selected = this.awningSelections.find(x => x.claimTypeCode === response.ClaimType);
                if (selected !== undefined) {
                    this.toggleSelection(selected);
                }
                this.submit();
            }

        });
        
    }

    public hasError: boolean = false;
    public firstSelectionMade: boolean = false;
    public isWeatherEvent: boolean = false;
    public isSingleCar: boolean = false;
    public isMultipleVehicle: boolean = false;
    public isPedestrian: boolean = false;
    public isWindshield: boolean = false;
    public isTowing: boolean = false;
    public selectedSubCategoryName: string = this.constantsService.EmptyString;
    public isMultipleVehicleCategorySelected: boolean = false;
    public isMiscDamage: boolean = false;

    public isCollision: boolean = false;
    public isTheftVandalism: boolean = false;


    // Top level
    public windshield: ClaimType = new ClaimType();
    public singlecar: ClaimType = new ClaimType();
    public mechanical: ClaimType = new ClaimType();
    public theft: ClaimType = new ClaimType();
    public multipleVehicleAccident: ClaimType = new ClaimType();
    public vandalism: ClaimType = new ClaimType();
    public weatherEvent: ClaimType = new ClaimType();
    public fire: ClaimType = new ClaimType();
    public pedestrian: ClaimType = new ClaimType();
    public collision: ClaimType = new ClaimType();
    public theftVandalism: ClaimType = new ClaimType();
    public miscRV: ClaimType = new ClaimType();

    // Secondary - Weather
    public earthquake: ClaimType = new ClaimType();
    public hail: ClaimType = new ClaimType();
    public flood: ClaimType = new ClaimType();
    public windTornado: ClaimType = new ClaimType();
    public snow: ClaimType = new ClaimType();
    public lightning: ClaimType = new ClaimType();

    // Single Vehicle
    public singleVehicleOther: ClaimType = new ClaimType();
    public tireBlowout: ClaimType = new ClaimType();
    public roadHazard: ClaimType = new ClaimType();
    public fallingObject: ClaimType = new ClaimType();
    public hitAndRun: ClaimType = new ClaimType();
    public animalCollision: ClaimType = new ClaimType();
    public fixedProperty: ClaimType = new ClaimType();

    // Multi Vehicle
    public rearEndedAF: ClaimType = new ClaimType();
    public rearEndedNAF: ClaimType = new ClaimType();
    public parkingLotAF: ClaimType = new ClaimType();
    public parkingLotNAF: ClaimType = new ClaimType();
    public backingAF: ClaimType = new ClaimType();
    public backingNAF: ClaimType = new ClaimType();
    public intersection: ClaimType = new ClaimType();
    public headOn: ClaimType = new ClaimType();
    public passingAF: ClaimType = new ClaimType();
    public passingNAF: ClaimType = new ClaimType();
    public sideSwipeAF: ClaimType = new ClaimType();
    public sideSwipeNAF: ClaimType = new ClaimType();
    public inMotion: ClaimType = new ClaimType();
    public notInMotion: ClaimType = new ClaimType();
    public mvHitAndRun: ClaimType = new ClaimType();

    // Multi Vehicle Top Level Sub-Categories
    public rearEndedCategory: ClaimType = new ClaimType();
    public parkingLotCategory: ClaimType = new ClaimType();
    public backingCategory: ClaimType = new ClaimType();
    public passingMergingCategory: ClaimType = new ClaimType();
    public sideswipeCategory: ClaimType = new ClaimType();
    public otherCategory: ClaimType = new ClaimType();

    // Pedestrian
    public hitPedestrian: ClaimType = new ClaimType();
    public wasHit: ClaimType = new ClaimType();

    // Towing Sub-Categories
    public mechanicalBreakdown: ClaimType = new ClaimType();
    public lockOut: ClaimType = new ClaimType();
    public jumpStart: ClaimType = new ClaimType();
    public flatTire: ClaimType = new ClaimType();
    public gasDelivery: ClaimType = new ClaimType();
    public winch: ClaimType = new ClaimType();
    public illegallyParkedTow: ClaimType = new ClaimType();
    public impounded: ClaimType = new ClaimType();
    public other: ClaimType = new ClaimType();

    // Awning Only
    public awning: ClaimType = new ClaimType();

    //misc RV claims
    public miscAnimals: ClaimType = new ClaimType();
    public miscWater: ClaimType = new ClaimType();
    public miscElectrical: ClaimType = new ClaimType();
    public miscOther: ClaimType = new ClaimType();

    private topLevelSelections: ClaimType[] = [this.collision, this.weatherEvent, this.fire, this.theftVandalism, this.miscRV, this.windshield, this.mechanical, this.singlecar,  this.theft, this.multipleVehicleAccident, this.vandalism, this.pedestrian];

    private weatherSelections: ClaimType[] = [this.earthquake, this.hail, this.flood, this.windTornado, this.snow, this.lightning];

    private singleCarSelections: ClaimType[] = [this.singleVehicleOther, this.tireBlowout, this.roadHazard, this.fallingObject, this.hitAndRun, this.fixedProperty, this.animalCollision];

    private multiCarSelections: ClaimType[] = [this.rearEndedAF, this.rearEndedNAF, this.parkingLotAF, this.parkingLotNAF, this.backingAF, this.backingNAF,
    this.intersection, this.mvHitAndRun, this.headOn, this.passingAF, this.passingNAF, this.sideSwipeAF, this.sideSwipeNAF, this.inMotion, this.notInMotion];

    private multiVehicleCategorySelections: ClaimType[] = [this.rearEndedCategory, this.parkingLotCategory, this.backingCategory, this.passingMergingCategory,
    this.sideswipeCategory, this.otherCategory];

    private pedestrianSelections: ClaimType[] = [this.hitPedestrian, this.wasHit];

    private towSelections: ClaimType[] = [this.mechanicalBreakdown, this.lockOut, this.jumpStart, this.flatTire, this.gasDelivery, this.winch, this.illegallyParkedTow, this.impounded, this.other];

    private awningSelections: ClaimType[] = [this.awning];

    private miscRVSelections: ClaimType[] = [this.miscAnimals, this.miscElectrical, this.miscOther, this.miscWater];

    private allSelections: ClaimType[] = [
        ...this.topLevelSelections
        , ...this.multiVehicleCategorySelections
        , ...this.weatherSelections
        , ...this.singleCarSelections
        , ...this.multiCarSelections
        , ...this.pedestrianSelections
        , ...this.towSelections
        , ...this.awningSelections
        , ...this.miscRVSelections
    ];

    public selectTypeService(e, claimType: ClaimType): void {
        this.hasError = false;
        let currentlySelected = this.getSelected();
        if (currentlySelected !== null && currentlySelected !== undefined) {
            this.toggleSelection(currentlySelected);
        }

        this.toggleSelection(claimType);

    }

    private toggleSelection(claimType: ClaimType): void {
        let isSelected = !claimType.isSelected;
        let updateAllSelections = this.allSelections.map(x => {
            if (x.isSelected)
                x.isSelected = false
            return x;
        });
        updateAllSelections.map(x => x.name != claimType.name && claimType.isSelected);
        this.allSelections = updateAllSelections;
        claimType.isSelected = isSelected;
    }

    public goingBackWithLocation() {
        this.fnolRouting.back();
    }

    public goBack(): void {
        if (this.isMechanical) {
            sessionStorage.removeItem('ClaimTypeCategory');            
            this.isTowing = false;
            this.firstSelectionMade = false;
            this.mechanical.isSelected = false;
            this.router.navigate(['/fnolAuto/towingAndLabor']);
        }
        if (this.isAwning) {
            sessionStorage.removeItem('ClaimTypeCategory');
            sessionStorage.setItem(this.constantsService.AwningSelectedKey, "false");

            this.firstSelectionMade = false;
            this.awning.isSelected = false;
            this.isAwning = false;
            this.router.navigate(['/fnolAuto/awningOnly']);
        }
        this.hasError = false;       
        let selected = this.getSelected();
        if (selected.parentCategory != this.constantsService.EmptyString) {

            this.firstSelectionMade = false;
            this.isTowing = false;
            this.isWeatherEvent = false;
            this.isMultipleVehicle = false;
            this.selectedSubCategoryName = "";
            this.isSingleCar = false;
            this.isPedestrian = false;
            this.isWindshield = false;
            this.isMiscDamage = false;

            switch (selected.parentCategory) {
                case this.constantsService.Mechanical:
                    this.mechanical.isSelected = true;
                    this.isTowing = true;
                    break;
                case this.constantsService.WeatherEvent:
                    this.toggleSelection(selected);
                    this.weatherEvent.isSelected = true;
                    this.isWeatherEvent = true;
                    break;
                case this.constantsService.MultipleVehicleAccident:
                    this.firstSelectionMade = true;
                    if (this.isMultipleVehicleCategorySelected && 
                        selected.subCategoryName !== this.constantsService.EmptyString) {
                        this.isMultipleVehicle = true;
                        this.isMultipleVehicleCategorySelected = false;
                    } else {
                        this.isCollision = true;
                        this.multipleVehicleAccident.isSelected = true; 
                    }
                    break;
                case this.constantsService.MultiVehicleSubType:
                    this.firstSelectionMade = true;
                    switch (selected.subCategoryName) {
                        case this.constantsService.RearEndedCategory:
                            selected = this.rearEndedCategory;
                            selected.isSelected = true;
                            break;
                        case this.constantsService.BackingCategory:
                            selected = this.backingCategory;
                            selected.isSelected = true;
                            break;
                        case this.constantsService.OtherCategory:
                            selected = this.otherCategory;
                            selected.isSelected = true;
                            break;
                        case this.constantsService.ParkingLotCategory:
                            selected = this.parkingLotCategory;
                            selected.isSelected = true;
                            break;
                        case this.constantsService.PassingMergingCategory:
                            selected = this.passingMergingCategory;
                            selected.isSelected = true;
                            break;
                        case this.constantsService.SideswipeCategory:
                            selected = this.sideswipeCategory;
                            selected.isSelected = true;
                            break;

                    }
                    this.selectedSubCategoryName = this.constantsService.EmptyString;
                    this.multipleVehicleAccident.isSelected = true;
                    this.isMultipleVehicleCategorySelected = false;
                    this.isMultipleVehicle = true;
                    break;
                case this.constantsService.SingleVehicleOther:
                    this.firstSelectionMade = true;
                    this.isCollision = true;
                    this.singlecar.isSelected = true;

                    break;
                case this.constantsService.Pedestrian:
                    this.firstSelectionMade = true;
                    this.isCollision = true;
                    this.pedestrian.isSelected = true;

                    break;
                case this.constantsService.Windshield:
                    this.windshield.isSelected = true;
                    this.isWindshield = true;
                    break;
                case this.constantsService.MiscRVDamages:
                    this.toggleSelection(selected);
                    this.miscRV.isSelected = true;
                    this.isMiscDamage = true;
                    break;
                default:
                    break;

            }


        } else {
            if (selected.selectionType == 0 &&
                (selected.name === this.constantsService.SingleCar || 
                 selected.name === this.constantsService.Pedestrian || 
                 selected.name === this.constantsService.MultipleVehicleAccident)) {
                if (this.isCollision) {
                    this.collision.isSelected = true;
                    this.firstSelectionMade = false;
                    this.isCollision = false;
                } else {
                    this.firstSelectionMade = true;
                    this.isCollision = true;
                }
            } else if (selected.selectionType == 0 &&
                selected.name === this.constantsService.TheftVandalism) {
                if (this.isTheftVandalism) {
                    this.firstSelectionMade = false;
                    this.isTheftVandalism = false;
                    this.theftVandalism.isSelected = true;
                } else {
                    this.firstSelectionMade = true;
                    this.isTheftVandalism = true;
                }
            } else if (selected.selectionType == 0 &&
                selected.name === this.constantsService.Collision)
            {
                this.collision.isSelected = true;
                this.firstSelectionMade = false;
            }
            else {
                this.firstSelectionMade = false;
            }
            this.isTowing = false;
            this.isWeatherEvent = false;
            this.isMultipleVehicle = false;
            this.isSingleCar = false;
            this.isPedestrian = false;
            this.isWindshield = false;
            this.isMiscDamage = false;

        }
        this.scrollToTopOfPage();

    }

    public setSubCategoryShow() {
        let selected = this.getSelected();
        this.isSingleCar = false;
        this.isTowing = false;
        this.isWeatherEvent = false;
        this.isPedestrian = false;
        this.isMultipleVehicle = false;
        this.isWindshield = false;
        this.isCollision = false;
        this.isTheftVandalism = false;
        this.isMiscDamage = false;

        if (selected.name === this.constantsService.MechanicalBreakdown) {
            this.isTowing = true;
        }
        if (selected.name === this.constantsService.WeatherEvent) {
            this.isWeatherEvent = true;
        }
        if (selected.name === this.constantsService.SingleCar) {
            this.isSingleCar = true;
        }
        if (selected.name === this.constantsService.Pedestrian) {
            this.isPedestrian = true;
        }
        if (selected.name === this.constantsService.MultipleVehicleAccident) {
            this.isMultipleVehicle = true;
        }
        if (selected.name === this.constantsService.Windshield) {
            this.isWindshield = true;
        }
        if (selected.name === this.constantsService.MultiVehicleSubType) {
            this.isMultipleVehicle = false;
        }
        if (selected.name === this.constantsService.Collision) {
            this.isCollision = true;
        }
        if (selected.name === this.constantsService.TheftVandalism) {
            this.isTheftVandalism = true;
        }
        if (selected.name === this.constantsService.MiscRVDamages) {
            this.isMiscDamage = true;
        }
    }
    //###############################################################
    public submit(): void {
        this.scrollToTopOfPage();
        let selected = this.getSelected();
        this.hasError = false;

        if (selected === null || selected === undefined) {
            this.hasError = true;
            return;
        }

        if (selected.name === this.constantsService.Other) {
            selected = new ClaimType();
            if (this.firstSelectionMade === false) {
                this.hasError = true;
            }
            this.firstSelectionMade = false;
            return;
        }

        if (selected.name === this.constantsService.Other) {
            selected = new ClaimType();
            this.firstSelectionMade = false;
            return;
        }

        if (!this.firstSelectionMade) {
            if (selected.name == this.constantsService.Collision ||
                selected.name == this.constantsService.WeatherEvent ||
                selected.name == this.constantsService.TheftVandalism ||
                selected.name == this.constantsService.Fire || 
                selected.name == this.constantsService.MiscRVDamages) {
                this.hasError = false;
            } else {
                this.hasError = true;
                return;
            }
        }

        this.checkHasErrors(selected);
        if (this.hasError) {
            return;
        }

        this.setSubCategoryShow();

        if (selected.selectionType === SelectionType.Final) {
            let model: ClaimTypeModel = new ClaimTypeModel();
            model.ClaimType = selected.claimTypeCode;

            let claimTypeCategory: ClaimTypeCategoryEnum = this.fnolAutoService.getClaimTypeCategory();

            // if it differs from what I had before (e.g.: user changed their claim type)
            if (claimTypeCategory != null && claimTypeCategory != selected.category) {
                this.breadcrumbService.clearBreadcrumbs();
            }

            this.fnolAutoService.setClaimTypeCategory(selected.category);
            this.fnolAutoService.setClaimTypeCode(selected.claimTypeCode);
            this.fnolRouting.initialize(selected.category, selected.claimTypeCode); // initialize the routing with the right claim type
            this.spinner.show();
            this.api.saveClaimType(model).then(response => {
                this.spinner.hide();
                if (response.Success) {
                    this.fnolRouting.next();
                }
            });
        } else {
            this.firstSelectionMade = true;

            switch (selected.name) {
                case this.constantsService.SingleCar:
                    this.isSingleCar = true;
                    break;
                case this.constantsService.WeatherEvent:
                    this.isWeatherEvent = true;
                    break;
                case this.constantsService.MultipleVehicleAccident:
                    this.isMultipleVehicle = true;
                    this.selectedSubCategoryName = this.constantsService.EmptyString;
                    break;
                case this.constantsService.Pedestrian:
                    this.isPedestrian = true;
                    break;
                case this.constantsService.Windshield:
                    this.isWindshield = true;
                    break;
                case this.constantsService.MultiVehicleSubType:
                    this.selectedSubCategoryName = selected.subCategoryName;
                    this.isMultipleVehicleCategorySelected = true;
                    this.isMultipleVehicle = false;
                    break;
                case this.constantsService.Mechanical:
                    this.isTowing = true;
                    break;
                case this.constantsService.MiscRVDamages:
                    this.isMiscDamage = true;
                    break;
            }

        }
    }
    private checkHasErrors(selected) {
        if (this.isTheftVandalism && this.firstSelectionMade) {
            if (!this.theft.isSelected
                && !this.vandalism.isSelected
                ) {
                this.hasError = true;
                return;
            }
        }
        if (this.isCollision && this.firstSelectionMade) {
            if (!this.singlecar.isSelected
                && !this.multipleVehicleAccident.isSelected
                && !this.pedestrian.isSelected) {
                this.hasError = true;
                return;
                }
        }
        if (this.isMiscDamage && this.firstSelectionMade) {
            if (!this.miscAnimals.isSelected &&
                !this.miscElectrical.isSelected &&
                !this.miscWater.isSelected &&
                !this.miscOther.isSelected) {
                this.hasError = true;
                return;
                }
        }

        if (selected.name !== '' && selected.parentCategory === '') {
            if (this.mechanical.isSelected && this.isTowing == true) {
                if (!this.mechanicalBreakdown.isSelected
                    && !this.jumpStart.isSelected
                    && !this.lockOut.isSelected
                    && !this.flatTire.isSelected
                    && !this.gasDelivery.isSelected
                    && !this.winch.isSelected
                    && !this.illegallyParkedTow.isSelected
                    && !this.impounded.isSelected
                    && !this.other.isSelected
                ) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.firstSelectionMade && this.isWeatherEvent == true) {
                if (!this.earthquake.isSelected
                    && !this.hail.isSelected
                    && !this.flood.isSelected
                    && !this.windTornado.isSelected
                    && !this.snow.isSelected
                    && !this.lightning.isSelected
                    && !this.windshield.isSelected
                ) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.singlecar.isSelected && this.isSingleCar == true) {
                if (!this.roadHazard.isSelected
                    && !this.hitAndRun.isSelected
                    && !this.fallingObject.isSelected
                    && !this.animalCollision.isSelected
                    && !this.fixedProperty.isSelected
                    && !this.singleVehicleOther.isSelected

                ) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.multipleVehicleAccident.isSelected && this.isMultipleVehicle == true) {
                if (!this.rearEndedCategory.isSelected
                    && !this.backingCategory.isSelected
                    && !this.parkingLotCategory.isSelected
                    && !this.intersection.isSelected
                    && !this.headOn.isSelected
                    && !this.passingMergingCategory.isSelected
                    && !this.sideswipeCategory.isSelected
                    && !this.mvHitAndRun.isSelected
                    && !this.otherCategory.isSelected
                ) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.multipleVehicleAccident.isSelected && this.isMultipleVehicle == true
                && this.selectedSubCategoryName === ''
            ) {
                if (!this.rearEndedNAF.isSelected
                    && !this.parkingLotAF.isSelected
                    && !this.parkingLotNAF.isSelected
                    && !this.backingAF.isSelected
                    && !this.backingNAF.isSelected
                    && !this.sideSwipeAF.isSelected
                    && !this.sideSwipeNAF.isSelected
                    && !this.passingAF.isSelected
                    && !this.notInMotion.isSelected
                    && !this.inMotion.isSelected
                ) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.pedestrian.isSelected && this.isPedestrian == true) {
                if (!this.hitPedestrian.isSelected
                    && !this.wasHit.isSelected) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
        }
        if (selected.name === 'multiVehicleSubType' && this.selectedSubCategoryName !== '') {
            if (this.rearEndedCategory.isSelected) {
                if (!this.rearEndedNAF.isSelected
                    && !this.rearEndedAF.isSelected) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.parkingLotCategory.isSelected) {
                if (!this.parkingLotNAF.isSelected
                    && !this.parkingLotAF.isSelected) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.backingCategory.isSelected) {
                if (!this.backingNAF.isSelected
                    && !this.backingAF.isSelected) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.sideswipeCategory.isSelected) {
                if (!this.sideSwipeNAF.isSelected
                    && !this.sideSwipeAF.isSelected) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
            if (this.passingMergingCategory.isSelected) {
                if (!this.parkingLotNAF.isSelected
                    && !this.parkingLotAF.isSelected) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }

            }
            if (this.otherCategory.isSelected) {
                if (!this.inMotion.isSelected
                    && !this.notInMotion.isSelected) {
                    this.hasError = true; return;
                } else {
                    this.hasError = false;
                }
            }
        }
        if (this.isMultipleVehicle) {
            if (this.multiCarSelections.find(x => x.isSelected) !== undefined) {
                this.hasError = false; 
                return;
            }
            if (!this.rearEndedCategory.isSelected
                && !this.backingCategory.isSelected
                && !this.parkingLotCategory.isSelected
                && !this.intersection.isSelected
                && !this.headOn.isSelected
                && !this.passingMergingCategory.isSelected
                && !this.sideswipeCategory.isSelected
                && !this.mvHitAndRun.isSelected
                && !this.otherCategory.isSelected
            ) {
                this.hasError = true; return;
            } else {
                this.hasError = false;
            }
        }

    }
    private setIsMechanical() {
        if (this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.Mechanical ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.MechanicalBreakdown ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.LockOut ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.JumpStart ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.FlatTire ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.GasDelivery ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.Winch ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.IllegallyParkedTow ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.Impounded ||
            this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.Other) {
            return true;
        }
        return false;
    }

    private setIsAwning(): boolean {
        if (this.fnolAutoService.getClaimTypeCategory() === ClaimTypeCategoryEnum.Awning)
            return true;
        return false;
    }
    private initializeClaimTypes(): void {
        // Top Level

        this.collision.category;
        this.collision.selectionType = SelectionType.Initial;
        this.collision.name = this.constantsService.Collision;
        this.collision.helpText = this.constantsService.CollisionHelpText;

        this.theftVandalism.category;
        this.theftVandalism.selectionType = SelectionType.Initial;
        this.theftVandalism.name = this.constantsService.TheftVandalism;
        this.theftVandalism.helpText = this.constantsService.TheftVandalismHelpText;

        this.windshield.category = ClaimTypeCategoryEnum.Glass;
        this.windshield.selectionType = SelectionType.Initial;
        this.windshield.name = this.constantsService.Windshield;
        this.windshield.helpText = this.constantsService.GlassOnlyHelpText;

        this.singlecar.category = ClaimTypeCategoryEnum.SingleVehicle;
        this.singlecar.selectionType = SelectionType.Initial;
        this.singlecar.name = this.constantsService.SingleCar;
        this.singlecar.helpText = this.constantsService.SingleVehicleHelpText;

        this.mechanical.category = ClaimTypeCategoryEnum.Mechanical;
        this.mechanical.selectionType = SelectionType.Initial;
        this.mechanical.name = this.constantsService.Mechanical;
        this.mechanical.helpText = this.constantsService.MechanicalHelpText;

        this.theft.category = ClaimTypeCategoryEnum.Theft;
        this.theft.selectionType = SelectionType.Final;
        this.theft.name = this.constantsService.Theft;
        this.theft.claimTypeCode = this.constantsService.ClaimTypeCodeTheft;
        this.theft.helpText = this.constantsService.TheftHelpText;

        this.multipleVehicleAccident.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.multipleVehicleAccident.selectionType = SelectionType.Initial;
        this.multipleVehicleAccident.name = this.constantsService.MultipleVehicleAccident;
        this.multipleVehicleAccident.helpText = this.constantsService.MultipleVehicleHelpText;

        this.vandalism.category = ClaimTypeCategoryEnum.Vandalism;
        this.vandalism.selectionType = SelectionType.Final;
        this.vandalism.name = this.constantsService.Vandalism;
        this.vandalism.claimTypeCode = this.constantsService.ClaimTypeCodeVandalism;
        this.vandalism.helpText = this.constantsService.VandalismHelpText;

        this.weatherEvent.category = ClaimTypeCategoryEnum.Weather;
        this.weatherEvent.selectionType = SelectionType.Initial;
        this.weatherEvent.name = this.constantsService.WeatherEvent;
        this.weatherEvent.helpText = this.constantsService.WeatherEventHelpText;

        this.fire.category = ClaimTypeCategoryEnum.Fire;
        this.fire.selectionType = SelectionType.Final;
        this.fire.name = this.constantsService.Fire;
        this.fire.claimTypeCode = this.constantsService.ClaimTypeCodeFire;
        this.fire.helpText = this.constantsService.FireHelpText;

        this.pedestrian.category = ClaimTypeCategoryEnum.Pedestrian;
        this.pedestrian.selectionType = SelectionType.Initial;
        this.pedestrian.name = this.constantsService.Pedestrian;
        this.pedestrian.helpText = this.constantsService.PedestrianHelpText;

        this.miscRV.category = ClaimTypeCategoryEnum.MiscRV;
        this.miscRV.name = this.constantsService.MiscRVDamages;
        this.miscRV.selectionType = SelectionType.Initial;
        this.miscRV.helpText = this.constantsService.MiscRVHelpText;

        // Weather
        this.earthquake.category = ClaimTypeCategoryEnum.Weather;
        this.earthquake.selectionType = SelectionType.Final;
        this.earthquake.name = this.constantsService.Earthquake;
        this.earthquake.claimTypeCode = this.constantsService.ClaimTypeCodeEarthquake;
        this.earthquake.parentCategory = this.constantsService.WeatherEvent;

        this.hail.category = ClaimTypeCategoryEnum.Weather;
        this.hail.selectionType = SelectionType.Final;
        this.hail.name = this.constantsService.Hail;
        this.hail.claimTypeCode = this.constantsService.ClaimTypeCodeHail
        this.hail.parentCategory = this.constantsService.WeatherEvent;

        this.flood.category = ClaimTypeCategoryEnum.Weather;
        this.flood.selectionType = SelectionType.Final;
        this.flood.name = this.constantsService.Flood;
        this.flood.claimTypeCode = this.constantsService.ClaimTypeCodeFloodRisingWater;
        this.flood.parentCategory = this.constantsService.WeatherEvent;

        this.windTornado.category = ClaimTypeCategoryEnum.Weather;
        this.windTornado.selectionType = SelectionType.Final;
        this.windTornado.name = this.constantsService.WindTornado;
        this.windTornado.claimTypeCode = this.constantsService.ClaimTypeCodeWindTornado;
        this.windTornado.parentCategory = this.constantsService.WeatherEvent;

        this.snow.category = ClaimTypeCategoryEnum.Weather;
        this.snow.selectionType = SelectionType.Final;
        this.snow.name = this.constantsService.Snow;
        this.snow.claimTypeCode = this.constantsService.ClaimTypeCodeSnowIce;
        this.snow.parentCategory = this.constantsService.WeatherEvent;

        this.lightning.category = ClaimTypeCategoryEnum.Weather;
        this.lightning.selectionType = SelectionType.Final;
        this.lightning.name = this.constantsService.Lightning;
        this.lightning.claimTypeCode = this.constantsService.ClaimTypeCodeLightning;
        this.lightning.parentCategory = this.constantsService.WeatherEvent;

        // Single Vehicle
        this.singleVehicleOther.category = ClaimTypeCategoryEnum.SingleVehicle;
        this.singleVehicleOther.selectionType = SelectionType.Final;
        this.singleVehicleOther.name = this.constantsService.SingleVehicleOther;
        this.singleVehicleOther.claimTypeCode = this.constantsService.ClaimTypeCodeSingleVehicleLoss;
        this.singleVehicleOther.parentCategory = this.constantsService.SingleVehicleOther;

        this.tireBlowout.category = ClaimTypeCategoryEnum.TireBlowout;
        this.tireBlowout.selectionType = SelectionType.Final;
        this.tireBlowout.name = this.constantsService.TireBlowout;
        this.tireBlowout.claimTypeCode = this.constantsService.ClaimTypeCodeTireBlowout;
        this.tireBlowout.parentCategory = this.constantsService.SingleVehicleOther;

        this.roadHazard.category = ClaimTypeCategoryEnum.RoadHazard;
        this.roadHazard.selectionType = SelectionType.Final;
        this.roadHazard.name = this.constantsService.RoadHazard;
        this.roadHazard.claimTypeCode = this.constantsService.ClaimTypeCodeRoadHazardNF;
        this.roadHazard.parentCategory = this.constantsService.SingleVehicleOther;

        this.fallingObject.category = ClaimTypeCategoryEnum.FallingObject;
        this.fallingObject.selectionType = SelectionType.Final;
        this.fallingObject.name = this.constantsService.FallingObject;
        this.fallingObject.claimTypeCode = this.constantsService.ClaimTypeCodeFallingObject;
        this.fallingObject.parentCategory = this.constantsService.SingleVehicleOther;

        this.hitAndRun.category = ClaimTypeCategoryEnum.SingleVehicle;
        this.hitAndRun.selectionType = SelectionType.Final;
        this.hitAndRun.name = this.constantsService.HitAndRun;
        this.hitAndRun.claimTypeCode = this.constantsService.ClaimTypeCodeHitAndRun;
        this.hitAndRun.parentCategory = this.constantsService.SingleVehicleOther;

        this.fixedProperty.category = ClaimTypeCategoryEnum.FixedProperty;
        this.fixedProperty.selectionType = SelectionType.Final;
        this.fixedProperty.name = this.constantsService.FixedProperty;
        this.fixedProperty.claimTypeCode = this.constantsService.ClaimTypeCodeFixedProperty;
        this.fixedProperty.parentCategory = this.constantsService.SingleVehicleOther;

        this.animalCollision.category = ClaimTypeCategoryEnum.AnimalCollision;
        this.animalCollision.selectionType = SelectionType.Final;
        this.animalCollision.name = this.constantsService.AnimalCollision;
        this.animalCollision.claimTypeCode = this.constantsService.ClaimTypeCodeAnimalCollision;
        this.animalCollision.parentCategory = this.constantsService.SingleVehicleOther;

        // Multiple Vehicles
        this.rearEndedAF.category = ClaimTypeCategoryEnum.RearEndedAF;
        this.rearEndedAF.selectionType = SelectionType.Final;
        this.rearEndedAF.name = this.constantsService.RearEndedAF;
        this.rearEndedAF.claimTypeCode = this.constantsService.ClaimTypeCodeRearEndAccidentAF;
        this.rearEndedAF.subCategoryName = this.constantsService.RearEndedCategory;
        this.rearEndedAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.rearEndedNAF.category = ClaimTypeCategoryEnum.RearEndedNAF;
        this.rearEndedNAF.selectionType = SelectionType.Final;
        this.rearEndedNAF.name = this.constantsService.RearEndedNAF;
        this.rearEndedNAF.claimTypeCode = this.constantsService.ClaimTypeCodeRearEndAccidentNF;
        this.rearEndedNAF.subCategoryName = this.constantsService.RearEndedCategory;
        this.rearEndedNAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.parkingLotAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.parkingLotAF.selectionType = SelectionType.Final;
        this.parkingLotAF.name = this.constantsService.ParkingLotAF;
        this.parkingLotAF.claimTypeCode = this.constantsService.ClaimTypeCodeParkingLotAccidentAF;
        this.parkingLotAF.subCategoryName = this.constantsService.ParkingLotCategory;
        this.parkingLotAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.parkingLotNAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.parkingLotNAF.selectionType = SelectionType.Final;
        this.parkingLotNAF.name = this.constantsService.ParkingLotNAF;
        this.parkingLotNAF.claimTypeCode = this.constantsService.ClaimTypeCodeParkingLotAccidentNF;
        this.parkingLotNAF.subCategoryName = this.constantsService.ParkingLotCategory;
        this.parkingLotNAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.backingAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.backingAF.selectionType = SelectionType.Final;
        this.backingAF.name = this.constantsService.BackingAF;
        this.backingAF.claimTypeCode = this.constantsService.ClaimTypeCodeBackingAccidentAF;
        this.backingAF.subCategoryName = this.constantsService.BackingCategory;
        this.backingAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.backingNAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.backingNAF.selectionType = SelectionType.Final;
        this.backingNAF.name = this.constantsService.BackingNAF;
        this.backingNAF.claimTypeCode = this.constantsService.ClaimTypeCodeBackingAccidentNF;
        this.backingNAF.subCategoryName = this.constantsService.BackingCategory;
        this.backingNAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.intersection.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.intersection.selectionType = SelectionType.Final;
        this.intersection.name = this.constantsService.Intersection;
        this.intersection.claimTypeCode = this.constantsService.ClaimTypeCodeIntersectionAccident;
        this.intersection.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.headOn.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.headOn.selectionType = SelectionType.Final;
        this.headOn.name = this.constantsService.HeadOn;
        this.headOn.claimTypeCode = this.constantsService.ClaimTypeCodeHeadOnCollision;
        this.headOn.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.passingAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.passingAF.selectionType = SelectionType.Final;
        this.passingAF.name = this.constantsService.PassingAF;
        this.passingAF.claimTypeCode = this.constantsService.ClaimTypeCodePassingMergingAccidentAF
        this.passingAF.subCategoryName = this.constantsService.PassingMergingCategory;
        this.passingAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.passingNAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.passingNAF.selectionType = SelectionType.Final;
        this.passingNAF.name = this.constantsService.PassingNAF;
        this.passingNAF.claimTypeCode = this.constantsService.ClaimTypeCodePassingMergingAccidentNF;
        this.passingNAF.subCategoryName = this.constantsService.PassingMergingCategory;
        this.passingNAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.sideSwipeAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.sideSwipeAF.selectionType = SelectionType.Final;
        this.sideSwipeAF.name = this.constantsService.SideSwipeAF;
        this.sideSwipeAF.claimTypeCode = this.constantsService.ClaimTypeCodeSideswipeAccidentAF;
        this.sideSwipeAF.subCategoryName = this.constantsService.SideswipeCategory;
        this.sideSwipeAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.sideSwipeNAF.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.sideSwipeNAF.selectionType = SelectionType.Final;
        this.sideSwipeNAF.name = this.constantsService.SideSwipeNAF;
        this.sideSwipeNAF.claimTypeCode = this.constantsService.ClaimTypeCodeSideswipeAccidentNF;
        this.sideSwipeNAF.subCategoryName = this.constantsService.SideswipeCategory;
        this.sideSwipeNAF.parentCategory = this.constantsService.MultiVehicleSubType;

        this.inMotion.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.inMotion.selectionType = SelectionType.Final;
        this.inMotion.name = this.constantsService.InMotion;
        this.inMotion.claimTypeCode = this.constantsService.ClaimTypeCodeInMotion;
        this.inMotion.subCategoryName = this.constantsService.OtherCategory;
        this.inMotion.parentCategory = this.constantsService.MultiVehicleSubType;

        this.notInMotion.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.notInMotion.selectionType = SelectionType.Final;
        this.notInMotion.name = this.constantsService.NotInMotion;
        this.notInMotion.claimTypeCode = this.constantsService.ClaimTypeCodeNotInMotion;
        this.notInMotion.subCategoryName = this.constantsService.OtherCategory;
        this.notInMotion.parentCategory = this.constantsService.MultiVehicleSubType;

        this.rearEndedCategory.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.rearEndedCategory.selectionType = SelectionType.Secondary;
        this.rearEndedCategory.name = this.constantsService.MultiVehicleSubType;
        this.rearEndedCategory.subCategoryName = this.constantsService.RearEndedCategory;
        this.rearEndedCategory.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.parkingLotCategory.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.parkingLotCategory.selectionType = SelectionType.Secondary;
        this.parkingLotCategory.name = this.constantsService.MultiVehicleSubType;
        this.parkingLotCategory.subCategoryName = this.constantsService.ParkingLotCategory;
        this.parkingLotCategory.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.backingCategory.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.backingCategory.selectionType = SelectionType.Secondary;
        this.backingCategory.name = this.constantsService.MultiVehicleSubType;
        this.backingCategory.subCategoryName = this.constantsService.BackingCategory;
        this.backingCategory.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.sideswipeCategory.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.sideswipeCategory.selectionType = SelectionType.Secondary;
        this.sideswipeCategory.name = this.constantsService.MultiVehicleSubType;
        this.sideswipeCategory.subCategoryName = this.constantsService.SideswipeCategory;
        this.sideswipeCategory.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.passingMergingCategory.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.passingMergingCategory.selectionType = SelectionType.Secondary;
        this.passingMergingCategory.name = this.constantsService.MultiVehicleSubType;
        this.passingMergingCategory.subCategoryName = this.constantsService.PassingMergingCategory;
        this.passingMergingCategory.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.mvHitAndRun.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.mvHitAndRun.selectionType = SelectionType.Final;
        this.mvHitAndRun.name = this.constantsService.MultiVehicleHitAndRun;
        this.mvHitAndRun.claimTypeCode = this.constantsService.ClaimTypeCodeMultiVehicleHitAndRun;
        this.mvHitAndRun.parentCategory = this.constantsService.MultipleVehicleAccident;

        this.otherCategory.category = ClaimTypeCategoryEnum.MultipleVehicleAccident;
        this.otherCategory.selectionType = SelectionType.Secondary;
        this.otherCategory.name = this.constantsService.MultiVehicleSubType;
        this.otherCategory.subCategoryName = this.constantsService.OtherCategory;
        this.otherCategory.parentCategory = this.constantsService.MultipleVehicleAccident;


        // Pedestrian
        this.hitPedestrian.category = ClaimTypeCategoryEnum.Pedestrian;
        this.hitPedestrian.selectionType = SelectionType.Final;
        this.hitPedestrian.name = this.constantsService.HitPedestrian;
        this.hitPedestrian.claimTypeCode = this.constantsService.ClaimTypeCodePedAF;
        this.hitPedestrian.parentCategory = this.constantsService.Pedestrian;

        this.wasHit.category = ClaimTypeCategoryEnum.Pedestrian;
        this.wasHit.selectionType = SelectionType.Final;
        this.wasHit.name = this.constantsService.WasHit;
        this.wasHit.claimTypeCode = this.constantsService.ClaimTypeCodePedNF;
        this.wasHit.parentCategory = this.constantsService.Pedestrian;

        // Towing and Roadside
        this.mechanicalBreakdown.category = ClaimTypeCategoryEnum.MechanicalBreakdown;
        this.mechanicalBreakdown.selectionType = SelectionType.Final;
        this.mechanicalBreakdown.name = this.constantsService.MechanicalBreakdown;
        this.mechanicalBreakdown.subCategoryName = this.constantsService.ClaimTypeCodeMechanicalBreakdown;
        this.mechanicalBreakdown.claimTypeCode = this.constantsService.ClaimTypeCodeMechanicalBreakdown;
        this.mechanicalBreakdown.parentCategory = this.constantsService.Mechanical;

        // Awning Only
        this.awning.category = ClaimTypeCategoryEnum.Awning;
        this.awning.selectionType = SelectionType.Final;
        this.awning.name = this.constantsService.Awning;
        this.awning.subCategoryName = this.constantsService.ClaimTypeCodeAwning;
        this.awning.claimTypeCode = this.constantsService.ClaimTypeCodeAwning;
        this.awning.parentCategory = this.constantsService.Awning;

        this.lockOut.category = ClaimTypeCategoryEnum.LockOut;
        this.lockOut.selectionType = SelectionType.Final;
        this.lockOut.name = this.constantsService.LockOut;
        this.lockOut.subCategoryName = this.constantsService.ClaimTypeCodeTOWLockOut;
        this.lockOut.claimTypeCode = this.constantsService.ClaimTypeCodeTOWLockOut;
        this.lockOut.parentCategory = this.constantsService.Mechanical;

        this.jumpStart.category = ClaimTypeCategoryEnum.JumpStart;
        this.jumpStart.selectionType = SelectionType.Final;
        this.jumpStart.name = this.constantsService.JumpStart;
        this.jumpStart.subCategoryName = this.constantsService.ClaimTypeCodeTOWJumpStart;
        this.jumpStart.claimTypeCode = this.constantsService.ClaimTypeCodeTOWJumpStart;
        this.jumpStart.parentCategory = this.constantsService.Mechanical;

        this.flatTire.category = ClaimTypeCategoryEnum.FlatTire;
        this.flatTire.selectionType = SelectionType.Final;
        this.flatTire.name = this.constantsService.FlatTire;
        this.flatTire.subCategoryName = this.constantsService.ClaimTypeCodeTOWFlatTire;
        this.flatTire.claimTypeCode = this.constantsService.ClaimTypeCodeTOWFlatTire;
        this.flatTire.parentCategory = this.constantsService.Mechanical;

        this.gasDelivery.category = ClaimTypeCategoryEnum.GasDelivery;
        this.gasDelivery.selectionType = SelectionType.Final;
        this.gasDelivery.name = this.constantsService.GasDelivery;
        this.gasDelivery.subCategoryName = this.constantsService.ClaimTypeCodeTOWGasDelivery;
        this.gasDelivery.claimTypeCode = this.constantsService.ClaimTypeCodeTOWGasDelivery;
        this.gasDelivery.parentCategory = this.constantsService.Mechanical;

        this.winch.category = ClaimTypeCategoryEnum.Winch;
        this.winch.selectionType = SelectionType.Final;
        this.winch.name = this.constantsService.Winch;
        this.winch.subCategoryName = this.constantsService.ClaimTypeCodeTOWWinch;
        this.winch.claimTypeCode = this.constantsService.ClaimTypeCodeTOWWinch;
        this.winch.parentCategory = this.constantsService.Mechanical;

        this.illegallyParkedTow.category = ClaimTypeCategoryEnum.IllegallyParkedTow;
        this.illegallyParkedTow.selectionType = SelectionType.Final;
        this.illegallyParkedTow.name = this.constantsService.IllegallyParkedTow;
        this.illegallyParkedTow.subCategoryName = this.constantsService.ClaimTypeCodeTOWIllegallyParked;
        this.illegallyParkedTow.claimTypeCode = this.constantsService.ClaimTypeCodeTOWIllegallyParked;
        this.illegallyParkedTow.parentCategory = this.constantsService.Mechanical;

        this.impounded.category = ClaimTypeCategoryEnum.Impounded;
        this.impounded.selectionType = SelectionType.Final;
        this.impounded.name = this.constantsService.Impounded;
        this.impounded.subCategoryName = this.constantsService.ClaimTypeCodeTOWImpounded;
        this.impounded.claimTypeCode = this.constantsService.ClaimTypeCodeTOWImpounded;
        this.impounded.parentCategory = this.constantsService.Mechanical;

        this.other.category = ClaimTypeCategoryEnum.Other;
        this.other.selectionType = SelectionType.Final;
        this.other.name = this.constantsService.Other;
        this.other.subCategoryName = this.constantsService.ClaimTypeCodeTOWOther;
        this.other.claimTypeCode = this.constantsService.ClaimTypeCodeTOWOther;
        this.other.parentCategory = this.constantsService.Mechanical;

        //miscRV
        this.miscAnimals.category = ClaimTypeCategoryEnum.MiscRV;
        this.miscAnimals.selectionType = SelectionType.Final;
        this.miscAnimals.claimTypeCode = this.constantsService.ClaimTypeCodeRVAnimalsNF;
        this.miscAnimals.name = this.constantsService.MiscRVAnimal;
        this.miscAnimals.parentCategory = this.constantsService.MiscRVDamages;

        this.miscWater.category = ClaimTypeCategoryEnum.MiscRV;
        this.miscWater.selectionType = SelectionType.Final;
        this.miscWater.claimTypeCode = this.constantsService.ClaimTypeCodeRVWater;
        this.miscWater.name = this.constantsService.MiscRVWater;
        this.miscWater.parentCategory = this.constantsService.MiscRVDamages;

        this.miscElectrical.category = ClaimTypeCategoryEnum.MiscRV;
        this.miscElectrical.selectionType = SelectionType.Final;
        this.miscElectrical.claimTypeCode = this.constantsService.ClaimTypeCodeRVElectricalNF;
        this.miscElectrical.name = this.constantsService.MiscRVElectrical;
        this.miscElectrical.parentCategory = this.constantsService.MiscRVDamages;

        this.miscOther.category = ClaimTypeCategoryEnum.MiscRV;
        this.miscOther.selectionType = SelectionType.Final;
        this.miscOther.claimTypeCode = this.constantsService.ClaimTypeCodeRVOtherNF;
        this.miscOther.name = this.constantsService.MiscRVOther;
        this.miscOther.parentCategory = this.constantsService.MiscRVDamages;

    }

    private getSelected(): ClaimType {
        let selected = this.allSelections.find(x => x.isSelected);
        if (this.firstSelectionMade) {
            if (this.isCollision || this.isTheftVandalism) {
                selected = this.topLevelSelections.slice().reverse().find(x => x.isSelected);
            } else {
                if (this.isMultipleVehicle) {
                    selected = this.multiVehicleCategorySelections.find(x => x.isSelected);
                    if (selected === undefined) {
                        selected = this.allSelections.slice().reverse().find(x => x.isSelected);
                    }
                } else {
                     selected = this.allSelections.slice().reverse().find(x => x.isSelected);
                }
            }
        }
        return selected;
    }

    private getRoadsidePhoneNumber(): string {
        let phoneNumber: string = this.contactService.GetRoadSidePhoneNumber();

        let brand: string = this.session.getBrand();

        switch (brand) {
            case BrandEnum.NationalGeneral:
                phoneNumber = this.getRoadSidePhoneNumberByPolicySubtype();
                break;
        }

        return phoneNumber;
    }

    private getRoadSidePhoneNumberByPolicySubtype(): string {
        let phoneNumber: string;

        switch (this.policySubType) {
            case this.constantsService.Preferred:
            case this.constantsService.Premier:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Premier; // Preferred and Premier have the same number.
                break;
            case this.constantsService.PEIC:
                phoneNumber = this.getRoadsidePhoneNumberByMCOName();
                break;
            default:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Default;
                break;
        }

        return phoneNumber;
    }

    private getRoadsidePhoneNumberByMCOName(): string {
        let phoneNumber: string;

        switch (this.policyMCOName) {
            case this.constantsService.PolicyMCOName.NatGenPrimerIns:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Premier;
                break;
            default:
                phoneNumber = this.constantsService.RoadsidePhoneNumbers.Default;
                break;
        }

        return phoneNumber;
    }

    private isPreferredPolicySubType(): boolean {
        let brand: string = this.session.getBrand();

        if (brand != BrandEnum.NationalGeneral) {
            return false;
        }

        let isPreferred: boolean = false;

        switch (this.policySubType) {
            case this.constantsService.Premier:
            case this.constantsService.Preferred:
            case this.constantsService.NFUFarmAuto:
            case this.constantsService.StandardMutual:
            case this.constantsService.PEIC:
            case this.constantsService.Century:
                isPreferred = true;
                break;
            default:
                isPreferred = false;
                break;
        }

        return isPreferred;
    }
}

class ClaimType {
    category: ClaimTypeCategoryEnum;
    isSelected: boolean = false;
    selectionType: SelectionType;
    name: string;
    claimTypeCode: string = "";
    subCategoryName: string = "";
    parentCategory: string = "";
    helpText: string = "";
}

enum SelectionType {
    Initial,
    Secondary,
    Final
}
